import { RiEditLine } from '@remixicon/react';
import { Checkbox, Result, Spin } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetContextNamesQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { usePagination, useTypedTranslation } from 'shared/hooks';
import { DropdownLink, WorkTable } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { DeleteContextNames } from '../DeleteContextNames';

interface ContextNamesTableProps {
	search?: string | null;
	selectable?: boolean;
	multipleSelect?: boolean;
	onClick?: (id: string) => void;
	onSelect?: (ids: string[]) => void;
}

const ContextNamesTableComponent = forwardRef((
	{
		search,
		selectable,
		multipleSelect,
		onClick,
		onSelect,
	}: ContextNamesTableProps,
	ref,
) => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const { pagination, asTablePagination } = usePagination();

	const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);

	useImperativeHandle(ref, () => ({
		setSelection: (ids: string[]) => setSelectedRowKeys(ids),
		clearSelection: () => setSelectedRowKeys([]),
	}));

	const {
		data,
		error,
		isLoading,
		isFetching,
	} = useGetContextNamesQuery({
		sortBy: 'contextDisplayName',
		sortType: 'asc',
		limit: pagination?.pageSize,
		page: pagination?.current,
		search: search,
	});

	const contextNamesList = data?.data;

	const columns = useMemo(() => [
		{
			title: t((l) => l.compatibilities.item.leftEntityName),
			dataIndex: 'leftEntityName',
			key: 'leftEntityName',
		},
		{
			title: t((l) => l.compatibilities.item.contextDisplayName),
			dataIndex: 'contextDisplayName',
			key: 'contextDisplayName',
		},
		{
			title: t((l) => l.compatibilities.item.rightEntityName),
			dataIndex: 'rightEntityName',
			key: 'rightEntityName',
		},
		{
			title: t((l) => l.compatibilities.item.inverseContextDisplayName),
			dataIndex: 'inverseContextDisplayName',
			key: 'inverseContextDisplayName',
		},
	], [ t ]);

	const dataSource = useMemo(() => {
		return contextNamesList?.map((item) => ({
			id: item.id,
			key: item.id,
			contextDisplayName: item.contextDisplayName,
			rightEntityName: item.rightEntityName,
			leftEntityName: item.leftEntityName,
			inverseContextDisplayName: item.inverseContextDisplayName,
			menu: (
				<ItemActions
					items={[
						{
							key: 'edit',
							label: (
								<DropdownLink
									icon={<RiEditLine size={16}/>}
									title="Редактировать"
									onClick={() =>
										navigate(`/${routes.metadata.main}/${routes.metadata.compatibilities}/${item?.id}`)
									}
								/>
							),
						},
						{
							key: 'delete',
							label: (
								<DeleteContextNames isDropdown
													itemId={[ item?.id ]}
								/>
							),
						},

					]}
				/>
			),
		}));
	}, [ contextNamesList ]);

	const rowSelection: TableRowSelection = {
		type: multipleSelect ? 'checkbox' : 'radio',
		selectedRowKeys: selectedRowKeys,
		onChange: setSelectedRowKeys,
		renderCell: (checked, record) => {
			return (
				<div onClick={e => e.stopPropagation()}>
					<Checkbox checked={checked}
							  onChange={() => {
								  if (multipleSelect) {
									  setSelectedRowKeys(prev => checked
										  ? prev.filter((key) => key !== record.key)
										  : [ ...prev, record.key ]);
								  } else {
									  setSelectedRowKeys(checked ? [] : [ record.key ]);
								  }
							  }}/>
				</div>
			);
		},
	};

	useEffect(() => {
		onSelect?.(selectedRowKeys);
	}, [ selectedRowKeys ]);

	if (isLoading) {
		return <Spin size="large"/>;
	}

	if (error) {
		return <Result status="error"
					   title="Ошибка!"
					   subTitle={JSON.stringify(error)}
		/>;
	}

	return (
		<WorkTable loading={isFetching}
				   columns={columns}
				   dataSource={dataSource}
				   rowSelection={selectable ? rowSelection : null}
				   {...asTablePagination(data?.meta?.total)}
				   onRow={(r) => ({
					   onClick: () => onClick?.(r.id),
				   })}
				   scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
		/>
	);
});

const ContextNamesTable = memo(ContextNamesTableComponent);

export {
	ContextNamesTable,
};