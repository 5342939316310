import { RiCloseLine, RiFolderAddLine } from '@remixicon/react';
import { App, Flex, Spin, Typography, Upload, Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useUploadFileMutation } from '../../api/generatedApi/mdmgApi';
import { errorHelper } from '../../helpers';
import { colors } from '../../styles';
import { FormInputProps } from '../../types';
import { FilePreview } from '../../ui';

type IFileInputProps = FormInputProps<IFileValue | string | null>;

interface IFileValue {
	id: string,
	displayName: string,
	contentType: string,
	size: number,
	presignedUrl: string,
	createdAt: string,
	updatedAt: string,
}

// Функция конвертации файла в Base64
const getBase64: (any) => Promise<string> = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve((reader.result as string).split(',')[1]);
		reader.onerror = (error) => reject(error);
	});

const FileInputUi: FC<IFileInputProps> = ({
											  value,
											  onChange,
										  }) => {
	const { notification } = App.useApp();

	const [ uploadFile, { isLoading } ] = useUploadFileMutation();

	const [ file, setFile ] = useState<IFileValue | null>(null);

	useEffect(() => {
		if (!value) {
			setFile(null);
			return;
		}

		if (typeof value === 'string') {
			//	...
		} else if (typeof value === 'object') {
			setFile(value);
		}
	}, [ value ]);

	const handleUpload = async ({ file }) => {
		try {
			const result = await uploadFile({
				uploadFileFrontRequest: {
					displayName: file.name,
					contentType: file.type,
					size: file.size,
					contentBase64: await getBase64(file),
				},
			})
				.unwrap();

			onChange && onChange(result.id);
			setFile({
				id: result.id,
				displayName: file.name,
				contentType: file.type,
				size: file.size,
				presignedUrl: null,
				createdAt: null,
				updatedAt: null,
			});
		} catch (error) {
			errorHelper(
				'Ошибка загрузки вложения',
				error,
				notification,
			);
		}
	};

	const handleCancel = async (e) => {
		e.stopPropagation();

		onChange && onChange(null);
	};

	if (file) {
		return (
			<Flex gap={8} align={'center'}>
				<FilePreview file={file} />

				<Button type="link"
						onClick={handleCancel}
				>
					<RiCloseLine size={16}
								 color={colors.grayIcon}
					/>
				</Button>

			</Flex>
		);
	}

	return (
		<Upload.Dragger customRequest={handleUpload}>
			<Spin spinning={isLoading}>
				<Flex align="center" justify="center" gap={4}>
					<RiFolderAddLine/>
					<Typography.Title level={3} style={{ color: colors.primary }}>
						Добавить файл
					</Typography.Title>
				</Flex>
			</Spin>
		</Upload.Dragger>
	);
};

const FileInput = React.memo(FileInputUi);

export {
	FileInput,
};
