import { RiDeleteBinLine } from '@remixicon/react';
import { App } from 'antd';
import React from 'react';
import { useDeleteConfigurationMutation } from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface IDeleteExchangeConfigProps {
	integrationId: string;
	exchangeConfigIds: Array<string>;
	isButton?: boolean;
	disabled?: boolean;
	fromConfigPage?: boolean;
	onDelete?: () => void;
}

const DeleteExchangeConfig = React.memo((
	{
		integrationId,
		exchangeConfigIds,
		isButton,
		onDelete,
	}: IDeleteExchangeConfigProps) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const [ deleteConfig, { isLoading } ] = useDeleteConfigurationMutation();

	const handleDelete = async () => {
		try {
			await deleteConfig({
				connectionId: integrationId,
				configurationIdList: exchangeConfigIds.join(','),
			}).unwrap();

			onDelete && onDelete();
		} catch (e) {
			errorHelper(t(l => l.integrations.exchangeConfig.deleteError), e, notification);
		}
	};

	return (
		<ButtonVsDropdownLink
			title={t((l) => l.common.buttons.delete)}
			callback={handleDelete}
			icon={<RiDeleteBinLine/>}
			type={!isButton ? 'dropdownLink' : null}
			isDisabled={!exchangeConfigIds.length}
			isLoading={isLoading}
		/>
	);
});

export {
	DeleteExchangeConfig,
};
