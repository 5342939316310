import { RiEditLine } from '@remixicon/react';
import { Flex, Typography } from 'antd';
import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { ClassifierResponse } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { isEmpty, truncateString } from 'shared/helpers';
import { DetailCard, DropdownLink, Hint } from 'shared/ui';
import { DeleteClassifier } from '../DeleteClassifier';

interface ClassifiersCardsListProps {
	items: ClassifierResponse[];
}

const ClassifiersCardsList = memo((
	{
		items,
	}: ClassifiersCardsListProps) => {
	const navigate = useNavigate();

	return (
		<>
			{items.map((classifier) => (
				<DetailCard
					link={`${classifier.id}/${routes.classifiers.records}`}
					tooltip={classifier.description && classifier.description}
					key={classifier.id}
					secondSection={
						<Flex vertical gap={4}>
							<Typography.Title level={3}>
								<Hint
									title={
										classifier?.displayName &&
										classifier?.displayName?.length > 25
											? `${classifier.displayName}`
											: ''
									}
								>
									{classifier?.displayName
										? truncateString(
											classifier?.displayName,
											25,
										)
										: '-'}
								</Hint>
							</Typography.Title>

							<Typography.Text>
								{`Связь: ${
									!isEmpty(
										classifier.linkClassifierAttributes,
									)
										? classifier.linkClassifierAttributes
											.map((item) => item.displayName)
											.join(', ')
										: '-'
								}`}
							</Typography.Text>
						</Flex>
					}
					dropdownButtons={[
						{
							key: `${classifier.id}-edit`,
							label: (
								<DropdownLink
									icon={<RiEditLine size={16}/>}
									onClick={() =>
										navigate(classifier.id)
									}
									title="Редактировать"
								/>
							),
						},
						{
							key: `${classifier.id}-delete`,
							label: <DeleteClassifier id={classifier?.id}/>,
						},
					]}
				/>
			))
			}
		</>
	);
});

export {
	ClassifiersCardsList,
};