import { PaginationProps } from 'antd';
import { TableProps } from 'antd/es/table/InternalTable';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { changeSearchParams, tablePaginationShowTotalFactory } from '../helpers';
import { useTypedTranslation } from './useTypedTranslation';

export const usePagination = () => {
	const { t } = useTypedTranslation();

	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ pagination, setPagination ] = useState<{ current: number, pageSize: number } | undefined>();

	const handlePaginationChange = (newPagination: { current?: number, pageSize?: number }) =>
		setSearchParams(changeSearchParams(searchParams, {
			page: newPagination.current,
			pageSize: newPagination.pageSize,
		}));

	const toTablePaginationProps = (
		total: number
	): PaginationProps => {
		return {
			...pagination,
			total: total,
			pageSizeOptions: [ 20, 50, 100, 200 ],
			showTotal: tablePaginationShowTotalFactory(t),
			showSizeChanger: true,
		};
	};

	const page = searchParams.get('page');
	const pageSize = searchParams.get('pageSize');
	useEffect(() => {
		setPagination({
			current: Number(searchParams.get('page') ?? 1),
			pageSize: Number(searchParams.get('pageSize') ?? 20),
		});
	}, [ page, pageSize ]);

	return {
		pagination,
		asTablePagination: (total: number): Pick<TableProps<any>, 'pagination' | 'onChange'> => ({
			pagination: toTablePaginationProps(total),
			onChange: handlePaginationChange
		})
	};
};
