import { Flex, Result, Skeleton, Spin, Typography } from 'antd';
import React, { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { EditMeasureUnit, DeleteMeasureUnit } from 'features/metadata/Measures';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { RealtimeBaseEvent, TaskType, useRealtimeEvent } from 'entities/events';
import {
	mdmgApi,
	useGetMeasurementGroupQuery,
	useGetMeasurement1Query,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/newCreateApiFile';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

const DetailMeasureUnitPage = memo(() => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const { metaMeasureGroupId, metaMeasureUnitId } = useParams();

	const {
		data: currentMeasureGroup,
		isLoading: loadingMeasureGroup,
		isFetching: fetchingMeasureGroup,
		error: errorMeasureGroup,
	} = useGetMeasurementGroupQuery({
		id: metaMeasureGroupId,
	});

	const {
		data: currentMeasureUnit,
		isLoading: loading,
		isFetching: fetchingMeasureUnit,
		error,
	} = useGetMeasurement1Query({
		id: metaMeasureUnitId,
	});

	useRealtimeEvent(TaskType.UPDATE_MEASUREMENT_BACKGROUND_TASK, (e: RealtimeBaseEvent) => {
		if ((e.payload.entityIds as string[]).includes(metaMeasureUnitId)) {
			dispatch(mdmgApi.util.invalidateTags([ 'MeasureUnits' ]));
			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		}
	}, [ metaMeasureUnitId ]);

	const customCallback = () =>
		navigate(`/${routes.metadata.main}/${routes.metadata.measures}/${metaMeasureGroupId}`);

	useEffect(() => {
		if (currentMeasureUnit && currentMeasureGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.breadcrumbs.metadata),
					url: `${routes.metadata.main}/${routes.metadata.measures}`,
					child: {
						displayName: `${currentMeasureGroup.displayName}`,
						url: `${routes.metadata.main}/${routes.metadata.measures}/${currentMeasureGroup.id}`,
						child: {
							displayName: t((l) => l.measures.unit.cardTitle),
							url: window.location.pathname,
						},
					},
				}),
			);
		}
	}, [ currentMeasureGroup, currentMeasureUnit ]);

	if (loadingMeasureGroup || loading) {
		return (
			<Skeleton paragraph={{ rows: 3 }} active/>
		);
	}

	if (error || errorMeasureGroup) {
		return (
			<Result
				title={`${t((l) => l.common.statuses.error)}!`}
				subTitle={JSON.stringify(error)}
			/>
		);
	}

	return (
		<Spin spinning={fetchingMeasureGroup || fetchingMeasureUnit}>
			<Flex vertical gap={24}>
				<Flex justify="space-between">
					<Typography.Title level={1}>
						{t((l) => l.measures.unit.cardTitle)}
					</Typography.Title>

					<Transactions/>
				</Flex>

				<EditMeasureUnit unit={currentMeasureUnit}
								 currentMeasureGroup={currentMeasureGroup}
				/>

				<DetailFooter customHandleBack={customCallback}>
					<DeleteMeasureUnit unitId={[ currentMeasureUnit?.id ]}
									   detailUnitPage
					/>
				</DetailFooter>
			</Flex>
		</Spin>
	);
});

export {
	DetailMeasureUnitPage,
};
