import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';

const CreateAttribute = memo(() => {
	const navigate = useNavigate();

	const onClick = () => {
		navigate(`${window.location.pathname}/${routes.metadata.attribute}/${routes.metadata.create}`);
	};

	return (
		<Button icon={<RiAddCircleLine/>}
				style={{ maxWidth: 'fit-content' }}
				onClick={onClick}
		>
			Добавить атрибут
		</Button>
	);
});

export {
	CreateAttribute,
};