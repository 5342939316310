import { Spin } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ClassifierItemsTable } from 'features/classifiers';
import { useGetClassifierAttributeDeclarationsQuery } from 'shared/api/generatedApi/mdmgApi';
import { ExtendedFilters } from 'shared/components/Filters';
import { routes } from 'shared/config';

interface ClassifierItemsTableProps {
	classifierId: string;
	classifierItemId: string | null;
}

const ClassifierItemsTableWidget = memo((
	{
		classifierId,
		classifierItemId,
	}: ClassifierItemsTableProps) => {
	const navigate = useNavigate();

	const [ searchParams ] = useSearchParams();

	const [ filters, setFilters ] = useState<any>(null);
	const searchValue = searchParams.get('searchValue');

	const searchDetails = useMemo(() => {
		if (filters == null) {
			return null;
		}
		return JSON.stringify(filters);
	}, [ filters ]);

	const {
		data: classifierAttributeDeclarations,
		isFetching: isFetchingDeclarations,
	} = useGetClassifierAttributeDeclarationsQuery({
		classifierId,
	});

	const filtersDeclarations = useMemo(() => {
		if (!classifierAttributeDeclarations) {
			return [];
		}
		return classifierAttributeDeclarations.map(x => ({
			key: x.id,
			title: x.attribute.displayName,
		}));
	}, [ classifierAttributeDeclarations ]);

	return (
		<>
			<Spin spinning={isFetchingDeclarations}>
				<ExtendedFilters filters={filtersDeclarations}
								 onChange={setFilters}
				/>
			</Spin>

			<ClassifierItemsTable classifierId={classifierId}
								  classifierItemId={classifierItemId}
								  searchSubstring={searchValue}
								  searchDetails={searchDetails}
								  onClick={(id) =>
									  navigate(
										  `/${routes.classifiers.main}/${classifierId}/${routes.classifiers.records}/${id}/${routes.classifiers.edit}`,
									  )}
			/>
		</>
	);
});

export {
	ClassifierItemsTableWidget,
};
