import { RiInformationLine } from '@remixicon/react';
import { App, Checkbox, Flex, Form, Input, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TaskType, useAsyncOperation } from 'entities/events';
import { Measure } from 'entities/metadata/measures';
import {
	MeasurementResponse,
	useUpdateMeasurementAsyncMutation,
	useUpdateMeasurementGroupMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Hint } from 'shared/ui';

interface IEditMeasureUnitProps {
	unit: MeasurementResponse;
	currentMeasureGroup: Measure;
}

const EditMeasureUnit = memo(({ unit, currentMeasureGroup }: IEditMeasureUnitProps) => {
	const { t } = useTypedTranslation();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const [ form ] = Form.useForm();

	const [ isLoading, setIsLoading ] = useState(false);

	const isBasicUnit = unit.id === currentMeasureGroup.baseMeasurement?.id;

	const { execute: editUnits } = useAsyncOperation(
		useUpdateMeasurementAsyncMutation,
		TaskType.UPDATE_MEASUREMENT_BACKGROUND_TASK,
	);
	const [ updateMeasurementGroup ] = useUpdateMeasurementGroupMutation();

	const deTouchFields = () => {
		const fields = Object
			.entries(form.getFieldsValue())
			.map(([ name, value ]) => ({
				name,
				value,
				touched: false,
				errors: [],
			}));

		form.setFields(fields);
	};

	const editBasic = async (e: CheckboxChangeEvent) => {
		if (currentMeasureGroup) {
			updateMeasurementGroup({
				id: currentMeasureGroup.id,
				updateMeasurementGroupRequest: {
					displayName: currentMeasureGroup?.displayName,
					description: currentMeasureGroup?.description,
					baseMeasurementId: e.target.checked ? unit.id : null,
				},
			});
		}
	};

	const editMeasureUnit = useDebouncedCallback(useCallback(async () => {
		try {
			setIsLoading(true);

			if (!form.isFieldsTouched()) {
				return;
			}

			const data = form.getFieldsValue();
			deTouchFields();

			await editUnits({
				measurementGroupId: unit.measurementGroupId,
				id: unit.id,
				updateMeasurementRequest: data,
			});

			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		} catch (error) {
			resetForm();
			errorHelper(
				t((l) => l.measures.error.editingMeasurementUnit),
				error,
				notification,
			);
		} finally {
			setIsLoading(false);
		}
	}, [ unit, form, t ]), 150);

	const resetForm = () => {
		form.setFieldsValue({
			displayName: unit?.displayName,
			isBasic: isBasicUnit,
			shortName: unit?.shortName,
			coefficient: unit?.coefficient,
			formula: isBasicUnit ? '—' : unit?.formula,
			inverseFormula: isBasicUnit ? '—' : unit?.inverseFormula,
		});
	};

	useEffect(() => {
		resetForm();
	}, [ unit ]);

	return (
		<Spin spinning={isLoading}>
			<Flex vertical gap={12} style={{ maxWidth: 920, marginBottom: 65 }}>
				<Typography.Title level={2}>
					{t((l) => l.measures.mainInfo)}
				</Typography.Title>
				<Form layout="vertical" form={form}>
					<Flex align="center" style={{ marginBottom: 12 }}>
						<Form.Item
							valuePropName="checked"
							name="isBasic"
							style={{ display: 'flex', alignItems: 'center', margin: 0 }}
						>
							<Checkbox onChange={editBasic} checked={isBasicUnit}>
								{t((l) => l.measures.unit.basicUnit)}
							</Checkbox>
						</Form.Item>
						<Hint placement="right" title={t((l) => l.measures.hint.baseUnit)}>
							<RiInformationLine size={16} color={colors.grayIcon}/>
						</Hint>
					</Flex>

					<Form.Item label={t((l) => l.measures.unit.displayName)} name="displayName">
						<Input allowClear onBlur={editMeasureUnit}/>
					</Form.Item>

					<Form.Item label={t((l) => l.measures.unit.shortName)} name="shortName">
						<Input allowClear onBlur={editMeasureUnit}/>
					</Form.Item>
					<Form.Item label={t((l) => l.measures.unit.coefficient)} name="coefficient">
						<Input
							allowClear
							onBlur={editMeasureUnit}
							disabled={isBasicUnit || !!unit?.inverseFormula || !!unit?.formula}
							suffix={
								<Hint title={t((l) => l.measures.unit.coefficient)}>
									<RiInformationLine size={16} color={colors.grayIcon}/>
								</Hint>
							}
						/>
					</Form.Item>
					{/* Закомментил пока не добавят формулы*/}
					{/* <Form.Item label={t(l=>l.measures.unit.formula)} name="formula">
				<Input
					allowClear
					disabled={unit?.isDefault || !!unit?.coefficient}
					onBlur={editInput}
					suffix={
						<Hint title={t(l=>l.measures.unit.formula)}>
							<RiInformationLine size={16} color={colors.grayIcon} />
						</Hint>
					}
				/>
			</Form.Item> */}

					{/* <Form.Item label={t(l=>l.measures.unit.inverseFormula)} name="inverseFormula">
				<Input
					allowClear
					onBlur={editInput}
					disabled={unit?.isDefault || !!unit?.coefficient}
					suffix={
						<Hint title={t(l=>l.measures.unit.inverseFormula)}>
							<RiInformationLine size={16} color={colors.grayIcon} />
						</Hint>
					}
				/>
			</Form.Item> */}
				</Form>
			</Flex>
		</Spin>
	);
});

export {
	EditMeasureUnit,
};
