import { Flex, Result, Skeleton, Typography } from 'antd';
import React, { useEffect } from 'react';
import { AddConstraintTable } from 'features/metadata/ConstraintTables';
import {
	SearchConstraintTables,
	useSearchConstraintTables,
} from 'features/metadata/ConstraintTables/searchConstraintTables';
import { useConstraintTables } from 'entities/metadata/constraintTables/constraintTables.model';
import { useAppSelector, useTypedTranslation } from 'shared/hooks';
import { Placeholder } from 'shared/ui';
import { ConstraintTableCard } from './components/ConstraintTableCard';

export const ConstraintTables: React.FC = () => {
	const { t } = useTypedTranslation();
	const { constraintTables, getConstraintTablesData, loading, error } = useConstraintTables();
	const { isLoading: isSearchLoading, searchValue } = useSearchConstraintTables();

	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	useEffect(() => {
		getConstraintTablesData();
	}, [transactionRollback]);

	if (error) {
		return <Result title={t((l) => l.common.error.title)} />;
	}

	return (
		<>
			<Flex vertical gap={12}>
				<SearchConstraintTables />

				<Typography.Paragraph>
					<AddConstraintTable />
				</Typography.Paragraph>

				<Typography.Title level={2}>
					{t((l) => l.constraintTables.listTitle)}
				</Typography.Title>
				<Flex gap={12} wrap="wrap">
					{loading || isSearchLoading ? (
						<>
							<Skeleton active paragraph={{ rows: 1 }} style={{ maxWidth: 325 }} />
							<Skeleton active paragraph={{ rows: 1 }} style={{ maxWidth: 325 }} />
							<Skeleton active paragraph={{ rows: 1 }} style={{ maxWidth: 325 }} />
						</>
					) : constraintTables && constraintTables.length > 0 ? (
						constraintTables.map((table, i) => (
							<ConstraintTableCard
								key={i}
								displayName={table.displayName}
								rowsCount={table.itemCount}
								tableId={table.id}
								tooltip={table.description && table.description}
							/>
						))
					) : (
						<Flex justify="center" style={{ width: '100%' }}>
							<Placeholder
								title={
									searchValue
										? t((l) => l.common.search.nothingFound)
										: t((l) => l.constraintTables.noTables)
								}
								isSearchIcon={!!searchValue}
								subTitle={searchValue && t((l) => l.common.search.checkRequest)}
							/>
						</Flex>
					)}
				</Flex>
			</Flex>
		</>
	);
};
