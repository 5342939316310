import { Button } from 'antd';
import React, { useRef } from 'react';
import { useTypedTranslation } from '../../hooks';
import { DateRangeFilter as DateRangeFilterComponent } from './DateRangeFilter';
import s from './Filters.module.scss';
import { ListOptionsFilter as ListOptionsFilterComponent } from './ListOptionsFilter';
import { DateRangeFilter, Filter, ListOptionsFilter } from './models';

interface FilterItemProps {
	filter: Filter;
	onChange: (any) => void;
}

const FilterItem = (
	{
		filter,
		onChange
	}: FilterItemProps,
) => {
	const { t } = useTypedTranslation();

	const filterControlRef = useRef<any>();

	return (
		<div className={s.dropdownContainer}>
			{(filter instanceof ListOptionsFilter) && (
				<ListOptionsFilterComponent ref={filterControlRef}
											options={filter.options}
				/>
			)}
			{(filter instanceof DateRangeFilter) && (
				<DateRangeFilterComponent ref={filterControlRef}/>
			)}
			<div className={s.buttonsContainer}>
				<Button type="text"
						onClick={() => filterControlRef.current.reset()}
				>
					{t((l) => l.common.filters.clearFilter)}
				</Button>
				<Button type="primary"
						onClick={() => onChange(filterControlRef.current.getValues())}
				>
					{t((l) => l.common.buttons.apply)}
				</Button>
			</div>
		</div>
	);
};

export {
	FilterItem,
};