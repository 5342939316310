import { App, TreeSelectProps } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useLazyGetCatalogsQuery } from '../../api/generatedApi/mdmgApi';
import { errorHelper } from '../../helpers';
import { useTypedTranslation } from '../../hooks';
import { FormInputProps } from '../../types';
import { DropdownTreeSelect } from '../../ui';

interface SelectCatalogInputProps extends FormInputProps<string | string[] | null> {
	multiple?: boolean;
}

const SelectCatalogInputBase = memo((
	{
		value,
		onChange,
		multiple,
	}: SelectCatalogInputProps) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const [ items, setItems ] = useState<any[]>([]);

	const [ getCatalogArr, { isFetching, error } ] = useLazyGetCatalogsQuery();

	const getCatalogs = async (parentId?: string) => {
		try {
			const res = await getCatalogArr({
				...(parentId ? { parentIds: [ parentId ] } : { root: true }),
			}).unwrap();

			setItems((prev) => {
				return [
					...prev,
					...res.map((c) => ({
						id: c.id,
						pId: c.parentId,
						value: c.id,
						title: c.displayName || t(l => l.common.defaultNames.emptyName),
						isLeaf: !c.parent,
					})),
				];
			});
		} catch (err) {
			errorHelper(
				t((l) => l.catalogs.groups.rootGroupErr),
				err,
				notification,
			);
		}
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => getCatalogs(id);

	useEffect(() => {
		getCatalogs().then();
	}, []);

	return (
		<DropdownTreeSelect
			treeDataSimpleMode
			loading={isFetching}
			value={value}
			loadData={onLoadData}
			onChange={onChange}
			errorText={
				error
					? 'Не удалось загрузить список справочников!'
					: ''
			}
			treeData={items}
			error={!!error}
			allowClear
			multiple={multiple}
		/>
	);
});

export {
	SelectCatalogInputBase,
};