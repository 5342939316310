import { Flex, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SuspectedDuplicates } from 'widgets/Catalogs/SuspectedDuplicates';
import { Transactions } from 'widgets/Transactions';
import { useDeleteDuplicates } from 'features/catalogs/deleteDuplicates';
import { useSetDuplicateItem } from 'features/catalogs/setDuplicateItem';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { AddCopyIcon, CancelCopyIcon, DetailFooter, setSubMenuCollapsed } from 'shared/ui';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

export const DuplicatesPage: React.FC = () => {
	const { t } = useTypedTranslation();

	const deletingSuspectedDuplicatesIds = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.deletingSuspectedDuplicatesIds
	);

	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const [ids, setIds] = useState<Array<string>>([]);

	const color = ids?.length ? colors.primary : colors.disabled;

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { catalogGroupId, deduplicationOptionId } = useParams();

	const { deleteDuplicates, isLoading: isDeleting } = useDeleteDuplicates();

	const { setDuplicateItem, isLoading: isSetting } = useSetDuplicateItem();

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/${catalogGroupId}/edit?tab=Deduplication`, {
			state: { deduplicationOptionId },
		});
	};

	useEffect(() => {
		dispatch(setSubMenuCollapsed(true));
	}, []);

	useEffect(() => {
		setIsDisabled(deletingSuspectedDuplicatesIds?.length ? false : true);
		setIds(deletingSuspectedDuplicatesIds);
	}, [deletingSuspectedDuplicatesIds]);

	return (
		<>
			<Flex vertical gap={12} style={{ marginBottom: 65 }}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{t(l => l.catalogs.deduplication.dupesSearchResult)}
						</Typography.Title>
					</Flex>

					<Transactions />
				</Flex>

				<SuspectedDuplicates />
			</Flex>

			<DetailFooter customHandleBack={handleBackClick}>
				<ButtonVsDropdownLink
					icon={<CancelCopyIcon size={16} color={color} />}
					title={t(l => l.catalogs.deduplication.deleteDupesForSelectedItems)}
					callback={() => deleteDuplicates(ids)}
					isLoading={isDeleting}
					isDisabled={isDisabled}
				/>

				<ButtonVsDropdownLink
					icon={<AddCopyIcon size={16} color={color} />}
					title={t(l => l.catalogs.deduplication.makeDupesForSelectedItems)}
					callback={() => setDuplicateItem(ids)}
					isLoading={isSetting}
					isDisabled={isDisabled}
				/>
			</DetailFooter>
		</>
	);
};
