import { TabsProps, Tabs, Flex, Typography, Result, Skeleton } from 'antd';
import React, { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
	IntegrationConnectionInfoWidget,
	ExchangeConfigsListWidget,
	ExchangeKeysTabWidget,
	JournalList,
} from 'widgets/integrations';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { useGetConnectionQuery } from 'shared/api/generatedApi/integrationsApi';
import { routes } from 'shared/config';
import { useAppDispatch, useEnumStateViaSearchParam, useTypedTranslation } from 'shared/hooks';

enum IntegrationDetailPageTab {
	ExchangeConfigs = 'ExchangeConfigs',
	ConnectionInfo = 'ConnectionInfo',
	Journal = 'Journal',
	ExchangeKeys = 'ExchangeKeys',
}

const tabSearchParamKey = 'tab';

const IntegrationDetailPage = memo(() => {
	const dispatch = useAppDispatch();
	const { t } = useTypedTranslation();

	const [ activeKey, setActiveKey ] = useEnumStateViaSearchParam(
		IntegrationDetailPageTab,
		tabSearchParamKey,
		IntegrationDetailPageTab.ConnectionInfo);

	const { integrationId } = useParams();

	const { data, isLoading, error } = useGetConnectionQuery({
		connectionId: integrationId,
	});

	const tabs: TabsProps['items'] = useMemo(
		() => [
			{
				key: IntegrationDetailPageTab.ExchangeConfigs,
				label: t(l => l.integrations.tabs.exchangeConfigs),
				children: <ExchangeConfigsListWidget integrationId={integrationId}/>,
			},
			{
				key: IntegrationDetailPageTab.ConnectionInfo,
				label: t(l => l.integrations.tabs.connectionInfo),
				children: <IntegrationConnectionInfoWidget integrationId={integrationId}/>,
			},
			{
				key: IntegrationDetailPageTab.Journal,
				label: t(l => l.integrations.tabs.journal),
				children: <JournalList/>,
			},
			{
				key: IntegrationDetailPageTab.ExchangeKeys,
				label: t(l => l.integrations.tabs.exchangeKeys),
				children: <ExchangeKeysTabWidget integrationId={integrationId}/>,
			},
		],
		[ data, t ],
	);

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: t(l => l.integrations.breadcrumbs.integrations),
				url: routes.integrations.main,
				child: {
					displayName: t(l => l.integrations.breadcrumbs.connectionCard),
					url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`,
				},
			}),
		);
	}, [ t ]);

	if (isLoading) {
		return (
			<Skeleton active/>
		);
	}

	if (error) {
		return (
			<Result title={t(l => l.common.error.title)} subTitle={JSON.stringify(error)}/>
		);
	}

	return (
		<Flex vertical gap={24}>
			<Typography.Title level={1}>{data?.connection_info?.name}</Typography.Title>
			<Tabs items={tabs}
				  style={{ marginBottom: 74 }}
				  onChange={key => setActiveKey(key as IntegrationDetailPageTab)}
				  activeKey={activeKey}
			/>
		</Flex>
	);
});

export {
	IntegrationDetailPage,
	tabSearchParamKey,
	IntegrationDetailPageTab,
};
