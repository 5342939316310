import { Flex, Typography } from 'antd';
import React from 'react';
import { AddCatalogRestrictionTable } from 'features/catalogs/AddCatalogRestrictionTable/AddCatalogRestrictionTable.ui';
import { EditCatalogRestrictionsTables } from 'features/catalogs/EditCatalogRestrictionsTables';
import { useTypedTranslation } from 'shared/hooks';

interface CatalogRestrictionsWidgetProps {
	catalogId: string;
}

const CatalogRestrictionsWidgetUi = ({ catalogId }: CatalogRestrictionsWidgetProps) => {
	const { t } = useTypedTranslation();

	return (
		<Flex vertical gap={24}>
			<Typography.Title level={1}>
				{t((l) => l.catalogs.restrictions.tabTitle)}
			</Typography.Title>
			<Flex>
				<AddCatalogRestrictionTable />
			</Flex>
			<EditCatalogRestrictionsTables catalogId={catalogId} />
		</Flex>
	);
};

export const CatalogRestrictionsWidget = React.memo(CatalogRestrictionsWidgetUi);
