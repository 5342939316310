import { RiCloseLine, RiFolderCheckLine } from '@remixicon/react';
import { Button, Flex, Modal } from 'antd';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { randomString } from '../../../helpers';
import { colors } from '../../../styles';
import { Chip } from '../Chip';
import s from './selectModal.module.scss';

interface ISelectModalValueType {
	label: string;
	callback: () => void;
	key?: React.Key;
}

interface ISelectModalProps {
	selectValue?: ISelectModalValueType;
	selectValues?: Array<ISelectModalValueType>;
	modalTitle: string;
	onCloseCallback?: () => void;
	onOkCallback: () => void;
	okDisabled?: boolean;
	modalWidth?: number;
	loading?: boolean;
}

const SelectModalUi: React.FC<PropsWithChildren<ISelectModalProps>> = ({
	selectValue,
	selectValues,
	modalTitle,
	onCloseCallback,
	onOkCallback,
	children,
	okDisabled,
	modalWidth,
	loading,
}) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleCloseModal = () => setIsModalOpen(false);

	const handleCancel = () => {
		if (onCloseCallback) onCloseCallback();
		handleCloseModal();
	};

	const handleOk = async () => {
		await onOkCallback();
		handleCloseModal();
	};

	const showModal = (e) => {
		if (e.currentTarget != e.target) return;
		setIsModalOpen(true);
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отменить
				</Button>

				<Button key="submit" type="primary" onClick={handleOk} disabled={okDisabled} loading={loading}>
					Подтвердить
				</Button>
			</Flex>
		),
		[okDisabled, onOkCallback, onCloseCallback, selectValues, selectValue, loading]
	);

	return (
		<>
			<div className={s.container}>
				<div className={s.input} onClick={showModal}>
					{selectValues &&
						selectValues.map((item) => (
							<Chip
								selectChip
								cancel={
									<RiCloseLine
										size={10}
										color={colors.grayIcon}
										className={s.icon}
										onClick={item.callback}
									/>
								}
								key={item.key ? item.key : randomString(4)}
							>
								{item.label}
							</Chip>
						))}
					{selectValue && (
						<Chip
							selectChip
							cancel={
								<RiCloseLine
									size={10}
									color={colors.grayIcon}
									className={s.icon}
									onClick={selectValue.callback}
								/>
							}
							key={selectValue.key ? selectValue.key : randomString(4)}
						>
							{selectValue.label}
						</Chip>
					)}
				</div>

				<Flex gap={4} align="center" className={s.controls}>
					<RiFolderCheckLine
						size={16}
						color={colors.grayIcon}
						onClick={showModal}
						className={s.icon}
					/>
				</Flex>
			</div>

			<Modal
				title={modalTitle}
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={!modalWidth ? 1288 : modalWidth}
				footer={footerButtons}
			>
				{children}
			</Modal>
		</>
	);
};

export const SelectModal = SelectModalUi;
