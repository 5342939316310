import { Button, Flex } from 'antd';
import React, { memo } from 'react';
import { useTypedTranslation } from 'shared/hooks';
import {
	filterButtonNames,
	FilterButtonType,
	useIntegrationsListFilter,
} from './useIntegrationsListFilter';

export const FilterButtons = memo(() => {
	const { t } = useTypedTranslation();
	const [ activeButton, setActiveButton ] = useIntegrationsListFilter();
	return (
		<Flex gap={12}>
			{Object
				.entries(filterButtonNames)
				.map(([ type, title ]) => (
					<Button key={type}
							name="all"
							type={activeButton === type ? 'primary' : 'default'}
							onClick={() => setActiveButton(type as FilterButtonType)}
					>
						{t(title)}
					</Button>
				))}
		</Flex>
	);
});