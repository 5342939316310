import { App } from 'antd';
import { useState } from 'react';
import { useDeleteCatalogItemMutation } from 'shared/api/generatedApi/mdmgApi';

import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';

export const useDeleteCatalogRecord = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { notification } = App.useApp();
	const { t } = useTypedTranslation();

	const [fetchDeleteCatalogRecord, { error }] = useDeleteCatalogItemMutation();

	const handleDeleteCatalogRecord = async (id: string) => {
		setLoading(true);

		try {
			await fetchDeleteCatalogRecord({
				ids: [id],
			}).unwrap();
		} catch (error) {
			errorHelper(
				t((l) => l.catalogs.deleteRecord.error),
				error,
				notification
			);
		} finally {
			setLoading(false);
		}
	};

	return {
		loading,
		error,
		handleDeleteCatalogRecord,
	};
};
