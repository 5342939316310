import { Checkbox, Spin } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useRecordsDtoToTableMapper } from 'entities/catalogs/catalogRecords';
import {
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogItemsQuery,
	useGetClassifierItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { useHandleQueryError, useTypedTranslation, useWorkTable } from 'shared/hooks';
import { WorkTable, WorkTableColumnType } from 'shared/ui/components/WorkTable';

interface CatalogItemsTableProps {
	catalogId: string;
	includeSubCatalogs: boolean;
	searchSubstring?: string | null;
	searchDetails?: any | null;
	selectable?: boolean;
	multipleSelect?: boolean;
	onClick?: (id: string) => void;
	onSelect?: (ids: string[]) => void;
}

const CatalogItemsTableComponent = forwardRef((
	{
		catalogId,
		includeSubCatalogs,
		searchSubstring,
		searchDetails,
		selectable,
		multipleSelect,
		onClick,
		onSelect,
	}: CatalogItemsTableProps,
	ref,
) => {
	const { t } = useTypedTranslation();

	const { mapRecordsDtoToTable } = useRecordsDtoToTableMapper();

	const tableState = useWorkTable();

	const [ catalogRecordsColumns, setCatalogRecordsColumns ] = useState<WorkTableColumnType[]>([]);
	const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);

	useImperativeHandle(ref, () => ({
		setSelection: (ids: string[]) => setSelectedRowKeys(ids),
		clearSelection: () => setSelectedRowKeys([]),
	}));

	const {
		data: catalogItems,
		error: catalogItemsError,
		isFetching: isCatalogItemsFetching,
		isLoading: isCatalogItemsLoading,
	} = useHandleQueryError(
		useGetCatalogItemsQuery({
			catalogIds: [ catalogId ],
			includeSubCatalogs: includeSubCatalogs,
			searchSubstring: searchSubstring,
			searchDetails: searchDetails,
			...tableState.toDto(),
		}, {
			skip: !tableState.isReady,
		}),
		(l) => l.catalogs.records.recordsListErr,
	);

	const {
		data: attributeDeclarations,
		isLoading: isDeclarationsLoading,
		isFetching: isDeclarationsFetching,
	} = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const relationItems = useMemo(() => {
		const result = { classifierItemIds: new Set<string>(), catalogItemIds: new Set<string>() };
		if (attributeDeclarations && catalogItems?.data) {
			const relationDeclarations = attributeDeclarations
				.filter(({ attribute }) => attribute.type === CellTypesEnum.RELATION);
			const relationToClassifier = relationDeclarations
				.filter(({ attribute }) => (attribute.restrictions.scopeIds as string[])?.length > 0);
			const relationToCatalog = relationDeclarations
				.filter(({ id }) =>
					relationToClassifier.findIndex(x => x.id === id) === -1,
				);

			return catalogItems.data.reduce((acc, item) => {
				relationToClassifier
					.map(({ id }) => item.values[id]?.value as unknown as string | null)
					.flat()
					.filter(x => !!x)
					.forEach(x => acc.classifierItemIds.add(x));

				relationToCatalog
					.map(({ id }) => item.values[id]?.value as unknown as string | null)
					.flat()
					.filter(x => !!x)
					.forEach(x => acc.catalogItemIds.add(x));
				return acc;
			}, result);
		} else {
			return result;
		}
	}, [ catalogItems?.data, attributeDeclarations ]);

	const { data: relationClassifierItems } = useGetClassifierItemsQuery({
		ids: Array.from(relationItems?.classifierItemIds),
	}, {
		skip: !relationItems?.classifierItemIds.size,
	});

	const { data: relationCatalogItems } = useGetCatalogItemsQuery({
		ids: Array.from(relationItems?.catalogItemIds),
	}, {
		skip: !relationItems?.catalogItemIds.size,
	});

	useEffect(() => {
		const baseColumns: WorkTableColumnType[] = [
			{
				title: t((l) => l.common.defaultNames.status),
				dataIndex: 'status',
				key: 'status',
				width: '175px',
				sorter: true,
			},
			{
				title: t((l) => l.common.defaultNames.name),
				dataIndex: 'displayName',
				key: 'displayName',
				sorter: true,
			},
		];

		setCatalogRecordsColumns([
				...baseColumns,
				...Array.from(
					attributeDeclarations?.reduce((acc, { id, attribute }) => {
						if (!acc.has(attribute.id)) {
							acc.set(attribute.id, {
								key: id,
								dataIndex: attribute.id,
								title: attribute.displayName,
								width: 'auto',
								sorter: true,
							});
						}
						return acc;
					}, new Map<string, WorkTableColumnType>())
						.values() ?? [],
				),
			],
		);
	}, [ attributeDeclarations ]);

	const catalogRecordsTableRows = useMemo(() => {
		if (!attributeDeclarations) {
			return [];
		}

		if (catalogItemsError) {
			return [];
		}

		if (catalogItems) {
			return mapRecordsDtoToTable(
				catalogItems.data,
				attributeDeclarations,
				relationClassifierItems?.data,
				relationCatalogItems?.data,
			);
		}

		return [];
	}, [ catalogItemsError, catalogItems, attributeDeclarations, relationClassifierItems?.data, relationCatalogItems?.data ]);

	const catalogRecordLoading = isCatalogItemsLoading || isDeclarationsLoading;

	const rowSelection: TableRowSelection = {
		type: multipleSelect ? 'checkbox' : 'radio',
		selectedRowKeys: selectedRowKeys,
		onChange: setSelectedRowKeys,
		renderCell: (checked, record) => {
			return (
				<div onClick={e => e.stopPropagation()}>
					<Checkbox checked={checked}
							  onChange={() => {
								  if (multipleSelect) {
									  setSelectedRowKeys(prev => checked
										  ? prev.filter((key) => key !== record.key)
										  : [ ...prev, record.key ]);
								  } else {
									  setSelectedRowKeys(checked ? [] : [ record.key ]);
								  }
							  }}/>
				</div>
			);
		},
	};

	useEffect(() => {
		onSelect?.(selectedRowKeys);
	}, [ selectedRowKeys ]);

	if (catalogRecordLoading) {
		return <Spin size="large"/>;
	}

	return (
		<WorkTable columns={catalogRecordsColumns}
				   dataSource={catalogRecordsTableRows}
				   loading={isCatalogItemsFetching || isDeclarationsFetching}
				   rowSelection={selectable ? rowSelection : null}
				   onRow={(r) => ({
					   onClick: () => onClick?.(r.id),
				   })}
				   {...tableState.toWorkTableProps(catalogItems?.meta?.total)}
		/>
	);
});

const CatalogItemsTable = memo(CatalogItemsTableComponent);

export {
	CatalogItemsTable,
};