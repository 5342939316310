import { RiArrowRightCircleLine, RiCheckboxCircleFill } from '@remixicon/react';
import { Flex, List, Result, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { TaskType, useRealtimeEvent } from 'entities/events';
import {
	mdmgApi,
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogQuery, useGetCatalogsQuery,
	useGetMeasurementQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { translateType } from 'shared/helpers/CellTypeParser';
import { declarationRestrictionParser } from 'shared/helpers/declarationRestrictionParser';
import { useAppDispatch, useHandleQueryError } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink, Placeholder } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { EditCatalogMainInfo } from '../../editCatalogMainInfo';
import { DeleteAttributeDeclaration } from '../DeleteAttribiteDeclaration';
import { SetupAttributeDeclaration } from '../SetupAttributeDeclaration';

const EditAttributeDeclarationListUi: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { catalogGroupId } = useParams();	//	todo: move in params

	const [descriptions, setDescriptions] = useState<{ [key: string]: React.ReactNode }>(null);

	const { data: currentCatalog } = useHandleQueryError(
		useGetCatalogQuery({
			id: catalogGroupId,
		}),
		(l) => l.catalogs.groups.currCatalogErr,
	);

	const { data: attributes, isLoading: loading, error: error } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogGroupId,
		}),
		l => l.catalogs.attributesDeclarations.attributesDeclarationsErr,
	);

	const relatedCatalogIds = attributes
		?.map(x => [...(x?.restrictions?.scopeIds as string[] | null) ?? []])
		.flat();

	const { data: catalogs } = useGetCatalogsQuery({
		ids: relatedCatalogIds,
	}, {
		skip: !(relatedCatalogIds?.length > 0),
	});

	const measurementsIds = useMemo(
		() => new Set(attributes?.map((x => x.restrictions.measurementId as unknown as string)).filter(x => x != null)
			?? []), [attributes]);

	const { data: measurements, isLoading: loadingMeasurements, } = useGetMeasurementQuery({
		ids: Array.from(measurementsIds)
	}, {
		skip: !(measurementsIds.size > 0),
	});

	const handler = useCallback(() => {
		dispatch(mdmgApi.util.invalidateTags(['CatalogAttributeDeclarations']));
		dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
	}, []);

	useRealtimeEvent(TaskType.CREATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK, handler);
	useRealtimeEvent(TaskType.UPDATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK, handler);
	useRealtimeEvent(TaskType.DELETE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK, handler);

	useEffect(() => {
		if (!attributes) {
			return;
		}
		const newDescriptions = Object.fromEntries(
			attributes?.map((item) => [
				item.id,
				<>
					<span>
						Тип данных:
						<span style={{ color: colors.text }}>
							{` ${translateType(item?.attribute?.type)}`}
						</span>
					</span>
					{Object
						.entries(item?.restrictions)
						.filter(([ , value]) => value != null)
						.map(([key, value]) => {
							let restriction = value as unknown as string;
							if (measurements && key == 'measurementId') {
								const measurementId = item.restrictions.measurementId as unknown as string;
								restriction = measurements.find(x => x.id === measurementId)?.displayName;
							}
							return declarationRestrictionParser(
								item?.attribute?.type,
								key,
								restriction,
								catalogs,
							);
						})
					}
				</>,
			]),
		);
		setDescriptions(newDescriptions);
	}, [catalogs, attributes, measurements]);

	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;
	}

	if (loading || loadingMeasurements) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Spin size="large" />
			</div>
		);
	}

	if (!attributes.length) {
		return (
			<Placeholder
				title="Пока не создано ни одного атрибута"
				subTitle="Для добавления нового атрибута нажмите на кнопку «Добавить атрибут»"
			/>
		);
	}

	return (
		<List itemLayout="horizontal"
			dataSource={attributes}
			renderItem={(item) => (
				<List.Item actions={[
					<ItemActions
						key={item.id}
						items={[
							currentCatalog?.displayNameAttributeDeclarationId !==
								item?.id
								? {
									label: (
										<EditCatalogMainInfo catalogId={currentCatalog.id}
											loading={false}
											error={null}
											isDropdownLink={true}
											displayNameAttributeDeclarationId={
												item?.id
											}
										/>
									),
									key: `${item.id}-edit`,
								}
								: null,
							{
								label: (
									<DropdownLink
										title="Перейти в карточку атрибута"
										icon={<RiArrowRightCircleLine size={16} />}
										onClick={() =>
											navigate(
												`/${routes.metadata.main}/${routes.metadata.group}/${item.attribute.attributeGroupId}/${routes.metadata.attribute}/${item.attribute.id}`,
											)
										}
									/>
								),
								key: `${item.id}-link`,
							},
							{
								label: <SetupAttributeDeclaration attributeDeclaration={item} />,
								key: `${item?.id}-setup`,
							},
							{
								label: <DeleteAttributeDeclaration id={item.id} />,
								key: `${item.id}-delete`,
							},
						]}
					/>,
				]}
				>
					<List.Item.Meta
						title={
							currentCatalog?.displayNameAttributeDeclarationId !==
								item?.id ? (
								item?.attribute?.displayName
							) : (
								<Flex gap={6}>
									{`${item?.attribute?.displayName}`}

									<Flex align="center" gap={2}>
										<RiCheckboxCircleFill
											color={colors.success}
											size={14}
										/>
										<Typography.Text style={{ fontWeight: 400 }}>
											Используется в названии справочной записи
										</Typography.Text>
									</Flex>
								</Flex>
							)
						}
						description={descriptions ? descriptions[item?.id] : []}
					/>
				</List.Item>
			)}
		/>
	);
};

export const EditAttributeDeclarationList = React.memo(EditAttributeDeclarationListUi);
