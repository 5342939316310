import { RiDeleteBinLine } from '@remixicon/react';
import { Button, Flex, Form, Skeleton, Spin, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useConstraintTableRow } from 'entities/metadata/constraintTables';
import { AttributeDto } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { mapValueForDto } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { mapValueForForm } from 'shared/helpers/mapValueForForm';
import { ItemValuesType } from 'shared/helpers/types';
import { DetailFooter } from 'shared/ui';
import { DeleteRowModal } from '../deleteRow';
import { EditRelationAttribute } from '../editRelationAttribute';
import { useEditConstraintTableRow } from './editConstraintTableRow.model';
import { flexStyle } from './EditConstraintTableRowStyles';

export const EditConstraintTableRow: React.FC = () => {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const { isLoading, tableColumns, currentRow } = useConstraintTableRow();

	const { form, editInfo, attributeLoading } = useEditConstraintTableRow();

	const { constraintTableId, constraintTableRowId } = useParams();

	const navigate = useNavigate();

	const goToConstraintTablePage = () => {
		navigate(
			`/${routes.metadata.main}/${routes.metadata.constraintTables}/${constraintTableId}`
		);
	};

	const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
	const handleDeleteModalOk = () => {
		setIsDeleteModalOpen(false);
		goToConstraintTablePage();
	};
	const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);

	const handleRenderInput = (
		value: ItemValuesType,
		attribute: AttributeDto
	) => {
		if (attribute.type === CellTypesEnum.RELATION) {
			return (
				<EditRelationAttribute
					key={attribute.id}
					attributeDeclaration={attribute}
					editInfo={
						editInfo as unknown as (
							attributeId: string,
							newValue: ItemValuesType[]
						) => Promise<void>
					}
					value={value as unknown as ItemValuesType[]}
				/>
			);
		}

		return CellInputParser(
			attribute.id,
			{
				...attribute,
				list: true,
			},
			{
				callback: () => editInfo(
					attribute.id,
					(form.getFieldValue(attribute.id) as any[])
						.map(value => mapValueForDto(attribute.type, value))
				),
				label: attribute.displayName,
				triggeredWhenAddedInList: false,
			}
		);
	};

	useEffect(() => {
		if (tableColumns?.length > 0 && currentRow) {
			form.setFieldsValue(
				Object.fromEntries(tableColumns
					.map(({ id, type, list }) => {
						const value = currentRow[id];
						return [
							id,
							mapValueForForm(type, list, value),
						];
					}))
			);
		}
	}, [ tableColumns, currentRow ]);

	useEffect(() => {
		if (currentRow === undefined)
			goToConstraintTablePage();
	}, [currentRow]);

	return (
		<>
			<Flex vertical gap={12} style={flexStyle}>
				<Typography.Title level={2}>Общая информация</Typography.Title>

				{isLoading ? (
					<Skeleton.Input active block />
				) : (
					tableColumns &&
					tableColumns?.length > 0 && (
						<Spin spinning={!!attributeLoading}>
							<Form form={form} layout="vertical">
								{tableColumns?.map((col) => {
									if (currentRow && Object.keys(currentRow).length > 0) {
										return (
											<Fragment key={col.id}>
												{
													handleRenderInput(
														currentRow[col.id] as ItemValuesType,
														col
													)
												}
											</Fragment>
										);
									} else return <Skeleton.Input active block />;
								})}
							</Form>
						</Spin>
					)
				)}
			</Flex>
			<DetailFooter customHandleBack={goToConstraintTablePage}>
				<Button
					type="text"
					onClick={handleDeleteModalOpen}
					icon={<RiDeleteBinLine size={18} />}
				>
					Удалить
				</Button>
			</DetailFooter>

			<DeleteRowModal
				onOk={handleDeleteModalOk}
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				rowsToDelete={[constraintTableRowId]}
			/>
		</>
	);
};
