import { PaginationProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { changeSearchParams, tablePaginationShowTotalFactory } from '../helpers';
import { IWorkTableProps } from '../ui/components/WorkTable';
import { useTypedTranslation } from './useTypedTranslation';

interface Pagination {
	current: number;
	pageSize: number;
}

interface Sorter {
	sortBy: string;
	sortType: 'ASC' | 'DESC';
}

interface Filters {
	[key: string]: any;
}

interface ITableState {
	pagination?: Pagination;
	sorter?: Sorter;
	filters?: Filters;
}

interface ITableStateDto {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: 'ASC' | 'DESC';
}

type WorkTableProps = Pick<IWorkTableProps, 'pagination' | 'onChange' | 'sorter'>;

interface UsePaginationHookResult {
	toDto(): ITableStateDto;

	isReady: boolean;

	toWorkTableProps(total?: number): WorkTableProps;

	sorter: Sorter | null;
}

export const useWorkTable: () => UsePaginationHookResult = () => {
	const { t } = useTypedTranslation();

	const [ searchParams, setSearchParams ] = useSearchParams();
	const [ tableState, setTableState ] = useState<ITableState>({});

	const toTablePaginationProps = (
		total: number,
	): PaginationProps => {
		return {
			...tableState.pagination,
			total: total,
			pageSizeOptions: [ 20, 50, 100, 200 ],
			showTotal: tablePaginationShowTotalFactory(t),
			showSizeChanger: true,
		};
	};

	const page = searchParams.get('page');
	const pageSize = searchParams.get('pageSize');
	const sortBy = searchParams.get('sortBy');
	const sortType = searchParams.get('sortType');
	useEffect(() => {
		setTableState({
			pagination: {
				current: Number(page ?? 1),
				pageSize: Number(pageSize ?? 20),
			},
			sorter: {
				sortBy,
				sortType: sortType === 'ASC' ? 'ASC' : 'DESC',
			},
		});
	}, [ page, pageSize, sortBy, sortType ]);

	const onTableChange = (newPagination: { current?: number, pageSize?: number }, filers: any, sorter: any) =>
		setSearchParams(changeSearchParams(searchParams, {
			page: newPagination.current,
			pageSize: newPagination.pageSize,
			sortBy: sorter.columnKey,
			sortType: sorter.order === 'ascend' ? 'ASC' : 'DESC',
		}));

	const toDto = (): ITableStateDto => {
		return {
			limit: tableState.pagination?.pageSize,
			page: tableState.pagination?.current,
			...(tableState.sorter?.sortBy ? { sortBy: tableState.sorter.sortBy } : {}),
			...(tableState.sorter?.sortType ? { sortType: tableState.sorter.sortType } : {}),
		};
	};

	const toWorkTableProps = (total?: number) => {
		return {
			pagination: toTablePaginationProps(total),
			onChange: onTableChange,
			sorter: tableState.sorter,
		};
	};

	return {
		toDto,
		isReady: tableState?.pagination != null,
		toWorkTableProps,
		sorter: tableState?.sorter,
	};
};
