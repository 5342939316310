import { Card, Flex, Result, Spin, Typography } from 'antd';
import React from 'react';
import { useGetSupportedSystemsQuery } from 'shared/api/generatedApi/integrationsApi';

interface SelectIntegrationSystemProps {
	onSelect: (systemId: string) => void;
}

const SelectIntegrationSystem = React.memo((
	{
		onSelect,
	}: SelectIntegrationSystemProps) => {
	const { data: supportedSystems, error, isLoading } = useGetSupportedSystemsQuery({
		page: 1,
		pageSize: 100,
	});

	if (isLoading) {
		return (
			<Spin/>
		);
	}

	if (error) {
		return (
			<Result status="error"
					title="Ошибка при получении списка возможных подключений!"
					subTitle={JSON.stringify(error)}
			/>
		);
	}

	return (
		<Flex gap={12}>
			{supportedSystems?.data.map((item) => (
				<Card
					style={{ width: 300 }}
					key={item.system_id}
					onClick={() => onSelect(item.system_id)}
					hoverable
				>
					<Flex vertical gap={12} align="center">
						{item.system_image_url && (
							<img src={item.system_image_url} width={64} height={64} alt={item.system_name}/>
						)}
						<Typography.Text strong>
							{item.system_name}
						</Typography.Text>
					</Flex>
				</Card>
			))}
		</Flex>
	);
});

export {
	SelectIntegrationSystem,
};