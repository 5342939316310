import { RiDeleteBinLine } from '@remixicon/react';
import { App } from 'antd';
import React from 'react';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useDeleteClassifierAttributeDeclarationAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';

interface IDeleteAttributeDeclarationProps {
	id: string;
}

const DeleteClassifierAttributeDeclarationUi: React.FC<IDeleteAttributeDeclarationProps> = ({
																								id,
																							}) => {
	const { notification } = App.useApp();

	const { execute: deleteDeclaration } = useAsyncOperation(
		useDeleteClassifierAttributeDeclarationAsyncMutation,
		TaskType.DELETE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK,
	);

	const handleDelete = async (id: string) => {
		try {
			await deleteDeclaration({ ids: [ id ] });
		} catch (error) {
			errorHelper('Ошибка при удалении атрибута!', error, notification);
		}
	};

	return (
		<DropdownLink
			title="Удалить"
			icon={<RiDeleteBinLine size={16}/>}
			onClick={() => handleDelete(id)}
		/>
	);
};

export const DeleteClassifierAttributeDeclaration = React.memo(
	DeleteClassifierAttributeDeclarationUi,
);
