import { RiLink } from '@remixicon/react';
import { Button } from 'antd';
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useNavigate } from 'react-router';
import { useGetCatalogItemQuery, useGetClassifierItemQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useHandleQueryError } from 'shared/hooks';
import { colors } from 'shared/styles';
import s from './CompatibilityUrl.module.scss';

interface IGetCompatibilityUrl {
	id: string;
	type: string;
	title: string;
}

export const CompatibilityUrl = memo(({ id, type, title }: IGetCompatibilityUrl) => {

	const navigate = useNavigate();

	const [ itemArg, setItemArg ] = useState<{
		id: string;
		type: 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | '';
	}>({
		id: '',
		type: '',
	});

	const { data: catalogItem, isFetching: isCatalogItemFetching } = useHandleQueryError(
		useGetCatalogItemQuery({ id: itemArg.id }, { skip: itemArg?.type !== 'CATALOG_ITEM' }),
		(l) => l.catalogs.error.gettingItem,
	);

	const { data: classifierItem, isFetching: isClassifierItemFetching } = useHandleQueryError(
		useGetClassifierItemQuery({ id: itemArg.id }, { skip: itemArg?.type !== 'CLASSIFIER_ITEM' }),
		(l) => l.classifier.error.gettingItem,
	);

	const compatibilityItemUrl = useCallback((id: string, type: string) => {
		switch (type) {
			case 'CATALOG':
				return `/${routes.catalogs.main}/${id}/edit`;

			case 'CATALOG_ITEM': {
				if (catalogItem) {
					return `/${routes.catalogs.main}/${catalogItem.catalogId}/record/${id}/`;
				}
				return '';
			}

			case 'CLASSIFIER':
				return `/${routes.classifiers.main}/${id}/edit`;

			case 'CLASSIFIER_ITEM':
				if (classifierItem) {
					return `/${routes.classifiers.main}/${classifierItem.classifierId}/records/${id}/`;
				}
				return '';

			default:
				return '';
		}
	}, [ catalogItem, classifierItem ]);

	useEffect(() => {
		if ((type === 'CATALOG_ITEM' || type === 'CLASSIFIER_ITEM')) {
			setItemArg({ id, type });
		}
	}, [ id, type ]);

	return (
		<Button type="text"
				className={s.button}
				onClick={() => navigate(compatibilityItemUrl(id, type))}
				icon={<RiLink size={16} color={colors.primary}/>}
				loading={isCatalogItemFetching || isClassifierItemFetching}
		>
			{title}
		</Button>
	);
});