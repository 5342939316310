import { RiDeleteBin6Line } from '@remixicon/react';
import { Checkbox, Collapse, CollapseProps, Form, Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { DropdownLink, DropdownSelect } from 'shared/ui';
import './styles.scss';
import ItemActions from 'shared/ui/components/ItemActions';

export interface ApprovalRouteStepProps {
	data: ApprovalRouteStepModel;
	isParallel?: boolean;
	onClickAddParallelStep?: () => void;
	onClickMakeStepSequential?: () => void;
	onClickRemoveStep?: () => void;
}

export interface ApprovalRouteStepModel {
	title: string | null;
	assigneeIds: string[];
	isEditAllowed: boolean;
	coordinationType: string | null;
	coordinationTime: string | null;
}

export enum ApprovalCoordinationType {
	ONE_OF_GROUP = 'ONE_OF_GROUP',
	ALL_OF_GROUP = 'ALL_OF_GROUP',
}

const selectOptions = [
	{ label: 'Один из участников', value: ApprovalCoordinationType.ONE_OF_GROUP },
	{ label: 'Все участники', value: ApprovalCoordinationType.ALL_OF_GROUP },
];

const ApprovalRouteStep: React.FC<ApprovalRouteStepProps> = ({
	data,
	isParallel,
	onClickAddParallelStep,
	onClickRemoveStep,
	onClickMakeStepSequential,
}) => {
	const [formModel] = Form.useForm();

	useEffect(() => {
		formModel.setFields(
			Object.entries(data).map(([key, value]) => ({
				name: key,
				value: value,
			}))
		);
	}, [data]);

	const expandIconFactory = useCallback(
		({ isActive = false }) => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				style={{ transform: `rotate(${isActive ? 0 : 180}deg)` }}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M18.7071 14.7039C18.3166 15.0987 17.6834 15.0987 17.2929 14.7039L12 9.44038L6.70711 14.7039C6.31658 15.0987 5.68342 15.0987 5.29289 14.7039C4.90237 14.3092 4.90237 13.6691 5.29289 13.2744L11.2929 7.29607C11.6834 6.90131 12.3166 6.90131 12.7071 7.29607L18.7071 13.2744C19.0976 13.6691 19.0976 14.3092 18.7071 14.7039Z"
					fill="#151D34"
					fillOpacity="0.4"
				/>
			</svg>
		),
		[]
	);

	const dropdownButtons = [
		{
			key: '0',
			label:
				isParallel !== true ? (
					<DropdownLink
						icon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="16"
								viewBox="0 0 14 16"
								fill="none"
							>
								<path
									d="M9.72443 8.40274L13.523 12.2014L9.72443 16L8.63904 14.9147L10.5853 12.9682L0.476562 12.9688V11.434L10.5853 11.4334L8.63904 9.48805L9.72443 8.40274Z"
									fill="#4760CB"
								/>
								<path
									d="M9.72443 -6.73335e-05L13.523 3.7986L9.72443 7.59719L8.63904 6.51188L10.5853 4.56542L0.476562 4.56604V3.03116L10.5853 3.03055L8.63904 1.08524L9.72443 -6.73335e-05Z"
									fill="#4760CB"
								/>
							</svg>
						}
						onClick={onClickAddParallelStep}
						title="Добавить параллельный этап"
					/>
				) : (
					<DropdownLink
						icon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="12"
								viewBox="0 0 16 12"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.36842 10.2107H0V11.8949H4.21053C4.6756 11.8949 5.05263 11.5179 5.05263 11.0528L5.05263 0.947574C5.05263 0.482479 4.6756 0.105469 4.21053 0.105469H0V1.78968H3.36842V10.2107ZM16 1.78968H12.6316V10.2107H16V11.8949H11.7895C11.3244 11.8949 10.9474 11.5179 10.9474 11.0528V0.947574C10.9474 0.482479 11.3244 0.105469 11.7895 0.105469H16V1.78968ZM6.73684 9.36863L10.1053 6.00021L6.73684 2.63178V9.36863Z"
									fill="#4760CB"
								/>
							</svg>
						}
						onClick={onClickMakeStepSequential}
						title="Сделать этап последовательным"
					/>
				),
		},
		{
			key: '1',
			label: (
				<DropdownLink
					icon={<RiDeleteBin6Line size={16} />}
					onClick={onClickRemoveStep}
					title="Удалить"
				/>
			),
		},
	];

	const handleBlur = () => {};

	const form = (
		<Form layout="vertical" form={formModel}>
			<Form.Item label="Наименование" name="title">
				<Input onBlur={handleBlur} allowClear />
			</Form.Item>
			<Form.Item label="Участники согласования" name="assigneeIds">
				<Input onBlur={handleBlur} allowClear />
			</Form.Item>
			<Form.Item label="Вид согласования" name="coordinationType">
				<DropdownSelect options={selectOptions} allowClear onChange={handleBlur} />
			</Form.Item>
			<Form.Item valuePropName="checked" name="isEditAllowed">
				<Checkbox onChange={handleBlur}>Возможность редактирования заявки</Checkbox>
			</Form.Item>
			<Form.Item label="Срок согласования" name="coordinationTime">
				<Input onBlur={handleBlur} allowClear />
			</Form.Item>
		</Form>
	);

	const items: CollapseProps['items'] = [
		{
			key: '1',
			forceRender: true,
			extra: (
				<div onClick={(e) => e.stopPropagation()}>
					<ItemActions items={dropdownButtons} />
				</div>
			),
			label: (
				<>
					<div
						style={{
							position: 'absolute',
							left: 20,
						}}
					>
						{isParallel ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="16"
								viewBox="0 0 14 16"
								fill="none"
							>
								<path
									d="M9.72443 8.40277L13.523 12.2014L9.72443 16L8.63904 14.9147L10.5853 12.9682L0.476562 12.9689V11.434L10.5853 11.4334L8.63904 9.48808L9.72443 8.40277Z"
									fill="#4760CB"
								/>
								<path
									d="M9.72443 0L13.523 3.79865L9.72443 7.59723L8.63904 6.51192L10.5853 4.56547L0.476562 4.56609V3.03122L10.5853 3.0306L8.63904 1.08531L9.72443 0Z"
									fill="#4760CB"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="12"
								viewBox="0 0 16 12"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.36842 10.2107H0V11.8949H4.21053C4.6756 11.8949 5.05263 11.5179 5.05263 11.0528L5.05263 0.947574C5.05263 0.482479 4.6756 0.105469 4.21053 0.105469H0V1.78968H3.36842V10.2107ZM16 1.78968H12.6316V10.2107H16V11.8949H11.7895C11.3244 11.8949 10.9474 11.5179 10.9474 11.0528V0.947574C10.9474 0.482479 11.3244 0.105469 11.7895 0.105469H16V1.78968ZM6.73684 9.36863L10.1053 6.00021L6.73684 2.63178V9.36863Z"
									fill="#E97B2D"
								/>
							</svg>
						)}
					</div>
					<div style={{ fontWeight: 700 }}>{data.title ?? 'Этап согласования'}</div>
				</>
			),
			children: form,
			className: 'routeStepCollapseItem',
		},
	];

	return <Collapse ghost items={items} expandIcon={expandIconFactory} />;
};

export { ApprovalRouteStep };
