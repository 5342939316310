import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translations } from 'shared/translations';

if (process.env.NODE_ENV !== 'test') {
	i18n
		.use(initReactI18next)
		.init({
			resources: {
				ru: {
					translation: translations.ru,
				},
				en: {
					translation: translations.en,
				},
			},
			lng: localStorage.getItem('language') ?? 'ru',
			fallbackLng: 'ru',

			interpolation: {
				escapeValue: false,
			},
		});
}

export default i18n;
