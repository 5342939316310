import {
	RiDeleteBinLine,
	RiEditLine,
	RiExpandUpDownLine,
	RiInformationLine,
} from '@remixicon/react';
import { Flex } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router';
import { AddNewRow } from 'features/metadata/ConstraintTables/addNewRow';
import { AssignColumn } from 'features/metadata/ConstraintTables/assignColumn';
import { DeleteRowModal } from 'features/metadata/ConstraintTables/deleteRow';
import { useConstraintTableDetails } from 'entities/metadata/constraintTables';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink, Hint, Placeholder, WorkTable } from 'shared/ui';
import { aStyles } from 'shared/ui/components/DropdownButtons/DropdownButtonsStyles';
import ItemActions from 'shared/ui/components/ItemActions';

interface IConstraintTableDetailsProps {
	onCheckRow: (ids: string[]) => void;
}

const ConstraintTableDetailsComponent = (
	{ onCheckRow }: IConstraintTableDetailsProps,
	ref: any
) => {
	const { t } = useTypedTranslation();
	const [columns, setColumns] = useState<any[] | null>(null);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [rowsToDelete, setRowsToDelete] = useState<string[]>([]);

	const { isTableInfoLoading, tableColumns, restrictionTableItems } = useConstraintTableDetails();

	const navigate = useNavigate();

	useImperativeHandle(ref, () => ({
		resetSelectedRows: () => {
			setSelectedRows([]);
			setRowsToDelete([]);
		},
	}));

	const dataSource = restrictionTableItems?.map((item) => ({
		key: item.id,
		...Object.fromEntries(
			Object.entries(item.values).map(([key, value]) => [
				key,
				(value as Array<string>).join(', '),
			])
		),
		menu: (
			<ItemActions
				items={[
					{
						label: (
							<DropdownLink
								title={t((l) => l.common.buttons.edit)}
								icon={<RiEditLine size={16} />}
								onClick={() => handleEditRow(item.id)}
							/>
						),
						key: '1',
					},
					{
						label: (
							<a onClick={() => handleDeleteModalOpen(item.id)} style={aStyles}>
								<RiDeleteBinLine size={16} />
								{t((l) => l.common.buttons.delete)}
							</a>
						),
						key: '2',
					},
				]}
			/>
		),
	}));

	const handleEditRow = (id: string) => {
		navigate(`${id}`);
	};

	useEffect(() => {
		if (tableColumns) {
			setColumns([
				...tableColumns.map((col, i) => ({
					title:
						i !== 0 ? (
							col.displayName
						) : (
							<Flex align="center" gap={12}>
								{col.displayName}
								<Hint
									title={
										<Flex vertical gap={6}>
											<div>{t((l) => l.constraintTables.hint.required)}</div>
											<div>{t((l) => l.constraintTables.hint.any)}</div>
											<div>{t((l) => l.constraintTables.hint.requiredAndFilled)}</div>
										</Flex>
									}
								>
									<RiInformationLine size={16} color={colors.grayIcon} />
								</Hint>
							</Flex>
						),
					dataIndex: col.id,
					key: col.id,
					sorter: true,
					sortIcon: () => <RiExpandUpDownLine size={16} />,
					showSorterTooltip: false,
				})),
			]);
		}
	}, [tableColumns, t]);

	const handleDeleteModalClose = () => {
		setRowsToDelete(selectedRows as string[]);
		setIsDeleteModalOpen(false);
	};
	const handleDeleteModalOpen = (soloRowId?: string) => {
		if (soloRowId) setRowsToDelete([soloRowId]);
		setIsDeleteModalOpen(true);
	};
	const handleDeleteModalOk = () => {
		setSelectedRows([]);
		setRowsToDelete([]);
		setIsDeleteModalOpen(false);
	};

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setSelectedRows(selectedRowKeys);
			setRowsToDelete(selectedRowKeys as string[]);
			onCheckRow(selectedRowKeys as string[]);
		},
		selectedRowKeys: selectedRows,
		getCheckboxProps: (record: { [key: string]: string }) => ({
			disabled: record.name === 'Disabled User',
			name: record.name,
		}),
	};

	return (
		<>
			<Flex gap={6}>
				<AddNewRow />
			</Flex>

			<AssignColumn />

			<div style={{ width: '100%', height: '100%' }}>
				{columns?.length > 1 || isTableInfoLoading ? (
					<WorkTable
						columns={columns}
						loading={isTableInfoLoading}
						dataSource={dataSource}
						rowSelection={rowSelection}
						style={{ marginBottom: selectedRows?.length > 0 ? 65 : 0 }}
						onRow={(r) => ({
							onClick: () => navigate(`${r.key}`),
						})}
						scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
					/>
				) : (
					<Placeholder title={t((l) => l.constraintTables.noColumns)} />
				)}
			</div>

			<DeleteRowModal
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				onOk={handleDeleteModalOk}
				rowsToDelete={rowsToDelete}
			/>
		</>
	);
};

export const ConstraintTableDetails = forwardRef<any, IConstraintTableDetailsProps>(
	ConstraintTableDetailsComponent
);
