import { deduplicationApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		startSearchDuplicatesAsync: build.mutation<
			StartSearchDuplicatesAsyncApiResponse,
			StartSearchDuplicatesAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/deduplication-options/${queryArg.id}/start`,
				method: 'POST',
			}),
		}),
		getDeduplicationOptions: build.query<
			GetDeduplicationOptionsApiResponse,
			GetDeduplicationOptionsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/deduplication-options',
				params: { catalogId: queryArg.catalogId },
			}),
		}),
		createDeduplicationOption: build.mutation<
			CreateDeduplicationOptionApiResponse,
			CreateDeduplicationOptionApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/deduplication-options',
				method: 'POST',
				body: queryArg.createDeduplicationOptionRequest,
			}),
		}),
		startSearchDuplicates: build.mutation<
			StartSearchDuplicatesApiResponse,
			StartSearchDuplicatesApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/deduplication-options/${queryArg.id}/start`,
				method: 'POST',
			}),
		}),
		createDeduplicationAttributeMapping: build.mutation<
			CreateDeduplicationAttributeMappingApiResponse,
			CreateDeduplicationAttributeMappingApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-deduplication-mappings',
				method: 'POST',
				body: queryArg.createDeduplicationAttributeMappingRequest,
			}),
		}),
		defineOriginalItem: build.mutation<DefineOriginalItemApiResponse, DefineOriginalItemApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/duplicates/${queryArg.id}`,
					method: 'PATCH',
					body: queryArg.defineOriginalItemRequest,
				}),
			}
		),
		getDeduplicationOption: build.query<
			GetDeduplicationOptionApiResponse,
			GetDeduplicationOptionApiArg
		>({
			query: (queryArg) => ({ url: `/api/v1/deduplication-options/${queryArg.id}` }),
		}),
		deleteDeduplicationOption: build.mutation<
			DeleteDeduplicationOptionApiResponse,
			DeleteDeduplicationOptionApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/deduplication-options/${queryArg.id}`,
				method: 'DELETE',
			}),
		}),
		updateDeduplicationOption: build.mutation<
			UpdateDeduplicationOptionApiResponse,
			UpdateDeduplicationOptionApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/deduplication-options/${queryArg.id}`,
				method: 'PATCH',
				body: queryArg.updateDeduplicationOptionRequest,
			}),
		}),
		deleteDuplicates: build.mutation<DeleteDuplicatesApiResponse, DeleteDuplicatesApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/deduplication-options/${queryArg.id}/duplicates`,
				method: 'DELETE',
				headers: { Authorization: queryArg.authorization },
				params: { suspectedDuplicateIds: queryArg.suspectedDuplicateIds },
			}),
		}),
		setDuplicateItem: build.mutation<SetDuplicateItemApiResponse, SetDuplicateItemApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/deduplication-options/${queryArg.id}/duplicates`,
				method: 'PATCH',
				headers: { Authorization: queryArg.authorization },
				params: { suspectedDuplicateIds: queryArg.suspectedDuplicateIds },
			}),
		}),
		deleteDeduplicationAttributeMapping: build.mutation<
			DeleteDeduplicationAttributeMappingApiResponse,
			DeleteDeduplicationAttributeMappingApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/attribute-deduplication-mappings/${queryArg.id}`,
				method: 'DELETE',
			}),
		}),
		updateDeduplicationAttributeMapping: build.mutation<
			UpdateDeduplicationAttributeMappingApiResponse,
			UpdateDeduplicationAttributeMappingApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/attribute-deduplication-mappings/${queryArg.id}`,
				method: 'PATCH',
				body: queryArg.updateDeduplicationAttributeMappingRequest,
			}),
		}),
		getSuspectedDuplicates: build.query<
			GetSuspectedDuplicatesApiResponse,
			GetSuspectedDuplicatesApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/deduplication-options/${queryArg.id}/suspected-duplicates`,
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
				},
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as deduplicationApi };
export type StartSearchDuplicatesAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type StartSearchDuplicatesAsyncApiArg = {
	id: string;
};
export type GetDeduplicationOptionsApiResponse = /** status 200 OK */ DeduplicationOptionDto[];
export type GetDeduplicationOptionsApiArg = {
	catalogId: string;
};
export type CreateDeduplicationOptionApiResponse = /** status 200 OK */ DeduplicationOptionDto;
export type CreateDeduplicationOptionApiArg = {
	createDeduplicationOptionRequest: CreateDeduplicationOptionRequest;
};
export type StartSearchDuplicatesApiResponse = unknown;
export type StartSearchDuplicatesApiArg = {
	id: string;
};
export type CreateDeduplicationAttributeMappingApiResponse =
	/** status 200 OK */ DeduplicationAttributeMappingDto;
export type CreateDeduplicationAttributeMappingApiArg = {
	createDeduplicationAttributeMappingRequest: CreateDeduplicationAttributeMappingRequest;
};
export type DefineOriginalItemApiResponse = unknown;
export type DefineOriginalItemApiArg = {
	id: string;
	defineOriginalItemRequest: DefineOriginalItemRequest;
};
export type GetDeduplicationOptionApiResponse = /** status 200 OK */ DeduplicationOptionDto;
export type GetDeduplicationOptionApiArg = {
	id: string;
};
export type DeleteDeduplicationOptionApiResponse = unknown;
export type DeleteDeduplicationOptionApiArg = {
	id: string;
};
export type UpdateDeduplicationOptionApiResponse = /** status 200 OK */ DeduplicationOptionDto;
export type UpdateDeduplicationOptionApiArg = {
	id: string;
	updateDeduplicationOptionRequest: UpdateDeduplicationOptionRequest;
};
export type DeleteDuplicatesApiResponse = unknown;
export type DeleteDuplicatesApiArg = {
	authorization?: string;
	id: string;
	suspectedDuplicateIds?: string[];
};
export type SetDuplicateItemApiResponse = unknown;
export type SetDuplicateItemApiArg = {
	authorization?: string;
	id: string;
	suspectedDuplicateIds?: string[];
};
export type DeleteDeduplicationAttributeMappingApiResponse = unknown;
export type DeleteDeduplicationAttributeMappingApiArg = {
	id: string;
};
export type UpdateDeduplicationAttributeMappingApiResponse =
	/** status 200 OK */ DeduplicationAttributeMappingDto;
export type UpdateDeduplicationAttributeMappingApiArg = {
	id: string;
	updateDeduplicationAttributeMappingRequest: UpdateDeduplicationAttributeMappingRequest;
};
export type GetSuspectedDuplicatesApiResponse = /** status 200 OK */ SliceSuspectedDuplicateDto;
export type GetSuspectedDuplicatesApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	id: string;
};
export type BackgroundTaskStartDto = {
	id: string;
};
export type DeduplicationAttributeMappingDto = {
	id: string;
	deduplicationOptionId: string;
	firstAttributeDeclarationId?: string;
	secondAttributeDeclarationId?: string;
	matchPercentage: number;
	isEmptyEqualEmpty: boolean;
	isEmptyEqualValue: boolean;
	createdAt: string;
	updatedAt: string;
};
export type DeduplicationOptionDto = {
	id: string;
	displayName?: string;
	catalogId: string;
	targetCatalogIds?: string[];
	filters?: {
		[key: string]: object;
	};
	deduplicationAttributeMappings?: DeduplicationAttributeMappingDto[];
	startMode: string;
	scheduler?: string;
	searchDirection: string;
	autoConsolidation: boolean;
	useForImport: boolean;
	isRestart: boolean;
	createdAt: string;
	updatedAt: string;
};
export type CreateDeduplicationOptionRequest = {
	displayName?: string;
	catalogId: string;
	targetCatalogIds?: string[];
	filters?: {
		[key: string]: object;
	};
	startMode: string;
	scheduler?: string;
	searchDirection: string;
	autoConsolidation: boolean;
	useForImport: boolean;
};
export type CreateDeduplicationAttributeMappingRequest = {
	deduplicationOptionId: string;
	firstAttributeDeclarationId?: string;
	secondAttributeDeclarationId?: string;
	matchPercentage: number;
	isEmptyEqualEmpty: boolean;
	isEmptyEqualValue: boolean;
};
export type DefineOriginalItemRequest = {
	newOriginalItemId: string;
};
export type UpdateDeduplicationOptionRequest = {
	displayName?: string;
	targetCatalogIds?: string[];
	filters?: {
		[key: string]: object;
	};
	startMode: string;
	scheduler?: string;
	searchDirection: string;
	autoConsolidation: boolean;
	useForImport: boolean;
};
export type UpdateDeduplicationAttributeMappingRequest = {
	firstAttributeDeclarationId?: string;
	secondAttributeDeclarationId?: string;
	matchPercentage: number;
	isEmptyEqualEmpty: boolean;
	isEmptyEqualValue: boolean;
};
export type ItemDto = {
	id: string;
	displayName?: string;
	catalogId: string;
	values: {
		[key: string]: object;
	};
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	createdAt: string;
	updatedAt: string;
};
export type SuspectedDuplicateDto = {
	id: string;
	deduplicationOptionId: string;
	suspectedOriginalItem: ItemDto;
	suspectedDuplicateItems: ItemDto[];
	createdAt: string;
	updatedAt: string;
};
export type SliceMeta = {
	page: number;
	limit: number;
	total: number;
	sortBy: string;
	sortType: string;
};
export type SliceSuspectedDuplicateDto = {
	data: SuspectedDuplicateDto[];
	meta: SliceMeta;
};
export const {
	useStartSearchDuplicatesAsyncMutation,
	useGetDeduplicationOptionsQuery,
	useLazyGetDeduplicationOptionsQuery,
	useCreateDeduplicationOptionMutation,
	useStartSearchDuplicatesMutation,
	useCreateDeduplicationAttributeMappingMutation,
	useDefineOriginalItemMutation,
	useGetDeduplicationOptionQuery,
	useLazyGetDeduplicationOptionQuery,
	useDeleteDeduplicationOptionMutation,
	useUpdateDeduplicationOptionMutation,
	useDeleteDuplicatesMutation,
	useSetDuplicateItemMutation,
	useDeleteDeduplicationAttributeMappingMutation,
	useUpdateDeduplicationAttributeMappingMutation,
	useGetSuspectedDuplicatesQuery,
	useLazyGetSuspectedDuplicatesQuery,
} = injectedRtkApi;
