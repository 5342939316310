import { LoadingOutlined } from '@ant-design/icons';
import { RiArrowRightSLine } from '@remixicon/react';
import { Breadcrumb, Flex, Layout, Select, Spin } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Outlet, Link } from 'react-router-dom';
import UserProfileMenu from 'widgets/UserProfile/UserProfileMenu';
import { IBreadcrumb } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppSelector } from 'shared/hooks';
import { LayoutHeader, WikiButton } from 'shared/ui';
import { Sidebar, Logo, Menu } from 'shared/ui';
import s from './pageLayout.module.scss';

const { Content } = Layout;

const PageLayout = () => {

	const { i18n } = useTranslation();

	const location = useLocation();
	const navigate = useNavigate();
	const [ collapsed, setCollapsed ] = useState<boolean>(true);
	const [ breadcrumbs, setBreadcrumbs ] = useState<Array<{ title: React.ReactElement }> | null>(
		null,
	);

	const { root } = useAppSelector((state) => state.breadcrumbs);

	const { hasSider: sider, subMenuCollapsed, backgroundTasks } = useAppSelector((state) => state.shared.ui);

	useEffect(() => {
		if (root) {
			const getBreadcrumbs = (root: IBreadcrumb) => {
				const resultArray: { title: JSX.Element }[] = [];
				resultArray.push({
					title: root.child ? (
						<Link to={root.url}>{root.displayName}</Link>
					) : (
						<span>{root.displayName}</span>
					),
				});
				if (root.child) {
					resultArray.push(...getBreadcrumbs(root.child));
				}
				return resultArray;
			};

			setBreadcrumbs(getBreadcrumbs(root));
		} else {
			setBreadcrumbs(null);
		}
	}, [ root ]);

	const toggleMenu = () => setCollapsed(!collapsed);

	useEffect(() => {
		const leftMenuWidth = 55;
		const subMenuWidth = 355;
		const leftMenuExpandedAdditionWidth = 195;

		const asideWidth =
			leftMenuWidth +
			(!collapsed && leftMenuExpandedAdditionWidth) +
			(!subMenuCollapsed && subMenuWidth);
		document.documentElement.style.setProperty('--aside-width', `${asideWidth}px`);
	}, [ collapsed, subMenuCollapsed ]);

	useEffect(() => {
		if (location.pathname === '/') {
			navigate(`/${routes.catalogs.main}`);
		}
	}, [ location ]);

	const shouldShowBreadcrumbs =
		location.pathname.split('/').length > 3 ||
		(location.pathname.includes(routes.metadata.measures) &&
			location.pathname.split('/').length > 3) ||
		(location.pathname.includes(routes.classifiers.main) &&
			location.pathname.split('/').length > 2) ||
		(location.pathname.includes(routes.integrations.main) &&
			location.pathname.split('/').length > 2) ||
		(location.pathname.includes(routes.metadata.constraintTables) &&
			location.pathname.split('/').length > 3);

	const changeLanguage = useCallback((language: string) => {
		i18n.changeLanguage(language)
			.then(() => localStorage.setItem('language', language));
	}, [ i18n ]);

	return (
		<Layout className={s.mainLayout}>
			<LayoutHeader>
				<Flex align="center" gap={41}>
					<Menu onClick={toggleMenu}/>
					<Logo/>
				</Flex>

				<div style={{ marginLeft: 'auto', marginRight: 12 }}>
					<Select value={i18n.language}
							options={[ { value: 'ru', label: 'RU' }, { value: 'en', label: 'EN' } ]}
							onChange={changeLanguage}
					/>
				</div>

				<div style={{ marginLeft: 0, marginRight: 24, marginTop: 16 }}>
					<a href={process.env.REACT_APP_HELP_URL} target="_blank" rel="noopener noreferrer">
						<WikiButton/>
					</a>
				</div>

				<UserProfileMenu/>
			</LayoutHeader>
			<Layout hasSider>
				<Sidebar collapsed={collapsed}/>
				<Content className={s.content}>
					{shouldShowBreadcrumbs && (
						<div
							className={cn([
								s.breadCrumbs,
								!subMenuCollapsed && sider && s.slideLeft,
							])}
						>
							<Breadcrumb
								items={breadcrumbs}
								separator={<RiArrowRightSLine size={16}/>}
							/>
						</div>
					)}
					<div className={cn([ sider && !subMenuCollapsed && s.slideLeft ])}>
						<Outlet/>
					</div>
				</Content>
			</Layout>

			{
				backgroundTasks.length > 0 &&
                <div style={{ position: 'fixed', right: 0, bottom: 0, padding: 10 }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin/>}/>
                </div>
			}

		</Layout>
	);
};

export default PageLayout;
