import { RiArrowDownSLine } from '@remixicon/react';
import { Button, Dropdown, MenuProps } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DropdownLink } from '../..';

type SortType = 'alphabet' | 'asc' | 'dsc' | 'default';

enum SortNameEnum {
	asc = 'По возрастанию',
	dsc = 'По убыванию',
	alphabet = 'По алфавиту',
	default = 'По умолчанию',
}

interface CardsSorterType<T> {
	defaultState: Array<T>;
	sorterProps: Array<{ sortType: SortType; sortField: keyof T }>;
	callback: React.Dispatch<React.SetStateAction<Array<T> | null>>;
}

export const CardsSorter = <T,>({
	callback,
	defaultState = [],
	sorterProps,
}: CardsSorterType<T>): JSX.Element => {
	const [title, setTitle] = useState<string>(SortNameEnum.default);
	const stateRef = useRef<Array<T>>(defaultState);

	const handleChangeFilter = (type: SortType, fieldName?: keyof T) => {
		let sortedArray = [...(stateRef.current || [])];

		switch (type) {
			case 'alphabet':
				setTitle(SortNameEnum.alphabet);
				sortedArray.sort((a, b) => {
					const valA = String(a[fieldName]).toUpperCase();
					const valB = String(b[fieldName]).toUpperCase();
					return valA.localeCompare(valB);
				});
				break;
			case 'asc':
				setTitle(SortNameEnum.asc);
				sortedArray.sort((a, b) => {
					if (typeof a[fieldName] === 'number' && typeof b[fieldName] === 'number') {
						return (a[fieldName] as number) - (b[fieldName] as number);
					}
					return 0;
				});

				break;
			case 'dsc':
				setTitle(SortNameEnum.dsc);
				sortedArray.sort((a, b) => {
					if (typeof a[fieldName] === 'number' && typeof b[fieldName] === 'number') {
						return (b[fieldName] as number) - (a[fieldName] as number);
					}
					return 0;
				});

				break;
			default:
				setTitle(SortNameEnum.default);
				sortedArray = stateRef.current;
				break;
		}

		callback(sortedArray);
	};

	const items: MenuProps['items'] = useMemo(() => {
		const items = sorterProps.map((item, i) => ({
			label: (
				<DropdownLink
					title={SortNameEnum[item.sortType]}
					defaultColor
					onClick={() => handleChangeFilter(item.sortType, item.sortField)}
				/>
			),
			key: i.toString(),
		}));
		return [
			{
				label: (
					<DropdownLink
						title={SortNameEnum['default']}
						defaultColor
						onClick={() => handleChangeFilter('default')}
					/>
				),
				key: 'default',
			},
			...items,
		];
	}, [sorterProps]);

	useEffect(() => {
		if (defaultState && defaultState.length > 0) {
			stateRef.current = defaultState;
			callback(defaultState);
		}
	}, [defaultState, callback]);

	return (
		<div>
			<Dropdown trigger={['click']} menu={{ items }}>
				<Button type="link" style={{ color: 'var(--primary)' }}>
					{title}
					<RiArrowDownSLine />
				</Button>
			</Dropdown>
		</div>
	);
};
