import { RiDeleteBin6Line } from '@remixicon/react';
import { Flex, Button, Modal, Typography } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { randomString } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import { useDeleteMeasureUnits } from './deleteMeasureUnit.model';

interface IDeleteMeasuresUnitProps {
	unitId: Array<string>;
	isDropdown?: boolean;
	detailUnitPage?: boolean;
}

const DeleteMeasureUnit = memo((
	{
		isDropdown,
		unitId,
		detailUnitPage,
	}: IDeleteMeasuresUnitProps) => {
	const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);

	const { deleteMeasureUnits, loading } = useDeleteMeasureUnits();
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setIsModalOpen(false);
		await deleteMeasureUnits(unitId);
		if (!isDropdown && detailUnitPage) {
			navigate(-1);
		}
	};

	const handleCancel = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setIsModalOpen(false);
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t((l) => l.common.buttons.cancel)}
				</Button>

				<Button key="submit" type="primary" onClick={handleOk} loading={loading}>
					{t((l) => l.common.buttons.delete)}
				</Button>
			</Flex>
		),
		[ loading, unitId ],
	);

	return (
		<>
			{isDropdown ? (
				<DropdownLink
					title={t((l) => l.common.buttons.delete)}
					icon={<RiDeleteBin6Line size={16}/>}
					onClick={showModal}
				/>
			) : (
				<Button
					icon={<RiDeleteBin6Line/>}
					type="text"
					onClick={showModal}
					disabled={!unitId?.length}
				>
					{t((l) => l.common.buttons.delete)}
				</Button>
			)}

			<Modal
				title={t((l) => l.measures.actions.delete.unit.modalTitle)}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[ footerButtons ]}
				closeIcon={null}
				width={600}
			>
				<Flex vertical gap={24}>
					<Typography.Text>
						{t((l) => l.measures.actions.delete.unit.modalMessage)}
					</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
});

export {
	DeleteMeasureUnit,
};
