import { RemixiconComponentType } from '@remixicon/react';
import { Flex } from 'antd';
import React, { CSSProperties } from 'react';

const getContainerStyle: (active: boolean) => CSSProperties = (active) => ({
	width: '32px',
	height: '32px',
	backgroundColor: active ? 'var(--primary)' : 'var(--white-base)',
	border: '1px solid',
	borderColor: active ? 'var(--primary)' : 'var(--inactive-button)',
	borderRadius: 6,
	padding: 8,
	cursor: 'pointer',
});

const IconButton = React.memo(({ Icon, active = false, onClick }: {
	Icon: RemixiconComponentType,
	active?: boolean,
	onClick?: () => void
}) =>
	<Flex justify={'center'}
		  align={'center'}
		  style={getContainerStyle(active)}
		  onClick={onClick}
	>
		<Icon color={active ? 'var(--white-base)' : 'var(--inactive-button)'}/>
	</Flex>);

export {
	IconButton,
};