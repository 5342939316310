import { RiEditLine, RiErrorWarningLine } from '@remixicon/react';
import { Flex, MenuProps, Skeleton, Typography } from 'antd';
import React, { memo, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ConnectionInfo, useGetSupportedSystemByIdQuery } from 'shared/api/generatedApi/integrationsApi';
import { routes } from 'shared/config';
import { truncateString, capitalizeFirstLetter } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Chip, DetailCard, DropdownLink, Hint } from 'shared/ui';
import { DeleteIntegration } from '../DeleteIntegration';
import { PinSwitchIntegration } from '../PinSwitchIntegration';


const IntegrationCardSkeleton = memo(() => (
	<Flex gap={25}>
		<Skeleton active avatar style={{ minWidth: 325, display: 'flex', alignItems: 'center' }}/>
		<Skeleton active avatar style={{ minWidth: 325, display: 'flex', alignItems: 'center' }}/>
		<Skeleton active avatar style={{ minWidth: 325, display: 'flex', alignItems: 'center' }}/>
	</Flex>
));

interface IntegrationCardProps {
	id: string;
	systemId: string;
	connectionInfo: ConnectionInfo;
}

const IntegrationCard = memo((
	{
		id,
		systemId,
		connectionInfo,
	}: IntegrationCardProps) => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const {
		name,
		date_last_import,
		count_configuration,
		count_successful_imports,
		count_failed_imports,
		is_pinned,
		is_failed,
	} = connectionInfo;

	const { data: system, isFetching: loading } = useGetSupportedSystemByIdQuery({
		systemId: systemId,
	});

	const link = `/${routes.integrations.main}/${routes.integrations.detail}/${id}`;

	const items: MenuProps['items'] = useMemo(
		() => [
			{
				key: 'pin',
				label: (
					<PinSwitchIntegration isPinned={is_pinned}
										  id={id}
					/>
				),
			},
			{
				key: 'edit',
				label: (
					<DropdownLink
						title={t(l => l.common.buttons.edit)}
						icon={<RiEditLine size={16}/>}
						onClick={() => navigate(link)}
					/>
				),
			},
			{
				key: 'delete',
				label: (
					<DeleteIntegration id={id}/>
				),
			},
		],
		[ navigate, id, is_pinned, t ],
	);

	if (loading) {
		return <IntegrationCardSkeleton/>;
	}

	return (
		<DetailCard
			firstSection={
				system.system_image_url && (
					<img src={system.system_image_url}
						 alt={name}
						 style={{ objectFit: 'contain', width: 48, height: '48px' }}
					/>
				)
			}
			secondSection={
				<Flex vertical justify="start" gap={4}>
					<Flex gap={4} justify="start">
						<Hint title={name && name.length > 20 ? name : ''}>
							<Typography.Title level={3}>
								{name
									? capitalizeFirstLetter(truncateString(name, 20))
									: t(l => l.common.defaultNames.emptyName)
								}
							</Typography.Title>
						</Hint>
						{is_failed && <RiErrorWarningLine size={15} color={colors.error}/>}
					</Flex>

					<Flex gap={2}>
						<Typography.Paragraph type="secondary">
							{t(l => l.integrations.connectionInfo.identifier)}:
						</Typography.Paragraph>
						<Typography.Paragraph>
							{id ? truncateString(id, 9) : ''}
						</Typography.Paragraph>
					</Flex>

					<Flex gap={2}>
						<Typography.Paragraph type="secondary">
							{t(l => l.integrations.connectionInfo.lastActivity)}:
						</Typography.Paragraph>
						<Typography.Paragraph>
							{(date_last_import &&
									new Date(date_last_import).toLocaleDateString()) ||
								'-'}
						</Typography.Paragraph>
					</Flex>

					<Flex justify="flex-start" gap={6}>
						<Chip
							color="success">{t(l => l.integrations.connectionInfo.completed)} {count_successful_imports}</Chip>
						<Chip
							color="error">{t(l => l.integrations.connectionInfo.stopped)} {count_failed_imports}</Chip>
					</Flex>
				</Flex>
			}
			dropdownButtons={items}
			link={link}
			tooltip={count_configuration && `${count_configuration}`}
		/>
	);
});

export {
	IntegrationCard,
	IntegrationCardSkeleton,
};
