import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useCreateDeduplicationAttributeMapping } from 'features/catalogs/AttrbuteDeclarations/createDeduplicationAttributeMapping';
import { deleteDeduplicationAttributeMapping } from 'features/catalogs/AttrbuteDeclarations/deleteDeduplicationAttributeMapping';
import { updateCatalogDeduplicationOption } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import { TaskType, useAsyncOperation } from 'entities/events';
import {
	DeduplicationOptionDto,
	UpdateDeduplicationOptionRequest,
	useUpdateDeduplicationOptionMutation,
	useLazyGetDeduplicationOptionQuery,
	useStartSearchDuplicatesAsyncMutation,
} from 'shared/api/generatedApi/deduplicationApi';
import {
	AttributeGroupTreeNodeResponse,
	CatalogResponse,
	CatalogAttributeDeclarationResponse,
	useLazyGetCatalogsQuery,
	useLazyGetCatalogAttributeDeclarationsQuery,
	useLazyGetCatalogAttributeDeclarationQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import type {
	ISelectModalValueType,
	ISelectAttributesType,
} from './CatalogDeduplicationCondition.ui';

export const useCatalogDeduplicationCondition = () => {
	const { t } = useTypedTranslation();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const [tree, setTree] = useState<Array<DisplayTreeNode>>([]);
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedCatalog, setSelectedCatalog] = useState<Array<ISelectModalValueType>>([]);
	const [initialCatalogs, setInitialCatalogs] = useState<CatalogResponse[]>([]);
	const [declarations, setDeclarations] = useState<CatalogAttributeDeclarationResponse[]>([]);
	const [deduplicationOption, setDeduplicationOption] = useState<DeduplicationOptionDto>(null);
	const [declaratedAttributes, setDeclaratetdAttributes] = useState<Array<ISelectAttributesType>>([]);
	const [ isSearchingDuplicates, setIsSearchingDuplicates ] = useState<boolean>(false);

	const deduplicationOptions = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.catalogDeduplication
	);

	const [getCatalogArr, { error: catalogListError }] = useLazyGetCatalogsQuery();
	const [getDeclarationsArr] = useLazyGetCatalogAttributeDeclarationsQuery();
	const [updateDeduplicationOption] = useUpdateDeduplicationOptionMutation();
	const [getDeclaration] = useLazyGetCatalogAttributeDeclarationQuery();
	const [getCurrentDeduplicationOption, { isFetching: isFetchigCurrentDeduplicationOption }] =
		useLazyGetDeduplicationOptionQuery();

	const { execute: startSearchDuplicatesAsync } = useAsyncOperation(
		useStartSearchDuplicatesAsyncMutation,
		TaskType.DEDUPLICATION_BACKGROUND_TASK
	);

	const { createMapping, isCreatingMapping } = useCreateDeduplicationAttributeMapping();
	const { deleteMapping, isDeletingMapping } = deleteDeduplicationAttributeMapping();

	const isChangingMappingList = isCreatingMapping || isDeletingMapping;

	useEffect(() => {
		getCatalogs();
	}, []);

	const getCatalogs = async (parentId?: string, ids?: string[]) => {
		setIsLoading(true);

		if (!ids) {
			getCatalogArr({ ...(parentId ? { parentIds: [parentId] } : { root: true }) })
				.unwrap()
				.then((data) => {
					if (!parentId) setTree(formatToTree(data));
					if (parentId) setTree(formatToTree(updateTreeData(tree, parentId, data)));
				})
				.catch((err) => {
					setError(catalogListError);
					errorHelper(t(l => l.catalogs.groups.listErr), err, notification);
				})
				.finally(() => setIsLoading(false));
		}

		if (ids?.length) {
			getCatalogArr({ ids })
				.unwrap()
				.then((res) => setInitialCatalogs(res))
				.catch((err) => {
					errorHelper(t(l => l.catalogs.groups.catalogErr), err, notification);
				});
		}
	};

	const formatToTree = (data: Array<CatalogResponse | AttributeGroupTreeNodeResponse>) =>
		data?.map((catalog) => ({
			...catalog,
			key: catalog.id,
			title: catalog.displayName,
			isLeaf: !catalog.parent,
			...(catalog?.parent &&
				'children' in catalog &&
				catalog?.children?.length && {
					children: formatToTree(catalog.children),
				}),
		}));

	const updateTreeData = (list, id, children) =>
		list.map((node) => {
			if (node.id === id) {
				return {
					...node,
					children,
				};
			}
			if ('children' in node && node.children) {
				return {
					...node,
					children: updateTreeData(node.children, id, children),
				};
			}
			return node;
		});

	const getAttributeName = async (id: string) => {
		let name = '';
		await getDeclaration({ id })
			.unwrap()
			.then((res) => {
				name = res.attribute.displayName;
			})
			.catch((err) =>
				errorHelper(t(l => l.catalogs.records.declarationsListErr), err, notification)
			);

		return name;
	};

	const passSelectedCatalog = (catlogs: ISelectModalValueType[]) => setSelectedCatalog(catlogs);
	const passDeduplicationOption = (option: DeduplicationOptionDto) =>
		setDeduplicationOption(option);

	useEffect(() => {
		setDeclarations([]);

		const recieveDeclarations = async () => {
			let newDeclarations: CatalogAttributeDeclarationResponse[] = [];

			if (selectedCatalog)
				selectedCatalog.forEach(async (catalog) => {
					const declarations = await getDeclarations(catalog.key as string);
					newDeclarations = [...newDeclarations, ...declarations];
					setDeclarations(newDeclarations);
				});
		};

		recieveDeclarations();
	}, [selectedCatalog]);

	const getDeclarations = async (catalogId: string) => {
		let declarations = [];
		await getDeclarationsArr({ catalogId })
			.unwrap()
			.then((res) => {
				declarations = res;
			})
			.catch((err) => {
				setError(err);
				errorHelper(t(l => l.catalogs.records.declarationsListErr), err, notification);
			});

		return declarations;
	};

	const editDeduplicationOption = async (id: string, data: UpdateDeduplicationOptionRequest) => {
		updateDeduplicationOption({
			id: id,
			updateDeduplicationOptionRequest: data,
		})
			.unwrap()
			.then((res) => {
				dispatch(updateCatalogDeduplicationOption(res));
				notification.success({ message: t(l => l.catalogs.deduplication.searchSaved) });
			})
			.catch((err) =>
				errorHelper(t(l => l.catalogs.deduplication.updateSearchErr), err, notification)
			);
	};

	useEffect(() => {
		if (!deduplicationOption) return;

		const { id } = deduplicationOption;

		const currentDeduplicationOption = deduplicationOptions.filter((item) => item.id == id)[0];

		const deduplicationAttributeMappings = currentDeduplicationOption
			? currentDeduplicationOption.deduplicationAttributeMappings
			: [];

		let attributes: ISelectAttributesType[] = [];

		if (!deduplicationAttributeMappings || deduplicationAttributeMappings.length === 0) {
			setDeclaratetdAttributes([]);
			return;
		}

		if (deduplicationAttributeMappings)
			deduplicationAttributeMappings.forEach((mapping) => {
				getAttributeName(mapping.firstAttributeDeclarationId).then((res) => {
					attributes = [
						...attributes,
						{
							label: res,
							value: mapping.firstAttributeDeclarationId,
							callback: () => deleteMapping(deduplicationOption.id, mapping.id),
							key: mapping.id,
						},
					];

					setDeclaratetdAttributes(attributes);
				});
			});
	}, [deduplicationOptions, deduplicationOption]);

	const editMappingList = (selectedDeclarationsId: string[]) => {
		const { id } = deduplicationOption;

		const currentMappings = deduplicationOptions?.filter((item) => item.id == id)[0]
			.deduplicationAttributeMappings;

		const createMappingList = selectedDeclarationsId.filter(
			(item) =>
				!currentMappings
					.map((mapping) => mapping.firstAttributeDeclarationId)
					.includes(item)
		);

		const deleteMappingList = currentMappings.filter(
			(mapping) => !selectedDeclarationsId.includes(mapping.firstAttributeDeclarationId)
		);

		if (deleteMappingList) {
			deleteMappingList.forEach((mapping) =>
				deleteMapping(deduplicationOption.id, mapping.id)
			);
		}

		if (createMappingList) {
			createMappingList.forEach((item) =>
				createMapping({
					deduplicationOptionId: deduplicationOption.id,
					firstAttributeDeclarationId: item,
					secondAttributeDeclarationId: item,
					matchPercentage: 100,
					isEmptyEqualEmpty: true,
					isEmptyEqualValue: true,
				})
			);
		}
	};

	const updateCurrentDeduplicationOption = (id: string) => {
		getCurrentDeduplicationOption({ id })
			.unwrap()
			.then((res) => {
				dispatch(updateCatalogDeduplicationOption(res));
			})
			.catch((err) =>
				errorHelper(t(l => l.catalogs.deduplication.updateSearchErr), err, notification)
			);
	};

	const startDeduplicationSearch = async (id: string) => {
		try {
			setIsSearchingDuplicates(true);

			await startSearchDuplicatesAsync({ id });

			updateCurrentDeduplicationOption(id);

			notification.success({ message: t(l => l.catalogs.deduplication.searchCompleted) });
		} catch (error) {
			errorHelper(
				t(l => l.catalogs.deduplication.updateSearchErr),
				error,
				notification
			);
		} finally {
			setIsSearchingDuplicates(false);
		}
	};

	return {
		tree,
		error,
		isLoading,
		getCatalogs,
		passSelectedCatalog,
		declarations,
		startDeduplicationSearch,
		editDeduplicationOption,
		initialCatalogs,
		passDeduplicationOption,
		declaratedAttributes,
		editMappingList,
		isChangingMappingList,
		isSearchingDuplicates,
		isFetchigCurrentDeduplicationOption,
	};
};
