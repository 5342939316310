import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { RiFilterLine } from '@remixicon/react';
import { Button, Dropdown, Flex, Form, Input } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTypedTranslation } from '../../hooks';
import { DropdownSelect } from '../../ui';
import s from './Filters.module.scss';
import { ComparisonType, ModifierType } from './models';

interface ExtendedFiltersProps {
	filters: Array<{ key: string, title: string }>;
	onChange: (any) => void;
}

const ExtendedFilters = (
	{
		filters,
		onChange,
	}: ExtendedFiltersProps) => {
	const { t } = useTypedTranslation();

	const [ form ] = Form.useForm();

	const modifierNames = useMemo<Record<ModifierType, string>>(() => ({
		[ModifierType.AND]: t(l => l.common.filters.and),
		[ModifierType.OR]: t(l => l.common.filters.or),
	}), [ t ]);

	const modifierOptions = useMemo(() =>
		Object.entries(modifierNames)
			.map(([ key, name ]) => ({
				value: key,
				label: name.toUpperCase(),
			})), [ modifierNames ]);

	const comparisonNames = useMemo<Record<ComparisonType, string>>(() => ({
		[ComparisonType.EQUALS]: t(l => l.common.filters.equal),
		[ComparisonType.NOT_EQUALS]: t(l => l.common.filters.notEqual),
		[ComparisonType.EMPTY]: t(l => l.common.filters.empty),
		[ComparisonType.NOT_EMPTY]: t(l => l.common.filters.notEmpty),
		[ComparisonType.MORE]: t(l => l.common.filters.more),
		[ComparisonType.MORE_OR_EQUAL]: t(l => l.common.filters.moreOrEqual),
		[ComparisonType.LESS]: t(l => l.common.filters.less),
		[ComparisonType.LESS_OR_EQUAL]: t(l => l.common.filters.lessOrEqual),
		[ComparisonType.CONTAINS]: t(l => l.common.filters.contains),
		[ComparisonType.NOT_CONTAINS]: t(l => l.common.filters.notContains),
	}), [ t ]);

	const comparisonOptions = useMemo(() =>
		Object.entries(comparisonNames).map(([ key, name ]) => ({
			value: key,
			label: name,
		})), []);

	const parameterOptions = useMemo(() => filters.map(x => ({
		value: x.key,
		label: x.title,
	})), [ filters ]);

	const onFinish = useCallback((values: any) => {
		onChange(values.items?.length > 0 ? {
			modifier: values.items[0].modifier,
			parameters: values.items.map(item => ({
				parameter: item.name,
				comparisonParam: item.comparisonParam,
				value: item.value,
			})),
		} : null);
	}, [ onChange ]);

	const content = useCallback(() => (
		<Form layout="vertical" form={form} onFinish={onFinish} style={{ width: 734 }}>
			<Flex align={'flex-end'}
				  vertical={true}
				  style={{
					  borderRadius: 6,
					  border: '1px solid var(--gray-table-border)',
					  background: '#FFF',
					  padding: 8,
				  }}
			>
				<Form.List name="items">
					{(fields, { add, remove }) => (
						<>
							<Button type="text"
									icon={<PlusCircleOutlined/>}
									style={{ marginBottom: 8 }}
									onClick={add}
							>
								{t((l) => l.common.filters.addFilterOption)}
							</Button>

							{fields.map(({ key, name, ...restField }) => {
								return (
									<Flex key={key} align="baseline" gap={8}>
										<div style={{ width: 80 }}>
											{name === 0 && (
												<Form.Item {...restField}
														   name={[ name, 'modifier' ]}
												>
													<DropdownSelect options={modifierOptions}/>
												</Form.Item>)}
										</div>
										<Form.Item {...restField}
												   name={[ name, 'name' ]}
												   style={{ width: 220 }}
										>
											<DropdownSelect options={parameterOptions}/>
										</Form.Item>
										<Form.Item {...restField}
												   name={[ name, 'comparisonParam' ]}
												   style={{ width: 150 }}
										>
											<DropdownSelect options={comparisonOptions}/>
										</Form.Item>
										<Form.Item {...restField}
												   name={[ name, 'value' ]}
												   style={{ width: 200 }}
										>
											<Input></Input>
										</Form.Item>

										<Button type="text"
												icon={<DeleteOutlined/>}
												onClick={() => remove(name)}
										/>
									</Flex>
								);
							})}
						</>
					)}
				</Form.List>

				<Flex>
					<Button type="text" onClick={() => form.resetFields()}>
						{t((l) => l.common.filters.clearFilter)}
					</Button>
					<Button type="primary" htmlType="submit">
						{t((l) => l.common.buttons.apply)}
					</Button>
				</Flex>

			</Flex>
		</Form>
	), [ form, onFinish, parameterOptions, modifierOptions, comparisonOptions, t ]);

	useEffect(() => {
		form.resetFields();
		onChange(null);
	}, [ filters ]);

	return (
		<Dropdown trigger={[ 'click' ]}
				  placement={'bottom'}
				  dropdownRender={content}
				  className={s.addFilterButton}
		>
			<Button type="text" icon={<RiFilterLine/>}>
				{t((l) => l.common.filters.extendedFilters)}
			</Button>
		</Dropdown>
	);
};

export {
	ExtendedFilters,
};