import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'shared/ui';

export const SearchInnerClassifiersItems: React.FC = () => {
	const [ searchParams, setSearchParams ] = useSearchParams();

	const searchHandler = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const searchValue = searchParams.get('searchValue');

	return <Search onChange={searchHandler} defaultValue={searchValue}/>;
};
