import { Form, Result, Spin } from 'antd';
import React from 'react';
import { WorkTable } from 'shared/ui';
import { useEditCatalogRecord } from './editCatalogRecord.model';

const columns = [
	{
		title: 'Наименование атрибута',
		dataIndex: 'attributeName',
		key: 'attributeName',
	},
	{
		title: 'Значение',
		dataIndex: 'value',
		key: 'value',
	},
];

const EditCatalogRecordUi: React.FC = () => {
	const { form, recordsList, loading, error } = useEditCatalogRecord();

	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;
	}

	return (
		<>
			{loading && (
				<div
					style={{
						right: '75px',
						bottom: '75px',
						position: 'fixed',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 1,
					}}
				>
					<Spin size="large" />
				</div>
			)}
			<Form form={form}>
				<WorkTable
					pagination={false}
					dataSource={recordsList}
					columns={columns}
				/>
			</Form>
		</>
	);
};

export const EditCatalogRecord = React.memo(EditCatalogRecordUi);
