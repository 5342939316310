import { App, Flex, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { DeleteSimpleAttributeUi } from 'features/metadata/deleteSimpleAttribute';
import { EditAttribute } from 'features/metadata/EditAttribute';
import { UsageAttributeInCatalogs } from 'features/metadata/UsageAttributeInCatalogs';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { RealtimeBaseEvent, TaskType, useRealtimeEvent } from 'entities/events';
import {
	mdmgApi,
	useGetAttributeGroup1Query,
	useGetAttributeQuery,
	useUpdateAttributeMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { NEW_ENTITY } from 'shared/helpers/Constants';
import { useAppDispatch, useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

const AttributeDetailPageUi: React.FC = () => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { metaAttributeGroupId: attributeGroupId, metaAttributeId: attributeId } = useParams();
	const navigate = useNavigate();

	const [ updateAttribute ] = useUpdateAttributeMutation();

	const { data: currAttrGroup } = useHandleQueryError(
		useGetAttributeGroup1Query({
			id: attributeGroupId,
		}, {
			skip: !attributeGroupId,
		}),
		l => l.attributes.attributeGroupErr);

	const { data: currAttribute } = useHandleQueryError(
		useGetAttributeQuery({
			id: attributeId,
		}, {
			skip: !attributeId || attributeId === NEW_ENTITY,
		}),
		l => l.attributes.attributeErr);

	useRealtimeEvent(TaskType.DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK, ({ payload }: RealtimeBaseEvent) => {
		const deletedAttributeGroupIds = (payload.entityIds as string[]);

		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		dispatch(mdmgApi.util.invalidateTags([ 'AttributeGroups' ]));

		if (deletedAttributeGroupIds.includes(attributeGroupId)) {
			navigate(`/${routes.metadata.main}`);
		}
	});

	useRealtimeEvent(TaskType.DELETE_ATTRIBUTES_BACKGROUND_TASK, ({ payload }: RealtimeBaseEvent) => {
		const deletedAttributeIds = (payload.entityIds as string[]);

		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		dispatch(mdmgApi.util.invalidateTags([ 'Attributes' ]));

		if (deletedAttributeIds.includes(attributeId)) {
			navigate(`/${routes.metadata.main}/${routes.metadata.group}/${attributeGroupId}`);
		}
	});

	useEffect(() => {
		if (currAttrGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Метаданные',
					url: `${routes.metadata.main}`,
					child: {
						displayName: `${currAttrGroup.displayName}`,
						url: `${routes.metadata.main}/${routes.metadata.group}/${currAttrGroup.id}`,
						child: {
							displayName: 'Редактирование атрибута',
							url: `${routes.metadata.main}/${routes.metadata.group}/${currAttrGroup.id}/${routes.metadata.attribute}/${attributeId}`,
						},
					},
				}),
			);
		}
	}, [ currAttrGroup, attributeId ]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.metadata.main}/${routes.metadata.group}/${attributeGroupId}`);
	};

	const onSubmitForm = useCallback(async (attr: any) => {
		try {
			await updateAttribute({
				attributeGroupId: attributeGroupId,
				id: attributeId,
				updateAttributeRequest: { ...attr },
			})
				.unwrap();
		} catch (err) {
			errorHelper(
				t((l) => l.attributes.attributeErr),
				err,
				notification,
			);
		}
	}, [ attributeGroupId, attributeId ]);

	return (
		<Flex vertical gap={24} style={{ marginBottom: 65 }}>
			<Flex justify="space-between">
				<Flex vertical gap={12}>
					<Typography.Title level={1}>
						{currAttribute?.displayName ? currAttribute.displayName : 'Нет названия'}
					</Typography.Title>
				</Flex>

				<Transactions/>
			</Flex>

			<EditAttribute attributeId={attributeId} onSubmit={onSubmitForm}/>

			<UsageAttributeInCatalogs attributeId={attributeId}/>

			<DetailFooter customHandleBack={handleBackClick}>
				<DeleteSimpleAttributeUi ids={[ attributeId ]}
										 isButton={true}
				/>
			</DetailFooter>
		</Flex>
	);
};

export const AttributeDetailPage = React.memo(AttributeDetailPageUi);
