import { Flex, Button } from 'antd';
import React, { memo, PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTypedTranslation } from '../../../hooks';
import s from './detailedFooter.module.scss';

interface IDetailFooterProps extends PropsWithChildren {
	isNavHidden?: boolean;
	navState?: {
		link: string;
		state: number;
	};
	customHandleBack?: () => void;
}

export const DetailFooter = memo((
	{
		children,
		isNavHidden,
		navState,
		customHandleBack,
	} : IDetailFooterProps) => {
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	useEffect(() => {
		document.body.classList.add('has-detail-footer');
		return () => {
			document.body.classList.remove('has-detail-footer');
		};
	}, []);

	const handleBack = () => {
		if (customHandleBack) {
			customHandleBack();
			return;
		}

		if (navState) {
			navigate(navState.link, { state: navState.state });
			return;
		}
		navigate(-1);
	};

	return (
		<Flex className={s.detailFooter}
			  justify={!isNavHidden ? 'space-between' : 'flex-end'}
		>
			{!isNavHidden && (
				<Button type="primary" onClick={handleBack}>
					{t((l) => l.common.buttons.back)}
				</Button>
			)}
			<Flex gap={4}>
				{children}
			</Flex>
		</Flex>
	);
});
