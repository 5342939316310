import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Button, Flex, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { EntityChangesTable, EntityStateDifference } from 'features/transactions';
import {
	IEntityStateDifference,
	IValue,
} from 'features/transactions/EntityStateDifference/EntityStateDifference.ui';
import { TransactionsList } from 'features/transactions/TransactionsList';
import { ITransaction } from 'features/transactions/TransactionsList/TransactionsList.ui';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import {
	useGetCatalogAttributeDeclarationsQuery,
	useGetEntityDifferenceQuery,
	useGetEntityTransactionsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { useEnumStateViaSearchParam, useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { Chip, ChipStatus } from 'shared/ui';

enum CatalogItemHistoryTab {
	Table = 'Table',
	Details = 'Details',
}

interface ICatalogItemHistoryWidgetProps {
	catalogId: string;
	catalogItemId: string;
}

const tabNames: Record<CatalogItemHistoryTab, string> = {
	[CatalogItemHistoryTab.Table]: 'Вся история',
	[CatalogItemHistoryTab.Details]: 'Детализация изменений по транзакциям',
};

const blankChanges = {
	oldData: null,
	newData: null,
	date: '',
	userName: '',
	title: '',
};

const CatalogItemHistoryWidget = ({ catalogId, catalogItemId }: ICatalogItemHistoryWidgetProps) => {
	const [displayedTransactionId, setDisplayedTransactionId] = useState<string>('');
	const [diffArg, setDiffArg] = useState<{ from: string; to: string }>(null);
	const [itemChanges, setItemChanges] = useState<IEntityStateDifference>(blankChanges);

	const { t } = useTypedTranslation();

	const [activeTab, setActiveTab] = useEnumStateViaSearchParam(
		CatalogItemHistoryTab,
		'historyTab',
		CatalogItemHistoryTab.Table
	);

	const { data: attributeDeclarations, isFetching } = useGetCatalogAttributeDeclarationsQuery({
		catalogId: catalogId,
	});

	const { data: transactionsList } = useHandleQueryError(
		useGetEntityTransactionsQuery({
			entityId: catalogItemId,
			entityType: 'ITEM',
		}),
		(l) => l.transactions.errorGetList
	);

	const {
		data: diff,
		isFetching: isLoadingDiff,
		error: diffError,
	} = useHandleQueryError(
		useGetEntityDifferenceQuery(
			{
				entityId: catalogItemId,
				entityType: 'ITEM',
				fromDate: diffArg?.from,
				toDate: diffArg?.to,
			},
			{ skip: !diffArg }
		),
		(l) => l.common.error.noStateDifference
	);

	useEffect(() => {
		if (!diff) return;
		const oldData = { status: diff.oldData.status, ...diff.oldData.values };
		const newData = { status: diff.newData.status, ...diff.newData.values };

		setItemChanges({
			oldData: oldData as { [key: string]: IValue },
			newData: newData as { [key: string]: IValue },
			date: diffArg.to,
			userName: '',
			title: transactionsList?.data?.find((x) => x.id === displayedTransactionId)
				?.displayName,
			propertyNamesMapper,
		});
	}, [diff]);

	useEffect(() => {
		if (!transactionsList?.data) return;

		if (!displayedTransactionId) {
			setDiffArg(null);
			return;
		}

		const transaction = transactionsList?.data?.find((x) => x.id === displayedTransactionId);
		const fromDate = transaction?.createdAt;

		const toDate = transaction?.committedAt
			? new Date(transaction?.committedAt) > new Date(transaction?.updatedAt)
				? transaction?.committedAt
				: transaction?.updatedAt
			: transaction?.updatedAt;

		setDiffArg({ from: fromDate, to: toDate });
	}, [displayedTransactionId, transactionsList]);

	const createTabButton = useCallback(
		(tab: CatalogItemHistoryTab) => (
			<Button
				name="active"
				type={activeTab === tab ? 'primary' : undefined}
				onClick={() => setActiveTab(tab)}
			>
				{tabNames[tab]}
			</Button>
		),
		[activeTab]
	);

	const propertyNamesMapper = useCallback(
		(propertyName: string) => {
			if (propertyName === 'status') {
				return t((l) => l.common.defaultNames.status);
			}
			if (propertyName === 'displayName') {
				return t((l) => l.common.defaultNames.name);
			}

			const attributeDeclaration = attributeDeclarations.find((x) => x.id === propertyName);

			if (attributeDeclaration) {
				return attributeDeclaration.attribute.displayName;
			}

			return propertyName;
		},
		[attributeDeclarations]
	);

	const propertyValuesMapper = useCallback(
		(propertyName: string, propertyValue: any) => {
			if (propertyName === 'status') {
				return (
					<Chip status={propertyValue.toLowerCase() as ChipStatus}>
						{chipTitle(propertyValue)}
					</Chip>
				);
			}

			const attributeDeclaration = attributeDeclarations.find((x) => x.id === propertyName);

			if (!attributeDeclaration) {
				return propertyValue;
			}

			return CellValueParser(
				propertyValue,
				attributeDeclaration.attribute.type as CellTypesEnum
			);
		},
		[attributeDeclarations]
	);

	const passCheckedIds = (ids: string[]) => {
		// TODO функция будет использоваться в задаче групповой обработки транзакций
		console.log(ids);
	};

	const content = {
		[CatalogItemHistoryTab.Table]: (
			<>
				{isFetching && <Spin spinning={true} />}

				{!isFetching && (
					<EntityChangesTable
						entityId={catalogItemId}
						propertyNamesMapper={propertyNamesMapper}
						propertyValuesMapper={propertyValuesMapper}
					/>
				)}
			</>
		),

		[CatalogItemHistoryTab.Details]: (
			<Flex gap={24}>
				<TransactionsList
					transactions={transactionsList?.data as ITransaction[]}
					onChange={passCheckedIds}
					onClick={setDisplayedTransactionId}
				/>
				<EntityStateDifference
					{...itemChanges}
					error={diffError as FetchBaseQueryError}
					isLoading={isLoadingDiff}
				/>
			</Flex>
		),
	};

	return (
		<>
			<Flex gap={6} style={{ marginBottom: 24 }}>
				{Object.values(CatalogItemHistoryTab).map((x) => (
					<div key={x}>{createTabButton(x)}</div>
				))}
			</Flex>

			{!!activeTab && content[activeTab]}
		</>
	);
};

export { CatalogItemHistoryWidget };
