import { useEnumStateViaSearchParam } from 'shared/hooks';
import { Localization } from 'shared/translations';

enum FilterButtonType {
	'all' = 'all',
	'active' = 'active',
	'failed' = 'failed',
}

const filterButtonNames: Record<FilterButtonType, (l: Localization) => string> = {
	[FilterButtonType.all]: l => l.integrations.filters.showAll,
	[FilterButtonType.active]: l => l.integrations.filters.showActive,
	[FilterButtonType.failed]: l => l.integrations.filters.showFailed,
};

const useIntegrationsListFilter = () => {
	return useEnumStateViaSearchParam(
		FilterButtonType,
		'filter',
		FilterButtonType.all
	);
};

export {
	useIntegrationsListFilter,
	FilterButtonType,
	filterButtonNames,
};