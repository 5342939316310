import { Tree, TreeProps } from 'antd';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
	useGetCatalogsQuery,
	useGetClassifiersQuery,
	useLazyGetCatalogsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { truncateString } from 'shared/helpers';
import { useConvertToTree } from 'shared/hooks';

interface CompatibilityEntityTreeSelectorProps {
	onSelect: (entity: { id: string, type: 'CATALOG' | 'CLASSIFIER' }) => void,
	onCheck: (entity: { id: string, type: 'CATALOG' | 'CLASSIFIER' }) => void,
}

const CompatibilityEntityTreeSelector = forwardRef((
	{
		onSelect,
		onCheck,
	}: CompatibilityEntityTreeSelectorProps, ref,
) => {
	const [ checkedKeys, setCheckedKeys ] = useState([]);

	const [ innerItems, setInnerItems ] = useState([]);

	useImperativeHandle(ref, () => ({
		resetCheckedKeys: () => setCheckedKeys([]),
	}));

	const { data: catalogs } = useGetCatalogsQuery({
		root: true,
	});
	const { data: classifiers } = useGetClassifiersQuery({});

	const [ getCatalogs ] = useLazyGetCatalogsQuery();

	const treeData: any = useConvertToTree(useMemo(() => ([
		{
			key: 'catalogs',
			id: 'catalogs',
			title: 'Справочник',
			checkable: false,
			selectable: false,
			isLeaf: false,
		},
		{
			key: 'classifiers',
			id: 'classifiers',
			title: 'Классификатор',
			checkable: false,
			selectable: false,
			isLeaf: false,
		},
		...catalogs?.map((catalog) => ({
			...catalog,
			id: catalog.id,
			pId: 'catalogs',
			key: `CATALOG/${catalog.id}`,
			title: truncateString(catalog?.displayName, 30),
			isLeaf: !catalog.parent,
		})) ?? [],
		...classifiers?.map((classifier) => ({
			...classifier,
			id: classifier.id,
			pId: 'classifiers',
			key: `CLASSIFIER/${classifier.id}`,
			title: truncateString(classifier?.displayName, 30),
			isLeaf: true,
		})) ?? [],
		...innerItems,
	]), [ catalogs, classifiers, innerItems ]));

	const onInnerSelect = useCallback((selectedKeys: Array<React.Key>) => {
		const [ type, id ] = String(selectedKeys[0]).split('/');
		onSelect({
			id: id,
			type: type as 'CATALOG' | 'CLASSIFIER',
		});
	}, [ onSelect ]);

	const loadData = async (treeNode) => {
		const [ type, id ] = String(treeNode.key).split('/');

		if (type === 'CATALOG') {
			const catalogs = await getCatalogs({
				parentIds: [ id ],
			}).unwrap();
			setInnerItems(prev => [
				...prev,
				...catalogs.map(catalog => ({
					...catalog,
					id: catalog.id,
					pId: catalog.parentId,
					key: `CATALOG/${catalog.id}`,
					title: truncateString(catalog?.displayName, 30),
					isLeaf: !catalog.parent,
				})),
			]);
		} else if (type === 'CLASSIFIER') {
			//	todo: будет реализовано после мержа ветки Андрюхи
		}
	};

	const onCheckTreeItem: TreeProps['onCheck'] = (checked) =>
		setCheckedKeys([ 'checked' in checked
			? checked.checked[checked.checked.length - 1]
			: checked[checked.length - 1],
		]);

	useEffect(() => {
		if (!checkedKeys[0]) return;
		const [ type, id ] = String(checkedKeys[0]).split('/');
		onCheck({
			id: id,
			type: type as 'CATALOG' | 'CLASSIFIER',
		});
	}, [ checkedKeys ]);

	return (
		<Tree treeData={treeData}
			  onSelect={onInnerSelect}
			  onCheck={onCheckTreeItem}
			  checkedKeys={checkedKeys}
			  loadData={loadData}
			  defaultExpandedKeys={['catalogs', 'classifiers']}
			  checkStrictly
			  checkable
		/>
	);
});

export {
	CompatibilityEntityTreeSelector,
};