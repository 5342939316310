import { RiAddCircleLine, RiEditLine, RiListSettingsLine } from '@remixicon/react';
import { Button, Flex, Modal, Result, Table, TableProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { DeleteSimpleAttributeUi } from 'features/metadata/deleteSimpleAttribute';
import { DataSourceType } from 'entities/metadata/metaAttributes/metaAttributes.model';
import { setCurrentMetaAttribute } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { AttributeResponse, useGetAttributesQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useAppDispatch, usePagination, useTypedTranslation } from 'shared/hooks';
import { DropdownLink, ItemActions } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { useAddAttributeTree } from './AddClassifierAttributeDeclaration.model';
import s from './AddClassifierAttributeDeclaration.module.scss';

export const AddClassifierAttributeDeclaration = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<Array<DataSourceType> | []>([]);
	const [attributeId, setAttributeId] = useState<string>('');
	const [attributesArg, setAttributesArg] = useState<{ id: string; page: number }>({
		id: '',
		page: 1,
	});
	const [dataSource, setDataSource] = useState<AttributeResponse[]>([]);

	const { pagination, asTablePagination } = usePagination();
	const { t } = useTypedTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const columns: TableProps['columns'] = useMemo(() => {
		return [
			{
				title: t((l) => l.common.defaultNames.name),
				dataIndex: 'displayName',
				key: 'displayName',
			},
			{
				title: t((l) => l.common.defaultNames.type),
				dataIndex: 'type',
				key: 'type',
			},
			{
				title: t((l) => l.common.defaultNames.isMulti),
				dataIndex: 'isMulti',
				key: 'isMulti',
			},
			{
				title: <RiListSettingsLine size={20} />,
				dataIndex: 'menu',
				key: 'menu',
			},
		];
	}, [t]);

	const { tree, onExpandTree, updateCatalogAttributes, loading, error } = useAddAttributeTree();

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
			setSelectedRow(!selectedRow);
		},
	};

	const {
		data,
		isLoading: metaAttributesLoading,
		error: attributesError,
	} = useGetAttributesQuery(
		{
			attributeGroupIds: [attributesArg.id],
			page: attributesArg.page,
			limit: pagination?.pageSize,
		},
		{ skip: !attributesArg.id || !attributesArg.page || !pagination }
	);

	const onSelect = (_, node) => {
		setAttributeId(node.id);
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const onConfirm = async () => {
		const data = await updateCatalogAttributes(selectedRows);
		if (data) {
			setSelectedRows([]);
			setSelectedRow(false);
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (!attributeId || !pagination?.current) return;

		setAttributesArg((prev) => ({
			id: attributeId,
			page: prev.id === attributeId ? pagination.current : 1,
		}));
	}, [attributeId, pagination?.current]);

	useEffect(() => {
		setDataSource(
			data?.data?.map((x) => ({
				...x,
				menu: (
					<ItemActions
						items={[
							{
								key: '0',
								label: (
									<DropdownLink
										icon={<RiEditLine size={16} />}
										title={t((l) => l.common.buttons.edit)}
										onClick={() => {
											dispatch(setCurrentMetaAttribute(x));
											navigate(
												`/${routes.metadata.main}/${routes.metadata.group}/${data?.data?.find((atr) => atr.id === x.id)?.attributeGroupId}/${routes.metadata.attribute}/${x.id}`
											);
										}}
									/>
								),
							},
							{
								key: '1',
								label: <DeleteSimpleAttributeUi ids={[x.id]} />,
							},
						]}
					/>
				),
				isMulti: x.list
					? t((l) => l.common.defaultNames.yes)
					: t((l) => l.common.defaultNames.no),
			}))
		);
	}, [data]);

	return (
		<>
			<Button
				style={{ maxWidth: 'fit-content' }}
				icon={<RiAddCircleLine size={16} />}
				onClick={openModal}
			>
				{t((l) => l.classifier.action.addAttribute)}
			</Button>

			<Modal
				title={t((l) => l.classifier.common.addAttribute)}
				open={isOpen}
				closable={false}
				width="50vw"
				footer={
					<Flex gap={12} justify="flex-end">
						<Button type="text" onClick={closeModal}>
							{t((l) => l.common.buttons.cancel)}
						</Button>
						<Button type="primary" disabled={!selectedRows.length} onClick={onConfirm}>
							{t((l) => l.common.buttons.confirm)}
						</Button>
					</Flex>
				}
			>
				<Flex vertical gap={24}>
					<p>{t((l) => l.classifier.common.chooseAttributeFromList)}</p>
					<Flex gap={12} vertical>
						<DropdownTreeSelect
							multiple={false}
							loading={loading}
							treeData={tree}
							value={attributesArg?.id && attributesArg.id}
							loadData={(treeNode) => onExpandTree(treeNode.id)}
							onSelect={onSelect}
							error={!!error}
						/>

						<div className={s.columnAttributes}>
							{dataSource && (
								<Table
									columns={columns}
									dataSource={dataSource}
									loading={metaAttributesLoading}
									rowKey="id"
									rowSelection={rowSelection}
									{...asTablePagination(data?.meta?.total)}
								/>
							)}
							{attributesError && (
								<Result
									status="error"
									title={t((l) => l.attributes.attributeGroupErr)}
									subTitle={JSON.stringify(attributesError)}
								/>
							)}
						</div>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};
