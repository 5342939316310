import { Flex } from 'antd';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { ExchangeKeysTable } from 'features/integrations';
import { routes } from 'shared/config';
import { DetailFooter } from 'shared/ui';

interface ExchangeKeysProps {
	integrationId: string;
}

const ExchangeKeysTabWidget = memo((
	{
		integrationId,
	}: ExchangeKeysProps,
) => {
	const navigate = useNavigate();

	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);

	return (
		<Flex vertical gap={24}>
			<ExchangeKeysTable integrationId={integrationId}/>

			<DetailFooter customHandleBack={customHandleBack}/>
		</Flex>
	);
});

export {
	ExchangeKeysTabWidget,
};