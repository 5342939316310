import { RiDeleteBinLine, RiSaveLine } from '@remixicon/react';
import { Table, Button, Spin, Flex, Alert, Result } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AddMappingAttribute } from 'features/integrations';
import { translateType } from 'entities/metadata/metaAttributes';
import {
	ColumnMetadata,
	RelationalMetadata,
	useGetConfigurationQuery,
	useGetMetadataQuery, useUpdateMappingMutation,
} from 'shared/api/generatedApi/integrationsApi';
import { useGetCatalogAttributeDeclarationsQuery } from 'shared/api/generatedApi/mdmgApi';
import { DropdownSelect, Placeholder } from 'shared/ui';
import s from './SetupMapping.module.scss';

interface ISetupMappingProps {
	integrationId: string;
	exchangeConfigId: string;
	onUpsert?: () => void;
}

const SetupMapping = React.memo((
	{
		integrationId,
		exchangeConfigId,
		onUpsert,
	}: ISetupMappingProps) => {

	const [ mappedAttributeDeclarationsIds, setMappedAttributeDeclarationsIds ] = useState<string[]>([]);
	const [ mappedAttributeDeclarations, setMappedAttributeDeclarations ] = useState<any[]>([]);
	const [ mappedSourceAttributes, setMappedSourceAttributes ] = useState<any[]>([]);
	const [ mapping, setMapping ] = useState<Record<string, string>>({});

	const { data: config, isLoading } = useGetConfigurationQuery({
		connectionId: integrationId,
		configurationId: exchangeConfigId,
	});

	const {
		data: attributeDeclarations,
		isLoading: loadingAttributeDeclarations,
		error: errorAttributeDeclarations,
	} = useGetCatalogAttributeDeclarationsQuery({
		catalogId: config?.destination,
	}, {
		skip: !config?.destination,
	});

	const { data: metadata, isLoading: loadingMetadata } = useGetMetadataQuery({
		connectionId: integrationId,
	});

	const [ updateMapping ] = useUpdateMappingMutation();

	const sourceAttributesMap: Map<string, {
		extraction_target: string,
		external_col: ColumnMetadata
	}> = useMemo(() => {
		if (!metadata || !config) {
			return new Map();
		}

		if (metadata.source_type == 'postgres') {
			return Object.entries((metadata.metadata as RelationalMetadata).tables)
				.filter(([ key ]) => (config.extraction_targets as string[]).includes(key))
				.reduce((acc, [ key, value ]) => {
					value.columns.forEach(x => acc.set(`${key}.${x.column_name}`, {
						extraction_target: key,
						external_col: x,
					}));
					return acc;
				}, new Map());
		}
		return new Map();
	}, [ metadata, config ]);

	useEffect(() => {
		if (!attributeDeclarations) {
			return;
		}

		setMappedAttributeDeclarations(mappedAttributeDeclarationsIds.map(id => {
			const declaration = attributeDeclarations.find(x => x.id === id);
			return {
				id: id,
				key: id,
				displayName: (
					declaration?.attribute.displayName ??
                    <Alert type="error"
                           message="Атрибут не найден"
                    />
				),
				type: translateType(declaration?.attribute.type),
			};
		}));

		setMappedSourceAttributes(mappedAttributeDeclarationsIds.map(id => {
			const sourceAttributeColumn = mapping[id];
			const sourceAttribute = sourceAttributesMap.get(sourceAttributeColumn);

			const suitableSourceAttributes = Array.from(sourceAttributesMap.entries())
				.filter(([ , value ]) => {
					const declaration = attributeDeclarations.find(x => x.id === id);
					const declarationType = declaration.attribute.type.toLowerCase();
					return value.external_col.mdm_types.includes(declarationType);
				})
				.map(([ key, value ]) => ({
					label: value.external_col.column_name,
					value: key,
				}));

			return {
				id: id,
				key: id,
				displayName: (
					<DropdownSelect options={suitableSourceAttributes}
									value={sourceAttributeColumn}
									onChange={v => {
										setMapping((prev) => ({
											...prev,
											[id]: v,
										}));
									}}/>
				),
				type: sourceAttribute?.external_col?.data_type,
				actions: (
					<Button type="text"
							icon={<RiDeleteBinLine size={16}/>}
							onClick={() => {
								setMappedAttributeDeclarationsIds(prev =>
									prev
										.filter(x => x !== id),
								);
								setMapping(prev =>
									Object.fromEntries(
										Object.entries(prev)
											.filter(([ key ]) => key !== id),
									),
								);
							}}
					/>
				),
			};
		}));
	}, [ mappedAttributeDeclarationsIds, attributeDeclarations, mapping ]);

	const columns = [
		{
			title: 'Наименование поля справочника',
			dataIndex: 'displayName',
			key: 'displayName',
		},
		{
			title: 'Тип данных',
			dataIndex: 'type',
			key: 'type',
		},
	];

	const sourceAttributeTableColumns = [
		{
			title: 'Наименование поля источника',
			dataIndex: 'displayName',
			key: 'displayName',
		},
		{
			title: 'Тип данных',
			dataIndex: 'type',
			key: 'type',
			width: 200,
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
		},
	];

	const handleSubmit = async () => {
		const data = Object.entries(mapping)
			.reduce((acc, [ key, value ]) => {
				const { extraction_target, external_col } = sourceAttributesMap.get(value);

				const target = acc.has(extraction_target)
					? acc.get(extraction_target)
					: {};
				target[external_col.column_name] = key;
				return acc.set(extraction_target, target);
			}, new Map<string, Record<string, string>>);

		const mappingData = Object.fromEntries(data);

		try {
			await updateMapping({
				connectionId: integrationId,
				configurationId: exchangeConfigId,
				mappingSchema: {
					data: mappingData,
				},
			}).unwrap();
			onUpsert && onUpsert();
		} catch (err) {
			//	...
		}
	};

	useEffect(() => {
		if (!config?.mapping) {
			return;
		}

		setMappedAttributeDeclarationsIds(
			Object.values(config.mapping)
				.flatMap(x => Object.values(x)),
		);

		setMapping(Object.fromEntries(Object.entries(config.mapping)
			.reduce((acc, [ extraction_target, value ]) => {
				Object.entries(value)
					.forEach(([ key, value ]) => acc.push([
						value,
						`${extraction_target}.${key}`,
					]));
				return acc;
			}, [])));
	}, [ config ]);

	if (isLoading || loadingAttributeDeclarations || loadingMetadata) {
		return (
			<Spin/>
		);
	}

	if (errorAttributeDeclarations) {
		return (
			<Result status="error"
					title="Ошибка"
					subTitle={JSON.stringify(errorAttributeDeclarations)}
			/>
		);
	}

	return (
		<div>
			<AddMappingAttribute catalogId={config.destination}
								 selectedAttributeDeclarationIds={mappedAttributeDeclarationsIds}
								 onSelect={setMappedAttributeDeclarationsIds}
			/>

			{!mappedAttributeDeclarations.length && (
				<Placeholder
					title="Не выбрано атрибутов для сопоставления"
					subTitle="Для маппинга атрибутов необходимо добавить нужные атрибуты, нажав на кнопку «Добавить атрибут»"
				/>
			)}

			{mappedAttributeDeclarations.length > 0 && (
				<>
					<Flex align={'stretch'} gap={12}>
						<Table columns={columns}
							   dataSource={mappedAttributeDeclarations}
							   rowKey="id"
							   pagination={false}
							   style={{ flex: 1 }}
							   rowClassName={() => s.row}
						/>

						<div style={{
							width: 1,
							alignSelf: 'stretch',
							background: '#E0E0E0',
						}}/>

						<Table columns={sourceAttributeTableColumns}
							   dataSource={mappedSourceAttributes}
							   rowKey="id"
							   pagination={false}
							   style={{ flex: 1 }}
							   rowClassName={() => s.row}
						/>
					</Flex>

					<Button
						onClick={handleSubmit}
						style={{ marginTop: '16px', width: '100%' }}
						icon={<RiSaveLine/>}
					>
						Сохранить настройки маппинга
					</Button>
				</>
			)}
		</div>
	);
});

export {
	SetupMapping,
};
