import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ColorPicker, DatePicker, Input, InputNumber, Form, Button, Alert, Flex } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import type { Rule } from 'rc-field-form/lib/interface';
import React, { ReactElement } from 'react';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { RelationToCatalogInput, RelationToClassifierInput } from 'features/attributes';
import {
	AttributeResponse,
	CatalogAttributeDeclarationResponse,
	ClassifierAttributeDeclarationResponse,
} from '../api/generatedApi/mdmgApi';
import { BooleanInput, FileInput } from '../components';
import { Restrictions } from '../types';
import { DropdownSelect } from '../ui';
import { CellTypesEnum } from './CellValueParser';


const safeCreateRegExp = (mask: string) => {
	try {
		if (mask == null || mask == '') {
			return;
		}
		return new RegExp(mask);
	} catch {
		return;
	}
};


const inputFactory = (
	attribute: AttributeResponse,
	attributeDeclaration?: CatalogAttributeDeclarationResponse | ClassifierAttributeDeclarationResponse,
	callback?: () => void,
	selectOptions?: DefaultOptionType[],
): ReactElement | { element: ReactElement, rules?: Rule[] } => {
	const restrictions = {
		...(attribute.restrictions ?? {}),
		...(attributeDeclaration?.restrictions ?? {}),
	} as never as Restrictions;

	const { maxLength, mask } = restrictions;
	const maskRegExp = safeCreateRegExp(mask);

	if (selectOptions && selectOptions.length > 0) {
		return (
			<DropdownSelect options={selectOptions}
							onChange={callback}
							allowClear
			/>
		);
	}

	switch (attribute.type) {
		case CellTypesEnum.COLOR:
			return (
				<ColorPicker size="small"
							 disabledAlpha
							 showText
							 allowClear
							 onChangeComplete={callback}
				/>
			);

		case CellTypesEnum.DATETIME:
			return (
				<DatePicker onChange={callback}/>
			);

		case CellTypesEnum.BOOLEAN:
			return (
				<BooleanInput onChange={callback}/>
			);

		case CellTypesEnum.INT:
		case CellTypesEnum.FLOAT:
			return (
				<InputNumber onBlur={callback}
							 controls={false}
				/>
			);

		case CellTypesEnum.RELATION: {
			const classifierId = (attribute.restrictions?.scopeIds as (string[] | null))?.[0];
			if (classifierId) {
				return <RelationToClassifierInput classifierId={classifierId} onChange={callback}/>;
			}

			const catalogIds = attributeDeclaration?.restrictions?.scopeIds as (string[] | null);
			if (catalogIds?.length > 0) {
				return <RelationToCatalogInput catalogIds={catalogIds} onChange={callback}/>;
			}

			return <Alert type="error"
						  message="Атрибут не связан ни с одним из справочников или классификатором"
			/>;
		}

		case CellTypesEnum.TEXT:
		case CellTypesEnum.STRING:
			return ({
				rules: [
					...[ maxLength != null ? {
						max: Number(maxLength),
						message: `Максимальная длинна ${maxLength} символов`,
					} : {} ],
					...[ maskRegExp != null ? {
						pattern: maskRegExp,
						message: 'Введенное значение не соответствует маске ввода',
					} : {} ],
				],
				element: <Input.TextArea autoSize
										 allowClear
										 onBlur={callback}
				/>,
			});

		case CellTypesEnum.FILE:
			return (
				<FileInput onChange={callback}/>
			);

		default:
			return <Alert type="error"
						  message="Неизвестный тип атрибута"
			/>;

	}
};

export const CellInputParser = (
	key: string,
	attribute: AttributeResponse,
	optionalProps?: {
		callback?: () => void,
		attributeDeclaration?: CatalogAttributeDeclarationResponse | ClassifierAttributeDeclarationResponse,
		selectOptions?: DefaultOptionType[],
		label?: string,
		triggeredWhenAddedInList?: boolean,
		triggeredWhenRemovedFromList?: boolean,
	},
) => {
	const { list: isList } = attribute;

	if (!isList) {
		const result = inputFactory(
			attribute,
			optionalProps?.attributeDeclaration,
			optionalProps?.callback,
			optionalProps?.selectOptions,
		);
		const element = 'element' in result ? result.element : result,
			rules = 'rules' in result ? result.rules : [];
		return (
			<Form.Item key={key}
					   name={key}
					   label={optionalProps?.label}
					   {...(rules?.length > 0 ? { rules } : {})}
			>
				{element}
			</Form.Item>
		);
	}

	return (
		<Form.Item key={key} label={optionalProps?.label}>
			<Form.List key={key} name={key}>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => {
							const result = inputFactory(
								attribute,
								optionalProps?.attributeDeclaration,
								optionalProps?.callback,
								optionalProps?.selectOptions,
							);
							const element = 'element' in result ? result.element : result,
								rules = 'rules' in result ? result.rules : [];
							return (
								<Flex key={key} style={{ marginBottom: 8 }} align="baseline" gap={8}>
									<Form.Item {...restField}
											   {...(rules?.length > 0 ? { rules } : {})}
											   name={name}
											   style={{ flexGrow: 1 }}
									>
										{element}
									</Form.Item>
									<MinusCircleOutlined onClick={() => {
										remove(name);
										(optionalProps?.triggeredWhenRemovedFromList ?? true) && optionalProps?.callback();
									}}/>
								</Flex>
							);
						})}
						<Button type="dashed"
								onClick={() => {
									add();
									(optionalProps?.triggeredWhenAddedInList ?? true) && optionalProps?.callback();
								}}
								icon={<PlusOutlined/>}
						>
							Добавить
						</Button>
					</>
				)}
			</Form.List>
		</Form.Item>
	);
};
