import { RiDeleteBinLine } from '@remixicon/react';
import { App, Button, Checkbox, Flex, Form, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import {
	deleteCatalogById,
	updateCatalogGroup,
} from 'entities/catalogs/catalogGroups/catalog.store';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogsAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { findCatalogById } from 'shared/helpers/findCatalogById';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

interface IDeleteCatalogUi {
	id: string;
}

const DeleteCatalogUi: React.FC<IDeleteCatalogUi> = ({ id }) => {
	const { t } = useTypedTranslation();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [form] = Form.useForm();

	const { notification } = App.useApp();
	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { catalogGroupId } = useParams();
	const { catalogGroups: catalogs } = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups
	);

	const { execute: deleteCatalogAsync } = useAsyncOperation(
		useDeleteCatalogsAsyncMutation,
		TaskType.DELETE_CATALOGS_BACKGROUND_TASK
	);

	const deleteCatalog = async () => {
		setLoading(true);

		const deletingCatalog = findCatalogById(catalogs as DisplayTreeNode[], id);

		const catalogId = (): string => {
			if (catalogGroupId === id) {
				return deletingCatalog.parentId || '';
			}
			if (findCatalogById([deletingCatalog], catalogGroupId) !== null) {
				return '';
			}
			return catalogGroupId || '';
		};

		try {
			await deleteCatalogAsync({ ids: [id] });

			dispatch(deleteCatalogById(id));
			navigate(`/${routes.catalogs.main}/${catalogId()}`);
			getTransactions();

			if (deletingCatalog.parentId) {
				const parentCatalog = findCatalogById(
					catalogs as DisplayTreeNode[],
					deletingCatalog.parentId
				);
				const children = parentCatalog.children.filter((item) => item.id !== id);
				dispatch(
					updateCatalogGroup({
						...parentCatalog,
						...{
							children: [...children],
							parent: children?.length > 0,
							isLeaf: !(children?.length > 0),
						},
					})
				);
			}

			setIsChecked(false);
			setIsModalOpen(false);
		} catch (error) {
			errorHelper(
				t((l) => l.catalogs.deleteCatalog.error),
				error,
				notification
			);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		setIsChecked(false);
		setIsModalOpen(false);
	};

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBinLine size={16} />}
				title={t((l) => l.common.buttons.delete)}
				onClick={() => setIsModalOpen(true)}
			/>

			<Modal
				title={t((l) => l.catalogs.deleteCatalog.title)}
				open={isModalOpen}
				onOk={() => setIsModalOpen(false)}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={deleteCatalog}>
					<Form.Item>
						<Typography.Text>
							{t((l) => l.catalogs.deleteCatalog.alert)}
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
							{t((l) => l.catalogs.deleteCatalog.agreement)}
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							{t((l) => l.common.buttons.cancel)}
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							{t((l) => l.common.buttons.delete)}
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteCatalog = React.memo(DeleteCatalogUi);
