import { RiEditLine } from '@remixicon/react';
import { Flex, Result, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { CreateAttribute } from 'features/metadata/CreateAttribute';
import { DeleteSimpleAttributeUi } from 'features/metadata/deleteSimpleAttribute';
import { useMetaAttributesGroups } from 'entities/metadata/attributesGroups/attributesGroups.model';
import { translateType } from 'entities/metadata/metaAttributes';
import { DataSourceType } from 'entities/metadata/metaAttributes/metaAttributes.model';
import { setCurrentMetaAttribute } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { AttributeDto, useGetAttributesQuery } from 'shared/api/generatedApi/mdmgApi';
import { useGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { routes } from 'shared/config';
import { changeSearchParam, tablePaginationShowTotalFactory } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter, DropdownLink, Placeholder, Search, setSubMenuCollapsed, WorkTable } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { WorkTableColumnsType } from 'shared/ui/components/WorkTable';

const columns: WorkTableColumnsType = [
	{
		title: 'Наименование',
		dataIndex: 'displayName',
		key: 'displayName',
		width: '33%',
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
		width: '33%',
	},
	{
		title: 'Множественный',
		dataIndex: 'isMulti',
		key: 'isMulti',
		width: '33%',
	},
];

const MetadataAttributesListWidgetUi: React.FC = () => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [ searchParams, setSearchParams ] = useSearchParams();
	const { metaAttributeGroupId } = useParams();

	const { currAttrGroup } = useMetaAttributesGroups();

	const [ atrIdArr, setAtrIdArr ] = useState<Array<string>>(null);
	const [ dataSource, setDataSource ] = useState<DataSourceType>([]);

	const [ pagination, setPagination ] = useState<{
		current: number;
		pageSize: number;
		total?: number;
		showTotal?: (n: number) => string;
	}>({
		current: 1,
		pageSize: 10,
	});

	const searchValue = searchParams.get('searchValue');
	const isSearchValueExist = searchValue?.length > 0;

	const { data: metaAttributes, isLoading: loading, error } = useGetAttributesQuery({
		limit: pagination.pageSize,
		page: pagination.current,
		attributeGroupIds: [ metaAttributeGroupId ],
	}, {
		skip: isSearchValueExist || !metaAttributeGroupId,
	});

	const { data: searchResult, isFetching: isSearchItemsLoading } = useGetSearchResultQuery({
		entityType: 'attribute',
		textRequest: searchValue,
		parentId: metaAttributeGroupId,
	}, {
		skip: !isSearchValueExist || !metaAttributeGroupId,
	});

	const searchHandler = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchParams(
			changeSearchParam(searchParams, 'searchValue', e.target.value),
		);
	}, 1000);

	const metaAttributesLoading = loading || isSearchItemsLoading;

	const handleTableChange = (pagination: TablePaginationConfig) => {
		setPagination(prev => ({
			...prev,
			current: pagination.current,
			pageSize: pagination.pageSize,
		}));
	};

	const convertToDatasource = (arr: Array<AttributeDto>): DataSourceType => {
		return arr.map((item) => ({
			...item,
			type: translateType(item.type),
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiEditLine size={16}/>}
									title="Редактировать"
									onClick={() => {
										dispatch(setCurrentMetaAttribute(item));
										navigate(
											`${window.location.pathname}/${routes.metadata.attribute}/${item.id}`,
										);
									}}
								/>
							),
						},
						{
							key: '1',
							label: <DeleteSimpleAttributeUi ids={[ item.id ]}/>,
						},
					]}
				/>
			),
			isMulti: item.list ? 'да' : 'нет',
		}));
	};

	useEffect(() => {
		if (isSearchValueExist) {
			if (searchResult) {
				setDataSource(convertToDatasource(searchResult as AttributeDto[]));
			}
		} else if (metaAttributes) {
			setDataSource(convertToDatasource(metaAttributes.data));
		}
	}, [ isSearchValueExist, metaAttributes, searchResult ]);

	useEffect(() => {
		if (searchParams.size) {
			handleTableChange({});
		}
	}, [ searchParams ]);

	const rowSelection = {
		onChange: (_: React.Key[], selectedRows: DataSourceType) => {
			setAtrIdArr(selectedRows.map((row) => row.id));
		},
	};

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	const tablePagination: TablePaginationConfig = {
		...(isSearchValueExist
			? {
				current: 1,
				pageSize: 20,
				total: dataSource.length,
			} : {
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: metaAttributes?.meta.total,
			}),
		showTotal: tablePaginationShowTotalFactory(t),
	};

	if (!metaAttributeGroupId || !currAttrGroup) {
		return (
			<Placeholder
				title="Не выбрана ни одна группа"
				subTitle="Выберите группу для отображения списка"
			/>
		);
	}

	return (
		<Flex vertical gap={24}>
			{currAttrGroup && (
				<>
					<Search onChange={searchHandler} defaultValue={searchValue}/>
					<Flex>
						<CreateAttribute/>
					</Flex>
				</>
			)}

			{error && (
				<Result
					status="error"
					title="Не удалось загрузить список атрибутов"
					subTitle={JSON.stringify(error)}
				/>
			)}

			{!error && (
				<>
					<WorkTable
						columns={columns}
						dataSource={dataSource}
						loading={metaAttributesLoading}
						pagination={tablePagination}
						rowKey="id"
						onChange={(pagination) => handleTableChange(pagination)}
						onRow={(r) => ({
							onClick: () =>
								navigate(
									`${window.location.pathname}/${routes.metadata.attribute}/${r.id}`,
								),
						})}
						rowSelection={{
							...rowSelection,
						}}
						scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}

					/>

					<DetailFooter isNavHidden={true}>
						<DeleteSimpleAttributeUi ids={atrIdArr} isButton={true}/>
					</DetailFooter>
				</>
			)}
		</Flex>
	);
};

export const MetadataAttributesListWidget = React.memo(MetadataAttributesListWidgetUi);
