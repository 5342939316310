import { App, Flex, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
    ContextNameResponse, useUpdateContextNameMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

interface IEditCompatibilityItemProps {
    item: ContextNameResponse;
}

const EditCompatibilityUi: React.FC<IEditCompatibilityItemProps> = ({ item }) => {

    const { t } = useTypedTranslation();
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const [updateContextName] = useUpdateContextNameMutation();
    const [form] = Form.useForm();

    const editCompatibilityItem = async () => {
        try {
            await updateContextName({
                id: item.id,
                updateContextNameRequest: form.getFieldsValue(),
            });
        } catch (error) {
            resetForm();
            errorHelper(
                t((l) => l.compatibilities.error.editingCompatibilityItem),
                error,
                notification
            );
        } finally {
            dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
        }
    };

    const resetForm = () => {
        form.setFieldsValue({
            leftEntityName: item?.leftEntityName,
            contextDisplayName: item?.contextDisplayName,
            rightEntityName: item?.rightEntityName,
            inverseContextDisplayName: item?.inverseContextDisplayName
        });
    };

    useEffect(() => {
        resetForm();
    }, [item]);

    return (
        <Flex vertical gap={12} style={{ maxWidth: 920 }}>
            <Typography.Title level={2}>{t((l) => l.compatibilities.mainInfo)}</Typography.Title>
            <Form layout="vertical" form={form}>
                <Form.Item label={t((l) => l.compatibilities.item.leftEntityName)} name="leftEntityName">
                    <Input allowClear onBlur={editCompatibilityItem} />
                </Form.Item>
                <Form.Item label={t((l) => l.compatibilities.item.contextDisplayName)} name="contextDisplayName">
                    <Input allowClear onBlur={editCompatibilityItem} />
                </Form.Item>
                <Form.Item label={t((l) => l.compatibilities.item.rightEntityName)} name="rightEntityName">
                    <Input allowClear onBlur={editCompatibilityItem} />
                </Form.Item>
                <Form.Item label={t((l) => l.compatibilities.item.inverseContextDisplayName)} name="inverseContextDisplayName">
                    <Input allowClear onBlur={editCompatibilityItem} />
                </Form.Item>

            </Form>
        </Flex>
    );
};

export const EditCompatibility = React.memo(EditCompatibilityUi);
