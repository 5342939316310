import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { DeleteIntegration, EditIntegrationMainInfo } from 'features/integrations';
import { routes } from 'shared/config';
import { DetailFooter } from 'shared/ui';

interface IntegrationConnectionInfoWidgetProps {
	integrationId: string;
}

const IntegrationConnectionInfoWidget = memo((
	{
		integrationId,
	} : IntegrationConnectionInfoWidgetProps
) => {
	const navigate = useNavigate();

	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);

	return (
		<>
			<EditIntegrationMainInfo integrationId={integrationId}/>

			<DetailFooter customHandleBack={customHandleBack}>
				<DeleteIntegration id={integrationId}
								   isButton
								   onDelete={customHandleBack}
				/>
			</DetailFooter>
		</>
	);
});

export {
	IntegrationConnectionInfoWidget
};