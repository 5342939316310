import { RiArrowDownSLine } from '@remixicon/react';
import { App, Dropdown, Flex } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
	useGetMeasurementsQuery,
	useLazyConvertQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useHandleQueryError, useTypedTranslation } from 'shared/hooks';

interface IUnitValue {
	value: number | null | undefined | void;
	measurementId: string;
}

interface IUnitConverter {
	measurementGroupId: string;
	value: IUnitValue;

	onChange: (newValue: IUnitValue, oldValue: IUnitValue) => void;
}

export const UnitConverter: React.FC<IUnitConverter> = ({
															measurementGroupId,
															value,
															onChange,
														}) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const [ currentMeasurementId, setCurrentMeasurementId ] = useState<string | null>(null);

	const [ fetchConvert ] = useLazyConvertQuery();
	const { data: units } = useHandleQueryError(
		useGetMeasurementsQuery({ measurementGroupId }),
		(t) => t.catalogs.records.getMeasurementsErr,
	);

	const dropdownTitle = units
		?.find((x) => x.id === currentMeasurementId)
		?.shortName;

	const items = useMemo(() =>
			units?.map((x) => ({
				label: x.shortName,
				key: x.id,
			})) ?? [],
		[ units ]);

	const convertToOtherUnits = async () => {

		if (typeof value.value !== 'number' || Number.isNaN(value.value)) {
			return;
		}

		if (!currentMeasurementId) {
			return;
		}

		const currentValue = Number(value.value);
		const fromMeasurementId = value.measurementId;

		if (value.measurementId === currentMeasurementId) {
			return onChange(
				{ value: currentValue, measurementId: currentMeasurementId },
				{ value: currentValue, measurementId: fromMeasurementId },
			);
		}

		try {
			const newValue = await fetchConvert({
				fromMeasurementId,
				toMeasurementId: currentMeasurementId,
				value: currentValue,
			})
				.unwrap();
			return onChange(
				{ value: newValue, measurementId: currentMeasurementId },
				{ value: currentValue, measurementId: fromMeasurementId },
			);
		} catch (err) {
			errorHelper(
				t((l) => l.catalogs.records.converterErr),
				err,
				notification,
			);
		}
	};

	useEffect(() => {
		convertToOtherUnits().then();
	}, [ value, currentMeasurementId ]);

	useEffect(() => {
		setCurrentMeasurementId(value.measurementId);
	}, []);

	return (
		<Dropdown trigger={[ 'click' ]} menu={{ items, onClick: e => setCurrentMeasurementId(e.key) }}>
			<Flex gap={2} align="center" style={{ cursor: 'pointer' }}>
				{dropdownTitle} <RiArrowDownSLine size={16}/>
			</Flex>
		</Dropdown>
	);
};
