import { RiCloseLine, RiFolderCheckLine } from '@remixicon/react';
import { Button, Flex, Form, Modal, Skeleton, Spin, TreeSelectProps } from 'antd';
import { ChangeEventExtra } from 'rc-tree-select/lib/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AttributeDto, CatalogItemResponse } from 'shared/api/generatedApi/mdmgApi';
import { randomString, sortStrings } from 'shared/helpers';
import { ItemValuesType } from 'shared/helpers/types';
import { colors } from 'shared/styles';
import { Chip, WorkTable } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { WorkTableColumnsType } from 'shared/ui/components/WorkTable';
import { useEditRelationAttribute } from './editRelationAttribute.model';
import s from './editRelationAttribute.module.scss';
import { formStyles } from './editRelationAttributeStyles';

const columns: WorkTableColumnsType<IRow> = [
	{
		title: 'Наименование',
		dataIndex: 'name',
		key: 'name',
		sorter: {
			compare: (a, b) => sortStrings(a.name, b.name),
			multiple: 1,
		},
		width: '95%',
	},
];

interface IRow {
	key: string;
	name: string;
	id: string;
	pid: string;
}

interface IEditRelationAttributeUiProps {
	value: ItemValuesType[];
	attributeDeclaration: AttributeDto;
	editInfo: (attributeId: string, newValue: ItemValuesType[]) => Promise<void>;
}

const EditRelationAttributeUi = ({
	attributeDeclaration,
	editInfo,
	value,
}: IEditRelationAttributeUiProps) => {
	const {
		getRootGroups,
		isRootGroupsLoading,
		getSubGroups,
		treeData,
		clearTreeData,
		getProperties,
		isCatalogItemsLoading,
		isTableColumnsLoading,
		chips,
	} = useEditRelationAttribute(value);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [selectedOptions, setSelectedOptions] = useState<React.Key[]>([]);

	const [dataSource, setDataSource] = useState<IRow[]>([]);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

	const [addedColumns, setAddedColumns] = useState<string[]>([]);
	const [deletedColumns, setDeletedColumns] = useState<string[]>([]);

	const showModal = () => {
		setIsModalOpen(true);
		getRootGroups();
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => getSubGroups(id);

	const handleOk = () => {
		editInfo(attributeDeclaration.id, selectedRows as string[]).finally(() => {
			setAddedColumns([]);
			setDeletedColumns([]);
			clearTreeData();
			setIsModalOpen(false);
		});
	};

	const handleCancel = useCallback(() => {
		setAddedColumns([]);
		setDeletedColumns([]);
		clearTreeData();
		setSelectedRows(chips?.map((col) => col.id));
		setIsModalOpen(false);
	}, [chips]);

	const propertiesToRowsMap = (properties: CatalogItemResponse[]) =>
		properties.map((property) => {
			return {
				key: property.id,
				name: property?.displayName || '<Название не заполнено>',
				id: property.id,
				pid: property.catalogId,
			};
		});

	useEffect(() => {
		if (chips) {
			setSelectedRows(chips.map((col) => col.id));
		}
	}, [chips]);

	const onSelectChange = (
		newSelected: React.Key[],
		_: React.ReactNode[],
		extra: ChangeEventExtra
	) => {
		setSelectedOptions(newSelected);
		if (extra.checked) {
			getProperties(extra.triggerValue.toString()).then((res) => {
				if (res) {
					setDataSource((prev) => [
						...(newSelected.length > 1 ? prev : []),
						...propertiesToRowsMap(res),
					]);
				}
			});
		} else if (newSelected.length > 0) {
			const newDataSource = dataSource.filter(
				(row) => row.pid !== extra.triggerValue.toString()
			);
			setDataSource(newDataSource);
		} else {
			setDataSource([]);
		}
	};

	const onDeleteColumn = (deleteId: string) => {
		editInfo(
			attributeDeclaration.id,
			value.filter((id) => id !== deleteId)
		);
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отменить
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={addedColumns.length === 0 && deletedColumns.length === 0}
				>
					Подтвердить
				</Button>
			</Flex>
		),
		[addedColumns, deletedColumns]
	);

	const rowSelection = {
		onSelect: (selectedRow: IRow, selected: boolean) => {
			const existingRow = chips?.find((col) => col.id === selectedRow.id);
			if (existingRow) {
				selected
					? setDeletedColumns((prev) => prev.filter((id) => id !== existingRow.id))
					: setDeletedColumns((prev) => [...prev, existingRow.id]);
			} else {
				selected
					? setAddedColumns((prev) => [...prev, selectedRow.id])
					: setAddedColumns((prev) => prev.filter((id) => id !== selectedRow.id));
			}
			selected
				? setSelectedRows((prev) => [...prev, selectedRow.key])
				: setSelectedRows((prev) => prev.filter((id) => id !== selectedRow.key));
		},
		selectedRowKeys: selectedRows,
		getCheckboxProps: (record: { [key: string]: string }) => ({
			disabled: record.name === 'Disabled User',
			name: record.name,
		}),
	};

	return (
		<>
			<Form layout="vertical" style={formStyles}>
				{isTableColumnsLoading ? (
					<Skeleton.Input active block />
				) : (
					<div className={s.container}>
						<div className={s.input}>
							{chips?.map((item) => (
								<Chip
									selectChip
									cancel={
										<RiCloseLine
											size={10}
											color={colors.grayIcon}
											className={s.icon}
											onClick={() => onDeleteColumn(item.id)}
										/>
									}
									key={item.id}
								>
									{item.displayName}
								</Chip>
							))}
						</div>

						<Flex gap={4} align="center" className={s.controls}>
							<RiFolderCheckLine
								size={16}
								color={colors.grayIcon}
								onClick={showModal}
								className={s.icon}
							/>
						</Flex>
					</div>
				)}
			</Form>
			<Modal
				title="Выбор наименования колонки"
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1288}
				footer={footerButtons}
			>
				{isRootGroupsLoading ? (
					<Flex style={{ width: '100%', height: '100%' }} align="center" justify="center">
						<Spin />
					</Flex>
				) : (
					<Flex vertical gap={24}>
						<Form layout="vertical">
							<Form.Item label="Наименование группы">
								<DropdownTreeSelect
									treeDataSimpleMode
									treeData={treeData}
									treeCheckable
									treeCheckStrictly
									showCheckedStrategy="SHOW_PARENT"
									loadData={onLoadData}
									allowClear
									onChange={onSelectChange}
									value={selectedOptions}
									showSearch={false}
								/>
							</Form.Item>
						</Form>
						<WorkTable
							loading={isCatalogItemsLoading}
							columns={columns}
							dataSource={dataSource}
							pagination={{
								showTotal: (total) => `Всего: ${total}`,
								pageSizeOptions: [10, 20, 30],
								showSizeChanger: true,
								size: 'small',
							}}
							rowSelection={rowSelection}
						/>
					</Flex>
				)}
			</Modal>
		</>
	);
};

export const EditRelationAttribute = React.memo(EditRelationAttributeUi);
