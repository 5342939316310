import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Modal, notification, Result } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { CatalogItemsTable } from 'features/catalogs';
import { ClassifierItemsTable } from 'features/classifiers';
import { useCreateCompatibilityItemsMutation } from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { SelectCatalogInput, SelectClassifierInput } from 'shared/components';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

enum CompatibilitiesItemsCreationWidgetTab {
	Catalogs = 'Catalogs',
	Classifiers = 'Classifiers',
}

interface ICompatibilitiesItemsCreationWidgetProps {
	compatibilityId?: string;
	currentEntityId?: string;
}

export const CompatibilitiesItemsCreationWidget: React.FC<ICompatibilitiesItemsCreationWidgetProps> = ({
	compatibilityId,
	currentEntityId,
}) => {
	const { t } = useTypedTranslation();

	const tabNames: Record<CompatibilitiesItemsCreationWidgetTab, string> = {
		[CompatibilitiesItemsCreationWidgetTab.Catalogs]: t((l) => l.compatibilities.tabCatalogs),
		[CompatibilitiesItemsCreationWidgetTab.Classifiers]: t((l) => l.compatibilities.tabClassifiers),
	};

	const dispatch = useAppDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [catalogIds, setCatalogIds] = useState<string | null>(null);
	const [secondItemIds, setSecondItemIds] = useState<string[]>([]);
	const [classifierId, setClassifierId] = useState<string | null>(null);

	const [createCompatibilityItems] = useCreateCompatibilityItemsMutation();

	const onSubmit = useCallback(async () => {
		try {
			await createCompatibilityItems({
				createCompatibilityItemRequest: {
					compatibilityId,
					firstItemId: currentEntityId,
					secondItemIds,
				},
			});
			setIsOpen(false);
			dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
		} catch (error) {
			errorHelper(
				t((l) => l.compatibilities.error.addCompatibilitiesItemsErr),
				error,
				notification
			);
		}
	}, [compatibilityId, currentEntityId, secondItemIds]);

	const footerButtons = useMemo(() => {
		return (
			<Flex justify="end" gap={8}>
				<Button type="text" onClick={() => setIsOpen(false)}>
					{t((l) => l.common.buttons.cancel)}
				</Button>
				<Button type="primary" onClick={onSubmit}>
					{t((l) => l.common.buttons.select)}
				</Button>
			</Flex>
		);
	}, [compatibilityId, currentEntityId, secondItemIds]);

	const [activeTab, setActiveTab] = useState<CompatibilitiesItemsCreationWidgetTab>(
		CompatibilitiesItemsCreationWidgetTab.Catalogs
	);

	const createTabButton = useCallback(
		(tab: CompatibilitiesItemsCreationWidgetTab) => (
			<Button
				name="active"
				type={activeTab === tab ? 'primary' : undefined}
				onClick={() => setActiveTab(tab)}
			>
				{tabNames[tab]}
			</Button>
		),
		[activeTab, t]
	);

	const content = {
		[CompatibilitiesItemsCreationWidgetTab.Catalogs]: (
			<>
				<Flex gap={4} vertical>
					{t((l) => l.catalogs.catalogs)}
					<SelectCatalogInput
						onChange={(value) => {
							setCatalogIds(value as string);
						}}
					/>
				</Flex>
				{catalogIds ? (
					<CatalogItemsTable
						catalogId={catalogIds}
						includeSubCatalogs={false}
						multipleSelect={true}
						selectable={true}
						onSelect={setSecondItemIds}
					/>
				) : (
					<Result
						status="info"
						title={t((l) => l.catalogs.groups.noCatalog)}
						subTitle={t((l) => l.catalogs.groups.selectGroup)}
					/>
				)}
			</>
		),
		[CompatibilitiesItemsCreationWidgetTab.Classifiers]: (
			<>
				<Flex gap={4} vertical>
					{t((l) => l.classifier.mainPage.title)}
					<SelectClassifierInput
						onChange={(value) => {
							setClassifierId(value as string);
						}}
					/>
				</Flex>
				{classifierId ? (
					<ClassifierItemsTable
						classifierId={classifierId}
						onSelect={setSecondItemIds}
						selectable={true}
						multipleSelect={true}
					/>
				) : (
					<Result
						status="info"
						title={t((l) => l.classifier.common.noClassifier)}
						subTitle={t((l) => l.classifier.common.selectClassifier)}
					/>
				)}
			</>
		),
	};

	return (
		<>
			<DropdownLink
				title={t((l) => l.compatibilities.actions.addCompatibilitiesItems)}
				icon={<RiAddCircleLine size={16} />}
				onClick={() => setIsOpen(true)}
			/>

			<Modal
				title={t((l) => l.compatibilities.choosingRecordTitle)}
				open={isOpen}
				onClose={() => setIsOpen(false)}
				footer={footerButtons}
				centered
				closable={false}
				width={'calc(100vw - 200px)'}
				styles={{
					body: {
						height: 'calc(100vh - 300px)',
						overflow: 'auto',
					},
				}}
			>
				<Flex vertical gap={12} style={{ height: '100%' }}>
					<Flex gap={8}>
						{Object.values(CompatibilitiesItemsCreationWidgetTab).map((x) => (
							<div key={x}>{createTabButton(x)}</div>
						))}
					</Flex>

					{!!activeTab && content[activeTab]}
				</Flex>
			</Modal>
		</>
	);
};
