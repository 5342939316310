import { Flex, Form } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import React, { useMemo } from 'react';
import {
	CatalogAttributeDeclarationResponse,
	ClassifierAttributeDeclarationResponse,
} from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';
import { Localization } from 'shared/translations';
import { DropdownSelect } from 'shared/ui';

export type CompatibilityConditionAttributes =
	CatalogAttributeDeclarationResponse
	| ClassifierAttributeDeclarationResponse;

interface CompatibilityConditionProps {
	formItemPath: Array<string | number>;
	firstAttributes: CompatibilityConditionAttributes[];
	secondAttributes: CompatibilityConditionAttributes[];
}

enum ComparisonType {
	EQUALS = 'EQUALS',
	NOT_EQUALS = 'NOT_EQUALS',
	CONTAINS = 'CONTAINS',
	NOT_CONTAINS = 'NOT_CONTAINS',
	EMPTY = 'EMPTY',
	NOT_EMPTY = 'NOT_EMPTY',
}

const comparisonTypeTranslation: Record<ComparisonType, (l: Localization) => string> = {
	[ComparisonType.EQUALS]: (l) => l.common.filters.equal,
	[ComparisonType.NOT_EQUALS]: (l) => l.common.filters.notEqual,
	[ComparisonType.CONTAINS]: (l) => l.common.filters.contains,
	[ComparisonType.NOT_CONTAINS]: (l) => l.common.filters.notContains,
	[ComparisonType.EMPTY]: (l) => l.common.filters.empty,
	[ComparisonType.NOT_EMPTY]: (l) => l.common.filters.notEmpty,
};

const CompatibilityCondition = (
	{
		formItemPath,
		firstAttributes,
		secondAttributes,
	}: CompatibilityConditionProps) => {

	const { t } = useTypedTranslation();

	const firstAttributeOptions = useMemo(() =>
			firstAttributes
				.map<DefaultOptionType>(x => ({
					label: x.attribute.displayName,
					value: x.id,
				})),
		[ firstAttributes ]);

	const comparisonOptions = useMemo(() =>
			Object.values(ComparisonType)
				.map<DefaultOptionType>(x => ({
					label: t(comparisonTypeTranslation[x]),
					value: x,
				})),
		[ t ]);

	const secondAttributeOptions = useMemo(() =>
			secondAttributes
				.map<DefaultOptionType>(x => ({
					label: x.attribute.displayName,
					value: x.id,
				})),
		[ secondAttributes ]);

	const formItemName = formItemPath[formItemPath.length - 1];

	return (
		<Flex align="baseline" gap={8} style={{ width: '100%' }}>
			<div style={{ flex: 1 }}>
				<Form.Item name={[ formItemName, 'firstDeclarationId' ]}>
					<DropdownSelect options={firstAttributeOptions}/>
				</Form.Item>
			</div>
			<div style={{ flex: 1 }}>
				<Form.Item name={[ formItemName, 'condition' ]}>
					<DropdownSelect options={comparisonOptions}/>
				</Form.Item>
			</div>
			<div style={{ flex: 1 }}>
				<Form.Item name={[ formItemName, 'secondDeclarationId' ]}>
					<DropdownSelect options={secondAttributeOptions}/>
				</Form.Item>
			</div>
		</Flex>
	);
};

export {
	CompatibilityCondition,
};