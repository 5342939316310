import { RiCloseLine, RiExpandUpDownLine, RiFolderCheckLine } from '@remixicon/react';
import { Button, Checkbox, Flex, Form, Modal, Skeleton, Spin, TreeSelectProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ChangeEventExtra } from 'rc-tree-select/lib/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translateType } from 'entities/metadata/metaAttributes';
import { AttributeDto } from 'shared/api/generatedApi/mdmgApi';
import { randomString, sortStrings } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Chip, WorkTable } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { WorkTableColumnsType } from 'shared/ui/components/WorkTable';
import { useAssignColumns } from './assignColumns.model';
import s from './assignColumns.module.scss';
import { formStyles } from './assignColumnStyles';

interface IRow {
	key: string;
	name: string;
	type: string;
	multiple: string;
	id: string;
	pid: string;
}

const AssignColumnUi = () => {
	const { t } = useTypedTranslation();
	const {
		getRootGroups,
		isRootGroupsLoading,
		getSubGroups,
		treeData,
		clearTreeData,
		getProperties,
		changeColumns,
		isAttributesLoading,
		isPatchTableLoading,
		isTableColumnsLoading,
		tableColumns,
	} = useAssignColumns();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedOptions, setSelectedOptions] = useState<React.Key[]>([]);
	const [dataSource, setDataSource] = useState<IRow[]>([]);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [addedColumns, setAddedColumns] = useState<string[]>([]);
	const [deletedColumns, setDeletedColumns] = useState<string[]>([]);

	const columns: WorkTableColumnsType<IRow> = useMemo(() => {
		return [
			{
				title: t((l) => l.common.defaultNames.name),
				dataIndex: 'name',
				key: 'name',
				sorter: {
					compare: (a, b) => sortStrings(a.name, b.name),
					multiple: 1,
				},
				width: '30%',
				sortIcon: () => <RiExpandUpDownLine size={16} />,
				showSorterTooltip: false,
			},
			{
				title: t((l) => l.common.defaultNames.type),
				dataIndex: 'type',
				key: 'type',
				sorter: {
					compare: (a, b) => sortStrings(a.type, b.type),
					multiple: 2,
				},
				width: '30%',
				sortIcon: () => <RiExpandUpDownLine size={16} />,
				showSorterTooltip: false,
			},
			{
				title: t((l) => l.common.defaultNames.isMulti),
				dataIndex: 'multiple',
				key: 'multiple',
				sorter: {
					compare: (a, b) => sortStrings(a.multiple, b.multiple),
					multiple: 3,
				},
				width: '30%',
				sortIcon: () => <RiExpandUpDownLine size={16} />,
				showSorterTooltip: false,
			},
		];
	}, [t]);

	const indeterminate = useMemo(() => {
		return selectedRows?.length > 0 && selectedRows?.length < dataSource?.length;
	}, [selectedRows, dataSource]);

	const isAllChecked = useMemo(() => {
		return selectedRows?.length === dataSource?.length;
	}, [selectedRows, dataSource]);

	const showModal = () => {
		setIsModalOpen(true);
		getRootGroups();
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => getSubGroups(id);

	const handleOk = () => {
		changeColumns(selectedRows).finally(() => {
			clearTreeData();
			setIsModalOpen(false);
		});
	};

	const handleCancel = useCallback(() => {
		clearTreeData();
		setSelectedRows(tableColumns?.map((col) => col.id));
		setIsModalOpen(false);
	}, [tableColumns]);

	const propertiesToRowsMap = (properties: AttributeDto[]) =>
		properties.map((property) => {
			return {
				key: property.id,
				name: property.displayName,
				type: translateType(property.type),
				multiple: property.list
					? t((l) => l.common.defaultNames.yes)
					: t((l) => l.common.defaultNames.no),
				id: property.id,
				pid: property.attributeGroupId,
			};
		});

	const onSelectChange = (
		newSelected: React.Key[],
		_: React.ReactNode[],
		extra: ChangeEventExtra
	) => {
		setSelectedOptions(newSelected);
		if (extra.checked) {
			getProperties(extra.triggerValue.toString()).then((res) => {
				if (res) {
					setDataSource((prev) => [
						...(newSelected.length > 1 ? prev : []),
						...propertiesToRowsMap(res),
					]);
				}
			});
		} else if (newSelected.length > 0) {
			const newDataSource = dataSource.filter(
				(row) => row.pid !== extra.triggerValue.toString()
			);
			setDataSource(newDataSource);
		} else {
			setDataSource([]);
		}
	};

	const onDeleteColumn = (id: string) => {
		changeColumns(selectedRows.filter((key) => key !== id));
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t((l) => l.common.buttons.cancel)}
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={addedColumns.length === 0 && deletedColumns.length === 0}
					loading={isPatchTableLoading}
				>
					{t((l) => l.common.buttons.confirm)}
				</Button>
			</Flex>
		),
		[isPatchTableLoading, addedColumns, deletedColumns]
	);

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		const dataSourcesIds = dataSource?.map((col) => col.id);
		const tableColumnsIds = tableColumns?.map((col) => col.id);

		if (e.target.checked) {
			setSelectedRows(dataSource?.map((col) => col.id));
			setAddedColumns(dataSourcesIds?.filter((id) => !tableColumnsIds?.includes(id)) || []);
		} else {
			setSelectedRows([]);
			setDeletedColumns(tableColumnsIds?.filter((id) => dataSourcesIds?.includes(id)) || []);
		}
	};

	const rowSelection = {
		onSelect: (selectedRow: IRow, selected: boolean) => {
			const existingRow = tableColumns?.find((col) => col.id === selectedRow.id);
			if (existingRow) {
				selected
					? setDeletedColumns((prev) => prev.filter((id) => id !== existingRow.id))
					: setDeletedColumns((prev) => [...prev, existingRow.id]);
			} else {
				selected
					? setAddedColumns((prev) => [...prev, selectedRow.id])
					: setAddedColumns((prev) => prev.filter((id) => id !== selectedRow.id));
			}
			selected
				? setSelectedRows((prev) => [...prev, selectedRow.key])
				: setSelectedRows((prev) => prev.filter((id) => id !== selectedRow.key));
		},
		selectedRowKeys: selectedRows,
		columnTitle: (
			<Checkbox
				indeterminate={indeterminate}
				checked={isAllChecked}
				onChange={(e) => onCheckAllChange(e)}
			/>
		),
	};

	useEffect(() => {
		if (tableColumns) {
			setSelectedRows(tableColumns.map((col) => col.id));
		}
	}, [tableColumns]);

	return (
		<>
			<Form layout="vertical" style={formStyles}>
				<Form.Item label={t((l) => l.constraintTables.assignColumnLabel)}>
					{isTableColumnsLoading || isPatchTableLoading ? (
						<Skeleton.Input active block />
					) : (
						<div className={s.container}>
							<div className={s.input}>
								{tableColumns?.map((item) => (
									<Chip
										selectChip
										cancel={
											<RiCloseLine
												size={10}
												color={colors.grayIcon}
												className={s.icon}
												onClick={() => onDeleteColumn(item.id)}
											/>
										}
										key={item.id}
									>
										{item.displayName}
									</Chip>
								))}
							</div>

							<Flex gap={4} align="center" className={s.controls}>
								<RiFolderCheckLine
									size={16}
									color={colors.grayIcon}
									onClick={showModal}
									className={s.icon}
								/>
							</Flex>
						</div>
					)}
				</Form.Item>
			</Form>
			<Modal
				title={t((l) => l.constraintTables.assignColumnModalTitle)}
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1288}
				footer={footerButtons}
			>
				{isRootGroupsLoading ? (
					<Flex style={{ width: '100%', height: '100%' }} align="center" justify="center">
						<Spin />
					</Flex>
				) : (
					<Flex vertical gap={24}>
						<Form layout="vertical">
							<Form.Item label={t((l) => l.common.defaultNames.groupName)}>
								<DropdownTreeSelect
									treeDataSimpleMode
									treeData={treeData}
									treeCheckable
									treeCheckStrictly
									showCheckedStrategy="SHOW_PARENT"
									loadData={onLoadData}
									allowClear
									onChange={onSelectChange}
									value={selectedOptions}
									showSearch={false}
								/>
							</Form.Item>
						</Form>
						<WorkTable
							loading={isAttributesLoading}
							columns={columns}
							dataSource={dataSource}
							pagination={{
								showTotal: (total) =>
									`${t((l) => l.common.tables.total)}: ${total}`,
								pageSizeOptions: [10, 20, 30],
								showSizeChanger: true,
								size: 'small',
							}}
							rowSelection={rowSelection}
						/>
					</Flex>
				)}
			</Modal>
		</>
	);
};

export const AssignColumn = React.memo(AssignColumnUi);
