import { Flex, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { UsingCompatibility, DeleteContextNames } from 'features/compatibilities';
import { EditCompatibility } from 'features/metadata/Compatibilities/editCompatibility/editCompatibility';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { useGetContextNameQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

export const CompatibilityCardPage = () => {

    const { t } = useTypedTranslation();
    const dispatch = useAppDispatch();
    const { compatibilityId } = useParams();
    const navigate = useNavigate();

    const {
        data: compatibilityCard,
        isFetching: fetchingCompatibilityCard,
    } = useGetContextNameQuery({
        id: compatibilityId,
    });

    const handleNavigate = () =>
        navigate(-1);

    useEffect(() => {
        if (compatibilityCard) {
            dispatch(
                setBreadcrumbs({
                    displayName: t((l) => l.breadcrumbs.metadata),
                    url: `${routes.metadata.main}/${routes.metadata.compatibilities}`,
                    child: {
                        displayName: `${t((l) => l.compatibilities.item.cardTitle)}`,
                        url: `${routes.metadata.main}/${routes.metadata.compatibilities}/${compatibilityCard.id}`,
                    },
                }),
            );
        }
    }, [compatibilityCard]);


    return (
        <Spin spinning={fetchingCompatibilityCard}>
            <Flex vertical gap={24}>
                <Flex justify="space-between">
                    <Typography.Title level={1}>
                        {t((l) => l.compatibilities.item.cardTitle)}
                    </Typography.Title>

                    <Transactions />
                </Flex>
                <EditCompatibility item={compatibilityCard} />
                <UsingCompatibility compatibilityId={compatibilityId} />
                <DetailFooter customHandleBack={handleNavigate} >
                    <DeleteContextNames  detailItemPage itemId={[compatibilityId]} />
                </DetailFooter>
            </Flex>
        </Spin>
    );
};
