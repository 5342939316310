import { RiAddCircleLine } from '@remixicon/react';
import { App, Button } from 'antd';
import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { useCreateConfigurationMutation } from 'shared/api/generatedApi/integrationsApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';

interface CreateExchangeConfigProps {
	integrationId: string;
}

const CreateExchangeConfig = memo((
	{
		integrationId,
	}: CreateExchangeConfigProps) => {

	const navigate = useNavigate();

	const { notification } = App.useApp();

	const [ createConfig, { isLoading } ] = useCreateConfigurationMutation();

	const createExchangeConfig = async () => {
		try {
			const { configuration_id: configId } = await createConfig({
				connectionId: integrationId,
				configurationCreateRequest: {
					name: '',
					status: 'inactive',
					execution_type: 'manual',
					on_delete_action: '',
					extraction_targets: [],
					destination: '',
					replication_direction: 'import',
				},
			})
				.unwrap();

			navigate(`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.exchangeConfigs}/${configId}`);
		} catch (e) {
			errorHelper(
				'Ошибка при создании конфигурации обмена!',
				e,
				notification,
			);
		}
	};

	return (
		<Button icon={<RiAddCircleLine/>}
				style={{ maxWidth: 'fit-content' }}
				onClick={createExchangeConfig}
				loading={isLoading}
		>
			Создать новую конфигурацию обмена
		</Button>
	);
});

export {
	CreateExchangeConfig,
};
