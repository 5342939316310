export const translateType = (type: string | undefined): string => {
	if (type) {
		switch (type) {
			case 'FLOAT':
				return 'Число с плавающей точкой';
			case 'BOOLEAN':
				return 'Логический тип';
			case 'STRING':
				return 'Строка';
			case 'DATE_TIME':
				return 'Дата';
			case 'FILE':
				return 'Файл';
			case 'INTEGER':
				return 'Целое число';
			case 'TEXT':
				return 'Многострочный текст';
			case 'RELATION':
				return 'Связь';
			case 'FORMULA':
				return 'Формула';
			case 'COLOR':
				return 'Цвет';
			case undefined:
				return 'не определен тип атрибута в AttributeTransfer';
		}
	} else {
		return '@type не отпределен';
	}
};