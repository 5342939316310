import React, { memo } from 'react';
import { styles } from './dropdownLink.styles';

interface IDropdownLinkProps {
	title: string;
	icon?: React.ReactElement;
	defaultColor?: boolean;
	onClick: () => void;
	loading?: boolean;
	disabled?: boolean;
}

export const DropdownLink = memo((
	{
		title,
		icon,
		onClick,
		defaultColor,
		disabled,
		loading,
	}: IDropdownLinkProps) => {
	const handleClick = (e: React.SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (disabled) {
			return;
		}
		onClick();
	};

	return (
		<a
			onClick={handleClick}
			style={{
				...styles,
				...(disabled && {
					color: 'var(--disabled)',
					cursor: 'not-allowed',
				}),
				...(defaultColor && {
					color: 'var(--text)',
					fontWeight: 500,
					opacity: `${loading ? '0,3' : 'unset'}`,
				}),
			}}
		>
			{icon && icon}
			{title}
		</a>
	);
});
