import React, { memo, useMemo } from 'react';
import { useGetTransitionKeysByConnectionIdQuery } from 'shared/api/generatedApi/integrationsApi';
import { useWorkTable, useTypedTranslation } from 'shared/hooks';
import { WorkTable } from 'shared/ui';

interface ExchangeKeysTableProps {
	integrationId: string;
}

const ExchangeKeysTable = memo((
	{
		integrationId,
	}: ExchangeKeysTableProps,
) => {
	const { t } = useTypedTranslation();
	const tableState = useWorkTable();

	const { page: page, limit: pageSize } = tableState.toDto();
	const { data: exchangeKeys, isFetching } = useGetTransitionKeysByConnectionIdQuery({
		connectionId: integrationId,
		page: page,
		pageSize: pageSize,
	}, {
		skip: !tableState.isReady,
	});

	const columns = [
		{
			title: t(l => l.integrations.exchangeKeys.externalId),
			dataIndex: 'externalId',
			key: 'externalId',
		},
		{
			title: t(l => l.integrations.exchangeKeys.internalId),
			dataIndex: 'internalId',
			key: 'internalId',
		},
		{
			title: t(l => l.integrations.exchangeKeys.position),
			dataIndex: 'referencePosition',
			key: 'referencePosition',
		},
	];

	const dataSource = useMemo(() =>
		exchangeKeys?.data.map(x => ({
			externalId: x.external_id,
			internalId: x.mdm_entity_id,
			referencePosition: x.display_name,
		})), [ exchangeKeys ]);

	return (
		<WorkTable columns={columns}
				   dataSource={dataSource}
				   loading={isFetching}
				   {...tableState.toWorkTableProps(exchangeKeys?.pagination.total)}
		/>
	);
});

export {
	ExchangeKeysTable,
};