import { RiEditLine } from '@remixicon/react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { AttributeGroupItem } from 'entities/metadata/attributesGroups/attributesGroups.model';
import { DropdownLink } from 'shared/ui';
import { useEditAttributeGroup } from './editAttributeGroup.model';

interface IEditAttributeGroupProps {
	group: AttributeGroupItem;
}

const EditAttributeGroupUi: React.FC<IEditAttributeGroupProps> = ({ group }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { loading, error, editNewGroup } = useEditAttributeGroup();

	const [form] = Form.useForm();

	const onFinish = async ({ displayName, desc }) => {
		editNewGroup(displayName, desc, group.id, group.parentId && group.parentId);
		if (!error) {
			form.resetFields();
			setIsModalOpen(false);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (group) {
			form.setFieldsValue({
				displayName: group.displayName,
				desc: group.description,
			});
		}
	}, [group]);

	return (
		<>
			<DropdownLink
				icon={<RiEditLine size={16} />}
				title="Редактировать"
				onClick={showModal}
			/>

			<Modal
				title="Редактирование группы атрибутов"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item name="displayName" label="Наименование группы атрибутов" required>
						<Input />
					</Form.Item>
					<Form.Item name="desc" label="Описание">
						<Input />
					</Form.Item>

					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!form.getFieldValue(['displayName'])}
						>
							Сохранить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const EditAttributeGroup = React.memo(EditAttributeGroupUi);
