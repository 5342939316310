import { RiDeleteBinLine } from '@remixicon/react';
import { App, Button, Checkbox, CheckboxProps, Flex, Modal, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useDeleteConnectionMutation } from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks/useTypedTranslation';
import { DropdownLink } from 'shared/ui';
import { ml15 } from './deleteIntegration.style';

interface IDeleteIntegration {
	id: string;
	isButton?: boolean;
	isNew?: boolean;
	onDelete?: () => void;
}

const DeleteIntegration = React.memo((
	{
		id,
		isButton,
		onDelete,
	}: IDeleteIntegration) => {
	const { notification } = App.useApp();
	const { t } = useTypedTranslation();

	const [ open, setOpen ] = useState<boolean>(false);
	const [ confirm, setConfirm ] = useState<boolean>(false);

	const [ deleteIntegration, { isLoading } ] = useDeleteConnectionMutation();

	const showModal = useCallback(() => {
		setOpen(true);
	}, []);

	const handleOk = useCallback(async () => {
		try {
			await deleteIntegration({ connectionId: id })
				.unwrap();
			onDelete?.();
		} catch (e) {
			errorHelper(t(l => l.integrations.deleteIntegration.deleteError), e, notification);
		} finally {
			setConfirm(false);
		}
	}, [ id, onDelete, t ]);

	const handleConfirmDelete: CheckboxProps['onChange'] = useCallback(() => {
		setConfirm(!confirm);
	}, [ confirm ]);

	const handleCancel = useCallback(() => {
		setConfirm(false);
		setOpen(false);
	}, []);

	const footerButtons = useMemo(
		() => (
			<Flex key="footer-buttons" justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t(l => l.common.buttons.cancel)}
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					loading={isLoading}
					disabled={!confirm || isLoading}
				>
					{t(l => l.common.buttons.delete)}
				</Button>
			</Flex>
		),
		[ confirm, isLoading, t ],
	);

	return (
		<>
			{isButton ? (
				<Button type="text"
						onClick={showModal}
						icon={<RiDeleteBinLine size={18}/>}
						loading={isLoading}
				>
					{t(l => l.common.buttons.delete)}
				</Button>
			) : (
				<DropdownLink title={t(l => l.common.buttons.delete)}
							  onClick={showModal}
							  icon={<RiDeleteBinLine size={16}/>}
							  loading={isLoading}
				/>
			)}

			<Modal
				open={open}
				title={t(l => l.integrations.deleteIntegration.confirmTitle)}
				footer={[ footerButtons ]}
				onCancel={handleCancel}
			>
				<Flex vertical gap={24} style={{ marginBottom: 24 }}>
					<Typography.Paragraph>
						{t(l => l.integrations.deleteIntegration.confirmMessage)}
					</Typography.Paragraph>

					<ul>
						<Typography.Paragraph>{t(l => l.integrations.deleteIntegration.relatedData)}</Typography.Paragraph>
						<li style={ml15}>{t(l => l.integrations.deleteIntegration.exchangeConfigs)}</li>
						<li style={ml15}>{t(l => l.integrations.deleteIntegration.replicationMessages)}</li>
						<li style={ml15}>{t(l => l.integrations.deleteIntegration.transitionKeys)}</li>
					</ul>
					<Checkbox onChange={handleConfirmDelete} checked={confirm}>
						{t(l => l.integrations.deleteIntegration.confirmCheckbox)}
					</Checkbox>
				</Flex>
			</Modal>
		</>
	);
});

export {
	DeleteIntegration,
};
