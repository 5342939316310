import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DropdownLink } from 'shared/ui';
import { useAddClassifierRecord } from './addClassifierRecord.model';

interface IAddClassifierRecordProps {
	parentId?: string;
	isButton?: boolean;
}

const AddClassifierRecordUi: React.FC<IAddClassifierRecordProps> = ({ isButton, parentId }) => {
	const { createClassifierRecord } = useAddClassifierRecord();
	const { classifierItemId } = useParams();

	const onClick = useCallback(() => {
		createClassifierRecord(classifierItemId || parentId);
	}, [classifierItemId, parentId]);

	return (
		<>
			{!isButton ? (
				<DropdownLink
					title="Добавить вложенную запись"
					icon={<RiAddCircleLine size={16} />}
					onClick={onClick}
				/>
			) : (
				<Button
					icon={<RiAddCircleLine />}
					onClick={onClick}
					style={{ maxWidth: 'fit-content' }}
				>
					Добавить запись классификатора
				</Button>
			)}
		</>
	);
};

export const AddClassifierRecord = React.memo(AddClassifierRecordUi);
