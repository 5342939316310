import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	useLazyGetMeasurementGroupsQuery,
	useLazyGetMeasurementGroupQuery,
	useLazyGetMeasurementsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { useDidUpdateEffect } from 'shared/hooks/useDidUpdateEffect';
import {
	editMeasuresList,
	Measure,
	setCurrentMeasureGroup,
	setMeasuresList,
} from './measures.store';

export const useGetMeasuresGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [getGroups, { isFetching: isLoading }] = useLazyGetMeasurementGroupsQuery();
	const { notification } = App.useApp();
	const { measuresList } = useAppSelector((state) => state.entities.metadata.measures);
	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	const getData = async () => {
		const data = await getGroups({});
		if ('error' in data) {
			errorHelper('Ошибка при получении списка групп ЕИ!', data.error, notification);
			setError(data.error);
		}
		if ('data' in data) {
			dispatch(setMeasuresList(data.data));
		}
	};

	useEffect(() => {
		getData();
	}, [transactionRollback]);

	useEffect(() => {
		if (!measuresList) {
			const searchValue = searchParams.get('searchValue');
			!searchValue && getData();
		}
	}, []);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');
		!searchValue && getData();
	}, [searchParams.get('searchValue')]);

	return {
		loading: isLoading,
		error,
		measuresList,
	};
};

export const useGetCurrentMeausureGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);
	const { metaMeasureGroupId } = useParams();
	const { loading: groupLoading, error: groupError, measuresList } = useGetMeasuresGroup();
	const dispatch = useAppDispatch();
	const { currentMeasureGroup } = useAppSelector((state) => state.entities.metadata.measures);
	const [getGroup, { isLoading }] = useLazyGetMeasurementGroupQuery();
	const [getMeasurements, { isLoading: isMeasurementsLoading }] = useLazyGetMeasurementsQuery();

	const [searchParams] = useSearchParams();

	const getData = async (id?: string) => {
		let group: Measure = null;

		await getGroup({ id: id ? id : metaMeasureGroupId, includeChildren: true })
			.unwrap()
			.then((res) => (group = { ...group, ...res }))
			.catch((err) => {
				errorHelper('Ошибка при получении группы ЕИ!', err, notification);
				setError(err);
			});

		await getMeasurements({ measurementGroupId: id ? id : metaMeasureGroupId })
			.unwrap()
			.then((res) => {
				group = { ...group, childrenMeasures: res };
				dispatch(setCurrentMeasureGroup(group));
				dispatch(editMeasuresList(group));
				return group;
			})
			.catch((err) => {
				errorHelper('Ошибка при получении ЕИ!', err, notification);
				setError(err);
			});

		return group;
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (!currentMeasureGroup && !searchValue) {
			if (metaMeasureGroupId && measuresList) {
				const currGroup = measuresList.find((item) => item.id === metaMeasureGroupId);
				if (currGroup?.childrenMeasures) {
					dispatch(setCurrentMeasureGroup(currGroup));
				} else {
					getData();
				}
			}
		}
	}, [metaMeasureGroupId, measuresList, currentMeasureGroup]);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');
		!searchValue && getData();
	}, [searchParams.get('searchValue')]);

	return {
		currentMeasureGroup,
		getData,
		loading: groupLoading || isLoading || isMeasurementsLoading,
		error: groupError || error,
	};
};
