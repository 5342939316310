const mainRoutes = {
	main: '/udmi/greeting',
};
const catalogsRoutes = {
	main: 'catalogs',
	classifier: 'classifier',
	record: 'record',
	deduplication: 'deduplication',
};

const compatibilityRoutes = {
	main: 'compatibility',
	create: 'create'
};

const metadataRoutes = {
	main: 'metadata',
	group: 'attributes',
	attribute: 'attribute',
	create: 'create',
	measures: 'measures',
	constraintTables: 'constraintTables',
	compatibilities: 'compatibilities'
};
const adminRoutes = {
	main: '/udmi/admin',
	users: 'users',
	groups: 'groups',
	journal: 'journal',
};
const settingsRoutes = {
	main: '/udmi/settings',
	basic: 'basic',
	integration: 'integration',
	debug: 'debug',
	plugins: 'plugins',
	db: 'db',
	utilities: 'utilities',
	test: 'test',
	gen: 'gen',
};
const loginRoutes = {
	main: '/login',
};
const integrationRoutes = {
	main: 'integrations',
	create: 'create',
	detail: 'detail',
	exchangeConfigs: 'exchangeConfigs',
	message: 'message',
	compatibilities: 'compatibilities',
};

const compatibilitiesRoutes = {
	main: 'compatibilities',
};

const classifiersRoutes = {
	main: 'classifiers',
	records: 'records',
	edit: 'edit',
};

const applicationsRoutes = {
	main: 'applications',
};

const dataQualityRoutes = {
	main: 'dq',
};

const profileRoutes = {
	main: 'profile',
};

export const routes = {
	main: mainRoutes,
	catalogs: catalogsRoutes,
	compatibility: compatibilityRoutes,
	metadata: metadataRoutes,
	classifiers: classifiersRoutes,
	admin: adminRoutes,
	integrations: integrationRoutes,
	compatibilities: compatibilitiesRoutes,
	applications: applicationsRoutes,
	dataQuality: dataQualityRoutes,
	settings: settingsRoutes,
	login: loginRoutes,
	profile: profileRoutes,
};
