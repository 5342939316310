import { Flex, Result, Spin, Table, TableProps, Tree } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { useAddAttributeTree } from 'features/catalogs/AttrbuteDeclarations/AddAttributeDeclaration/AddAttributeDeclaration.model';
import { useMetaAttibutes } from 'entities/metadata/metaAttributes/metaAttributes.model';
import {
	AttributeResponse,
	ClassifierResponse,
	useGetAttributesQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { useHandleQueryError, usePagination, useTypedTranslation } from 'shared/hooks';
import { SelectModal } from 'shared/ui';
import s from './AddAttributeDeclaration.module.scss';
import { useSelectAttribute } from './selectAttribute.model';

interface ISelectAttribute {
	classifier?: ClassifierResponse;
}

const SelectAttributeUi: React.FC<ISelectAttribute> = ({ classifier }) => {
	const [defaultSelectedChips, setDefaultSelectedChips] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]); // для дерева
	const [selectedRowsKeys, setSelectedRowsKeys] = useState<string[]>(
		classifier?.linkClassifierAttributes?.map((item) => item.id)
	); // для таблицы
	const [dataSource, setDataSource] = useState<AttributeResponse[]>([]);

	const { pagination, asTablePagination } = usePagination();

	const { tree, onExpandTree, loading, error } = useAddAttributeTree();

	const { fetchAttributesByIds } = useMetaAttibutes();

	const { handleUpdate } = useSelectAttribute();

	const { t } = useTypedTranslation();

	const columns: TableProps['columns'] = useMemo(() => {
		return [
			{
				title: t((l) => l.common.defaultNames.name),
				dataIndex: 'displayName',
				key: 'displayName',
			},
			{
				title: t((l) => l.common.defaultNames.type),
				dataIndex: 'type',
				key: 'type',
			},
			{
				title: t((l) => l.common.defaultNames.isMulti),
				dataIndex: 'isMulti',
				key: 'isMulti',
			},
		];
	}, [t]);

	const rowSelection = {
		onChange: (selectedRowKeys) => {
			const newSelectedRowsKeys = new Set([
				...(selectedRowsKeys?.filter(
					(id) => !tableAttributes?.data?.map((x) => x.id).includes(id)
				) || []),
				...selectedRowKeys,
			]);

			setSelectedRowsKeys(Array.from(newSelectedRowsKeys));
		},
		selectedRowKeys: selectedRowsKeys,
	};

	const {
		data: tableAttributes,
		error: attributesError,
		isLoading: attributesLoading,
	} = useHandleQueryError(
		useGetAttributesQuery(
			{
				attributeGroupIds: selectedKeys,
				type: 'RELATION',
				limit: pagination?.pageSize,
				page: pagination?.current,
			},
			{ skip: !selectedKeys?.length || !pagination }
		),
		(l) => l.attributes.attributeGroupErr
	);

	const onOkCallback = useCallback(async () => {
		await handleUpdate(classifier, selectedRowsKeys);
	}, [selectedRowsKeys, classifier]);

	const handleDeleteAttribute = async (id: string) => {
		await handleUpdate(
			classifier,
			classifier?.linkClassifierAttributes
				?.map((item) => item.id)
				.filter((item) => item !== id)
		);
	};

	useEffect(() => {
		if (!tableAttributes) return;
		setDataSource(
			tableAttributes?.data?.map((x) => ({
				...x,
				isMulti: x.list
					? t((l) => l.common.defaultNames.yes)
					: t((l) => l.common.defaultNames.no),
			}))
		);
	}, [tableAttributes]);

	useEffect(() => {
		const items = classifier?.linkClassifierAttributes?.filter((item) => !!item);
		if (items?.length && items[0] !== undefined) {
			const getData = async () => {
				const data = await fetchAttributesByIds(
					classifier?.linkClassifierAttributes?.map((item) => item.id)
				);
				if (data) {
					setDefaultSelectedChips(
						data?.map((item) => ({
							label: item.displayName,
							callback: () => handleDeleteAttribute(item.id),
						}))
					);
					setSelectedKeys(data.map((item) => item.id));
				}
			};
			getData();
		} else {
			setDefaultSelectedChips([]);
			setSelectedKeys([]);
		}
	}, [classifier]);

	useEffect(() => {
		if (!classifier) return;
		setSelectedRowsKeys(classifier?.linkClassifierAttributes?.map((item) => item.id));
	}, [classifier]);

	return (
		<SelectModal
			modalTitle={t((l) => l.classifier.common.attributesSetting)}
			selectValues={defaultSelectedChips}
			onOkCallback={onOkCallback}
			onCloseCallback={() => {
				setSelectedRowsKeys(classifier?.linkClassifierAttributes?.map((item) => item.id));
			}}
		>
			<Flex vertical gap={24}>
				<p>{t((l) => l.classifier.common.chooseAttributeFromList)}</p>
				<Flex gap={24} justify="space-between">
					<div className={s.columnTree}>
						{loading && <Spin />}
						<Tree
							treeData={tree}
							onSelect={(key) => setSelectedKeys(key as string[])}
							selectedKeys={selectedKeys}
							loadData={(treeNode) => onExpandTree(treeNode.id)}
							checkStrictly
						/>
						{error && (
							<Result
								status="error"
								title={t((l) => l.attributes.attributeTreeErr)}
								subTitle={JSON.stringify(error)}
							/>
						)}
					</div>

					<div className={s.columnAttributes}>
						{!attributesError ? (
							dataSource && (
								<Table
									columns={columns}
									dataSource={dataSource}
									loading={attributesLoading}
									rowKey="id"
									rowSelection={rowSelection}
									{...asTablePagination(tableAttributes?.meta?.total)}
								/>
							)
						) : (
							<Result
								status="error"
								title={t((l) => l.attributes.attributeGroupErr)}
								subTitle={JSON.stringify(attributesError)}
							/>
						)}
					</div>
				</Flex>
			</Flex>
		</SelectModal>
	);
};

export const SelectAttribute = React.memo(SelectAttributeUi);
