import { Badge } from 'antd';
import React, { ReactElement } from 'react';
import { CatalogResponse } from '../api/generatedApi/mdmgApi';
import { AttributeType } from '../models';
import { colors } from '../styles';
import { AttributeRestrictionEnum } from './CellValueParser';

const descriptionItemUi = (key: string, value: string | ReactElement) => {
	const keyRu = AttributeRestrictionEnum[key];
	return (
		<span key={key}>
			<span
				style={{
					margin: '0 6px',
					display: 'inline-block',
				}}
			>
				•
			</span>
			{keyRu}: <span style={{ color: colors.text }}>{value}</span>
		</span>
	);
};

export const declarationRestrictionParser = (
	type: AttributeType,
	key: string,
	value: string | object | null | undefined | string[],
	catalogs: Array<CatalogResponse>
) => {
	switch (key) {
		case 'formulaId':
			return descriptionItemUi(key, value as string);

		case 'required': {
			return descriptionItemUi(key, value ? 'Да' : 'Нет');
		}

		case 'unique': {
			return descriptionItemUi(key, value ? 'Да' : 'Нет');
		}

		case 'defaultValue':
			if (value == null) {
				return;
			}

			if (type === 'RELATION') {
				if (!(value as string[] | null)?.length) return;

				return; //	todo: подгружать displayName сущностей с id = value из справочника scopeIds
			}

			if (type === 'BOOLEAN') {
				return descriptionItemUi(key, value ? 'Правда' : 'Ложь');
			}

			if (type === 'DATE_TIME') {
				return descriptionItemUi(key, new Date(value as string).toLocaleDateString());
			}

			if (type === 'COLOR') {
				return descriptionItemUi(
					key,
					<>
						<Badge
							color={`${value}`}
							style={{ display: 'inline', marginLeft: 4 }}
							styles={{ indicator: { width: 15, height: 15 } }}
						/>
						<span style={{ color: colors.text }}> {value as string}</span>
					</>
				);
			}

			return descriptionItemUi(key, value as string);

		case 'mask':
			if (!value) {
				return;
			}
			return descriptionItemUi(key, value as string);

		case 'maxLength':
			return descriptionItemUi(key, value as string);

		case 'accuracy':
			return descriptionItemUi(key, value as string);

		case 'measurementId':
			return descriptionItemUi(key, value as string);

		case 'removalChildren':
			return descriptionItemUi(key, '');

		case 'scopeIds': {
			if (catalogs) {
				return descriptionItemUi(
					key,
					catalogs
						.filter((c) => (value as string[]).includes(c.id))
						.map((c) => (c.displayName ? `${c.displayName}` : 'без названия'))
						.join(', ')
						.toString()
				);
			} else {
				return;
			}
		}

		default:
			break;
	}
};
