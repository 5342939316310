import React from 'react';

export enum ModifierType {
	AND = 'AND',
	OR = 'OR'
}

export enum ComparisonType {
	EQUALS = 'EQUALS',
	NOT_EQUALS = 'NOT_EQUALS',
	EMPTY = 'EMPTY',
	NOT_EMPTY = 'NOT_EMPTY',
	MORE = 'MORE',
	MORE_OR_EQUAL = 'MORE_OR_EQUAL',
	LESS = 'LESS',
	LESS_OR_EQUAL = 'LESS_OR_EQUAL',
	CONTAINS = 'CONTAINS',
	NOT_CONTAINS = 'NOT_CONTAINS',
}

export abstract class Filter {
	readonly key: string;
	readonly title: string;

	constructor(key: string, title: string) {
		this.key = key;
		this.title = title;
	}

}

export class DateRangeFilter extends Filter {
	constructor(key: string, title: string) {
		super(key, title);
	}
}

export interface ListOptionsFilterOption {
	label: string | React.ReactElement | number;
	value: string | number | boolean;
}

export class ListOptionsFilter extends Filter {
	readonly options: Array<ListOptionsFilterOption>;

	constructor(key: string, title: string, options: Array<ListOptionsFilterOption>) {
		super(key, title);
		this.options = options;
	}
}

export interface FilterParametersDto {
	parameter: string;
	value: string;
	comparisonParam: ComparisonType;
}