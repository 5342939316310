const validateRegex = (_, value) => {
	if (!value) {
		return Promise.resolve();
	}
	try {
		new RegExp(value);
		return Promise.resolve();
	} catch (e) {
		return Promise.reject('Некорректное регулярное выражение');
	}
};

export {
	validateRegex,
};