import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	editCurrentMeasureGroup,
	setCurrentMeasureUnit,
	useGetCurrentMeausureGroup,
} from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { MeasurementResponse } from 'shared/api/generatedApi/mdmgApi';
import { useCreateMeasurementMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateMeasureUnit = () => {
	const { metaMeasureGroupId } = useParams();
	const navigate = useNavigate();

	const [ createUnit, { isLoading } ] = useCreateMeasurementMutation();
	const { currentMeasureGroup } = useGetCurrentMeausureGroup();

	const { getTransactions } = useTransactions();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const createMeasureUnit = async () => {
		await createUnit({
			measurementGroupId: metaMeasureGroupId,
			createMeasurementRequest: {
				displayName: '',
				coefficient: 1,
			},
		})
			.then((res) => {
				if ('data' in res) {
					const result = res.data as unknown as MeasurementResponse;
					dispatch(setCurrentMeasureUnit(result));
					dispatch(
						editCurrentMeasureGroup({
							...currentMeasureGroup,
							childrenMeasures: [ ...currentMeasureGroup.childrenMeasures, result ],
						}),
					);
					getTransactions();
					navigate(`unit/${result.id}`);
				}
				if ('error' in res) {
					errorHelper('Ошибка при создании ЕИ!', res.error, notification);
				}
			})
			.catch((e) => errorHelper('Ошибка при создании ЕИ!', e, notification));
	};

	return {
		createMeasureUnit,
		isLoading,
	};
};
