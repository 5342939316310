import { Alert, App, Spin, TreeSelectProps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	ClassifierItemTreeNodeResponse,
	useLazyGetClassifierItemTreeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { FormInputProps } from 'shared/types';
import { DropdownTreeSelect } from 'shared/ui';

interface TreeSelectClassifierItemProps extends FormInputProps<string | null> {
	classifierId: string;
}

const mapDtoToItems = (
	items: ClassifierItemTreeNodeResponse[] | null | undefined,
	parentItemId?: string,
) => {
	if (!items?.length) {
		return [];
	}
	return items.reduce((acc, { id, displayName, parent, children }) => {
		acc.push(
			{
				id: id,
				key: id,
				pId: parentItemId ?? null,
				value: id,
				title: displayName,
				isLeaf: !parent,
			},
			...mapDtoToItems(children, id),
		);
		return acc;
	}, []);
};

const RelationToClassifierInput = React.memo(({
												  classifierId,
												  value,
												  onChange,
											  }: TreeSelectClassifierItemProps) => {

	const { notification } = App.useApp();

	const [ treeData, setTreeData ] = useState<any[] | null>(null);

	const [ getClassifierItemTree, { error } ] = useLazyGetClassifierItemTreeQuery();

	useEffect(() => {
		getClassifierItemTree({
			classifierId: classifierId,
			...(value ? { includePathToItemId: value } : {}),
		})
			.unwrap()
			.then((res) => {
				setTreeData(mapDtoToItems(res));
			})
			.catch(err =>
				errorHelper(
					'Не удалось получить данные по классификатору',
					err,
					notification,
				));
	}, [ classifierId, value ]);

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) =>
		getClassifierItemTree({
			classifierId: classifierId,
			fromItemId: id,
		})
			.unwrap()
			.then(res => {
				setTreeData(
					treeData.concat(mapDtoToItems(res, id)),
				);
			})
			.catch(err =>
				errorHelper(
					'Не удалось получить данные по классификатору',
					err,
					notification,
				));

	if (error) {
		return (
			<Alert type="error"
				   message={JSON.stringify(error)}
			/>
		);
	}

	if (!treeData) {
		return (
			<Spin spinning={true}/>
		);
	}

	return (
		<DropdownTreeSelect allowClear
							value={value}
							treeDefaultExpandedKeys={[ value ]}
							treeDataSimpleMode
							treeData={treeData}
							loadData={onLoadData}
							onChange={onChange}
		/>
	);
});

export {
	RelationToClassifierInput,
};