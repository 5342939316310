import React from 'react';
import { FormInputProps } from '../../types';
import { SelectCatalogInputBase } from './SelectCatalogInputBase';

type SelectCatalogInputProps = FormInputProps<string | null>;

const SelectCatalogInput = (
	{
		value,
		onChange,
	}: SelectCatalogInputProps) => {
	return <SelectCatalogInputBase value={value}
								   onChange={onChange as (value: string | string[] | null) => void}
	/>;
};

export {
	SelectCatalogInput,
};