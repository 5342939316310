import { RiArrowRightCircleLine, RiListSettingsLine } from '@remixicon/react';
import { Result, Spin, Table, TableProps } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { useGetClassifierByItemIdQuery, useGetClassifierItemsQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { Chip, ChipStatus, DropdownLink } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';

const defaultCols: TableProps['columns'] = [
	{
		dataIndex: 'displayName',
		key: 'displayName',
		title: 'Наименование позиции справочника',
	},
	//TODO добавить колонку с именем атрибута и dataindex его айди, потом заполнять ее из values которые приходят в ответе
	{
		dataIndex: 'status',
		key: 'status',
		title: 'Статус',
	},
	{
		title: <RiListSettingsLine size={20}/>,
		dataIndex: 'menu',
		key: 'menu',
		width: '50px',
	},
];

interface ClassifierItemCatalogItemsListWidgetProps {
	classifier: any;
	attributeId: string;
	classifierItemId?: string | null;
}

const ClassifierItemCatalogItemsListWidget = React.memo((
	{
		classifier,
		classifierItemId,
		attributeId,
	}: ClassifierItemCatalogItemsListWidgetProps) => {
	const navigate = useNavigate();

	const { data: catalogItems, isLoading, error } = useGetClassifierByItemIdQuery({
		classifierItemId: classifierItemId,
		attributeId: attributeId,
	});

	const classifierItemIds = useMemo(
		() => new Set(
			catalogItems
				?.map(item =>
					Object
						.values(item.values)
						.find(x => x.attributeId === attributeId)
						?.value as unknown as string,
				).filter(x => x && x.length > 0)
			?? []),
		[ catalogItems ]);

	const { data: classifierItems } = useGetClassifierItemsQuery({
		ids: Array.from(classifierItemIds),
		page: 1,
		limit: classifierItemIds.size,
	}, {
		skip: !classifierItemIds.size,
	});

	const cols: TableProps<any>['columns'] = useMemo(() => [
		{
			dataIndex: attributeId,
			title: classifier
				.linkClassifierAttributes
				.find((item) => item.id === attributeId)
				?.displayName,
		},
		...defaultCols,
	], [ attributeId, classifier ]);

	const data = useMemo(() =>
		catalogItems?.map((item) => {
			const classifierItemId = Object
				.values(item.values)
				.find(x => x.attributeId === attributeId)
				?.value as never as string;

			const classifierItem = classifierItems?.data
				.find(x => x.id === classifierItemId);

			return ({
				key: item.id,
				[attributeId]: (
					classifierItem
						? classifierItem.displayName
						: <Spin size={'small'}/>
				),
				displayName: <div dangerouslySetInnerHTML={{ __html: String(item.displayName) }} />,
				status: (
					<Chip status={item.status.toLowerCase() as ChipStatus}>
						{chipTitle(item.status)}
					</Chip>
				),
				menu: (
					<ItemActions
						items={[
							{
								key: 'nav',
								label: (
									<DropdownLink
										icon={<RiArrowRightCircleLine size={16}/>}
										title="Переход в карточку записи"
										onClick={() =>
											navigate(
												`/${routes.catalogs.main}/${item.catalogId}/${routes.catalogs.record}/${item.id}`,
											)
										}
									/>
								),
							},
						]}
					/>
				),
				...item.values,
			});
		}), [ catalogItems, classifierItems ]);

	if (isLoading) {
		return (
			<Spin/>
		);
	}

	if (error) {
		return <Result title={'Ошибка получения записей!'}
					   subTitle={JSON.stringify(error)}
		/>;
	}

	return (
		<Table<any>
			columns={cols}
			dataSource={data}
			onChange={undefined}
			pagination={{ defaultCurrent: 1, pageSize: 10 }}
			scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
		/>
	);
});

export {
	ClassifierItemCatalogItemsListWidget,
};