import { RiDeleteBinLine } from '@remixicon/react';
import { Button, Flex, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConstraintTableDetails } from 'widgets/Metadata/ConstraintTables/ConstraintTableDetails/ConstraintTableDetails.ui';
import { Transactions } from 'widgets/Transactions';
import { DeleteRowModal } from 'features/metadata/ConstraintTables';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { removeConstraintTableDetails } from 'entities/metadata/constraintTables';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';
import { titleStyle } from '../configPage.style';

export const ConstraintTableCardPage = () => {
	const { t } = useTypedTranslation();
	const [rowsToDelete, setRowsToDelete] = useState<string[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const tableRef = useRef<any>(null);
	const tableInfo = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails.table
	);

	const openDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const handleOk = () => {
		setIsDeleteModalOpen(false);
		setRowsToDelete([]);
		tableRef.current?.resetSelectedRows();
	};

	const handleCancelDelete = () => {
		setIsDeleteModalOpen(false);
	};

	const handleNavigate = () =>
		navigate(`/${routes.metadata.main}/${routes.metadata.constraintTables}`);

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: t((l) => l.breadcrumbs.metadata),
				url: `${routes.metadata.main}/${routes.metadata.constraintTables}`,
				child: {
					displayName: t((l) => l.constraintTables.card),
					url: window.location.pathname,
				},
			})
		);
	}, []);

	useEffect(() => {
		return () => {
			dispatch(removeConstraintTableDetails());
		};
	}, []);

	return (
		<Flex align="start" gap={24} vertical style={{ height: '100%' }}>
			<Flex vertical style={{ width: '100%' }}>
				<Flex justify="space-between" style={{ width: '100%' }}>
					<Typography.Title level={1} style={titleStyle}>
						{tableInfo?.displayName}
					</Typography.Title>
					<Transactions />
				</Flex>
			</Flex>

			<ConstraintTableDetails
				onCheckRow={setRowsToDelete}
				ref={tableRef}
			/>

			<DetailFooter customHandleBack={handleNavigate}>
				<Button
					onClick={openDeleteModal}
					disabled={rowsToDelete?.length === 0}
					icon={<RiDeleteBinLine />}
				>
					{t((l) => l.common.buttons.deleteSelected)}
				</Button>
			</DetailFooter>

			<DeleteRowModal
				isOpen={isDeleteModalOpen}
				onClose={handleCancelDelete}
				onOk={handleOk}
				rowsToDelete={rowsToDelete}
			/>
		</Flex>
	);
};
