import { useRef } from 'react';

class ConversionCache {
	cache: Map<any, any>;

	constructor() {
		this.cache = new Map();
	}

	addConversion(fromValue: any, fromUnit: string, toValue: any, toUnit: string) {
		const fromKey = `${fromValue}:${fromUnit}`;

		if (!this.cache.has(fromKey)) {
			this.cache.set(fromKey, new Map());
		}

		this.cache
			.get(fromKey)
			.set(toUnit, { value: toValue, unit: toUnit });
	}

	findConversion(value: any, fromUnit: string, toUnit: string) {
		const startKey = `${value}:${fromUnit}`;
		if (!this.cache.has(startKey)) {
			return null;
		}

		const queue = [ { key: startKey, value, unit: fromUnit } ];
		const visited = new Set();

		while (queue.length > 0) {
			const { key, value, unit } = queue.shift();
			if (unit === toUnit) {
				return value;
			}

			if (!this.cache.has(key) || visited.has(key)) {
				continue;
			}
			visited.add(key);

			for (const [ nextUnit, { value: newValue } ] of this.cache.get(key).entries()) {
				queue.push({ key: `${newValue}:${nextUnit}`, value: newValue, unit: nextUnit });
			}
		}

		return null;
	}
}

const useMeasurementUnitConversionCache = () => {
	const attributeDeclarationMeasurementUnitValues = useRef(new Map<string, ConversionCache>);

	const addItemToCache = (
		declarationId: string,
		oldValue: any, oldMeasurementId: string,
		value: any, measurementId: string,
	) => {
		const cache = attributeDeclarationMeasurementUnitValues.current;
		const declarationCache = cache.get(declarationId) ?? new ConversionCache();
		declarationCache.addConversion(oldValue, oldMeasurementId, value, measurementId);
		cache.set(declarationId, declarationCache);
	};

	const findConversion = (declarationId: string, value: any, fromUnit: string, toUnit: string) => {
		const cacheForDeclaration = attributeDeclarationMeasurementUnitValues.current.get(declarationId);
		return cacheForDeclaration?.findConversion(value, fromUnit, toUnit);
	};

	return {
		addItemToCache,
		findConversion,
	};
};

export {
	useMeasurementUnitConversionCache,
};