import { RiEditLine, RiInformation2Line } from '@remixicon/react';
import { Button, Flex, Modal, Table, TableProps, Typography } from 'antd';
import React, { ReactElement, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDeleteDuplicates } from 'features/catalogs/deleteDuplicates';
import { useSetDuplicateItem } from 'features/catalogs/setDuplicateItem';
import { useSetOriginalItem } from 'features/catalogs/setOriginalItem';
import {
	setDeletingSuspectedDuplicatesIds,
	setPotentialOriginalItemId,
} from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { SuspectedDuplicateDto, useGetSuspectedDuplicatesQuery } from 'shared/api/generatedApi/deduplicationApi';
import { useGetCatalogsQuery } from 'shared/api/generatedApi/mdmgApi';
import { randomString, sortStrings } from 'shared/helpers';
import { useAppDispatch, useAppSelector, useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { AddCopyIcon, CancelCopyIcon, DropdownLink, Hint } from 'shared/ui';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';
import ItemActions from 'shared/ui/components/ItemActions';
import { SuspectedDuplicate } from '../SuspectedDuplicate';

interface IDataSource {
	key: string;
	name: string;
	catalog: string;
	menu: ReactElement;
}

export const SuspectedDuplicates = () => {
	const potentialOriginalItemId = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.potentialOriginalItemId,
	);

	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();

	const { deduplicationOptionId } = useParams();

	const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
	const [ suspectedDuplicate, setSuspectedDuplicate ] = useState<SuspectedDuplicateDto | null>(null);

	const { data: suspectedDuplicates, isFetching: isSuspectedLoading } =
		useHandleQueryError(
			useGetSuspectedDuplicatesQuery({
				id: deduplicationOptionId,
			}),
			l => l.catalogs.deduplication.dupesGroupsErr,
		);

	const catalogIds = useMemo(() => new Set<string>(
		suspectedDuplicates?.data
			?.map(item => item.suspectedOriginalItem.catalogId) ?? [],
	), [ suspectedDuplicates ]);

	const { data: catalogs } = useGetCatalogsQuery({
		ids: Array.from(catalogIds),
	}, {
		skip: !catalogIds.size,
	});

	const { deleteDuplicates, isLoading: isDeleting } = useDeleteDuplicates();
	const { setDuplicateItem, isLoading: isSetting } = useSetDuplicateItem();

	const columns = useMemo(
		() => [
			{
				title: t((l) => l.common.defaultNames.name),
				dataIndex: 'name',
				key: 'name',
				sorter: (a, b) => sortStrings(a.name, b.name),
				showSorterTooltip: false,
			},
			{
				title: t((l) => l.catalogs.catalog),
				dataIndex: 'catalog',
				key: 'catalog',
				sorter: (a, b) => sortStrings(a.catalog, b.catalog),
				showSorterTooltip: false,
			},
			{
				title: '',
				dataIndex: 'menu',
				key: 'menu',
				width: 20,
			},
		],
		[ t ],
	);

	const openModal = (id: string) => {
		setSuspectedDuplicate(suspectedDuplicates.data.find(x => x.id === id));
		setIsModalOpen(true);
	};

	const closeModal = () => setIsModalOpen(false);

	const dataSource = useMemo(() => suspectedDuplicates?.data
		?.map((item) => ({
			key: item.id,
			name:
				!item.suspectedOriginalItem.displayName ||
				item.suspectedOriginalItem.displayName === 'null'
					? t((l) => l.common.defaultNames.emptyName)
					: item.suspectedOriginalItem.displayName,
			catalog: catalogs
				?.find((cat) => cat.id === item.suspectedOriginalItem.catalogId)
				?.displayName,
			menu: (
				<ItemActions
					items={[
						{
							label: (
								<DropdownLink
									title={t((l) => l.common.buttons.edit)}
									icon={<RiEditLine size={16}/>}
									onClick={() => openModal(item.id)}
								/>
							),
							key: '0',
						},
						{
							label: (
								<ButtonVsDropdownLink
									type="dropdownLink"
									icon={<CancelCopyIcon size={16}/>}
									title={t((l) => l.catalogs.deduplication.deleteDupes)}
									callback={() => deleteDuplicates([ item.id ])}
									isLoading={isDeleting}
								/>
							),
							key: '1',
						},
						{
							label: (
								<ButtonVsDropdownLink
									type="dropdownLink"
									icon={<AddCopyIcon size={16}/>}
									title={t((l) => l.catalogs.deduplication.setStatus)}
									callback={() => setDuplicateItem([ item.id ])}
									isLoading={isSetting}
								/>
							),
							key: '2',
						},
					]}
				/>
			),
		})), [ suspectedDuplicates, catalogs ]);

	const { setOriginalItem, isOriginalItemSetting } = useSetOriginalItem();

	const searchDate = suspectedDuplicates?.data[0]?.updatedAt
		? new Date(suspectedDuplicates?.data[0]?.updatedAt).toLocaleDateString()
		: null;

	const rowSelection: TableProps<IDataSource>['rowSelection'] = {
		onChange: (selRowKeys: React.Key[]) => {
			dispatch(setDeletingSuspectedDuplicatesIds(selRowKeys as string[]));
		},
	};

	const handleOk = async () => {
		if (potentialOriginalItemId) {
			await setOriginalItem({
				suspectedDuplicateId: suspectedDuplicate.id,
				newOriginalItemId: potentialOriginalItemId,
			});
			dispatch(setPotentialOriginalItemId(null));
		}
		closeModal();
	};

	const handleCancel = () => {
		dispatch(setPotentialOriginalItemId(null));
		closeModal();
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t((l) => l.common.buttons.cancel)}
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={!potentialOriginalItemId}
					loading={isOriginalItemSetting}
				>
					{t((l) => l.common.buttons.continue)}
				</Button>
			</Flex>
		),
		[ handleOk, handleCancel ],
	);

	return (
		<>
			<Flex vertical gap={24}>
				{searchDate && (
					<Flex align="center" gap={4}>
						<Typography>{t((l) => l.catalogs.deduplication.actualOn)} {searchDate}</Typography>
						<Hint
							title={t((l) => l.catalogs.deduplication.actualOnTooltip)}
							placement="bottomLeft"
						>
							<RiInformation2Line color={colors.grayIcon} size={16}/>
						</Hint>
					</Flex>
				)}

				<Table columns={columns}
					   dataSource={dataSource}
					   loading={isSuspectedLoading}
					   rowSelection={rowSelection}
					   onRow={(r) => {
						   return {
							   onClick: () => openModal(r.key),
						   };
					   }}
				/>
			</Flex>

			<Modal
				title={t((l) => l.catalogs.deduplication.deduplication)}
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={'calc(100vw - 200px)'}
				footer={footerButtons}
				centered
				styles={{ body: { overflow: 'scroll' } }}
			>
				<SuspectedDuplicate suspectedDuplicate={suspectedDuplicate}
									closeModal={closeModal}/>
			</Modal>
		</>
	);
};
