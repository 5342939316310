import { RiArrowDownSLine } from '@remixicon/react';
import { Checkbox, Dropdown, Flex } from 'antd';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useTypedTranslation } from '../../hooks';
import s from './Filters.module.scss';
import { ListOptionsFilterOption } from './models';

enum ComparisonType {
	Equal = 'Equal',
	NotEqual = 'NotEqual',
}

interface ListOptionsFilterProps {
	options: ListOptionsFilterOption[];
}

const ListOptionsFilter = forwardRef((
	{
		options,
	}: ListOptionsFilterProps, ref,
) => {
	const { t } = useTypedTranslation();

	const [ comparisonType, setComparisonType ] = useState<ComparisonType>(ComparisonType.Equal);
	const [ selectedValues, setSelectedValues ] = useState<any[]>([]);

	useImperativeHandle(ref, () => ({
		reset: () => setSelectedValues([]),
		getValues: () => ({
			comparisonType: comparisonType,
			selectedValues: selectedValues,
		}),
	}), [ selectedValues, comparisonType ]);

	const items = useMemo(() => [
		{
			key: ComparisonType.Equal,
			label: t(l => l.common.filters.equal),
		},
		{
			key: ComparisonType.NotEqual,
			label: t(l => l.common.filters.notEqual),
		},
	], [ t ]);

	const comparisonTypeLabel = useMemo(() =>
			items.find(x => x.key === comparisonType).label,
		[ items, comparisonType ],
	);

	const onChangeValue = useCallback((value: string | number | boolean, checked: boolean) => {
		if (checked) {
			setSelectedValues(prev => [ ...prev, value ]);
		} else {
			setSelectedValues(prev => prev.filter(x => x !== value));
		}
	}, []);

	return (
		<>
			<div style={{ alignSelf: 'end' }}>
				<Dropdown trigger={[ 'click' ]}
						  menu={{
							  items,
							  onClick: e => setComparisonType(e.key as ComparisonType),
						  }}
				>
					<Flex gap={2} align="center" style={{ cursor: 'pointer' }}>
						{comparisonTypeLabel} <RiArrowDownSLine size={16}/>
					</Flex>
				</Dropdown>
			</div>


			{options.map((option, idx) => (
				<Checkbox key={idx}
						  checked={selectedValues.findIndex(x => x === option.value) !== -1}
						  onChange={(e) => onChangeValue(option.value, e.target.checked)}
						  onClick={(e) => e.stopPropagation()}
						  className={s.checkboxStyles}
				>
					{option.label}
				</Checkbox>
			))}
		</>
	);
});

export {
	ListOptionsFilter,
};