import { Flex, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CatalogItemsTable } from 'features/catalogs';
import { ClassifierItemsTable } from 'features/classifiers';
import { CompatibilityEntityTreeSelector } from 'features/compatibilities';

interface SelectSecondEntityWidgetProps {
	onSelect: (entity: {
		id: string,
		type: 'CATALOG' | 'CLASSIFIER' | 'CATALOG_ITEM' | 'CLASSIFIER_ITEM'
	}) => void;
}

const SelectSecondEntityWidget = (
	{
		onSelect,
	}: SelectSecondEntityWidgetProps,
) => {

	const treeRef = useRef<{ resetCheckedKeys: () => void }>();
	const catalogItemsRef = useRef<{ setSelection: (ids: string[]) => void, clearSelection: () => void }>();
	const classifierItemsRef = useRef<{ setSelection: (ids: string[]) => void, clearSelection: () => void }>();

	const [ selectedEntity, setSelectedEntity ] = useState<{
		id: string,
		type: 'CATALOG' | 'CLASSIFIER'
	} | null>(null);
	const [ checkedEntity, setCheckedEntity ] = useState<{
		id: string,
		type: 'CATALOG' | 'CLASSIFIER' | 'CATALOG_ITEM' | 'CLASSIFIER_ITEM'
	} | null>(null);

	useEffect(() => {
		if (!checkedEntity) {
			return;
		}
		if ([ 'CATALOG', 'CLASSIFIER', 'CLASSIFIER_ITEM' ].includes(checkedEntity.type)) {
			catalogItemsRef.current?.clearSelection();
		}
		if ([ 'CATALOG', 'CLASSIFIER', 'CATALOG_ITEM' ].includes(checkedEntity.type)) {
			classifierItemsRef.current?.clearSelection();
		}
		if ([ 'CATALOG_ITEM', 'CLASSIFIER_ITEM' ].includes(checkedEntity.type)) {
			treeRef.current.resetCheckedKeys();
		}

		onSelect(checkedEntity);
	}, [ checkedEntity ]);

	return (
		<Flex vertical gap={12}>
			<Typography.Title level={1}>
				Выбор сущности справа
			</Typography.Title>

			<Flex gap={24}>

				<div style={{ width: 294 }}>
					<div style={{ height: 'calc(100vh - 320px)', overflow: 'auto' }}>
						<CompatibilityEntityTreeSelector ref={treeRef}
														 onSelect={setSelectedEntity}
														 onCheck={setCheckedEntity}
						/>
					</div>
				</div>

				{selectedEntity?.type === 'CATALOG' && (
					<CatalogItemsTable ref={catalogItemsRef}
									   catalogId={selectedEntity?.id}
									   includeSubCatalogs={false}
									   selectable
									   onClick={id => catalogItemsRef.current?.setSelection([id])}
									   onSelect={(ids) =>
										   ids[0] && setCheckedEntity({ id: ids[0], type: 'CATALOG_ITEM' })
									   }
					/>
				)}

				{selectedEntity?.type === 'CLASSIFIER' && (
					<ClassifierItemsTable ref={classifierItemsRef}
										  classifierId={selectedEntity?.id}
										  selectable
										  onClick={id => classifierItemsRef.current?.setSelection([id])}
										  onSelect={(ids) =>
											  ids[0] && setCheckedEntity({ id: ids[0], type: 'CLASSIFIER_ITEM' })
										  }
					/>
				)}
			</Flex>
		</Flex>
	);
};

export {
	SelectSecondEntityWidget,
};