import React from 'react';
import { FormInputProps } from '../../types';
import { SelectCatalogInputBase } from './SelectCatalogInputBase';

type SelectMultipleCatalogInputProps = FormInputProps<string[] | null>;

const SelectMultipleCatalogInput = (
	{
		value,
		onChange,
	}: SelectMultipleCatalogInputProps) => {
	return <SelectCatalogInputBase value={value}
								   onChange={onChange as (value: string | string[]) => void}
								   multiple
	/>;
};

export {
	SelectMultipleCatalogInput,
};