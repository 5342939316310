import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Modal, Result, Table, TableProps } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { translateType } from 'entities/metadata/metaAttributes';
import { useGetCatalogAttributeDeclarationsQuery } from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';
import s from './AddAttributeDeclaration.module.scss';

interface AddMappingAttributeProps {
	catalogId: string;
	selectedAttributeDeclarationIds: string[];
	onSelect: (attributeDeclarationIds: string[]) => void;
}

export const AddMappingAttribute = memo((
	{
		catalogId,
		selectedAttributeDeclarationIds,
		onSelect,
	}: AddMappingAttributeProps) => {
	const { t } = useTypedTranslation();

	const [ isOpen, setIsOpen ] = useState<boolean>(false);
	const [ selectedRows, setSelectedRows ] = useState<Array<string>>([]);
	const [ dataSource, setDataSource ] = useState<any>();

	const { data: attributeDeclarations, isFetching, error } = useGetCatalogAttributeDeclarationsQuery({
		catalogId,
	});

	const columns = useMemo<TableProps['columns']>(() => [
		{
			title: t(l => l.common.defaultNames.name),
			dataIndex: 'displayName',
			key: 'displayName',
		},
		{
			title: t(l => l.common.defaultNames.type),
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: t(l => l.common.defaultNames.isMulti),
			dataIndex: 'isMulti',
			key: 'isMulti',
		},
	], [ t ]);

	const onConfirm = useCallback(() => {
		onSelect(selectedRows);
		setSelectedRows([]);
		setIsOpen(false);
	}, [ selectedRows ]);

	useEffect(() => {
		setSelectedRows(selectedAttributeDeclarationIds);
	}, [ selectedAttributeDeclarationIds ]);

	useEffect(() => {
		if (attributeDeclarations) {
			setDataSource(attributeDeclarations.map((item) => ({
				id: item.id,
				key: item.id,
				displayName: item.attribute.displayName,
				type: translateType(item.attribute.type),
				isMulti: item.attribute.list ? 'да' : 'нет',
			})));
		}
	}, [ attributeDeclarations ]);

	const openModal = useCallback(() => setIsOpen(true), []);
	const closeModal = useCallback(() => setIsOpen(false), []);

	const footer = useMemo(() => (
		<Flex gap={12} justify="flex-end">
			<Button type="text"
					onClick={closeModal}
			>
				{t(l => l.common.buttons.cancel)}
			</Button>
			<Button type="primary"
					disabled={!selectedRows.length}
					onClick={onConfirm}
			>
				{t(l => l.common.buttons.confirm)}
			</Button>
		</Flex>
	), [ closeModal, onConfirm, selectedRows ]);

	return (
		<>
			<Button
				style={{ maxWidth: 'fit-content' }}
				icon={<RiAddCircleLine size={16}/>}
				onClick={openModal}
			>
				Добавить атрибут
			</Button>

			<Modal title="Поля справочника"
				   width="50vw"
				   open={isOpen}
				   closable={false}
				   footer={footer}
			>
				<Flex vertical gap={24}>
					<Flex gap={12} vertical flex={1}>
						<div className={s.columnAttributes}>
							{dataSource && (
								<Table columns={columns}
									   dataSource={dataSource}
									   loading={isFetching}
									   rowKey="id"
									   rowSelection={{
										   selectedRowKeys: selectedRows,
										   onChange: x => setSelectedRows(x as string[]),
									   }}
									   pagination={false}
								/>
							)}
							{error && (
								<Result
									status="error"
									title={t(l => l.common.statuses.error)}
									subTitle={JSON.stringify(error)}
								/>
							)}
						</div>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
});
