import { App, Form, Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	ClassifierItemResponse,
	useGetClassifierAttributeDeclarationsQuery,
	useGetClassifierItemQuery,
	useUpdateClassifierItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper, mapValueForDto } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { mapValueForForm } from 'shared/helpers/mapValueForForm';
import { RecordType } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';
import { WorkTable } from 'shared/ui';
import { EditRecordStatus } from '../EditRecordStatus/EditRecordStatus';

const columns = [
	{
		title: 'Наименование атрибута',
		dataIndex: 'attributeName',
		key: 'attributeName',
	},
	{
		title: 'Значение',
		dataIndex: 'value',
		key: 'value',
	},
];

const EditClassifierRecordUi: React.FC = () => {
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { classifierGroupId, classifierItemId } = useParams();

	const [ form ] = Form.useForm();
	const [ recordsList, setRecordsList ] = useState<RecordType[]>([]);

	const { data: classifierAttributeDeclarations, isLoading } = useGetClassifierAttributeDeclarationsQuery({
		classifierId: classifierGroupId,
	});

	const { data: record, error: recordsError, isFetching: isRecordsLoading } = useGetClassifierItemQuery({
		id: classifierItemId,
	});

	const [ fetchUpdateItem ] = useUpdateClassifierItemMutation();

	const status = Form.useWatch('status', form);
	useEffect(() => {
		updateItem().then();
	}, [ status ]);

	const mapValues = (data?: ClassifierItemResponse) => {
		form.setFieldsValue({
			...Object.fromEntries(classifierAttributeDeclarations
				.map(({ id, attribute }) => {
					const { type, list } = attribute;
					const value = record.values[id]?.value;

					return [
						id,
						mapValueForForm(type, list, value),
					];
				})),
			status: data.status.toLowerCase(),
		});

		const flattenedRecords: RecordType[] = [
			{
				attributeName: 'Статус позиции',
				id: 'status',
				key: 'status',
				value: (
					<Form.Item name="status">
						<EditRecordStatus/>
					</Form.Item>
				),
			},
			...classifierAttributeDeclarations
				.map(({ id, attribute }) => {
						return {
							id: id,
							key: id,
							attributeName: attribute.displayName,
							value: CellInputParser(
								id,
								attribute,
								{
									callback: updateItem,
								},
							),
						};
					},
				),
		];

		setRecordsList(flattenedRecords);
	};

	const deTouchFields = () => {
		const fields = Object
			.entries(form.getFieldsValue())
			.map(([ name, value ]) => ({
				name,
				value,
				touched: false,
				errors: [],
			}));

		form.setFields(fields);
	};

	const updateItem = async () => {
		try {
			if (!form.isFieldsTouched()) {
				return;
			}

			try {
				await form.validateFields();
			} catch (error) {
				console.error(error);
				return;
			}

			const values: { [k: string]: any } = form.getFieldsValue();
			deTouchFields();

			await fetchUpdateItem({
				id: classifierItemId,
				classifierId: classifierGroupId,
				updateClassifierItemRequest: {
					parentItemId: record.parentItemId,
					status: values.status.toUpperCase(),
					values: Object.fromEntries(
						Object.entries(values)
							.filter(([ key ]) => key !== 'status')
							.map(([ declarationId, value ]) => {
								const { attribute } = classifierAttributeDeclarations.find(
									(x) => x.id === declarationId,
								);
								return [
									declarationId,
									mapValueForDto(attribute.type, value),
								] as [ string, any ];
							}),
					),
				},
			})
				.unwrap();

			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		} catch (err) {
			errorHelper('Ошибка при редактировании записи', err, notification);
		}
	};

	useEffect(() => {
		if (record && classifierAttributeDeclarations) {
			mapValues(record);
		}
	}, [ classifierAttributeDeclarations, record ]);

	if (recordsError) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(recordsError)}/>;
	}

	const loading = isRecordsLoading || isLoading;

	return (
		<>
			{loading ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Spin size="large"/>
				</div>
			) : (
				<Form form={form}>
					<WorkTable
						pagination={false}
						dataSource={recordsList}
						columns={columns}
					/>
				</Form>
			)}
		</>
	);
};

export const EditClassifierRecord = React.memo(EditClassifierRecordUi);
