import dayjs from 'dayjs';
import { AttributeType } from '../models';

const mapValueForForm = (type: AttributeType, isList: boolean, value: any) => {
	if (isList) {
		if (!value) {
			return [];
		}

		return (
			Array.isArray(value)
				? value
				: [ value ]
		).map(x => mapValueForForm(type, false, x));
	}

	if (!value) {
		return value;
	}

	if (type === 'DATE_TIME') {
		return dayjs(value);
	}

	return value;
};

export {
	mapValueForForm,
};