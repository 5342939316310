import { RiDeleteBin6Line } from '@remixicon/react';
import { Button, Checkbox, Flex, Modal, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useMemo, useState } from 'react';
import { MeasurementGroupResponse } from 'shared/api/generatedApi/mdmgApi';
import { randomString } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import { useDeleteMeasureGroup } from './deleteMeasureGroup.model';

interface IDeleteMeasuresGroupProps {
	measure: MeasurementGroupResponse;
	isDropdown?: boolean;
}

const DeleteMeasuresGroupUi: React.FC<IDeleteMeasuresGroupProps> = ({ measure, isDropdown }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [confirm, setConfirm] = useState<boolean>(false);

	const { deleteMesasureGroup, loading } = useDeleteMeasureGroup();
	const { t } = useTypedTranslation();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		setIsModalOpen(false);
		await deleteMesasureGroup(measure);
		setConfirm(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setConfirm(false);
	};

	const onChange = (e: CheckboxChangeEvent) => setConfirm(e.target.checked);

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t((l) => l.common.buttons.cancel)}
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={!confirm}
					loading={loading}
				>
					{t((l) => l.common.buttons.delete)}
				</Button>
			</Flex>
		),
		[confirm, loading, measure]
	);

	return (
		<>
			{isDropdown ? (
				<DropdownLink
					title={t((l) => l.common.buttons.delete)}
					icon={<RiDeleteBin6Line size={16} />}
					onClick={showModal}
				/>
			) : (
				<Button type="text" icon={<RiDeleteBin6Line />} onClick={showModal}>
					{t((l) => l.common.buttons.delete)}
				</Button>
			)}

			<Modal
				title={t((l) => l.measures.actions.delete.group.modalTitle)}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[footerButtons]}
				closeIcon={null}
				width={600}
			>
				<Flex vertical gap={24}>
					<Typography.Text>
						{t((l) => l.measures.actions.delete.group.modalMessage)}
					</Typography.Text>
					<Checkbox onChange={onChange} checked={confirm} style={{ marginBottom: 38 }}>
						{t((l) => l.measures.actions.delete.group.agreement)}
					</Checkbox>
				</Flex>
			</Modal>
		</>
	);
};

export const DeleteMeasuresGroup = React.memo(DeleteMeasuresGroupUi);
