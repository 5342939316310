import { Spin } from 'antd';
import keycloak, { initializeKeycloak } from 'keycloak';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { providerStyles } from '../styles/providerStyles';

const clearIssQueryParam = () => {
	const url = new URL(window.location.href);
	if (url.searchParams.has('iss')) {
		url.searchParams.delete('iss');
		window.history.replaceState(null, null, url.toString());
	}
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [ authenticated, setAuthenticated ] = useState<boolean>(false);
	const [ loading, setLoading ] = useState<boolean>(true);

	useEffect(() => {
		let refreshTimeout: NodeJS.Timer | undefined;

		const initKeycloak = async () => {
			try {
				const authenticated = await initializeKeycloak();
				setAuthenticated(authenticated);

				if (!authenticated) {
					keycloak.login();
				} else {
					clearIssQueryParam();

					refreshTimeout = setInterval(() => {
						keycloak.updateToken(30)
							.then((refreshed) => {
								if (refreshed) {
									console.debug('Token was successfully refreshed');
								}
							})
							.catch((error) => {
								console.error('Failed to refresh token', error);
								keycloak.login();
							});
					}, 10000);
				}
			} catch (error) {
				console.error('Keycloak initialization failed:', error);
			} finally {
				setLoading(false);
			}
		};

		initKeycloak();

		return () => {
			if (refreshTimeout) {
				clearInterval(refreshTimeout);
			}
		};
	}, []);

	if (loading) {
		return <Spin style={providerStyles} size="large"/>;
	}

	return authenticated
		? <>{children}</>
		: <div>You are not authenticated. Please log in.</div>;
};
