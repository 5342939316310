import { App, Flex, Typography } from 'antd';
import React, { memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { EditAttribute } from 'features/metadata/EditAttribute';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { RealtimeBaseEvent, TaskType, useRealtimeEvent } from 'entities/events';
import {
	mdmgApi,
	useCreateAttributeMutation,
	useGetAttributeGroup1Query,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

const CreateAttributePage = memo(() => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { attributeGroupId } = useParams();
	const navigate = useNavigate();

	const [createAttribute] = useCreateAttributeMutation();

	const { data: currAttrGroup } = useHandleQueryError(
		useGetAttributeGroup1Query(
			{
				id: attributeGroupId,
			},
			{
				skip: !attributeGroupId,
			}
		),
		(l) => l.attributes.attributeGroupErr
	);

	useRealtimeEvent(
		TaskType.DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK,
		({ payload }: RealtimeBaseEvent) => {
			const deletedAttributeGroupIds = payload.entityIds as string[];

			dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
			dispatch(mdmgApi.util.invalidateTags(['AttributeGroups']));

			if (deletedAttributeGroupIds.includes(attributeGroupId)) {
				navigate(`/${routes.metadata.main}`);
			}
		}
	);

	useEffect(() => {
		if (currAttrGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Метаданные',
					url: `${routes.metadata.main}`,
					child: {
						displayName: `${currAttrGroup.displayName}`,
						url: `${routes.metadata.main}/${routes.metadata.group}/${currAttrGroup.id}`,
						child: {
							displayName: 'Создание атрибута',
							url: `${routes.metadata.main}/${routes.metadata.group}/${currAttrGroup.id}/${routes.metadata.attribute}/${routes.metadata.create}`,
						},
					},
				})
			);
		}
	}, [currAttrGroup]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.metadata.main}/${routes.metadata.group}/${attributeGroupId}`);
	};

	const onSubmitForm = useCallback(
		async (attr: any) => {
			if (!attr.displayName || attr.displayName.length === 0 || !attr.type) {
				return;
			}

			try {
				const { id } = await createAttribute({
					attributeGroupId: attributeGroupId,
					createAttributeRequest: { ...attr, list: !!attr.list },
				}).unwrap();
				navigate(
					`/${routes.metadata.main}/${routes.metadata.group}/${attributeGroupId}/${routes.metadata.attribute}/${id}`
				);
			} catch (err) {
				errorHelper(
					t((l) => l.attributes.attributeErr),
					err,
					notification
				);
			}
		},
		[attributeGroupId]
	);

	return (
		<Flex vertical gap={24}>
			<Flex justify="space-between">
				<Flex vertical gap={12}>
					<Typography.Title level={1}>Создание атрибута</Typography.Title>
				</Flex>

				<Transactions />
			</Flex>

			<EditAttribute onSubmit={onSubmitForm} />

			<DetailFooter customHandleBack={handleBackClick} />
		</Flex>
	);
});

export { CreateAttributePage };
