import React, { memo } from 'react';
import { FilterButtons } from './FilterButtons';
import { IntegrationsList } from './IntegrationsList';

const IntegrationsListWidget = memo(() => {
	return (
		<>
			<FilterButtons/>
			<IntegrationsList/>
		</>
	);
});

export {
	IntegrationsListWidget,
};
