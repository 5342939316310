import { RiDeleteBin6Line } from '@remixicon/react';
import { App, Button } from 'antd';
import React from 'react';
import { useDeleteCompatibilityItemsMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';

interface IDeleteCompatibilityItems {
	ids: string[];
}

export const DeleteCompatibilityItems: React.FC<IDeleteCompatibilityItems> = ({ ids }) => {
	const { notification } = App.useApp();
	const { t } = useTypedTranslation();
	const [deleteItems] = useDeleteCompatibilityItemsMutation();

	const delItems = () => {
		deleteItems({ ids })
			.unwrap()
			.then(
				// TODO инвалидация Compatibility-Items
				() => {}
			)
			.catch((err) => {
				errorHelper(
					t((l) => l.compatibilities.error.deletingCompatibilityItems),
					err,
					notification
				);
			});
	};

	return (
		<>
			<Button type="text" onClick={() => delItems()}>
				<RiDeleteBin6Line size={18} color={colors.grayIcon} />
			</Button>
		</>
	);
};
