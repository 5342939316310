import { RiDeleteBin2Line } from '@remixicon/react';
import { Flex, Button, Modal, Typography, App } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { deleteCatalogRestrictionTableDeclaration } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogRestrictionTableDeclarationMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper, randomString } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

interface IDeleteCatalogRestrictionTableUiProps {
	declarationId: string;
}

const DeleteCatalogRestrictionTableUi = ({
											 declarationId,
										 }: IDeleteCatalogRestrictionTableUiProps) => {
	const { notification } = App.useApp();
	const { getTransactions } = useTransactions();
	const dispatch = useAppDispatch();

	const [ open, setOpen ] = useState<boolean>(false);

	const [ useDeleteCatalogRestrictionTableDeclarationMutationElement, { isLoading } ] = useDeleteCatalogRestrictionTableDeclarationMutation();

	const handleDeleteDeclaration = async (declarationId: string) => {
		await useDeleteCatalogRestrictionTableDeclarationMutationElement({ ids: [ declarationId ] })
			.unwrap()
			.then(() => {
				dispatch(deleteCatalogRestrictionTableDeclaration({ id: declarationId }));
				getTransactions();
			})
			.catch((err) =>
				errorHelper('Ошибка удаления таблицы допустимых значений', err, notification),
			);
	};

	const showModal = () => setOpen(true);
	const handleCancel = () => setOpen(false);

	const handleOk = useCallback(
		async (e: React.SyntheticEvent) => {
			e.stopPropagation();
			handleDeleteDeclaration(declarationId).finally(() => setOpen(false));
		},
		[ declarationId ],
	);

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отмена
				</Button>

				<Button key="submit" type="primary" onClick={handleOk} loading={isLoading}>
					Удалить
				</Button>
			</Flex>
		),
		[ isLoading ],
	);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBin2Line size={16}/>}
				title="Удалить"
				onClick={showModal}
			/>
			<Modal
				open={open}
				title="Подтверждение удаления"
				footer={[ footerButtons ]}
				onCancel={handleCancel}
			>
				<Flex vertical gap={20}>
					<Typography.Paragraph>
						Вы действительно хотите удалить данную таблицу допустимых значений? Таблицу
						допустимых значений будет удалена без возможности восстановления
					</Typography.Paragraph>
				</Flex>
			</Modal>
		</>
	);
};

export const DeleteCatalogRestrictionTable = React.memo(DeleteCatalogRestrictionTableUi);
