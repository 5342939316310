import { Localization } from './types';

const localization: Localization = {
	common: {
		buttons: {
			confirm: 'Confirm',
			cancel: 'Cancel',
			back: 'Back',
			delete: 'Delete',
			deleteSelected: 'Delete selected',
			edit: 'Edit',
			columnsSetup: 'Column Setup',
			create: 'Create',
			selectAll: 'Select All',
			save: 'Save',
			apply: 'Apply',
			continue: 'Continue',
			select: 'Select',
		},
		statuses: {
			normalized: 'Normalized',
			nonNormalized: 'Non-Normalized',
			blocked: 'Blocked',
			deleted: 'Deleted',
			dupe: 'Duplicate',
			error: 'Error',
		},
		defaultNames: {
			emptyName: '<Name not filled in>',
			status: 'Status',
			name: 'Name',
			designation: 'Name',
			type: 'Type',
			isMulti: 'Multiple',
			description: 'Description',
			dataType: 'Data type',
			mainInfo: 'General Information',
			defaultValue: 'Default value',
			inputMask: 'Input mask',
			formula: 'Formula',
			maxLength: 'Maximum length',
			accuracy: 'Accuracy',
			emptyValue: '<Value not specified>',
			yes: 'Yes',
			no: 'No',
			itemsNumber: 'Items number',
			groupName: 'Group name',
		},
		search: {
			nothingFound: 'Nothing found for your query',
			checkRequest: 'Check if your query is entered correctly or try modifying it',
		},
		inputs: {
			emptyValueLabel: 'Empty',
			trueValueLabel: 'True',
			falseValueLabel: 'False',
		},
		tables: {
			total: 'Total',
		},
		filters: {
			filterControl: 'Filter control',
			clearFilter: 'Clear filter',
			equal: 'Equal',
			notEqual: 'Not equal',
			extendedFilters: 'Advanced filter',
			addFilterOption: 'Add filtering option',
			and: 'And',
			or: 'Or',
			empty: 'Empty value',
			notEmpty: 'Non-empty value',
			more: 'Greater than',
			moreOrEqual: 'Greater than or equal to',
			less: 'Less than',
			lessOrEqual: 'Less than or equal to',
			contains: 'Contains',
			notContains: 'Does not contain',
		},
		error: {
			message: 'Error message',
			emptyRequiresFields: 'Required fields must be filled in',
			noStateDifference: 'Error while receiving a state difference',
			title: 'Error!',
		},
	},
	catalogs: {
		mainInfo: 'General Information',
		catalogs: 'Catalogs',
		catalog: 'Catalog',
		catalogInfo: 'Catalog Details',
		attributesDeclarations: {
			attributesDeclarationsErr: 'Error fetching the list of declarations!',
			addAttribute: 'Add Attribute',
			addAttributeDeclModal: 'To add an attribute, you need to select it from the list',
			mappingCreated: 'Mapping created',
			mappingUpdated: 'Mapping updated',
			mappingDeleted: 'Mapping deleted',
		},
		deduplication: {
			main: 'Position Deduplication',
			deduplication: 'Deduplication',
			dupesGroupsErr: 'Error getting duplicate groups',
			dupesSearchResult: 'Duplicate Search Results',
			deleteDupesForSelectedItems: 'Delete duplicates for selected positions',
			makeDupesForSelectedItems: 'Mark selected positions as "Duplicate"',
			searchSaved: 'Search variant saved',
			searchCompleted: 'Search variant completed',
			updateSearchErr: 'Error updating duplicate search variant',
			importSearch: 'Search during import',
			addCatalog: 'Add Catalog',
			attributesList: 'Attributes used for duplicate search',
			addAttribute: 'Attributes used for duplicate search',
			selectAttribute: 'To add an attribute, you need to select it from the list',
			startDupeSearch: 'Start search',
			dupeSearchResult: 'View duplicate search results',
			variantN: 'Variant №',
			relaunchSearch: 'Restart search',
			addNewVariant: 'Add new duplicate search variant',
			isOriginal: 'Is Original',
			setAsOriginal: 'Set as Original',
			position: 'Position',
			source: 'Source',
			unionAndDelete: 'Merge and delete duplicates',
			setStatus: 'Set status "Duplicate"',
			deleteDupes: 'Delete duplicates for the position',
			deleteSearchOption: 'Delete',
			actualOn: 'Actual on',
			actualOnTooltip: 'To get an actual result, run the search again',
		},
		groups: {
			noCatalog: 'No catalog',
			noGroups: 'No reference groups',
			selectGroup: 'Select a reference group to display a list of records',
			currCatalogErr: 'Error fetching the current catalog',
			catalogErr: 'Error fetching catalog',
			rootGroupErr: 'Error fetching root reference group!',
			listErr: 'Error fetching root reference group!',
			noGroupErr: "You haven't added any reference groups yet",
			createNewGroup: 'To create a new group, click the "Add Reference Group" button',
			createNestedGroup: 'Add nested catalog',
			addNewCatalog: 'Add new catalog',
		},
		records: {
			recordsValues: 'Attribute Values',
			attributesSetup: 'Attribute Setup',
			editRecord: 'Edit Record',
			recordCard: 'Catalog Position Card',
			recordsRelationsErr: 'Error fetching records for "Relation" type attribute!',
			recordsListErr: 'Error fetching the list of records!',
			declarationsListErr: 'Error fetching declarations',
			noRecords: 'The catalog contains no positions',
			addRecordTooltip: 'To add a new position, click the "Add Reference Record" button',
			getMeasurementsErr: 'Error when getting units of measurement',
			converterErr: 'Unit conversion error',
			historyTabTitle: 'History',
		},
		export: {
			exportCatalogTitle: 'Export to Excel',
			exportCatalogTooltip:
				'To export catalog records to Excel, you need to select the columns to export',
			exportCatalogBtn: 'Export',
			fetchErr: 'Error fetching data for export',
		},
		restrictions: {
			restrictions: 'Restrictions',
			restrictionsTableErr: 'Error editing the table of allowed values',
			declarationsTableErr: 'Error editing the table of allowed values',
			addTable: 'Add allowed values table',
			noTableErr: "You haven't created any restriction tables yet",
			tabTitle: 'Allowed values tables',
			addTableBtn: 'Add allowed values table',
			noTableMsg: 'Add an allowed values table to start editing',
			requiredCheckbox: 'Required',
			requiredCheck: 'Required check',
			requiredAttributes: 'Required attributes',
			addAttributeModalTitle: 'Add attribute',
			uniqueValueCheck: 'Unique value check',
			uniqueAttributes: 'Unique attributes',

		},
		deleteRecord: {
			title: 'Delete Reference Record',
			message:
				'Do you really want to delete this reference record? The reference record and related data will be permanently deleted',
			relatedData: 'Related data:',
			integrationMessages: 'integration exchange messages',
			transitionKeys: 'transition keys',
			agreement: 'I agree to delete all the above related data',
			error: 'Error deleting catalog item!',
		},
		deleteCatalog: {
			title: 'Delete Catalog',
			agreement: 'I agree to delete the catalog and all its sub-catalogs',
			alert: 'Do you really want to delete this catalog? The catalog and all its sub-catalogs will be permanently deleted',
			error: 'Error deleting catalog!',
		},
		error: {
			gettingItem: 'Error while catalog item getting',
			addAttributeToRestrictionErr: 'Error while adding an attribute to a restriction!',
			gettingDeclarationsErr: 'Error while getting declarations!',
		},
	},
	classifier: {
		mainPage: {
			title: 'Classifiers',
		},
		common: {
			classifiers: 'Classifiers',
			classifierCard: 'Classifier card',
			attributesSetting: 'Attributes setting for a classification',
			chooseAttributeFromList: 'Select an attribute from the list to add an attribute',
			addAttribute: 'Adding an attribute',
			classifiersList: 'Classifiers list',
			classifierFilling: 'Classifier filling',
			editRecord: 'Edit record',
			recordCard: 'Classifier record card',
			noClassifier: 'No classifier',
			selectClassifier: 'Select a classifier to display a list of records',
		},
		action: {
			addClassifier: 'Add classifier',
			setAsRecordName: 'Set as a record name',
			setAttributesForClassification: 'Assign attributes to classify reference data',
			addAttribute: 'Add attribute',
		},
		error: {
			newClassifierCreation: 'Error while creating a new classifier!',
			classifierGetting: 'Error while getting a classifier!',
			classifierEditing: 'Error while editing a classifier!',
			gettingItem: 'Error while a classifier item getting',
		},
	},
	attributes: {
		attributeTreeErr: 'Error while loading attributes tree',
		attributeGroupErr: 'Error getting attribute group',
		attributeListErr: 'Error getting attribute list',
		attributeErr: 'Error getting attribute',
		gettingAttributeApplicabilityErr: 'Error loading attribute applicability in directories',
		receivingDataForEditingAttributeErr: 'Error when receiving data for editing the attribute!',
		applicabilityinCatalogs: 'Applicability in catalogs',
		unusedAttribute: 'The attribute is not used in the catalogs',
		applicabilityListError: 'Error when getting the list of applicability in the catalogs!',
		attributesSetup: 'Attribute Setup',
		attributesTabTitle: 'Attributes',
	},
	attributeGroup: {
		deleteAttributeGroup: {
			title: 'Delete Attribute Group',
			agreement: 'I agree to delete the attribute group and all its attributes',
			alert: 'Do you really want to delete this attribute group? The attribute group and all its attributes will be permanently deleted',
			error: 'Error deleting attribute group!',
		},
	},
	measures: {
		mainInfo: 'General Information',
		measurementUnit: 'Measurement unit',
		measuresTabTitle: 'Measurement units',
		group: {
			displayName: "Measurement units group's name",
			description: 'Description',
			groupTitle: 'Measurement units group',
			groupsTitle: 'Measurement units groups',
		},
		unit: {
			displayName: 'Name',
			shortName: 'Designation',
			basicUnit: 'The basic measurement unit',
			formula: 'Conversion formula',
			inverseFormula: 'Inverse conversion formula',
			coefficient: 'Conversion factor',
			noCoefficient: '<Not specified>',
			isBasic: {
				true: 'Yes',
				false: 'No',
			},
			cardTitle: 'Measurement unit card',
		},
		error: {
			gettingMeasurementUnits: 'Measurement units receiving error!',
			editingMeasurementUnit: 'Measurement unit editing error!',
			designatingSecondBaseUnit:
				'The choice of the second basic measurements unit is impossible',
			gettingDataToSelectUnits: 'Could not get the data to select the Units of Measurement!',
		},
		actions: {
			delete: {
				unit: {
					modalTitle: 'Delete a measurement unit',
					modalMessage:
						'Do you really want to delete this unit of measurement? The measurement unit will be permanently deleted',
				},
				group: {
					modalTitle: 'Delete a measurement unit group',
					modalMessage:
						'Do you really want to delete this measurement units group? The group and its measurement units will be permanently deleted.',
					agreement: 'I agree to delete the group and its measurement units.',
				},
			},
			add: {
				unit: {
					btnTitle: 'Add a measurement units',
				},
				group: {
					btnTitle: 'Add a measurement units group',
					editGroupTitle: 'Edit measurement units group',
					createGroupTitle: 'Create measurement units group',
				},
			},
		},
		hint: {
			baseUnit:
				'There can be only one basic measurement unit in a group of measurement units. The remaining measurement units are linked to the basic unit through a conversion factor or conversion formula. The conversion factor of the basic measurement units is 1.',
		},
		noGroups: "You haven't added any unit groups yet.",
		createNewGroup:
			'To create a new group, click on the «Add a measurement units group» button',
	},
	constraintTables: {
		hint: {
			required: '«+» — Attribute must be required',
			any: '«*» — Attribute value can be any',
			requiredAndFilled:
				'«*+» — Attribute value can be any, but must be filled, i.e. the cell cannot be empty',
		},
		card: 'Card of the restrictive table',
		noColumns: 'You have not selected any columns for the constraint table yet',
		noTables: 'You have not created any constraint tables yet',
		listTitle: 'List of constraint tables',
		pageSubtitle: 'Section for managing reference data',
		assignColumnLabel: 'Columns of the table',
		assignColumnModalTitle: 'Select a column name',
		constraintTablesTabTitle: 'Constraint tables',
	},
	breadcrumbs: {
		metadata: 'Metadata',
	},
	profile: {
		goToProfileButton: 'Profile',
		logoutButton: 'Logout',
		userName: 'User name',
	},
	transactions: {
		errorGetList: 'Error retrieving transaction list!',
		addToCompareTitle: 'Compare',
	},
	integrations: {
		exchangeConfig: {
			deleteError: 'Error deleting exchange configuration!',
			tabs: {
				configInfo: 'Exchange Configuration Information',
				mapping: 'Mapping Setup',
			},
			list: {
				configName: 'Configuration Name',
				linkedCatalogs: 'Linked Catalogs',
				replicationDirection: 'Replication Direction',
				status: 'Status',
				error: 'Error getting exchange configurations list!',
			},
		},
		replicationDirections: {
			import: 'Import',
			export: 'Export',
		},
		breadcrumbs: {
			integrations: 'Integrations',
			connectionCard: 'Connection Card',
			configCard: 'Exchange Configuration Card',
		},
		tabs: {
			exchangeConfigs: 'Exchange Configurations',
			connectionInfo: 'Connection Information',
			journal: 'Message Journal',
			exchangeKeys: 'Exchange Keys',
		},
		filters: {
			showAll: 'Show all',
			showActive: 'Show active integrations',
			showFailed: 'Show failed integrations',
		},
		connectionInfo: {
			identifier: 'Identifier',
			lastActivity: 'Last activity',
			completed: 'Compl...',
			stopped: 'Stopp...',
		},
		deleteIntegration: {
			confirmTitle: 'Delete Confirmation',
			confirmMessage:
				'Do you really want to delete this integration? The integration and related data will be permanently deleted',
			relatedData: 'Related data:',
			exchangeConfigs: 'exchange configurations',
			replicationMessages: 'replication messages',
			transitionKeys: 'transition keys',
			confirmCheckbox: 'I agree to delete all the above related data',
			deleteError: 'Error deleting integration!',
		},
		errors: {
			listError: 'Error getting integrations list!',
		},
		exchangeKeys: {
			externalId: 'External ID',
			internalId: 'Internal ID',
			position: 'Position',
		},
	},
	compatibilities: {
		mainInfo: 'General information',
		usingCompatibility: 'Using compatibility',
		addCompatibility: 'Adding compatibility',
		tabTitle: 'Compatibilities',
		tabCatalogs: 'Catalogs',
		tabClassifiers: 'Classifiers',
		choosingRecordTitle: 'Choosing a record',
		mainPage: {
			title: 'Classifiers',
		},
		item: {
			cardTitle: 'Compatibility card',
			leftEntityName: 'Left entity name',
			contextDisplayName: 'Context display name',
			rightEntityName: 'Right entity name',
			inverseContextDisplayName: 'Inverse context display name',
		},
		actions: {
			addRecordToGroup: 'Add a record to a group',
			setup: 'Setup compatibility',
			linkToEntity: 'Link to a catalog/item',
			addContextName: 'Add a new contextual link',
			delete: {
				modalTitle: 'Delete compatibility',
				modalMessage: 'Do you really want to remove this compatibility? Compatibility will be permanently deleted.'
			},
			add: {
				btnTitle: 'Add compatibility'
			},
			addCompatibilitiesItems: 'Add a record to a group',
		},
		error: {
			getting: 'Error while getting compatibilities',
			deleting: 'Error while deleting compatibilities',
			gettingItems: 'Error while getting compatibility by items',
			editingCompatibilityItem: 'Error when changing the compatibility value!',
			usingCompatibilityListError: 'Error when getting the compatibility!',
			deletingCompatibilityItems: 'Error while compatibility items deleting',
			addCompatibilitiesItemsErr: 'Error while adding compatibility items!',
		},
	}
};

export default localization;
