import { Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { ClassifiersListWidget } from 'widgets/Classifiers/ClassifiersListWidget';
import { Transactions } from 'widgets/Transactions';
import { CreateClassifierGroup, SearchClassifiersGroup } from 'features/classifiers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { setSubMenuCollapsed } from 'shared/ui';

export const ClassifierPage: React.FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTypedTranslation();

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	return (
		<Flex vertical gap={24}>
			<Flex justify="space-between" vertical style={{ width: '100%' }}>
				<Flex vertical gap={12} style={{ width: '100%' }}>
					<Flex vertical gap={12} style={{ width: '100%' }}>
						<Flex justify="space-between">
							<Typography.Title level={1}>
								{t(l => l.classifier.mainPage.title)}
							</Typography.Title>
							<Transactions />
						</Flex>
						<SearchClassifiersGroup />
						<CreateClassifierGroup />
					</Flex>
				</Flex>

				<ClassifiersListWidget />
			</Flex>
		</Flex>
	);
};
