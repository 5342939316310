import { RiFilterLine, RiSettings5Line } from '@remixicon/react';
import { Button, Checkbox, Dropdown, Flex } from 'antd';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTypedTranslation } from '../../hooks';
import { colors } from '../../styles';
import { FilterItem } from './FilterItem';
import s from './Filters.module.scss';
import { Filter } from './models';

export interface FiltersProps {
	filters: Filter[];
	onChange: (any) => void;
}

const Filters = ({
					 filters,
					 onChange,
				 }: FiltersProps) => {
	const { t } = useTypedTranslation();

	const [ activeFilters, setActiveFilters ] = useState<string[]>([]);

	const [ filterValues, setFilterValues ] = useState({});

	console.log(filterValues);

	const filterToggles = useMemo(() =>
		filters.map((filter: Filter) => ({
			key: filter.key,
			label: (
				<Checkbox onChange={(e) => {
					if (e.target.checked) {
						setActiveFilters(prev => [
							...prev,
							filter.key,
						]);
					} else {
						setActiveFilters(prev => prev.filter(x => x === filter.key));
					}
				}}
				>
					{filter.title}
				</Checkbox>
			),
		})), []);

	useEffect(() => {
		onChange(filterValues);
	}, [ filterValues ]);

	const filterItems = useMemo(() => {
		return filters
			.filter(x => activeFilters.includes(x.key))
			.map((filter) => {
				return (
					<Dropdown
						key={filter.key}
						trigger={[ 'click' ]}
						destroyPopupOnHide
						dropdownRender={() => (
							<FilterItem filter={filter}
										onChange={e => {
											console.log('CHECK 2', filter.key, e);
											setFilterValues(prev => ({
												...prev,
												[filter.key]: e,
											}));
										}}
							/>
						)}
					>
						<Button type="default"
								icon={<RiSettings5Line color={colors.grayIcon}/>}
								className={cn([ s.filterButton ])}
						>
							{filter.title}
						</Button>
					</Dropdown>
				);
			});
	}, [ activeFilters ]);

	return (
		<Flex gap={6}>
			{filterItems}
			<Dropdown trigger={[ 'click' ]}
					  menu={{ items: filterToggles }}
					  className={s.addFilterButton}
			>
				<Button type="text" icon={<RiFilterLine/>}>
					{t((l) => l.common.filters.filterControl)}
				</Button>
			</Dropdown>
		</Flex>
	);
};

export {
	Filters,
};