import { Flex, Spin } from 'antd';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CatalogItemsTable } from 'features/catalogs';
import { useGetCatalogAttributeDeclarationsQuery } from 'shared/api/generatedApi/mdmgApi';
import { ExtendedFilters } from 'shared/components/Filters';
import { routes } from 'shared/config';
import { useHandleQueryError } from 'shared/hooks';

interface CatalogItemsTableWidgetProps {
	catalogId: string;
	isSubordinatedCatalogsRecordsShown: boolean;
}

const CatalogItemsTableWidget = memo((
	{
		catalogId,
		isSubordinatedCatalogsRecordsShown,
	}: CatalogItemsTableWidgetProps) => {
	const navigate = useNavigate();

	const [ searchParams ] = useSearchParams();
	const searchValue = searchParams.get('searchValue');

	const [ filters, setFilters ] = useState<any>(null);

	const searchDetails = useMemo(() => {
		if (filters == null) {
			return null;
		}
		return JSON.stringify(filters);
	}, [ filters ]);

	const {
		data: attributeDeclarations,
		isFetching: isDeclarationsFetching,
	} = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const onClickItem = useCallback(
		(id: string | number) =>
			navigate(`/${routes.catalogs.main}/${catalogId}/record/${id}`),
		[ navigate ],
	);

	const filtersDeclarations = useMemo(() => {
		if (!attributeDeclarations) {
			return [];
		}
		return attributeDeclarations.map(x => ({
			key: x.id,
			title: x.attribute.displayName,
		}));
	}, [ attributeDeclarations ]);

	return (
		<Flex vertical gap={12}>
			<Spin spinning={isDeclarationsFetching}>
				<ExtendedFilters filters={filtersDeclarations} onChange={setFilters}/>
			</Spin>

			<CatalogItemsTable catalogId={catalogId}
							   includeSubCatalogs={isSubordinatedCatalogsRecordsShown}
							   searchSubstring={searchValue}
							   searchDetails={searchDetails}
							   onClick={onClickItem}
			/>
		</Flex>
	);
});

export {
	CatalogItemsTableWidget,
};