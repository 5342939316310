import { App } from 'antd';
import { TaskType, useAsyncOperation } from 'entities/events';
import {
	ClassifierResponse,
	mdmgApi,
	useUpdateClassifierAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';

import { transactionServiceApi } from 'shared/api/generatedApi/newCreateApiFile';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { useTypedTranslation } from 'shared/hooks/useTypedTranslation';

export const useSelectAttribute = () => {
	const dispatch = useAppDispatch();

	const { t } = useTypedTranslation();

	const { notification } = App.useApp();

	const { execute: editClassifier } = useAsyncOperation(
		useUpdateClassifierAsyncMutation,
		TaskType.UPDATE_CLASSIFIER_BACKGROUND_TASK
	);

	const handleUpdate = async (
		classifier: ClassifierResponse,
		linkClassifierAttributes: Array<string>
	) => {
		try {
			await editClassifier({
				id: classifier.id,
				updateClassifierRequest: {
					...classifier,
					linkClassifierAttributes,
				},
			});
			dispatch(mdmgApi.util.invalidateTags(['Classifier']));
			dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
		} catch (e) {
			errorHelper(
				t((l) => l.classifier.error.classifierEditing),
				e,
				notification
			);
		}
	};

	return {
		handleUpdate,
	};
};
