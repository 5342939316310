import { RiEditLine } from '@remixicon/react';
import { Alert, Flex, Result, Spin } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { StartExchangeButton, StatusBadge } from 'features/integrations';
import { CreateExchangeConfig } from 'features/integrations/ExchangeConfiguration/CreateExchangeConfig';
import { DeleteExchangeConfig } from 'features/integrations/ExchangeConfiguration/deleteExchangeConfig';
import { ReplicationDirection, useGetConfigurationsListQuery } from 'shared/api/generatedApi/integrationsApi';
import { useGetCatalogsQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { usePagination, useTypedTranslation } from 'shared/hooks';
import { Localization } from 'shared/translations';
import { DetailFooter, DropdownLink, WorkTable } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { WorkTableColumnsType } from 'shared/ui/components/WorkTable';

const GuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

interface ExchangeConfigsListWidgetProps {
	integrationId: string;
}

const ExchangeConfigsListWidget = memo((
	{ integrationId }: ExchangeConfigsListWidgetProps,
) => {
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const { pagination, asTablePagination } = usePagination();

	const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);
	const [ dataSource, setDataSource ] = useState<any[] | null>(null);

	const columns: WorkTableColumnsType = [
		{
			title: t(l => l.integrations.exchangeConfig.list.configName),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t(l => l.integrations.exchangeConfig.list.linkedCatalogs),
			dataIndex: 'destination',
			key: 'destination',
		},
		{
			title: t(l => l.integrations.exchangeConfig.list.replicationDirection),
			dataIndex: 'replicationDirection',
			key: 'replicationDirection',
		},
		{
			title: t(l => l.integrations.exchangeConfig.list.status),
			dataIndex: 'status',
			key: 'status',
		},
	];

	const replicationDirectionTranslations: Record<ReplicationDirection, (l: Localization) => string> = {
		import: l => l.integrations.replicationDirections.import,
		export: l => l.integrations.replicationDirections.export,
	};

	const { data, isLoading, isFetching, error } = useGetConfigurationsListQuery({
		connectionId: integrationId,
		pageSize: pagination?.pageSize,
		page: pagination?.current,
	}, {
		skip: !pagination,
	});

	const destinationIds = useMemo(
		() => data?.data
			.map(x => x.destination)
			.filter(x => GuidRegex.test(x)),
		[ data ],
	);

	const { data: catalogs, isFetching: isCatalogsFetching } = useGetCatalogsQuery({
		ids: destinationIds,
	}, {
		skip: !destinationIds?.length,
	});

	const handleNavigate = useCallback((id: string) =>
			navigate(`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.exchangeConfigs}/${id}`),
		[],
	);
	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);

	useEffect(() => {
		if (!data) {
			return;
		}

		setDataSource(data.data.map((
			{
				configuration_id: configurationId,
				name,
				status,
				replication_direction,
				destination,
			}) => {
			const catalog = catalogs?.find(x => x.id === destination);
			return ({
				key: configurationId,
				id: configurationId,
				name: name,
				destination: catalog?.displayName ?? (
					isCatalogsFetching
						? <Spin size={'small'}/>
						: <Alert type="error" message={`Не найден каталог ${destination}`}/>
				),
				replicationDirection: t(replicationDirectionTranslations[replication_direction]),
				status: <StatusBadge status={status}/>,
				menu: (
					<ItemActions
						items={[
							{
								key: 'start',
								label: (
									<StartExchangeButton isDropdown
														 integrationId={integrationId}
														 configurationId={configurationId}
									/>
								),
							},
							{
								key: 'edit',
								label: (
									<DropdownLink
										icon={<RiEditLine size={16}/>}
										title={t((l) => l.common.buttons.edit)}
										onClick={() => handleNavigate(configurationId)}
									/>
								),
							},
							{
								key: 'delete',
								label: <DeleteExchangeConfig integrationId={integrationId}
															 exchangeConfigIds={[ configurationId ]}
								/>,
							},
						]}
					/>
				),
			});
		}));
	}, [ data, catalogs ]);

	if (isLoading) {
		return (
			<Spin spinning/>
		);
	}

	if (error) {
		return (
			<Flex justify="center">
				<Result status="error"
						title={t(l => l.integrations.exchangeConfig.list.error)}
						subTitle={JSON.stringify(error)}
				/>
			</Flex>
		);
	}

	return (
		<Flex vertical gap={24}>
			<CreateExchangeConfig integrationId={integrationId}/>
			<WorkTable columns={columns}
					   dataSource={dataSource}
					   loading={isFetching}
					   rowSelection={{
						   selectedRowKeys,
						   onChange: setSelectedRowKeys,
					   }}
					   onRow={(row) => ({
						   onClick: () => handleNavigate(row.id),
					   })}
					   {...asTablePagination(data?.pagination.total)}
			/>
			<DetailFooter customHandleBack={customHandleBack}>
				<DeleteExchangeConfig integrationId={integrationId}
									  exchangeConfigIds={selectedRowKeys}
				/>
			</DetailFooter>
		</Flex>
	);
});

export {
	ExchangeConfigsListWidget,
};
