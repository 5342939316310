import { RiFolderSettingsLine } from '@remixicon/react';
import {
	App,
	Button,
	Flex,
	Form,
	Input,
	Modal,
	Spin,
	Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { useGetClassifiersGroups } from 'entities/classifiers/classifiersGroups/classifier.model';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useGetCurrentMeausureGroup } from 'entities/metadata/measures';
import {
	AttributeDto,
	CatalogAttributeDeclarationResponse,
	useLazyGetCatalogItemsQuery,
	useLazyGetClassifierItemsQuery,
	useUpdateCatalogAttributeDeclarationAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { SelectMultipleCatalogInput } from 'shared/components/SelectCatalogInput';
import { errorHelper, isEmpty, mapValueForDto } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { translateType } from 'shared/helpers/CellTypeParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { mapValueForForm } from 'shared/helpers/mapValueForForm';
import { ItemValues } from 'shared/helpers/types';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Restrictions } from 'shared/types';
import { DropdownLink, DropdownSelect } from 'shared/ui/components';
import { validateRegex } from 'shared/validators/validateRegex';

interface ISetupAttribute {
	attributeDeclaration: CatalogAttributeDeclarationResponse;
}

const SetupAttributeDeclarationUi: React.FC<ISetupAttribute> = ({ attributeDeclaration }) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const { attribute, restrictions } = attributeDeclaration as unknown as {
		attribute: Omit<typeof attributeDeclaration['attribute'], 'restrictions'> & { restrictions: Restrictions };
		restrictions: Restrictions;
	};

	const [ form ] = Form.useForm();

	const [ isLoading, setIsLoading ] = useState(false);
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ linkData, setLinkData ] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [ scopeIdsList, setScopeIdsList ] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [ selectedScopeIds, setSelectedScopeIds ] = useState<Array<string>>([]);

	const [ getCatalogItems ] =
		useLazyGetCatalogItemsQuery();
	const [ getClassifierItemsList ] =
		useLazyGetClassifierItemsQuery();

	const { execute: updateAttributeDeclarationAsync } = useAsyncOperation(
		useUpdateCatalogAttributeDeclarationAsyncMutation,
		TaskType.UPDATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK,
	);

	const relatedClassifierIds = attribute.restrictions.scopeIds;
	const relatedCatalogIds = restrictions.scopeIds;

	const isRelationToClassifier =
		attribute.type === CellTypesEnum.RELATION &&
		relatedClassifierIds?.length > 0;

	const isRelationToCatalogs =
		attribute.type === CellTypesEnum.RELATION &&
		!isRelationToClassifier;

	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();

	const { catalogList } = useCatalogGroups();

	const {
		classifiersGroups,
		loading: classifierGroupsLoading,
		error: catalogError,
	} = useGetClassifiersGroups();


	// Используем useWatch для отслеживания изменений значений
	const mask = Form.useWatch('mask', form);
	const maxLength = Form.useWatch('maxLength', form);
	const scopeIds = Form.useWatch('scopeIds', form);

	useEffect(() => {
		(async () => {
			try {
				await form.validateFields([ 'defaultValue' ]);
			} catch {
				//	do nothing
			}
		})();
	}, [ mask, maxLength, scopeIds ]);

	const editData = async (attributeDeclaration: CatalogAttributeDeclarationResponse, v: ItemValues) => {
		try {
			setIsLoading(true);

			await updateAttributeDeclarationAsync({
				id: attributeDeclaration.id,
				updateCatalogAttributeDeclarationRequest:
					{
						restrictions: {
							...v,
						} as unknown as { [key: string]: object; },
					},
			});
		} catch (error) {
			errorHelper(
				'Ошибка при редактировании декларации атрибута справочника!',
				error,
				notification,
			);
		} finally {
			setIsLoading(false);
		}
	};

	const showModal = () => setIsModalOpen(true);
	const handleCancel = () => setIsModalOpen(false);

	const onFinish = async (values) => {
		const defaultValue = mapValueForDto(attribute.type, values.defaultValue);
		await editData(attributeDeclaration, {
			...values,
			...(([
				CellTypesEnum.TEXT,
				CellTypesEnum.STRING,
				CellTypesEnum.COLOR,
				CellTypesEnum.DATETIME,
				CellTypesEnum.INT,
				CellTypesEnum.FLOAT,
				CellTypesEnum.RELATION,
			].includes(attribute.type as CellTypesEnum)) && {
				defaultValue,
			}),
		});
		setIsModalOpen(false);
	};

	useEffect(() => {
		const defaultValue = mapValueForForm(
			attribute.type,
			attribute.list,
			restrictions.defaultValue,
		);

		if (relatedClassifierIds?.length) {
			getClassifierItemsList({
				classifierId: relatedClassifierIds[0],
				root: true,
			})
				.unwrap()
				.then((res) => {
					const options = res.data
						.map((c) => ({
							id: c.id,
							pId: c.parentItemId,
							value: c.id,
							title: c.displayName || t(l => l.common.defaultNames.emptyName),
							isLeaf: !c.parent,
						}));
					setLinkData(options);
				});
		} else {
			if (isEmpty(scopeIdsList)) {
				setScopeIdsList(
					catalogList.map((item) => ({
						id: item.id,
						pId: item.parentId,
						value: item.id,
						title: item.displayName,
						isLeaf: !item.parent,
					})),
				);
				if (relatedCatalogIds && catalogList) {
					const ids = relatedCatalogIds
						.filter((id) => !!id);
					setSelectedScopeIds(ids);
				}
			}
		}

		form.setFieldsValue({
			mask: restrictions.mask,
			maxLength: restrictions.maxLength,
			accuracy: restrictions.accuracy,
			measurementId: restrictions.measurementId,
			defaultValue: defaultValue,
			scopeIds: isRelationToClassifier
				? relatedClassifierIds
				: isRelationToCatalogs
					? relatedCatalogIds
					: null,
		});
	}, [ attributeDeclaration, classifiersGroups, catalogList ]);

	useEffect(() => {
		if (selectedScopeIds?.length > 0) {
			if (relatedClassifierIds?.length > 0) {
				const promises = selectedScopeIds
					?.map((item) =>
						getClassifierItemsList({
							classifierId: item,
							root: true,
						}),
					);
				Promise.all(promises)
					.then((res) => {
						const data = res
							.flat(Infinity)
							.map((v) =>
								v.data.data.map((c) => ({
									id: c.id,
									pId: c.parentItemId,
									value: c.id,
									title: c.displayName,
									isLeaf: !c.parent,
								})),
							)
							.flat();

						setLinkData(data);
					});
			} else {
				getCatalogItems({
					catalogIds: selectedScopeIds,
				}).unwrap()
					.then(res => {
						const { data } = res;
						if (data) {
							setLinkData(data
								.map((c) => ({
									id: c.id,
									value: c.id,
									title: c.displayName || t(l => l.common.defaultNames.emptyName),
									isLeaf: true,
								})));
						}
					});
			}
		}
	}, [ selectedScopeIds ]);

	useEffect(() => {
		if (
			attribute.type === CellTypesEnum.FLOAT &&
			!currentMeasureGroup &&
			attribute.measurementGroupId
		) {
			getData(attribute.measurementGroupId);
		}
	}, [ currentMeasureGroup, attributeDeclaration ]);

	useEffect(() => {
		if (restrictions.defaultValue && linkData) {
			const opts = linkData.filter((item) =>
				(restrictions.defaultValue as Array<string>).includes(item.id),
			);
			opts
				? form.setFieldValue('defaultValue', opts)
				: form.setFieldValue('defaultValue', []);
		}
	}, [ linkData ]);

	const DefaultValueInput = CellInputParser(
		'defaultValue',
		attribute as never as AttributeDto,
		{
			label: 'Значение по умолчанию',
			attributeDeclaration: {
				...attributeDeclaration,
				restrictions: {
					...attributeDeclaration.restrictions,
					...form.getFieldsValue([ 'mask', 'maxLength', 'accuracy', 'measurementId', 'scopeIds' ]),
				},
			},
			triggeredWhenAddedInList: false,
			triggeredWhenRemovedFromList: false,
		},
	);

	return (
		<>
			<DropdownLink
				icon={<RiFolderSettingsLine size={16}/>}
				title="Настроить декларацию атрибута"
				onClick={showModal}
			/>

			<Modal
				title={
					<Typography.Title level={1}>
						<Flex gap={4} justify="flex-start" vertical>
							Управление декларацией атрибута:
							<span style={{ color: colors.primary }}>
								{translateType(attribute?.type, 'AttributeTransfer')}
							</span>
						</Flex>
					</Typography.Title>
				}
				open={isModalOpen}
				footer={null}
				closable={false}
			>
				<Spin spinning={isLoading}>
					<Form layout="vertical" form={form} onFinish={onFinish}>
						{attribute.type === CellTypesEnum.STRING && (
							<>
								<Form.Item label="Маска ввода"
										   name="mask"
										   rules={[ {
											   validator: validateRegex,
											   message: 'Некорректное регулярное выражение',
										   } ]}
								>
									<Input/>
								</Form.Item>

								<Form.Item label="Максимальная длина" name="maxLength">
									<Input/>
								</Form.Item>
							</>
						)}

						{attribute.type === CellTypesEnum.FLOAT && (
							<>
								<Form.Item label="Точность" name="accuracy">
									<Input/>
								</Form.Item>
								<Form.Item label="Единица измерения" name="measurementId">
									<DropdownSelect
										loading={measuresLoading}
										error={
											!!measuresError || !attribute?.measurementGroupId
										}
										errorText={
											!attribute?.measurementGroupId
												? 'У атрибута нет назначенной группы единиц измерения!'
												: 'Не удалось загрузить список единиц измерения!'
										}
										disabled={!!measuresError}
										options={currentMeasureGroup?.childrenMeasures?.map(
											(measure) => ({
												label: measure.displayName,
												value: measure.id,
											}),
										)}
									/>
								</Form.Item>
							</>
						)}

						{isRelationToClassifier && (
							<Form.Item label="Область" name="scopeIds">
								<DropdownSelect
									disabled={true}
									mode="multiple"
									options={
										classifiersGroups?.map((item) => ({
											label: item.displayName,
											value: item.id,
										}))
									}
									loading={classifierGroupsLoading}
									error={!!catalogError}
								/>
							</Form.Item>
						)}

						{isRelationToCatalogs && (
							<Form.Item label="Область" name="scopeIds">
								<SelectMultipleCatalogInput />
							</Form.Item>
						)}

						{DefaultValueInput}

						<Flex justify="flex-end" gap={4}>
							<Button type="text" onClick={handleCancel}>
								Отменить
							</Button>
							<Button type="primary" htmlType="submit" loading={isLoading}>
								Сохранить
							</Button>
						</Flex>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export const SetupAttributeDeclaration = React.memo(SetupAttributeDeclarationUi);
