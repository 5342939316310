import { Flex, Result } from 'antd';
import React from 'react';
import { CreateCompatibilityButton } from 'features/compatibilities';
import { useGetCompatibilitiesQuery } from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';
import { CompatibilitiesOption } from '../CompatibilitiesOption';

interface ICompatibilitiesWidget {
	entityId?: string;
	contextNameId?: string;
}

export const CompatibilitiesWidget = ({ entityId }: ICompatibilitiesWidget) => {
	const { t } = useTypedTranslation();
	const { data, error } = useGetCompatibilitiesQuery({
		entityId,
	});

	return (
		<>
			{error ? (
				<Result status="error" title={t((l) => l.compatibilities.error.gettingItems)} />
			) : (
				<Flex vertical gap={12}>
					<Flex gap={6}>
						<CreateCompatibilityButton entityId={entityId} />
					</Flex>
					{data?.map((item) => (
						<CompatibilitiesOption key={item.id} currentEntityId={entityId} {...item} />
					))}
				</Flex>
			)}
		</>
	);
};
