import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useCreateContextNameMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useTypedTranslation } from 'shared/hooks';

const CreateCompatibility = memo(() => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const [ createContextName ] = useCreateContextNameMutation();

	const onClick = useCallback(async () => {
		const contextName = await createContextName({
			createContextNameRequest: {
				contextDisplayName: '',
				inverseContextDisplayName: '',
				leftEntityName: '',
				rightEntityName: '',
			},
		}).unwrap();
		navigate(`/${routes.metadata.main}/${routes.metadata.compatibilities}/${contextName.id}`);
	}, []);

	return (
		<Button
			type="default"
			icon={<RiAddCircleLine/>}
			onClick={onClick}
		>
			{t((l) => l.compatibilities.actions.add.btnTitle)}
		</Button>
	);
});

export {
	CreateCompatibility,
};