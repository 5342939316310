import { RiDeleteBin6Line } from '@remixicon/react';
import { Flex, Button, Modal, Typography } from 'antd';
import { App } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTransactions } from 'entities/transactions';
import { useDeleteContextNamesMutation } from 'shared/api/generatedApi/mdmgApi';
import { randomString } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface IDeleteCompatibilityProps {
	itemId: Array<string>;
	isDropdown?: boolean;
	detailItemPage?: boolean;
}

export const DeleteContextNames = memo((
	{
		isDropdown,
		itemId,
		detailItemPage,
	}: IDeleteCompatibilityProps) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const { notification } = App.useApp();
	const [deleteCompatibility, { isLoading }] = useDeleteContextNamesMutation();

	const { getTransactions } = useTransactions();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async (e: React.SyntheticEvent) => {
		try {
			e.stopPropagation();

			await deleteCompatibility({ ids: itemId })
				.unwrap();
			await getTransactions();

			setIsModalOpen(false);
			if (!isDropdown && detailItemPage) {
				navigate(-1);
			}
		} catch(err) {
			notification.error({
				message: 'Ошибка при удалении совместимости!',
				description: JSON.stringify(err),
			});
		}
	};

	const handleCancel = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setIsModalOpen(false);
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t((l) => l.common.buttons.cancel)}
				</Button>

				<Button key="submit" type="primary" onClick={handleOk} loading={isLoading}>
					{t((l) => l.common.buttons.delete)}
				</Button>
			</Flex>
		),
		[isLoading, itemId],
	);

	return (
		<>
			<ButtonVsDropdownLink
				title={t((l) => l.common.buttons.delete)}
				callback={showModal}
				icon={<RiDeleteBin6Line />}
				type={isDropdown ? 'dropdownLink' : null}
				isDisabled={!itemId?.length}
				isLoading={isLoading}
			/>
			<Modal
				title={t((l) => l.compatibilities.actions.delete.modalTitle)}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[footerButtons]}
				closeIcon={null}
				width={600}
			>
				<Flex vertical gap={24}>
					<Typography.Text>
						{t((l) => l.compatibilities.actions.delete.modalMessage)}
					</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
});
