import { RiCheckboxCircleLine, RiCircleFill, RiFileCopyFill } from '@remixicon/react';
import { Button, Flex, Table, Alert } from 'antd';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDeleteDuplicates } from 'features/catalogs/deleteDuplicates';
import { useSetDuplicateItem } from 'features/catalogs/setDuplicateItem';
import { useSetOriginalItem } from 'features/catalogs/setOriginalItem';
import { setPotentialOriginalItemId } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { SuspectedDuplicateDto } from 'shared/api/generatedApi/deduplicationApi';
import {
	CatalogItemResponse,
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogsQuery,
	useGetClassifierItemsQuery,
	useGetCatalogItemsQuery
} from 'shared/api/generatedApi/mdmgApi';
import { useAppDispatch, useAppSelector, useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { AddCopyIcon, CancelCopyIcon, ChipStatus, Hint } from 'shared/ui';
import { ChipStatusRuEnum, chipStyleHelper } from 'shared/ui/components/Chip/chipStylehelper';

interface ISuspectedDuplicate {
	suspectedDuplicate: SuspectedDuplicateDto;
	closeModal: () => void;
}

export const SuspectedDuplicate = memo((
	{
		suspectedDuplicate,
		closeModal,
	}: ISuspectedDuplicate) => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();

	const potentialOriginalItemId = useAppSelector(
		(state) => state.entities?.catalogs?.catalogDeduplication?.potentialOriginalItemId,
	);

	const [possibleOriginalItemId, setPossibleOriginalItemId] = useState<string>('');

	const suspectedDuplicateId = useMemo(() =>
		suspectedDuplicate.id,
		[suspectedDuplicate],
	);
	const suspectedOriginalItem = useMemo(() =>
		suspectedDuplicate.suspectedOriginalItem as unknown as CatalogItemResponse,
		[suspectedDuplicate],
	);
	const suspectedDuplicateItems = useMemo(() =>
		suspectedDuplicate.suspectedDuplicateItems as unknown as CatalogItemResponse[],
		[suspectedDuplicate],
	);

	const catalogIds = useMemo(() => new Set(
		suspectedDuplicate.suspectedDuplicateItems
			?.map((item) => item.catalogId)
			.concat(suspectedDuplicate.suspectedOriginalItem?.catalogId)
			.filter(x => !!x),
	), [suspectedDuplicate]);

	const { data: catalogs } = useHandleQueryError(
		useGetCatalogsQuery({
			ids: Array.from(catalogIds),
		}, {
			skip: !catalogIds.size,
		}),
		l => l.common.statuses.error);

	const declarationIds = useMemo(() => Array.from(
		new Set(
			Object.keys(suspectedDuplicate.suspectedOriginalItem?.values ?? {}),
		),
	), [suspectedDuplicate]);

	//TODO убрать catalogGroupId и проверить по всем справочникам из suspectedOriginalItem suspectedDuplicateItems
	const { data: declarations } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: suspectedDuplicate.suspectedOriginalItem.catalogId,
			ids: declarationIds,
		}, {
			skip: !declarationIds.length,
		}), l => l.common.statuses.error);

	const suspectedDuplicateItemClassifierIds: string[] = useMemo(() => Array.from(new Set(
		declarations
			?.map((item) => (item.attribute.restrictions?.scopeIds as unknown as string[])?.length > 0 ? suspectedDuplicate.suspectedDuplicateItems.map((el) => el.values[item.id]) as unknown as string : null)
			.flat()
	)), [declarations]);

	const suspectedItemClassifierIds: string[] = useMemo(() => Array.from(new Set(
		declarations
			?.map((item) => (item.attribute.restrictions?.scopeIds as unknown as string[])?.length > 0 ? suspectedOriginalItem?.values[item.id] as unknown as string : null)
			.concat(suspectedDuplicateItemClassifierIds)
			.filter(x => !!x)

	)), [declarations, suspectedDuplicateItemClassifierIds]);

	const suspectedDuplicateItemCatalogIds: string[] = useMemo(() => Array.from(new Set(
		declarations
			?.map((item) => (item.restrictions?.scopeIds as unknown as string[])?.length > 0 ? suspectedDuplicate.suspectedDuplicateItems.map((el) => el.values[item.id]) as unknown as string : null)
			.flat()
	)), [declarations]);

	const suspectedItemCatalogIds: string[] = useMemo(() => Array.from(new Set(
		declarations
			?.map((item) => (item.restrictions?.scopeIds as unknown as string[])?.length > 0 ? suspectedOriginalItem?.values[item.id] as unknown as string : null)
			.concat(suspectedDuplicateItemCatalogIds)
			.filter(x => !!x)

	)), [declarations, suspectedDuplicateItemCatalogIds]);

	const { data: catalogsSuspectedItems } = useHandleQueryError(
		useGetCatalogItemsQuery({
			ids: suspectedItemCatalogIds,
		}, {
			skip: !suspectedItemCatalogIds.length
		}),
		l => l.common.statuses.error);

	const { data: classifierSuspectedItems } = useHandleQueryError(
		useGetClassifierItemsQuery({
			ids: suspectedItemClassifierIds,
		}, {
			skip: !suspectedItemClassifierIds.length
		}),
		l => l.common.statuses.error);

	const { deleteDuplicates, isLoading: isDuplicateDeleting } = useDeleteDuplicates();
	const { setDuplicateItem, isLoading: isDuplicateSetting } = useSetDuplicateItem();
	const { setOriginalItem, isOriginalItemSetting } = useSetOriginalItem();

	const titleButton = useCallback((id: string) => {
		return id === possibleOriginalItemId ? (
			<Flex
				gap={6}
				style={{ color: colors.success, fontWeight: 700, whiteSpace: 'nowrap' }}
				justify="center"
			>
				<RiCheckboxCircleLine size={16} color={colors.success} />
				{t(l => l.catalogs.deduplication.isOriginal)}
			</Flex>
		) : (
			<Flex justify="center">
				<Button
					type="text"
					icon={<RiFileCopyFill size={16} />}
					onClick={() => setPossibleOriginalItemId(id)}
				>
					{t(l => l.catalogs.deduplication.setAsOriginal)}
				</Button>
			</Flex>
		);
	}, [possibleOriginalItemId, t]);

	const isColor = (id: string) => {
		return declarations?.find((d) => d.id === id)?.attribute?.type === 'COLOR';
	};

	const style = (id: string, declarationId?: string): React.CSSProperties => {
		return possibleOriginalItemId === id
			? {
				backgroundColor: colors.successBg,
				borderRadius: 6,
				paddingTop: isColor(declarationId) ? 6 : 10,
				paddingBottom: isColor(declarationId) ? 6 : 10,
				justifyContent: 'center',
				alignItems: 'center',
				minWidth: 198,
			}
			: {
				justifyContent: 'center',
				alignItems: 'center',
				minWidth: 198,
			};
	};

	const statusAndName = (status: ChipStatus, displayName: string, id: string): ReactElement => {
		return (
			<Flex gap={6} style={style(id)}>
				<Hint title={ChipStatusRuEnum[status]}>
					<RiCircleFill size={10} color={chipStyleHelper(status).main} />
				</Hint>
				{!displayName || displayName === 'null'
					? t(l => l.common.defaultNames.emptyName)
					: displayName}
			</Flex>
		);
	};

	const setNewOriginalItem = async () => {
		if (possibleOriginalItemId && possibleOriginalItemId !== suspectedOriginalItem?.id) {
			await setOriginalItem({
				suspectedDuplicateId,
				newOriginalItemId: possibleOriginalItemId,
			});
		}
	};

	const deleteDuplicatedItems = async () => {
		await setNewOriginalItem();
		await deleteDuplicates([suspectedDuplicateId]);
		closeModal();
	};

	const setDuplicatedStatus = async () => {
		await setNewOriginalItem();
		await setDuplicateItem([suspectedDuplicateId]);
		closeModal();
	};

	const getDisplayName = (id) => {
		const classifierItem = classifierSuspectedItems?.data?.find((el) => el.id === id);
		if (classifierItem) {
			return classifierItem.displayName;
		}

		const catalogItem = catalogsSuspectedItems?.data?.find((el) => el.id === id);
		if (catalogItem) {
			return catalogItem.displayName;
		}

		const isSuspected =
			suspectedItemCatalogIds?.some((i) => i === id) ||
			suspectedItemClassifierIds?.some((i) => i === id);

		if (isSuspected) {
			return <Alert type="error" message="Запись не найдена" />;
		}

		return id;
	};

	const columns: any = useMemo(() => !suspectedOriginalItem
		? []
		: [
			{
				title: '',
				dataIndex: 'displayNames',
				key: 'displayNames',
				width: `${100 / (suspectedDuplicateItems?.length + 2)}%`,
			},
			{
				title: titleButton(suspectedOriginalItem?.id),
				dataIndex: suspectedOriginalItem?.id,
				key: suspectedOriginalItem?.id,
				align: 'center',
				width: `${100 / (suspectedDuplicateItems?.length + 2)}%`,
			},
			...suspectedDuplicateItems.map((item) => ({
				title: titleButton(item.id),
				dataIndex: item.id,
				key: item.id,
				align: 'center',
				width: `${100 / (suspectedDuplicateItems?.length + 2)}%`,
			})),
		], [suspectedOriginalItem, suspectedDuplicateItems, titleButton]);

	const dataSource = useMemo(() =>
		!suspectedOriginalItem?.values || !suspectedDuplicateItems
			? []
			: [
				{
					key: 'position',
					displayNames: t(l => l.catalogs.deduplication.position),
					[suspectedOriginalItem?.id]: statusAndName(
						suspectedOriginalItem?.status.toLowerCase() as ChipStatus,
						suspectedOriginalItem?.displayName,
						suspectedOriginalItem?.id,
					),
					...Object.fromEntries(
						suspectedDuplicateItems?.map((item) => [
							item.id,
							statusAndName(
								item.status.toLowerCase() as ChipStatus,
								item.displayName,
								item.id,
							),
						]),
					),
				},
				{
					key: 'source',
					displayNames: t(l => l.catalogs.deduplication.source),

					[suspectedOriginalItem?.id]: catalogs
						?.find((cat) => cat.id === suspectedOriginalItem?.catalogId)
						?.displayName,

					...Object.fromEntries(
						suspectedDuplicateItems?.map((item) => [
							item.id,
							catalogs?.find((cat) => cat.id === item.catalogId)?.displayName,
						]),
					),
				},
				...Object.keys(suspectedOriginalItem?.values).map((declarationId) => ({
					key: declarationId,

					displayNames: declarations
						?.find((d) => d.id === declarationId)
						?.attribute
						?.displayName,

					[suspectedOriginalItem?.id]: isColor(declarationId) ? (
						<Flex gap={6} style={style(suspectedOriginalItem?.id, declarationId)}>
							<RiCircleFill
								color={
									suspectedOriginalItem?.values[
									declarationId
									] as unknown as string
								}
								size={20}
							/>
							{suspectedOriginalItem?.values[declarationId] as unknown as string}
						</Flex>
					) : (
						<Flex gap={6} style={style(suspectedOriginalItem?.id)}>
							{getDisplayName(suspectedOriginalItem?.values[declarationId])}
						</Flex>
					),

					...Object.fromEntries(
						suspectedDuplicateItems?.map((item) => [
							item.id,
							isColor(declarationId) ? (
								<Flex gap={6} style={style(item.id, declarationId)}>
									<RiCircleFill
										color={item.values[declarationId] as unknown as string}
										size={20}
									/>
									{item.values[declarationId] as unknown as string}
								</Flex>
							) : (
								<Flex gap={6} style={style(item.id)}>
									{getDisplayName(item.values[declarationId])}
								</Flex>
							),
						]),
					),
				})),
			], [suspectedOriginalItem, suspectedDuplicateItems, catalogs, declarations, t, classifierSuspectedItems, catalogsSuspectedItems]);

	useEffect(() => {
		if (!potentialOriginalItemId) {
			const initialPossibleItemId = suspectedOriginalItem?.id;
			setPossibleOriginalItemId(initialPossibleItemId);
		}
	}, [potentialOriginalItemId]);

	useEffect(() => {
		if (!possibleOriginalItemId) {
			return;
		}

		const id = possibleOriginalItemId !== suspectedOriginalItem.id ? possibleOriginalItemId : null;
		dispatch(setPotentialOriginalItemId(id));
	}, [possibleOriginalItemId]);

	useEffect(() => {
		if (!suspectedDuplicate) {
			return;
		}

		setPossibleOriginalItemId(suspectedDuplicate.suspectedOriginalItem?.id);
	}, [suspectedDuplicate, suspectedDuplicateId]);

	return (
		<Flex vertical gap={24}>
			<Flex gap={6} wrap>
				<Button
					icon={<CancelCopyIcon />}
					onClick={deleteDuplicatedItems}
					loading={isOriginalItemSetting || isDuplicateDeleting}
				>
					{t(l => l.catalogs.deduplication.unionAndDelete)}
				</Button>

				<Button
					icon={<AddCopyIcon />}
					onClick={setDuplicatedStatus}
					loading={isOriginalItemSetting || isDuplicateSetting}
				>
					{t(l => l.catalogs.deduplication.setStatus)}
				</Button>
			</Flex>

			<Table
				columns={columns}
				dataSource={dataSource}
				scroll={{ x: 'max-content' }}
				size="small"
			/>
		</Flex>
	);
});
