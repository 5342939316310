import { integrationsApi as api } from './newCreateApiFile';
export const addTagTypes = [
	'Connections',
	'Configurations',
	'MDMG-service',
	'Internal APIs',
	'Transition Keys',
] as const;
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			createConnection: build.mutation<CreateConnectionApiResponse, CreateConnectionApiArg>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections',
					method: 'POST',
					body: queryArg.connectionCreate,
				}),
				invalidatesTags: ['Connections'],
			}),
			getConnectionsList: build.query<
				GetConnectionsListApiResponse,
				GetConnectionsListApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections',
					params: {
						reverse: queryArg.reverse,
						sort_by: queryArg.sortBy,
						filter_key: queryArg.filterKey,
						filter_value: queryArg.filterValue,
						page: queryArg.page,
						page_size: queryArg.pageSize,
					},
				}),
				providesTags: ['Connections'],
			}),
			getMetadata: build.query<GetMetadataApiResponse, GetMetadataApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/metadata`,
				}),
				providesTags: ['Connections'],
			}),
			getConnection: build.query<GetConnectionApiResponse, GetConnectionApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
				}),
				providesTags: ['Connections'],
			}),
			updateConnection: build.mutation<UpdateConnectionApiResponse, UpdateConnectionApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
					method: 'PUT',
					body: queryArg.connectionUpdateRequest,
				}),
				invalidatesTags: ['Connections'],
			}),
			patchConnection: build.mutation<PatchConnectionApiResponse, PatchConnectionApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
					method: 'PATCH',
					body: queryArg.connectionPartialUpdateRequest,
				}),
				invalidatesTags: ['Connections'],
			}),
			deleteConnection: build.mutation<DeleteConnectionApiResponse, DeleteConnectionApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Connections'],
			}),
			pinConnection: build.mutation<PinConnectionApiResponse, PinConnectionApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/pin`,
					method: 'PATCH',
					body: queryArg.pinConnectionRequest,
				}),
				invalidatesTags: ['Connections'],
			}),
			testConnection: build.mutation<TestConnectionApiResponse, TestConnectionApiArg>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections/test-connection',
					method: 'POST',
					body: queryArg.connectionTest,
				}),
				invalidatesTags: ['Connections'],
			}),
			getSupportedSystems: build.query<
				GetSupportedSystemsApiResponse,
				GetSupportedSystemsApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections/systems/supported-systems',
					params: { page: queryArg.page, page_size: queryArg.pageSize },
				}),
				providesTags: ['Connections'],
			}),
			getSupportedSystemById: build.query<
				GetSupportedSystemByIdApiResponse,
				GetSupportedSystemByIdApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/systems/supported-systems/${queryArg.systemId}`,
				}),
				providesTags: ['Connections'],
			}),
			getConfigurationsActivationMode: build.query<
				GetConfigurationsActivationModeApiResponse,
				GetConfigurationsActivationModeApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/activation_mode`,
				}),
				providesTags: ['Configurations'],
			}),
			triggerDag: build.mutation<TriggerDagApiResponse, TriggerDagApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}/trigger_dag`,
					method: 'POST',
				}),
				invalidatesTags: ['Configurations'],
			}),
			updateConfiguration: build.mutation<
				UpdateConfigurationApiResponse,
				UpdateConfigurationApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}`,
					method: 'PATCH',
					body: queryArg.exchangeConfigurationRequest,
				}),
				invalidatesTags: ['Configurations'],
			}),
			getConfiguration: build.query<GetConfigurationApiResponse, GetConfigurationApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}`,
				}),
				providesTags: ['Configurations'],
			}),
			createDraftMapping: build.mutation<
				CreateDraftMappingApiResponse,
				CreateDraftMappingApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}/mapping/draft`,
					method: 'POST',
					body: queryArg.mappingSchema,
				}),
				invalidatesTags: ['Configurations'],
			}),
			getDraftMapping: build.query<GetDraftMappingApiResponse, GetDraftMappingApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}/mapping/draft`,
				}),
				providesTags: ['Configurations'],
			}),
			updateMapping: build.mutation<UpdateMappingApiResponse, UpdateMappingApiArg>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}/mapping`,
					method: 'PATCH',
					body: queryArg.mappingSchema,
				}),
				invalidatesTags: ['Configurations'],
			}),
			createConfiguration: build.mutation<
				CreateConfigurationApiResponse,
				CreateConfigurationApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations`,
					method: 'POST',
					body: queryArg.configurationCreateRequest,
				}),
				invalidatesTags: ['Configurations'],
			}),
			getConfigurationsList: build.query<
				GetConfigurationsListApiResponse,
				GetConfigurationsListApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations`,
					params: {
						reverse: queryArg.reverse,
						sort_by: queryArg.sortBy,
						filter_key: queryArg.filterKey,
						filter_value: queryArg.filterValue,
						page: queryArg.page,
						page_size: queryArg.pageSize,
					},
				}),
				providesTags: ['Configurations'],
			}),
			deleteConfiguration: build.mutation<
				DeleteConfigurationApiResponse,
				DeleteConfigurationApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationIdList}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Configurations'],
			}),
			getAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGet: build.query<
				GetAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGetApiResponse,
				GetAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGetApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/mdmg/attributes',
					params: {
						sort_by: queryArg.sortBy,
						ids: queryArg.ids,
						sort_type: queryArg.sortType,
						limit: queryArg.limit,
						offset: queryArg.offset,
						type: queryArg['type'],
						exclude_ids: queryArg.excludeIds,
						attribute_group_ids: queryArg.attributeGroupIds,
						include_sub_groups: queryArg.includeSubGroups,
					},
				}),
				providesTags: ['MDMG-service'],
			}),
			getCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGet: build.query<
				GetCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGetApiResponse,
				GetCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGetApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/mdmg/catalogs',
					params: {
						parent_ids: queryArg.parentIds,
						root: queryArg.root,
						ids: queryArg.ids,
					},
				}),
				providesTags: ['MDMG-service'],
			}),
			getDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGet: build.query<
				GetDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGetApiResponse,
				GetDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGetApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/mdmg/declarations',
					params: {
						catalog_ids: queryArg.catalogIds,
						ids: queryArg.ids,
						attribute_ids: queryArg.attributeIds,
					},
				}),
				providesTags: ['MDMG-service'],
			}),
			createTransitionKey: build.mutation<
				CreateTransitionKeyApiResponse,
				CreateTransitionKeyApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/internal/transition_key',
					method: 'POST',
					body: queryArg.createTransitionKeyRequest,
				}),
				invalidatesTags: ['Internal APIs'],
			}),
			updateTransitionKeyExternalId: build.mutation<
				UpdateTransitionKeyExternalIdApiResponse,
				UpdateTransitionKeyExternalIdApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/internal/transition_key',
					method: 'PATCH',
					body: queryArg.updateTransitionKeyRequest,
				}),
				invalidatesTags: ['Internal APIs'],
			}),
			getTransitionKeyBySourceInfo: build.query<
				GetTransitionKeyBySourceInfoApiResponse,
				GetTransitionKeyBySourceInfoApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/internal/transition_key_by_source_info',
					params: {
						configuration_id: queryArg.configurationId,
						extraction_target: queryArg.extractionTarget,
						external_id: queryArg.externalId,
						mdm_entity_id: queryArg.mdmEntityId,
					},
				}),
				providesTags: ['Internal APIs'],
			}),
			getTransitionKeyByMdmEntityId: build.query<
				GetTransitionKeyByMdmEntityIdApiResponse,
				GetTransitionKeyByMdmEntityIdApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/internal/transition_key_by_mdm_entity_id',
					params: { mdm_entity_id: queryArg.mdmEntityId },
				}),
				providesTags: ['Internal APIs'],
			}),
			updateTransitionKeyDeduplication: build.mutation<
				UpdateTransitionKeyDeduplicationApiResponse,
				UpdateTransitionKeyDeduplicationApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/internal/transition_key/deduplication',
					method: 'PATCH',
					body: queryArg.updateMdmIdTransitionKey,
				}),
				invalidatesTags: ['Internal APIs'],
			}),
			updateSupportedSystem: build.mutation<
				UpdateSupportedSystemApiResponse,
				UpdateSupportedSystemApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/internal/supported-systems',
					method: 'PATCH',
					body: queryArg.supportedSystemUpdateRequest,
				}),
				invalidatesTags: ['Internal APIs'],
			}),
			getTransitionKeysByConnectionId: build.query<
				GetTransitionKeysByConnectionIdApiResponse,
				GetTransitionKeysByConnectionIdApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/transition-keys/connection_transition_keys',
					params: {
						connection_id: queryArg.connectionId,
						page: queryArg.page,
						page_size: queryArg.pageSize,
					},
				}),
				providesTags: ['Transition Keys'],
			}),
		}),
		overrideExisting: false,
	});
export { injectedRtkApi as integrationsApi };
export type CreateConnectionApiResponse =
	/** status 200 Successful Response */ ConnectionCreateResponse;
export type CreateConnectionApiArg = {
	connectionCreate: ConnectionCreate;
};
export type GetConnectionsListApiResponse =
	/** status 200 Successful Response */ ListConnectionsResponse;
export type GetConnectionsListApiArg = {
	reverse?: boolean | null;
	sortBy?: string | null;
	filterKey?: string | null;
	filterValue?: string | null;
	page?: number;
	pageSize?: number;
};
export type GetMetadataApiResponse = /** status 200 Successful Response */ MetadataResponse;
export type GetMetadataApiArg = {
	connectionId: string;
};
export type GetConnectionApiResponse =
	/** status 200 Connection Info returned successfuly */ GetConnectionResponse;
export type GetConnectionApiArg = {
	connectionId: string;
};
export type UpdateConnectionApiResponse =
	/** status 200 Connection updated successfully */ ConnectionUpdateResponse;
export type UpdateConnectionApiArg = {
	connectionId: string;
	connectionUpdateRequest: ConnectionUpdateRequest;
};
export type PatchConnectionApiResponse =
	/** status 200 Connection updated successfully */ ConnectionUpdateResponse;
export type PatchConnectionApiArg = {
	connectionId: string;
	connectionPartialUpdateRequest: ConnectionPartialUpdateRequest;
};
export type DeleteConnectionApiResponse = /** status 204 No content */ string;
export type DeleteConnectionApiArg = {
	connectionId: string;
};
export type PinConnectionApiResponse = /** status 200 Connection pinned successfully */ object;
export type PinConnectionApiArg = {
	connectionId: string;
	pinConnectionRequest: PinConnectionRequest;
};
export type TestConnectionApiResponse =
	/** status 200 Successful Response */ ConnectionTestResponse;
export type TestConnectionApiArg = {
	connectionTest: ConnectionTest;
};
export type GetSupportedSystemsApiResponse =
	/** status 200 List of supported systems retrieved successfully */ SupportedSystemsResponse;
export type GetSupportedSystemsApiArg = {
	page?: number;
	pageSize?: number;
};
export type GetSupportedSystemByIdApiResponse =
	/** status 200 Supported systems retrieved successfully */ SupportedSystemExtended;
export type GetSupportedSystemByIdApiArg = {
	systemId: string;
};
export type GetConfigurationsActivationModeApiResponse =
	/** status 200 List of activation modes retrieved successfully */ ApiActivationMode[];
export type GetConfigurationsActivationModeApiArg = {
	connectionId: string;
};
export type TriggerDagApiResponse = /** status 200 Successful Response */ object;
export type TriggerDagApiArg = {
	connectionId: string;
	configurationId: string;
};
export type UpdateConfigurationApiResponse =
	/** status 200 Configuration updated successfully */ ConfigurationUpdateResponse;
export type UpdateConfigurationApiArg = {
	connectionId: string;
	configurationId: string;
	exchangeConfigurationRequest: ExchangeConfigurationRequest;
};
export type GetConfigurationApiResponse =
	/** status 200 Successful Response */ ConfigurationGetResponse;
export type GetConfigurationApiArg = {
	connectionId: string;
	configurationId: string;
};
export type CreateDraftMappingApiResponse =
	/** status 200 Successful Response */ MappingDraftResponse;
export type CreateDraftMappingApiArg = {
	connectionId: string;
	configurationId: string;
	mappingSchema: MappingSchema;
};
export type GetDraftMappingApiResponse = /** status 200 Successful Response */ MappingSchema;
export type GetDraftMappingApiArg = {
	connectionId: string;
	configurationId: string;
};
export type UpdateMappingApiResponse = /** status 200 Successful Response */ MappingResponse;
export type UpdateMappingApiArg = {
	connectionId: string;
	configurationId: string;
	mappingSchema: MappingSchema;
};
export type CreateConfigurationApiResponse =
	/** status 200 Successful Response */ ConfigurationCreateResponse;
export type CreateConfigurationApiArg = {
	connectionId: string;
	configurationCreateRequest: ConfigurationCreateRequest;
};
export type GetConfigurationsListApiResponse =
	/** status 200 Successful Response */ ListConfigurationsResponse;
export type GetConfigurationsListApiArg = {
	connectionId: string;
	reverse?: boolean | null;
	sortBy?: string | null;
	filterKey?: string | null;
	filterValue?: string | null;
	page?: number;
	pageSize?: number;
};
export type DeleteConfigurationApiResponse = /** status 204 No content */ string;
export type DeleteConfigurationApiArg = {
	connectionId: string;
	configurationIdList: string;
};
export type GetAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGetApiResponse =
	/** status 200 Successful Response */ GetAttributesResponse;
export type GetAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGetApiArg = {
	/** Sort parameter */
	sortBy: string;
	/** Attributes IDs */
	ids: string;
	/** Sort type parameter */
	sortType?: string | null;
	/** Limit for pagination */
	limit?: number | null;
	/** Offset for pagination */
	offset?: number | null;
	/** Type */
	type?: string | null;
	/** Exclude IDs */
	excludeIds?: string | null;
	/** Attributes group IDs */
	attributeGroupIds?: string | null;
	includeSubGroups?: boolean | null;
};
export type GetCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGetApiResponse =
	/** status 200 Successful Response */ GetCatalogsResponse[] | null[];
export type GetCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGetApiArg = {
	/** Parent IDs */
	parentIds?: string | null;
	/** Root */
	root?: boolean | null;
	/** IDs */
	ids?: string | null;
};
export type GetDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGetApiResponse =
	/** status 200 Successful Response */ GetDeclarationsResponse[] | null[];
export type GetDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGetApiArg = {
	/** Catalog IDs */
	catalogIds: string;
	/** Optional list of IDs */
	ids?: string | null;
	/** Optional list of attribute IDs */
	attributeIds?: string | null;
};
export type CreateTransitionKeyApiResponse =
	/** status 200 Successful Response */ CreateTransitionKeyResponse;
export type CreateTransitionKeyApiArg = {
	createTransitionKeyRequest: CreateTransitionKeyRequest;
};
export type UpdateTransitionKeyExternalIdApiResponse =
	/** status 200 Transition key updated successfully */ TransitionKeyUpdateResponse;
export type UpdateTransitionKeyExternalIdApiArg = {
	updateTransitionKeyRequest: UpdateTransitionKeyRequest;
};
export type GetTransitionKeyBySourceInfoApiResponse =
	/** status 200 Successful Response */ GetTransitionKey;
export type GetTransitionKeyBySourceInfoApiArg = {
	/** ID of exchange configuration */
	configurationId: string;
	/** Extraction target name */
	extractionTarget: string;
	/** Row ID in external source */
	externalId?: string | null;
	/** Row ID in mdmg system */
	mdmEntityId?: string | null;
};
export type GetTransitionKeyByMdmEntityIdApiResponse =
	/** status 200 Successful Response */ GetTransitionKey[];
export type GetTransitionKeyByMdmEntityIdApiArg = {
	/** Item ID in MDMG system */
	mdmEntityId: string;
};
export type UpdateTransitionKeyDeduplicationApiResponse =
	/** status 200 Transition key updated successfully */ MdmEntityIdUpdateResponse;
export type UpdateTransitionKeyDeduplicationApiArg = {
	updateMdmIdTransitionKey: UpdateMdmIdTransitionKey;
};
export type UpdateSupportedSystemApiResponse =
	/** status 200 Successful Response */ SupportedSystemUpdateResponse;
export type UpdateSupportedSystemApiArg = {
	supportedSystemUpdateRequest: SupportedSystemUpdateRequest;
};
export type GetTransitionKeysByConnectionIdApiResponse =
	/** status 200 Successful Response */ ConnectionTransitionKeysResponse;
export type GetTransitionKeysByConnectionIdApiArg = {
	/** ID of connection */
	connectionId: string;
	page?: number;
	pageSize?: number;
};
export type ConnectionCreateResponse = {
	connection_id: string;
};
export type ValidationError = {
	loc: (string | number)[];
	msg: string;
	type: string;
};
export type HttpValidationError = {
	detail?: ValidationError[];
};
export type ConnectionCreate = {
	/** Field contains id of certain system type */
	system_id: string;
	/** Human-readable name for connection */
	connection_name: string;
	/** Contains params for certain connection */
	connection_params: object;
};
export type ConnectionInfo = {
	name: string;
	source_type_id: string;
	date_last_import?: string | null;
	count_configuration: number;
	count_successful_imports: number;
	count_failed_imports: number;
	is_pinned?: boolean;
	is_failed?: boolean;
	is_active?: boolean;
};
export type Integration = {
	connection_id: string;
	connection_info: ConnectionInfo;
};
export type PaginationInfo = {
	total: number;
	page_size: number;
	current_page: number;
};
export type ListConnectionsResponse = {
	data: Integration[];
	pagination: PaginationInfo;
};
export type ColumnMetadata = {
	column_name: string;
	data_type: string;
	mdm_types: string[];
};
export type ForeignKeyMetadata = {
	column_name: string;
	references_table: string;
	references_column: string;
};
export type TableMetadata = {
	columns: ColumnMetadata[];
	foreign_keys?: ForeignKeyMetadata[];
};
export type RelationalMetadata = {
	tables: {
		[key: string]: TableMetadata;
	};
};
export type TopicMetadata = {
	topics: string[];
	total_topics: number;
};
export type S3Metadata = {
	buckets: string[];
	total_buckets: number;
};
export type QueueMetadata = {
	name: string;
	message_count: number;
	consumer_count: number;
};
export type ExchangeMetadata = {
	name: string;
	type?: string | null;
};
export type RabbitMqMetadata = {
	queues: QueueMetadata[];
	exchanges: ExchangeMetadata[];
	total_queues: number;
	total_exchanges: number;
};
export type MetadataResponse = {
	source_type: string;
	metadata: RelationalMetadata | TopicMetadata | S3Metadata | RabbitMqMetadata;
};
export type ConnectionProperty = {
	return_parameter: string;
	title: string | null;
	type: string;
	mandatory: boolean;
	current_value: string | null;
};
export type GetConnectionResponse = {
	connection_properties: ConnectionProperty[];
	connection_info: ConnectionInfo;
};
export type ConnectionUpdateResponse = {
	detail: string;
};
export type ConnectionUpdateRequest = {
	conn_name: string;
	connection_params: object;
};
export type ConnectionPartialUpdateRequest = {
	conn_name: string | null;
	connection_params?: object | null;
};
export type PinConnectionRequest = {
	is_pinned?: boolean;
};
export type ConnectionTestResponse = {
	status?: boolean;
	message?: string;
};
export type ConnectionTest = {
	connection_params: object;
};
export type SupportedSystem = {
	system_id: string;
	system_name: string;
	system_image_url?: string | null;
};
export type SupportedSystemsResponse = {
	data: SupportedSystem[];
	pagination: PaginationInfo;
};
export type ConnectionParameter = {
	return_parameter: string;
	title: string;
	type: string;
	mandatory: boolean;
	example: string;
};
export type SupportedSystemExtended = {
	system_id: string;
	system_name: string;
	system_image_url?: string | null;
	system_short_name: string;
	connection_parameters?: ConnectionParameter[] | null;
};
export type ExecutionParam = {
	return_parameter: string;
	type: string;
	mandatory: boolean;
};
export type ApiActivationMode = {
	name: string;
	value: string;
	extra?: ExecutionParam[] | null;
};
export type ConfigurationStatus = 'active' | 'inactive';
export type ExecutionType = 'scheduled' | 'manual' | 'manual_immediate' | 'external_event';
export type ActivationMode = {
	activation_mode_id: string;
	execution_type: ExecutionType;
	schedule_interval: string | null;
	description: string | null;
};
export type ReplicationDirection = 'import' | 'export';
export type ConfigurationGetResponse = {
	configuration_id: string;
	name: string;
	description: string | null;
	status: ConfigurationStatus;
	destination: string;
	activation_mode: ActivationMode;
	replication_direction: ReplicationDirection;
	mapping: object | null;
	extraction_targets:
		| string[]
		| {
				[key: string]: string;
		  }
		| null;
	on_delete_action: string;
	created_at: string;
	updated_at: string;
};
export type ConfigurationUpdateResponse = {
	data: ConfigurationGetResponse;
	detail: string;
};
export type UpdateActivationMode = {
	execution_type?: ExecutionType | null;
	schedule_interval?: string | null;
};
export type ExchangeConfigurationRequest = {
	name?: string | null;
	description?: string | null;
	destination?: string | null;
	status?: ConfigurationStatus | null;
	activation_mode?: UpdateActivationMode | null;
	on_delete_action?: string | null;
	extraction_targets?:
		| string[]
		| {
				[key: string]: string;
		  }
		| null;
};
export type MappingDraftResponse = {
	detail: string;
};
export type MappingSchema = {
	data: object;
};
export type MappingResponse = {
	mapping_id: string;
	detail: string;
};
export type ConfigurationCreateResponse = {
	configuration_id: string;
};
export type ConfigurationCreateRequest = {
	name: string;
	description?: string | null;
	destination: string;
	on_delete_action: string;
	status: ConfigurationStatus;
	execution_type: ExecutionType;
	schedule_interval?: string | null;
	execution?: boolean | null;
	extraction_targets:
		| string[]
		| {
				[key: string]: string;
		  };
	replication_direction: ReplicationDirection;
};
export type ListConfigurationsResponse = {
	data: ConfigurationGetResponse[];
	pagination: PaginationInfo;
};
export type GrpcAttributeResponse = {
	id: string;
	display_name: string;
	description: string;
	type: string;
	list: boolean;
	measurement_group_id: string;
	associated_attribute_ids: any[];
	attribute_group_id: string;
	created_at: string;
	updated_at: string;
	restrictions: string;
};
export type GetAttributesResponse = {
	data: GrpcAttributeResponse[];
	meta: object;
};
export type GetCatalogsResponse = {
	id: string;
	display_name: string;
	description: string;
	parent_id: string;
	create_formula_id: string;
	update_formula_id: string;
	parent: boolean;
	created_at: string;
	updated_at: string;
	display_name_attribute_declaration_id: string;
};
export type GetDeclarationsResponse = {
	id: string;
	catalog_id: string;
	attribute_id: string;
	inherited: boolean;
	measurement_id: string;
	created_at: string;
	updated_at: string;
	restrictions: string;
};
export type CreateTransitionKeyResponse = {
	transition_key_id: string;
};
export type CreateTransitionKeyRequest = {
	/** Идентификатор конфигурации, в которой была получена сущность,переходной ключ которой нужно получить */
	configuration_id: string;
	/** Идентификатор сущности в исходной системе */
	external_id?: string | null;
	/** Название таблицы/топика источника интеграции */
	extraction_target: string;
	/** Идентификатор сущности в исходной системе */
	mdm_entity_id?: string | null;
};
export type GetTransitionKey = {
	transition_key_id: string;
	configuration_id: string;
	external_id?: string | null;
	extraction_target: string;
	mdm_entity_id?: string | null;
};
export type TransitionKeyUpdateResponse = {
	data: GetTransitionKey;
	detail: string;
};
export type UpdateTransitionKeyRequest = {
	configuration_id: string;
	external_id: string;
	extraction_target: string;
	mdm_entity_id: string;
};
export type MdmEntityIdUpdateResponse = {
	data: GetTransitionKey[];
	detail: string;
};
export type UpdateMdmIdTransitionKey = {
	mdm_entity_id: string;
	new_mdm_entity_id: string;
};
export type SupportedSystemUpdateResponse = {
	detail: string;
	data: SupportedSystem;
};
export type SupportedSystemUpdateRequest = {
	system_id: string;
	system_name?: string | null;
	system_short_name?: string | null;
	system_image_url?: string | null;
	connection_parameters?: ConnectionParameter[] | null;
};
export type ConnectionTransitionKey = {
	transition_key_id: string;
	configuration_id: string;
	external_id?: string | null;
	extraction_target: string;
	mdm_entity_id?: string | null;
	display_name?: string | null;
};
export type ConnectionTransitionKeysResponse = {
	data: ConnectionTransitionKey[];
	pagination: PaginationInfo;
};
export const {
	useCreateConnectionMutation,
	useGetConnectionsListQuery,
	useLazyGetConnectionsListQuery,
	useGetMetadataQuery,
	useLazyGetMetadataQuery,
	useGetConnectionQuery,
	useLazyGetConnectionQuery,
	useUpdateConnectionMutation,
	usePatchConnectionMutation,
	useDeleteConnectionMutation,
	usePinConnectionMutation,
	useTestConnectionMutation,
	useGetSupportedSystemsQuery,
	useLazyGetSupportedSystemsQuery,
	useGetSupportedSystemByIdQuery,
	useLazyGetSupportedSystemByIdQuery,
	useGetConfigurationsActivationModeQuery,
	useLazyGetConfigurationsActivationModeQuery,
	useTriggerDagMutation,
	useUpdateConfigurationMutation,
	useGetConfigurationQuery,
	useLazyGetConfigurationQuery,
	useCreateDraftMappingMutation,
	useGetDraftMappingQuery,
	useLazyGetDraftMappingQuery,
	useUpdateMappingMutation,
	useCreateConfigurationMutation,
	useGetConfigurationsListQuery,
	useLazyGetConfigurationsListQuery,
	useDeleteConfigurationMutation,
	useGetAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGetQuery,
	useLazyGetAttributesFromMdmgServiceMdmIntegrationsV1MdmgAttributesGetQuery,
	useGetCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGetQuery,
	useLazyGetCatalogsFromMdmgServiceMdmIntegrationsV1MdmgCatalogsGetQuery,
	useGetDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGetQuery,
	useLazyGetDeclarationsFromMdmgServiceMdmIntegrationsV1MdmgDeclarationsGetQuery,
	useCreateTransitionKeyMutation,
	useUpdateTransitionKeyExternalIdMutation,
	useGetTransitionKeyBySourceInfoQuery,
	useLazyGetTransitionKeyBySourceInfoQuery,
	useGetTransitionKeyByMdmEntityIdQuery,
	useLazyGetTransitionKeyByMdmEntityIdQuery,
	useUpdateTransitionKeyDeduplicationMutation,
	useUpdateSupportedSystemMutation,
	useGetTransitionKeysByConnectionIdQuery,
	useLazyGetTransitionKeysByConnectionIdQuery,
} = injectedRtkApi;
