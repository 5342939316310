import {
	CompatibilityElementResponse,
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogItemQuery,
} from 'shared/api/generatedApi/mdmgApi';

interface GetEntityAttributeDeclarationsHookProps {
	entity?: CompatibilityElementResponse | null,
	skip: boolean,
}

export const useGetEntityAttributeDeclarations = (
	{
		entity,
		skip,
	}: GetEntityAttributeDeclarationsHookProps) => {
	const { data: entityCatalogItem } = useGetCatalogItemQuery({
		id: entity?.id,
	}, {
		skip: skip || entity?.type !== 'CATALOG_ITEM',
	});

	const { data, isFetching } = useGetCatalogAttributeDeclarationsQuery({
		catalogId: entity?.type === 'CATALOG'
			? entity?.id
			: entityCatalogItem?.catalogId,
	}, {
		skip: skip || !(entity?.type == 'CATALOG' || (entity?.type == 'CATALOG_ITEM' && entityCatalogItem?.catalogId)),
	});

	return {
		data,
		isFetching,
	};
};