import { AggregationColor } from 'antd/es/color-picker/color';
import dayjs from 'dayjs';
import { AttributeType } from '../models';

const isNullOrEmptyString = (value: any) =>
	value === '' || value == null;

const mapValueForDto = (
	type: AttributeType,
	value: any,
) => {

	if (Array.isArray(value)) {
		return value.map(x => mapValueForDto(type, x));
	}

	if (type == 'STRING' || type == 'TEXT') {
		return isNullOrEmptyString(value)
			? null
			: value;
	}

	if (type == 'INTEGER') {
		return isNullOrEmptyString(value)
			? null
			: Number(value);
	}

	if (type == 'FLOAT') {
		return isNullOrEmptyString(value)
			? null
			: parseFloat(value);
	}

	if (type == 'BOOLEAN') {
		return isNullOrEmptyString(value)
			? null
			: Boolean(value);
	}

	if (type == 'COLOR') {
		if (isNullOrEmptyString(value)) {
			return null;
		}

		if (value instanceof AggregationColor) {
			return value.cleared
				? null
				: value.toHexString();
		}

		return value;
	}

	if (type == 'DATE_TIME') {
		return isNullOrEmptyString(value)
			? null
			: dayjs(value).format();
	}

	if (type == 'RELATION') {
		return isNullOrEmptyString(value)
			? null
			: value;
	}

	return value;
};

export { mapValueForDto };