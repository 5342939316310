import { RiListSettingsLine } from '@remixicon/react';
import { Flex, Spin, Table, TableProps } from 'antd';
import type { AnyObject } from 'antd/es/_util/type';
import { ColumnType } from 'antd/es/table/interface';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTableScroll } from '../../../hooks';
import { ColumnsAdjuster } from '../ColumnsAdjuster';

export type WorkTableColumnType<RecordType = AnyObject> =
	Pick<
		ColumnType<RecordType>,
		'dataIndex' |
		'key' |
		'width' |
		'sorter' |
		'defaultSortOrder'
	> & {
	title?: React.ReactNode;
};
export type WorkTableColumnsType<RecordType = AnyObject> = WorkTableColumnType<RecordType>[];

export interface IWorkTableProps extends TableProps<any> {
	columns: WorkTableColumnsType<any>;
	sorter?: any | null;
}

const sortIcon = ({ sortOrder }) => {
	const fill = [
		sortOrder === 'ascend' ? 'var(--primary)' : 'var(--text-faded)',
		sortOrder === 'descend' ? 'var(--primary)' : 'var(--text-faded)',
	];
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
			<path fillRule="evenodd"
				  clipRule="evenodd"
				  d="M12.3071 5.23317C12.6976 5.60395 12.6976 6.20511 12.3071 6.57589C11.9166 6.94668 11.2834 6.94668 10.8929 6.57589L8.60001 4.3989L6.30711 6.57589C5.91659 6.94668 5.28342 6.94668 4.8929 6.57589C4.50238 6.20511 4.50238 5.60395 4.8929 5.23317L7.18579 3.05618C7.96684 2.31461 9.23317 2.31461 10.0142 3.05618L12.3071 5.23317Z"
				  fill={fill[0]}
			/>
			<path fillRule="evenodd"
				  clipRule="evenodd"
				  d="M4.8929 10.7668C4.50238 10.396 4.50238 9.79489 4.8929 9.42411C5.28342 9.05332 5.91659 9.05332 6.30711 9.42411L8.60001 11.6011L10.8929 9.42411C11.2834 9.05332 11.9166 9.05332 12.3071 9.42411C12.6976 9.79489 12.6976 10.396 12.3071 10.7668L10.0142 12.9438C9.23317 13.6854 7.96684 13.6854 7.18579 12.9438L4.8929 10.7668Z"
				  fill={fill[1]}
			/>
		</svg>
	);
};

const WorkTableUi: React.FC<IWorkTableProps> = (
	{
		dataSource,
		columns,
		sorter,
		...props
	}) => {
	const [ rows, setRows ] = useState<TableProps['dataSource']>([]);

	const { tableRef, scroll } = useTableScroll();

	const columnsAdjusterRef = useRef<any>();
	const [ adjustedColumns, setAdjustedColumns ] = useState<any[] | null>(null);

	const antdColumns = useMemo(() => {
		return columns?.map(c => ({
			...c,
			...c.sorter ? {
				sortIcon: sortIcon,
				showSorterTooltip: false,
				defaultSortOrder: (sorter?.sortBy === c.key
					? sorter?.sortType === 'ASC'
						? 'ascend'
						: sorter?.sortType === 'DESC'
							? 'descend'
							: null
					: null) as 'descend' | 'ascend' | null,
			} : {},
		}));
	}, [ columns ]);

	useEffect(() => {
		if (dataSource) {
			setRows([ ...dataSource ]);
		}
	}, [ dataSource ]);

	const cols = useMemo(() => {
		if (!adjustedColumns) {
			return null;
		}

		return [
			...adjustedColumns.map((x) =>
				antdColumns.find((c) => c.key === x.key),
			),
			{
				title: (
					<RiListSettingsLine size={20}
										onClick={() => columnsAdjusterRef.current.open()}
					/>
				),
				dataIndex: 'menu',
				key: 'menu',
				width: '50px',
			},
		];
	}, [ adjustedColumns ]);

	return (
		<Flex vertical gap={12}>
			<ColumnsAdjuster ref={columnsAdjusterRef}
							 columns={antdColumns as any[]}
							 onChangeColumns={setAdjustedColumns}
			/>

			<div style={{ height: '100%' }}>
				{!cols?.length && (
					<Spin/>
				)}

				{cols?.length > 0 && (
					<Table {...props} dataSource={rows} columns={cols} ref={tableRef} scroll={scroll}/>
				)}
			</div>
		</Flex>
	);
};

export const WorkTable = React.memo(WorkTableUi);
