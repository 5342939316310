import { Col, Flex, Result, Spin, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { ClassifierAttributesWidget } from 'widgets/Classifiers/ClassifierItems/ClassifierAttributesListWidget';
import { CompatibilitiesWidget } from 'widgets/compatibilities';
import { Transactions } from 'widgets/Transactions';
import { EditClassifierMainInfo } from 'features/classifiers/DetailClassifier/EditClassifierMainInfo';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { removeCurrentClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.store';
import { useGetClassifierQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useAppDispatch, useEnumStateViaSearchParam, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

enum ClassifierGroupPageTab {
	MainInfo = 'MainInfo',
	Attributes = 'Attributes',
	Compatibilities = 'Compatibilities',
}

const ClassifierGroupPageUi = () => {
	const { t } = useTypedTranslation();

	const [activeKey, setActiveKey] = useEnumStateViaSearchParam(
		ClassifierGroupPageTab,
		'tab',
		ClassifierGroupPageTab.MainInfo
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { classifierGroupId } = useParams();

	const {
		data: currentClassifierGroup,
		isLoading: loading,
		error,
	} = useGetClassifierQuery({
		id: classifierGroupId,
	});

	const items: TabsProps['items'] = useMemo(
		() => [
			{
				key: ClassifierGroupPageTab.MainInfo,
				label: t((l) => l.common.defaultNames.mainInfo),
				children: <EditClassifierMainInfo classifier={currentClassifierGroup} />,
			},
			{
				key: ClassifierGroupPageTab.Attributes,
				label: t((l) => l.attributes.attributesSetup),
				children: <ClassifierAttributesWidget />,
			},
			{
				key: ClassifierGroupPageTab.Compatibilities,
				label: t((l) => l.compatibilities.tabTitle),
				children: <CompatibilitiesWidget entityId={classifierGroupId} />,
			},
		],
		[currentClassifierGroup, t]
	);

	const handleBackClick = () => {
		navigate(`/${routes.classifiers.main}/`);
	};

	useEffect(() => {
		if (currentClassifierGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.classifier.common.classifiers),
					url: `${routes.classifiers.main}`,
					child: {
						displayName: t((l) => l.classifier.common.classifierCard),
						url: `${routes.classifiers.main}/${currentClassifierGroup.id}`,
					},
				})
			);
		}
	}, [currentClassifierGroup, t]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
			dispatch(removeCurrentClassifierGroup());
		};
	}, []);

	if (error)
		return (
			<Result
				title={t((l) => l.classifier.error.classifierGetting)}
				subTitle={JSON.stringify(error)}
				status="error"
			/>
		);

	return (
		<>
			{loading ? (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Spin />
				</div>
			) : (
				<Flex vertical gap={12}>
					<Flex justify="space-between">
						<Typography.Title level={1}>
							{currentClassifierGroup?.displayName}
						</Typography.Title>

						<Transactions />
					</Flex>
					<Col xl={18} xs={24} sm={24} md={24} lg={18}>
						<Tabs
							items={items}
							destroyInactiveTabPane
							defaultActiveKey={activeKey}
							activeKey={activeKey}
							onChange={(key) => setActiveKey(key as ClassifierGroupPageTab)}
						/>
					</Col>
				</Flex>
			)}

			<DetailFooter customHandleBack={handleBackClick} />
		</>
	);
};

export const ClassifierGroupPage = React.memo(ClassifierGroupPageUi);
