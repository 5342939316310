import { RiArrowLeftRightLine } from '@remixicon/react';
import { App, Button, Form, Input, Result, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
	ConnectionParameter,
	useCreateConnectionMutation,
	useGetConnectionQuery, useGetSupportedSystemByIdQuery,
	useUpdateConnectionMutation,
} from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

interface IEditIntegrationMainInfoProps {
	integrationId?: string | null;
	systemId?: string | null;
	onUpsert?: (integrationId: string) => void;
}

const mapParam = (parameters: ConnectionParameter[], key: string, value: any) => {
	const { title, type, mandatory } = parameters.find(x => x.return_parameter === key);

	if (value == null || value == '') {
		if (mandatory) {
			throw new Error(`Не заполнен обязательный параметр "${title}"`);
		} else {
			return null;
		}
	}

	if (type === 'integer') {
		return Number(value);
	} else if (type === 'string') {
		return value;
	} else if (type === 'json') {
		try {
			return JSON.parse(value);
		} catch (e) {
			throw new Error(`Параметр "${title}" должен содержать данные в формате JSON!`);
		}
	}

	return value;
};

const EditIntegrationMainInfo = React.memo((
	{
		integrationId,
		systemId,
		onUpsert,
	}: IEditIntegrationMainInfoProps) => {

	const [ form ] = Form.useForm();
	const { notification } = App.useApp();

	const { data, isLoading, error } = useGetConnectionQuery({
		connectionId: integrationId,
	}, {
		skip: !integrationId,
	});

	const sysId = systemId ?? data.connection_info.source_type_id;
	const { data: system } = useGetSupportedSystemByIdQuery({
		systemId: sysId,
	}, {
		skip: !sysId,
	});

	const [ updateConnection, { isLoading: updateLoading } ] = useUpdateConnectionMutation();
	const [ createConnection, { isLoading: createConnectionLoading } ] = useCreateConnectionMutation();

	const onFinish = async () => {
		try {
			const { name, ...connectionParams } = form.getFieldsValue();
			let connectionId = integrationId;
			if (connectionId) {
				await updateConnection({
					connectionId,
					connectionUpdateRequest: {
						conn_name: name,
						connection_params: {
							...Object.fromEntries(
								Object.entries(connectionParams)
									.map(([ key, value ]) => [
										key,
										mapParam(system.connection_parameters, key, value as any),
									])
									.filter(([ , value ]) => !!value),
							),
						},
					},
				}).unwrap();
			} else {
				const { connection_id } = await createConnection({
					connectionCreate: {
						system_id: systemId ?? system?.system_id,
						connection_name: name,
						connection_params: {
							...Object.fromEntries(
								Object.entries(connectionParams)
									.map(([ key, value ]) => [
										key,
										mapParam(system.connection_parameters, key, value as any),
									])
									.filter(([ , value ]) => !!value),
							),
						},
					},
				}).unwrap();

				connectionId = connection_id;
			}
			onUpsert && onUpsert(connectionId);
		} catch (e) {
			errorHelper('Ошибка при редактировании информации об интеграции!', e, notification);
		}
	};

	useEffect(() => {
		if (data && data.connection_properties.length) {
			form.setFieldValue('name', data.connection_info.name);
			for (const item of data.connection_properties) {
				form.setFieldValue(item.return_parameter, item.current_value);
			}
		}
	}, [ data ]);

	if (isLoading) {
		return (
			<Spin/>
		);
	}

	if (error) {
		return (
			<Result status="error"
					title="Ошибка при получении списка возможных подключений!"
					subTitle={JSON.stringify(error)}
			/>
		);
	}

	return (
		<Form
			layout="vertical"
			form={form}
			style={{ marginBottom: 54 }}
			onFinish={onFinish}
		>
			<Typography.Title level={2} style={{ marginBottom: 24 }}>
				Общая информация
			</Typography.Title>
			<Form.Item
				label="Наименование подключения"
				rules={[ { required: true, message: 'Это поле обязательно' } ]}
				name="name"
			>
				<Input/>
			</Form.Item>
			<Typography.Title level={2} style={{ marginBottom: 24 }}>
				Настройки параметров подключения
			</Typography.Title>
			{system?.connection_parameters.map((param) => (
				<Form.Item key={param.return_parameter}
						   name={param.return_parameter}
						   label={param.title || param.return_parameter}
						   rules={[
							   {
								   required: param.mandatory,
								   message: 'Это поле обязательно',
							   },
						   ]}
				>
					{param.type === 'json' ? (
						<Input.TextArea rows={5}/>
					) : (
						<Input type={param.type === 'integer' ? 'number' : 'text'}/>
					)}
				</Form.Item>
			))}

			<Button
				icon={<RiArrowLeftRightLine/>}
				style={{ width: '100%' }}
				onClick={onFinish}
				loading={updateLoading || createConnectionLoading}
			>
				{integrationId ? 'Обновить подключение' : 'Создать подключение'}
			</Button>
		</Form>
	);
});

export {
	EditIntegrationMainInfo,
};
