import { App, Flex, Image, Spin } from 'antd';
import React from 'react';
import { useLazyDownloadFileQuery } from '../../../api/generatedApi/mdmgApi';
import DocumentSvg from '../../../assets/document.svg';
import { errorHelper } from '../../../helpers';
import { FileValue } from '../../../types';

interface IFilePreviewProps {
	file: FileValue;
	options?: {
		hidePreview?: boolean;
		hideSize?: boolean;
	};
}

function formatBytes(bytes: number): string {
	if (bytes >= 1024 * 1024 * 1024) {
		return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' ГБ';
	}
	if (bytes >= 1024 * 1024) {
		return (bytes / (1024 * 1024)).toFixed(2) + ' МБ';
	}
	if (bytes >= 1024) {
		return (bytes / 1024).toFixed(2) + ' КБ';
	}
	return bytes + ' Б';
}

const FilePreview = React.memo((
	{
		file,
		options,
	}: IFilePreviewProps) => {
	const { notification } = App.useApp();

	const [ downloadFileQuery, { isLoading: isDownloading } ] = useLazyDownloadFileQuery();

	const downloadFile = async () => {
		try {
			const blobUrl = await downloadFileQuery({
				id: file.id,
			})
				.unwrap();

			const a = document.createElement('a');
			a.href = blobUrl as never as string;
			a.download = file.displayName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} catch (error) {
			errorHelper(
				'Ошибка скачивания вложения',
				error,
				notification,
			);
		}
	};

	return (
		<Flex gap={8} align={'center'} onClick={e => e.stopPropagation()}>
			{!options?.hidePreview && file.presignedUrl && (
				<div style={{
					marginRight: 4,
					borderRadius: 6,
					overflow: 'hidden',
				}}>
					<Image
						width={64}
						src={file.presignedUrl}
						style={{
							borderRadius: 6,
						}}
					/>
				</div>
			)}
			{!options?.hidePreview && !file.presignedUrl && (
				<Spin spinning={isDownloading}>
					<Flex style={{
						width: 64,
						height: 64,
						borderRadius: 6,
						background: 'var(--light-gray)',
						cursor: 'pointer',
					}}
						  align={'center'}
						  justify={'center'}
						  onClick={() => downloadFile()}
					>
						<img src={DocumentSvg} alt="Скачать вложение"/>
					</Flex>
				</Spin>
			)}
			<span style={{ fontWeight: 500 }}>
				{file.displayName}
			</span>
			{!options?.hideSize && (
				<span style={{
					color: 'var(--text-faded)',
					fontWeight: 500,
				}}>
					{formatBytes(file.size)}
				</span>
			)}
		</Flex>
	);
});

export {
	FilePreview,
};