import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const DateRangeFilter = forwardRef((_props, ref) => {
	const [ dates, setDates ] = useState<[ Dayjs, Dayjs ] | null>(null);

	useImperativeHandle(ref, () => ({
		reset: () => setDates(null),
		getValues: (): [ Dayjs, Dayjs ] | null => (
			dates?.length === 2 ? [
				dates[0].startOf('day'),
				dates[1].endOf('day'),
			] : null
		),
	}));

	return (
		<DatePicker.RangePicker value={dates}
								onChange={setDates}
		/>
	);
});

export {
	DateRangeFilter,
};