import { RiEditLine } from '@remixicon/react';
import React, { memo, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ClassifierResponse } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { DropdownLink, WorkTable } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { WorkTableColumnsType } from 'shared/ui/components/WorkTable';
import { DeleteClassifier } from '../DeleteClassifier';

const columns: WorkTableColumnsType = [
	{
		title: 'Наименование',
		dataIndex: 'displayName',
		key: 'displayName',
	},
	{
		title: 'Классификационные связи',
		dataIndex: 'linkClassifierAttribute',
		key: 'linkClassifierAttribute',
	},
];

interface ClassifiersTableProps {
	items: ClassifierResponse[];
}

const ClassifiersTable = memo((
	{
		items,
	}: ClassifiersTableProps) => {
	const navigate = useNavigate();

	const dataSource = useMemo(() =>
		items.map((item) => ({
			...item,
			key: item.id,
			menu: (
				<ItemActions
					items={[
						{
							key: `${item.id}-edit`,
							label: (
								<DropdownLink
									icon={<RiEditLine size={16}/>}
									onClick={() =>
										navigate(item.id)
									}
									title="Редактировать"
								/>
							),
						},
						{
							key: `${item.id}-delete`,
							label: <DeleteClassifier id={item?.id}/>,
						},
					]}
				/>
			),
		})), [ items ]);

	return (
		<WorkTable columns={columns}
				   dataSource={dataSource}
				   style={{ width: '100%' }}
				   onRow={(record) => ({
					   onClick: () => navigate(`${record.id}/${routes.classifiers.records}`),
				   })}
		/>
	);
});

export {
	ClassifiersTable,
};