import { App } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useAttributesDeclarations } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.model';
import { setAttributeDeclarationsList } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { TaskType, useAsyncOperation } from 'entities/events';
import {
	CatalogAttributeDeclarationResponse,
	mdmgApi,
	UpdateCatalogAttributeDeclarationApiArg,
	useUpdateCatalogAttributeDeclarationAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const useEditCatalogRestrictionsTables = () => {
	const { t } = useTypedTranslation();
	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();
	const { getAttributes } = useAttributesDeclarations();
	const { catalogGroupId } = useParams();

	const { execute: updateCatalogAttributeDeclarationAsync } = useAsyncOperation(
		useUpdateCatalogAttributeDeclarationAsyncMutation,
		TaskType.UPDATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK
	);

	const edit = async (arg: UpdateCatalogAttributeDeclarationApiArg) => {
		try {
			await updateCatalogAttributeDeclarationAsync({ ...arg });
			dispatch(mdmgApi.util.invalidateTags(['CatalogAttributeDeclarations']));
			dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
		} catch (error) {
			errorHelper(
				t((l) => l.catalogs.error.addAttributeToRestrictionErr),
				error,
				notification
			);
		}
	};

	const searchHandler = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchParams.set('searchValue', e.target.value);
		setSearchParams(searchParams);
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({
			entityType: 'ATTRIBUTE_DECLARATION',
			textRequest: searchValue,
			parentId: catalogGroupId,
		})
			.unwrap()
			.then((res) => {
				dispatch(
					setAttributeDeclarationsList(res as CatalogAttributeDeclarationResponse[])
				);
			})
			.catch((err) => {
				errorHelper(
					t((l) => l.attributes.attributeListErr),
					err,
					notification
				);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			fetchSearch(searchValue);
		} else {
			getAttributes(catalogGroupId).then((res) =>
				dispatch(setAttributeDeclarationsList(res))
			);
		}
	}, [searchParams.get('searchValue')]);

	return {
		edit,
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		isLoading: isSearchItemsLoading,
	};
};
