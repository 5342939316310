import { RiUnpinLine, RiPushpinLine } from '@remixicon/react';
import React from 'react';
import { usePinConnectionMutation } from 'shared/api/generatedApi/integrationsApi';
import { DropdownLink } from 'shared/ui';

interface IPinSwitchIntegration {
    isPinned?: boolean;
    id: string;
}

const PinSwitchIntegration = React.memo((
    {
        isPinned,
        id
    }: IPinSwitchIntegration) => {

    const [pinConnection, { isLoading } ] = usePinConnectionMutation();

    const onClick = () => {
        pinConnection({
            connectionId: id,
            pinConnectionRequest: { is_pinned: !isPinned }
        });
    };


    return (
        <>
            <DropdownLink
                title={isPinned ? 'Открепить' : 'Закрепить'}
                onClick={onClick}
                loading={isLoading}
                icon={isPinned ? <RiUnpinLine size={16} /> : <RiPushpinLine size={16} />}
            />
        </>
    );
});

export {
    PinSwitchIntegration,
};
