import { RiArrowRightCircleLine } from '@remixicon/react';
import { Flex, Typography, Steps, Button, App, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SelectContextNameWidget, SelectSecondEntityWidget } from 'widgets/compatibilities';
import { Transactions } from 'widgets/Transactions';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import {
	useGetCatalogItemQuery, useGetClassifierItemQuery,
	useGetClassifierQuery, useGetCatalogQuery, useCreateCompatibilityMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter, setSubMenuCollapsed } from 'shared/ui';
import s from './CreateCompatibilityPage.module.scss';

enum CreateCompatibilityStep {
	SelectConextName,
	SelectEntity,
	EditorCompatibilities,
}

const createCompatibilityStepsNames: Record<CreateCompatibilityStep, string> = {
	[CreateCompatibilityStep.SelectConextName]: 'Выбор/создание совместимости',
	[CreateCompatibilityStep.SelectEntity]: 'Выбор сущности справа',
	[CreateCompatibilityStep.EditorCompatibilities]: 'Редактор совместимостей',
};

const CreateCompatibilityPage = () => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();
	const navigate = useNavigate();

	const { entityType, entityId } = useParams();

	const [ current, setCurrent ] = useState<CreateCompatibilityStep>(CreateCompatibilityStep.SelectConextName);
	const [ contextNameId, setContextNameId ] = useState<string | null>(null);
	const [ secondEntity, setSecondEntity ] = useState<any | null>(null);

	const items = Object.entries(createCompatibilityStepsNames)
		.map(([ key, title ]) => ({ key, title: title }));

	const {
		data: catalogItem,
	} = useGetCatalogItemQuery({
		id: entityId,
	}, {
		skip: entityType === 'CLASSIFIER' || entityType === 'CLASSIFIER_ITEM' || entityType === 'CATALOG',
	});

	const {
		data: catalog,
	} = useGetCatalogQuery({
		id: entityType === 'CATALOG' ? entityId : catalogItem?.catalogId,
	}, {
		skip: entityType === 'CLASSIFIER' || entityType === 'CLASSIFIER_ITEM',
	});

	const {
		data: classifierItem,
	} = useGetClassifierItemQuery({
		id: entityId,
	}, {
		skip: entityType === 'CATALOG' || entityType === 'CATALOG_ITEM' || entityType === 'CLASSIFIER',
	});

	const {
		data: classifier,
	} = useGetClassifierQuery({
		id: entityType === 'CLASSIFIER' ? entityId : classifierItem?.classifierId,
	}, {
		skip: entityType === 'CATALOG' || entityType === 'CATALOG_ITEM',
	});

	const [ createCompatibility ] = useCreateCompatibilityMutation();


	useEffect(() => {
		dispatch(setSubMenuCollapsed(true));

		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	useEffect(() => {
		const lastChild = {
			displayName: t((l) => l.compatibilities.addCompatibility),
			url: `${routes.compatibilities.main}/${routes.compatibility.create}/${entityType}/${entityId}`,
		};

		if (entityType === 'CATALOG' || entityType === 'CATALOG_ITEM') {
			const child = entityType === 'CATALOG_ITEM'
				? {
					displayName: catalogItem?.displayName ?? <Spin spinning={true} size="small"/>,
					url: catalog && catalogItem && `${routes.catalogs.main}/${catalog.id}/record/${catalogItem.id}`,
					child: lastChild,
				} : lastChild;

			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.catalogs.catalogs),
					url: `${routes.catalogs.main}`,
					child: {
						displayName: catalog?.displayName ?? <Spin spinning={true} size="small"/>,
						url: catalog && `${routes.catalogs.main}/${catalog.id}`,
						child: child,
					},
				}),
			);
		} else if (entityType === 'CLASSIFIER' || entityType === 'CLASSIFIER_ITEM') {
			const child = entityType === 'CLASSIFIER_ITEM'
				? {
					displayName: classifierItem?.displayName ?? <Spin spinning={true} size="small"/>,
					url: classifier && classifierItem && `${routes.classifiers.main}/${classifier.id}/${routes.classifiers.records}/${classifierItem.id}/${routes.classifiers.edit}`,
					child: lastChild,
				} : lastChild;

			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.classifier.mainPage.title),
					url: `${routes.classifiers.main}`,
					child: {
						displayName: classifier?.displayName ?? <Spin spinning={true} size="small"/>,
						url: classifier && `${routes.classifiers.main}/${classifier.id}/${routes.classifiers.records}`,
						child: child,
					},
				}),
			);
		}
	}, [ catalogItem, classifierItem, catalog, classifier ]);

	const handleBack = () => {
		if (current === CreateCompatibilityStep.SelectConextName) {
			navigate(-1);
		} else if (current === CreateCompatibilityStep.SelectEntity) {
			setCurrent(CreateCompatibilityStep.SelectConextName);
		}
	};

	const next = async () => {
		if (current === CreateCompatibilityStep.SelectConextName) {
			if (!contextNameId) {
				return;
			}

			setCurrent(CreateCompatibilityStep.SelectEntity);
		} else if (current === CreateCompatibilityStep.SelectEntity) {
			try {
				const { id } = await createCompatibility({
					createCompatibilityRequest: {
						firstEntityId: entityId,
						firstEntityType: entityType as 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CLASSIFIER' | 'CATALOG',
						secondEntityId: secondEntity.id,
						secondEntityType: secondEntity.type,
						contextNameId: contextNameId,
					},
				}).unwrap();

				navigate(`/${routes.compatibilities.main}/${id}`);
			} catch (err) {
				errorHelper(
					'Не удалось создать совместимость',
					err,
					notification,
				);
			}
		}
	};

	return (
		<>
			<Flex vertical>
				<Flex justify="space-between" style={{ width: '100%', paddingBottom: 24 }}>
					<Typography.Title level={1}>{t((l) => l.compatibilities.addCompatibility)}</Typography.Title>
					<Transactions/>
				</Flex>
				<Flex justify="start" gap={24} style={{ paddingBottom: 24 }}>
					<Steps className={s.steps} current={current} items={items}/>
				</Flex>

				{current === CreateCompatibilityStep.SelectConextName && (
					<SelectContextNameWidget onSelect={setContextNameId}/>
				)}
				{current === CreateCompatibilityStep.SelectEntity && (
					<SelectSecondEntityWidget onSelect={setSecondEntity}/>
				)}
			</Flex>

			<DetailFooter customHandleBack={handleBack}>
				<Button type="text"
						onClick={next}
						icon={<RiArrowRightCircleLine size={16}/>}
						disabled={
							(current === CreateCompatibilityStep.SelectConextName && !contextNameId) ||
							(current === CreateCompatibilityStep.SelectEntity && !secondEntity)
						}
				>
					Перейти к следующему этапу
				</Button>
			</DetailFooter>
		</>
	);
};

export {
	CreateCompatibilityPage,
};