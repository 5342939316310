import { RiPlayCircleLine } from '@remixicon/react';
import React, { memo, useCallback } from 'react';
import { useTriggerDagMutation } from 'shared/api/generatedApi/integrationsApi';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface StartExchangeButtonProps {
	integrationId: string;
	configurationId: string;
	isDropdown?: boolean;
	isDisabled?: boolean;
	isLoading?: boolean;
}

const StartExchangeButton = memo((
	{
		isDropdown,
		integrationId,
		configurationId,
		isDisabled,
		isLoading,
	}: StartExchangeButtonProps,
) => {
	const [ triggerDag ] = useTriggerDagMutation();

	const onClick = useCallback(() => triggerDag({
		connectionId: integrationId,
		configurationId: configurationId,
	}), [ integrationId, configurationId ]);

	return (<ButtonVsDropdownLink
		title="Запустить обмен"
		callback={onClick}
		icon={<RiPlayCircleLine/>}
		type={isDropdown ? 'dropdownLink' : null}
		isDisabled={isDisabled}
		isLoading={isLoading}
	/>);
});

export {
	StartExchangeButton,
};