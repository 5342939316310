import { Flex, Result, Spin, Tree, TreeDataNode, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AddClassifierRecord } from 'features/classifiers/ClassifierRecords/AddClassifierRecord';
import {
	SearchClassifiersItems,
	useSearchClassifiersItems,
} from 'features/classifiers/SearchClassifiersItems';
import { removeCurrentClassifierTreeItem } from 'entities/classifiers/classifiersItemsTree/classifiersItemsTree.store';
import { ClassifierItemTreeNodeResponse } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { setSubMenuCollapsed, Placeholder } from 'shared/ui';
import { useClassifierItemTree } from './classifierItemTree.model';

type ClassifierItem = ClassifierItemTreeNodeResponse &
	TreeDataNode & {
	key: React.Key;
	title: string;
	children?: Array<ClassifierItem>;
};

type DisplayTreeNode = Omit<ClassifierItem, 'title'> & { title: React.ReactNode };

const ClassifierItemTreeWidgetUi = () => {
	const [ selectedKeys, setSelectedKeys ] = useState<React.Key[]>([]);
	const { classifierGroupId } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const dispatch = useAppDispatch();
	const {
		selectedTreeItem,
		selectCurrentClassifier,
		loading,
		error,
		formatToTree,
		addMenuToTreeNodeItems,
		classifiersTree,
		expandedKeys,
		onExpandTree,
	} = useClassifierItemTree();

	const { searchValue, searchedClassifierItems } = useSearchClassifiersItems();

	const onSelect = (
		key,
		info: { node: DisplayTreeNode; selectedNodes: Array<DisplayTreeNode> },
	) => {
		if (info.selectedNodes[0]) {
			setSelectedKeys(key);
			selectCurrentClassifier(info.selectedNodes[0]);
		} else {
			dispatch(removeCurrentClassifierTreeItem());
			setSelectedKeys([]);
			navigate(
				`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`,
			);
		}
	};

	useEffect(() => {
		if (selectedTreeItem) {
			setSelectedKeys([ selectedTreeItem.key ]);
		}
	}, [ selectedTreeItem, classifiersTree ]);

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));

		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	const [ treeData, setTreeData ] = useState<Array<DisplayTreeNode> | null>(null);

	useEffect(() => {
		const treeData = addMenuToTreeNodeItems(
			formatToTree(!searchValue ? classifiersTree : searchedClassifierItems),
		);

		setTreeData(treeData as DisplayTreeNode[]);
	}, [ classifiersTree, searchedClassifierItems, searchValue ]);

	const isLoading = loading || expandedKeys == null;

	return (
		<Flex vertical gap={24}>
			<Typography.Title level={2}>Записи классификатора</Typography.Title>
			<SearchClassifiersItems/>
			{error && (
				<Result
					title="Ошибка при получении записей классификатора!"
					subTitle={`Текст ошибки ${JSON.stringify(error)}`}
				/>
			)}
			<AddClassifierRecord isButton/>

			{isLoading && <Spin/>}

			{!isLoading &&
				(classifiersTree && classifiersTree.length > 0 ? (
					<Tree
						treeData={treeData}
						onSelect={onSelect}
						selectedKeys={selectedKeys}
						checkStrictly
						checkable={false}
						defaultExpandedKeys={expandedKeys}
						loadData={(treeNode) => onExpandTree(treeNode)}
					/>
				) : (
					<Placeholder
						title="Вы не добавили еще ни одной записи в классификатор"
						subTitle="Для создания новой записи нажмите на кнопку «Добавить запись классификатора"
						small
					/>
				))}
		</Flex>
	);
};

export const ClassifierItemTreeWidget = React.memo(ClassifierItemTreeWidgetUi);
