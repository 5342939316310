// Удаление настроек для поиска совместимостей

import { RiDeleteBin6Line } from '@remixicon/react';
import { App } from 'antd';
import React from 'react';
import { useDeleteCompatibilitiesMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

interface IDeleteCompatibilities {
	ids: string[];
}

export const DeleteCompatibilities = ({ ids }: IDeleteCompatibilities) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();
	const [delCompatibilities] = useDeleteCompatibilitiesMutation();

	const deleteCompatibilities = () => {
		delCompatibilities({ ids })
			.unwrap()
			.then(() => {
				//TODO добавить инвалидацию тэга получения совместимостей
			})
			.catch((err) =>
				errorHelper(
					t((l) => l.compatibilities.error.deleting),
					err,
					notification
				)
			);
	};

	return (
		<DropdownLink
			title={t((l) => l.common.buttons.delete)}
			icon={<RiDeleteBin6Line size={18} />}
			onClick={deleteCompatibilities}
		/>
	);
};
