import { Flex } from 'antd';
import React, { memo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ContextNamesTable, DeleteContextNames } from 'features/compatibilities';
import { CreateCompatibility } from 'features/metadata/Compatibilities/createCompatibility';
import { SearchCompatibility } from 'features/metadata/Compatibilities/searchCompatibility/SearchCompatibility';
import { DetailFooter } from 'shared/ui';

interface CompatibilitiesMetadataWidgetProps {
	onClick?: (id: string) => void;
}

const ContextNamesWidget = memo((
	{
		onClick,
	}: CompatibilitiesMetadataWidgetProps,
) => {
	const navigate = useNavigate();

	const { entityId } = useParams();

	const [ searchParams ] = useSearchParams();
	const searchValue = searchParams.get('searchValue');

	const [ selectedRows, setSelectedRows ] = useState<string[]>([]);

	const onRowClick = (id) => {
		if (entityId) {
			onClick?.(id);
		} else {
			navigate(`${id}`);
		}
	};

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={12}>
				{!entityId && <SearchCompatibility/>}
				<Flex gap={6}>
					<CreateCompatibility/>
				</Flex>
			</Flex>

			<ContextNamesTable search={searchValue}
							   selectable
							   multipleSelect
							   onSelect={setSelectedRows}
							   onClick={onRowClick}
			/>

			<DetailFooter isNavHidden>
				<DeleteContextNames itemId={selectedRows}/>
			</DetailFooter>
		</Flex>
	);
});

export {
	ContextNamesWidget,
};