import { App, Checkbox, Col, Flex, Form, Input, Result, Spin, Typography } from 'antd';
import React, { useEffect, useState, useRef, memo, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useGetCurrentMeausureGroup, useGetMeasuresGroup } from 'entities/metadata/measures';
import {
	AttributeResponse,
	MeasurementResponse,
	useGetAttributeQuery,
	useGetCatalogsByAttributeQuery,
	useLazyGetMeasurementGroupQuery,
	useLazyGetMeasurementsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { mapValueForDto } from 'shared/helpers';
import { errorHelper } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { mapValueForForm } from 'shared/helpers/mapValueForForm';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownSelect } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';

const selectOptions = [
	{ label: 'Строка', value: 'STRING' },
	{ label: 'Число с плавающей точкой', value: 'FLOAT' },
	{ label: 'Целое число', value: 'INTEGER' },
	{ label: 'Логический тип', value: 'BOOLEAN' },
	{ label: 'Многострочный текст', value: 'TEXT' },
	{ label: 'Дата', value: 'DATE_TIME' },
	{ label: 'Цвет', value: 'COLOR' },
	{ label: 'Формула', value: 'FORMULA' },
	{ label: 'Связь', value: 'RELATION' },
	{ label: 'Файл', value: 'FILE' },
];

const mapFormToAttributeResponse = (
	attribute: AttributeResponse,
	form: any,
): AttributeResponse => {
	return {
		...attribute,
		...form.getFieldsValue([
			'displayName',
			'description',
			'type',
			'list',
			'associatedAttributeIds',
			'measurementGroupId',
		]),
		restrictions: {
			...form.getFieldsValue([
				'measurementId',
				'mask',
				'formulaId',
				'maxLength',
				'accuracy',
				'scopeId',
				'defaultValue',
			]),
		},
	};
};

interface EditSimpleAttributeProps {
	attributeId?: string | null;
	onSubmit: (form: any) => void;
}

const EditAttribute = memo((
	{
		attributeId,
		onSubmit,
	}: EditSimpleAttributeProps) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const [ form ] = Form.useForm();

	const typeValue = Form.useWatch('type', form);
	const mask = Form.useWatch('mask', form);
	const maxLength = Form.useWatch('maxLength', form);
	const list = Form.useWatch('list', form);

	useEffect(() => {
		(async () => {
			try {
				await form.validateFields([ 'defaultValue' ]);
			} catch {
				//	do nothing
			}
		})();
	}, [ mask, maxLength ]);

	useEffect(() => {
		if (typeValue != initialValuesRef.current?.type ||
			list != initialValuesRef.current?.list
		) {
			form.setFieldValue('defaultValue', null);
			handleBlur();
		}
	}, [ typeValue, list ]);

	const initialValuesRef = useRef(null);

	const [ measurements, setMeasurements ] = useState<MeasurementResponse[]>([]);
	const { measuresList, loading, error } = useGetMeasuresGroup();
	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();

	const [ getMeasurementGroup ] = useLazyGetMeasurementGroupQuery();
	const [ getMeasurements ] = useLazyGetMeasurementsQuery();

	const { data: formVals, isLoading, error: getAttributeError } = useGetAttributeQuery({
		id: attributeId,
	}, {
		skip: !attributeId,
	});

	const { data: usageList } = useGetCatalogsByAttributeQuery({
		attributeId: attributeId,
	}, {
		skip: !attributeId,
	});

	const handleBlur = useDebouncedCallback(useCallback(async () => {
		//	Без этого возникает ошибка валидации с пустым списком ошибок
		//	Видимо из-за того, что меняется состав полей формы
		//	Пока не понятно как правильно сделать, поэтому костыль
		await (() => new Promise(resolve => setTimeout(resolve, 0)))();

		try {
			await form.validateFields();
		} catch (error) {
			console.error(error);
			return;
		}

		const type = form.getFieldValue('type');
		const rawValues = form.getFieldsValue([
			'displayName',
			'description',
			'type',
			'list',
			'associatedAttributeIds',
			'measurementGroupId',
		]);

		let restrictions = {};

		const defaultValue = mapValueForDto(type, form.getFieldValue('defaultValue'));
		const mask = mapValueForDto('STRING', form.getFieldValue('mask'));
		const maxLength = mapValueForDto('INTEGER', form.getFieldValue('maxLength'));
		const accuracy = mapValueForDto('FLOAT', form.getFieldValue('accuracy'));

		if (type === 'STRING') {
			restrictions = {
				...form.getFieldsValue([ 'formulaId' ]),
				maxLength,
				mask,
				defaultValue,
			};
		} else if (type === 'FLOAT') {
			restrictions = {
				...form.getFieldsValue([
					'measurementId',
					'formulaId',
				]),
				accuracy,
				defaultValue,
			};
		} else if (type === 'INTEGER') {
			restrictions = {
				...form.getFieldsValue([ 'formulaId' ]),
				defaultValue,
			};
		} else if ([ 'TEXT', 'DATE_TIME', 'COLOR' ].includes(type)) {
			restrictions = {
				...form.getFieldsValue([ 'formulaId' ]),
				defaultValue,
			};
		} else if (type === 'FORMULA') {
			restrictions = {
				...form.getFieldsValue([ 'formulaId' ]),
			};
		} else if (type === 'RELATION') {
			restrictions = {
				...form.getFieldsValue([ 'scopeId', 'formulaId' ]),
				defaultValue,
			};
		} else if (type === 'FILE') {
			restrictions = {
				defaultValue,
			};
		} else if (type === 'BOOLEAN') {
			restrictions = {
				defaultValue,
			};
		}

		const combinedRawValues = {
			...rawValues,
			restrictions: {
				...restrictions,
			},
		};

		if (type === CellTypesEnum.FLOAT) {
			if (!combinedRawValues?.measurementGroupId) {
				combinedRawValues.measurementGroupId = null;
				combinedRawValues.restrictions = {
					...combinedRawValues.restrictions,
					measurementId: null,
				};
			}

			if (
				combinedRawValues?.measurementGroupId &&
				combinedRawValues.restrictions.measurementId === undefined
			) {
				combinedRawValues.restrictions = {
					...combinedRawValues.restrictions,
					measurementId: null,
				};
			}
		}

		if (JSON.stringify(combinedRawValues) === JSON.stringify(initialValuesRef.current)) {
			return;
		}

		onSubmit(combinedRawValues);

		initialValuesRef.current = combinedRawValues;
	}, [ form, onSubmit ]), 500);

	const handleMeasurementGroup = async (e: string) => {
		if (!e) {
			form.setFieldValue('measurementId', null);
		} else {
			try {
				const group = await getMeasurementGroup({
					id: e,
				}).unwrap();
				form.setFieldValue('measurementId', group.baseMeasurement?.id);
			} catch (err) {
				errorHelper(
					t((l) => l.measures.error.gettingMeasurementUnits),
					err,
					notification,
				);
			}
		}

		handleBlur();
	};

	useEffect(() => {
		if (formVals) {
			form.setFieldsValue(formVals);
			form.setFieldValue(
				'associatedAttributeIds',
				formVals
					?.associatedAttributeIds
					?.map((item) => ({
						label: item,
						value: item,
					})),
			);

			const { defaultValue, ...rest } = formVals.restrictions;
			form.setFieldValue('defaultValue', mapValueForForm(formVals.type, formVals.list, defaultValue));
			form.setFieldsValue(rest);

			if (formVals.measurementGroupId) {
				getMeasurements({
					measurementGroupId: formVals.measurementGroupId,
				})
					.unwrap()
					.then((res) => {
						setMeasurements(res);

						const data = res.find((item) =>
							typeof formVals?.restrictions?.measurementId === 'string' &&
							item.id === formVals.restrictions.measurementId,
						);
						if (data) {
							form.setFieldValue('measurementId', {
								value: data.id,
							});
						} else {
							form.setFieldValue('measurementId', null);
						}
					});
			}
			if (typeof formVals.description === 'object') {
				const v = (formVals.description as { value: string })?.value;
				form.setFieldValue('description', v);
			}

			if (typeValue === CellTypesEnum.FLOAT && form.getFieldValue('measurementGroupId')) {
				getData(form.getFieldValue('measurementGroupId'));
			}

			initialValuesRef.current = form.getFieldsValue();
		}
	}, [ formVals ]);

	const DefaultValueInput = CellInputParser(
		'defaultValue',
		mapFormToAttributeResponse(formVals, form),
		{
			callback: handleBlur,
			label: t(l => l.common.defaultNames.defaultValue),
		},
	);

	if (isLoading) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Spin size="large"/>
			</div>
		);
	}

	if (getAttributeError) {
		return (
			<Result
				status="error"
				title={t((l) => l.attributes.receivingDataForEditingAttributeErr)}
				subTitle={JSON.stringify(getAttributeError)}
			/>
		);
	}

	return (
		<Col sm={24} md={24} lg={20} xl={14} xxl={14}>
			<Flex vertical gap={12} style={{ width: '100%' }}>
				<Typography.Title level={2}>
					{t((l) => l.common.defaultNames.mainInfo)}
				</Typography.Title>
				<Form layout="vertical" form={form}>
					<Form.Item
						label={t((l) => l.common.defaultNames.name)}
						required
						name="displayName"
					>
						<Input onBlur={handleBlur} allowClear/>
					</Form.Item>

					<Form.Item
						label={t((l) => l.common.defaultNames.description)}
						name="description"
					>
						<Input onBlur={handleBlur} allowClear/>
					</Form.Item>

					<Form.Item name="list" valuePropName="checked">
						<Checkbox onChange={handleBlur}>
							{t((l) => l.common.defaultNames.isMulti)}
						</Checkbox>
					</Form.Item>

					<Form.Item
						label={t((l) => l.common.defaultNames.dataType)}
						required
						name="type"
					>
						<DropdownSelect
							options={selectOptions}
							allowClear
							onChange={handleBlur}
							disabled={usageList?.length > 0}
						/>
					</Form.Item>

					{typeValue === 'STRING' && (
						<>
							<Form.Item
								label={t((l) => l.common.defaultNames.inputMask)}
								name="mask"
							>
								<Input onBlur={handleBlur} allowClear/>
							</Form.Item>

							<Form.Item
								label={t((l) => l.common.defaultNames.maxLength)}
								name="maxLength"
							>
								<Input onBlur={handleBlur} allowClear/>
							</Form.Item>
						</>
					)}

					{typeValue === 'FLOAT' && (
						<>
							<Form.Item
								label={t((l) => l.measures.group.groupTitle)}
								name="measurementGroupId"
							>
								{error ? (
									<Input
										value={t((l) => l.measures.error.gettingDataToSelectUnits)}
										disabled
									/>
								) : (
									<DropdownTreeSelect
										loading={loading}
										onChange={handleMeasurementGroup}
										treeData={measuresList?.map((item) => ({
											title: item.displayName,
											key: item.id,
											value: item.id,
										}))}
										value={formVals?.measurementGroupId}
										allowClear
									/>
								)}
							</Form.Item>

							<Form.Item
								label={t((l) => l.measures.measurementUnit)}
								name="measurementId"
							>
								{measuresError && currentMeasureGroup ? (
									<Input
										value={t(
											(l) => l.measures.error.gettingDataToSelectUnits,
										)}
										disabled
									/>
								) : (
									<DropdownSelect
										loading={measuresLoading}
										onChange={handleBlur}
										options={measurements?.map((item) => ({
											label: item.displayName,
											key: item.id,
											value: item.id,
										}))}
										value={formVals?.restrictions?.measurementId}
										disabled={!measurements?.length}
										allowClear
									/>
								)}
							</Form.Item>

							<Form.Item
								label={t((l) => l.common.defaultNames.accuracy)}
								name="accuracy"
							>
								<Input onBlur={handleBlur} allowClear/>
							</Form.Item>
						</>
					)}


					{/* <Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
							<Input onBlur={handleBlur} allowClear />
						</Form.Item> */}

					{!!typeValue && DefaultValueInput}

				</Form>
			</Flex>
		</Col>
	);
});

export {
	EditAttribute,
};
