import { RiPlayCircleLine } from '@remixicon/react';
import { App } from 'antd';
import React, { memo, useEffect } from 'react';
import { useSearchCompatibilitiesMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface StartSearchCompatibilitiesButtonProps {
	compatibilityId: string;
	isDropdown: boolean;
}

const StartSearchCompatibilitiesButton = memo((
	{
		compatibilityId,
		isDropdown,
	}: StartSearchCompatibilitiesButtonProps,
) => {
	const { notification } = App.useApp();

	const [ startSearchCompatibilities, { isLoading, error } ] = useSearchCompatibilitiesMutation();

	useEffect(() => {
		if (!error) {
			return;
		}

		errorHelper('Ошибка запуска поиска совместимостей', error, notification);
	}, [ error ]);

	return (
		<ButtonVsDropdownLink title="Запустить поиск"
							  callback={() => startSearchCompatibilities({
								  id: compatibilityId,
							  })}
							  icon={<RiPlayCircleLine size={16}/>}
							  type={isDropdown ? 'dropdownLink' : null}
							  isLoading={isLoading}
		/>
	);
});

export {
	StartSearchCompatibilitiesButton,
};