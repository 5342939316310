import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiAddCircleLine, RiArrowRightLine, RiDeleteBin6Line, RiEdit2Line } from '@remixicon/react';
import { Flex, List, Result } from 'antd';
import { diffChars, diffWords } from 'diff';
import React, { useEffect, useState } from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import s from './EntityStateDifference.module.scss';

export type IValue = string | boolean | number | Array<string | boolean | number>;

type IListItem = {
	displayName: string;
	icon: JSX.Element | null;
	comparisonResult: any;
}[];

export interface IEntityStateDifference {
	oldData: {
		[key: string]: IValue;
	};
	newData: {
		[key: string]: IValue;
	};
	date?: string;
	userName?: string;
	title?: string;
	error?: FetchBaseQueryError;
	isLoading?: boolean;
	propertyNamesMapper?: (propertyName: string) => string;
}

export const EntityStateDifference: React.FC<IEntityStateDifference> = ({
	oldData,
	newData,
	date,
	userName,
	title,
	error,
	isLoading,
	propertyNamesMapper,
}) => {
	const { t } = useTypedTranslation();

	const _title = title || '—';
	const _date = date ? new Date(date).toLocaleString('ru-RU') : '';
	const _userName = userName || t((l) => l.common.defaultNames.emptyName);

	const [data, setData] = useState<IListItem>([]);

	const highlightDiff = (oldText: string, newText: string, method?: 'word' | 'chars') => {
		const diff = method === 'word' ? diffWords(oldText, newText) : diffChars(oldText, newText);

		return {
			added: diff.map((part, i) => {
				if (part.added) {
					return (
						<span className={s.added} key={i}>
							{part.value}
						</span>
					);
				} else if (part.removed) {
					return;
				} else {
					return part.value;
				}
			}),

			removed: diff.map((part, i) => {
				if (part.added) {
					return;
				} else if (part.removed) {
					return (
						<span className={s.removed} key={i}>
							{part.value}
						</span>
					);
				} else {
					return part.value;
				}
			}),
		};
	};

	const compareValues = (key: string) => {
		const res = {
			displayName: propertyNamesMapper(key),
			icon: null,
			comparisonResult: null,
		};

		// занчение может быть false, поэтому проверяем на undefined
		const newValue = newData[key] !== undefined ? newData[key] : null;
		const oldValue = oldData[key] !== undefined ? oldData[key] : null;

		const setupRes = (
			oldValue: JSX.Element | string | Array<JSX.Element | string>,
			newValue: JSX.Element | string | Array<JSX.Element | string>
		) => {
			res.icon = <RiEdit2Line size={18} color={colors.warning} />;
			res.comparisonResult = (
				<Flex gap={16} align="center">
					<div>{oldValue}</div>
					{<RiArrowRightLine size={16} />}
					<div>{newValue}</div>
				</Flex>
			);
		};

		if (newValue && oldValue && typeof newValue !== typeof oldValue) {
			setupRes(oldValue.toString(), newValue.toString());
			return res;
		}

		if (Array.isArray(newValue) && Array.isArray(oldValue)) {
			if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
				const _res = highlightDiff(oldValue.join(', '), newValue.join(', '), 'word');
				setupRes(_res.removed, _res.added);
			} else {
				res.comparisonResult = newValue.join(', ');
			}
			return res;
		}

		if (typeof oldValue === 'boolean' && typeof newValue === 'boolean') {
			if (oldValue !== newValue) {
				const _res = highlightDiff(
					oldValue ? 'истина' : 'ложь',
					newValue ? 'истина' : 'ложь'
				);
				setupRes(_res.removed, _res.added);
				return res;
			} else {
				res.comparisonResult = newValue ? 'истина' : 'ложь';
				return res;
			}
		}

		if (newValue === oldValue) {
			res.comparisonResult = newValue;
			return res;
		}

		if (newValue && !oldValue) {
			res.icon = <RiAddCircleLine size={18} color={colors.success} />;
			res.comparisonResult = newValue.toString();
			return res;
		}

		if (!newValue && oldValue) {
			res.icon = <RiDeleteBin6Line size={18} color={colors.error} />;
			res.comparisonResult = oldValue.toString();
			return res;
		}

		if (newValue && oldValue && oldValue !== newValue) {
			const _res = highlightDiff(oldValue.toString(), newValue.toString());
			setupRes(_res.removed, _res.added);
			return res;
		} else {
			res.comparisonResult = newValue;
			return res;
		}
	};

	useEffect(() => {
		const combinedData = { ...oldData, ...newData };
		const listItems = Object.keys(combinedData).map((x) => compareValues(x));
		setData(listItems);
	}, [oldData, newData]);

	return (
		<div className={s.container}>
			{error ? (
				<Result
					status="error"
					subTitle={JSON.stringify(error)}
					title={t((l) => l.common.error.noStateDifference)}
					style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}
				/>
			) : (
				<List
					style={{ width: '100%' }}
					loading={isLoading}
					header={
						<>
							<div className={s.title}>{_title}</div>
							<div className={s.subtitle}>
								{_date} • {_userName}
							</div>
						</>
					}
					dataSource={data}
					renderItem={(x) => (
						<List.Item>
							<Flex gap={8} align="center">
								<div className={s.nameCell}>{x.displayName}</div>
								<div className={s.iconCell}>{x.icon}</div>
								<div className={s.resultCell}>{x.comparisonResult}</div>
							</Flex>
						</List.Item>
					)}
				/>
			)}
		</div>
	);
};
