import {
	DndContext,
	KeyboardSensor,
	useSensor,
	useSensors,
	closestCorners,
	MouseSensor,
	TouchSensor,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
	useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RiCloseLine, RiDraggable } from '@remixicon/react';
import { Button, Checkbox, Drawer, Flex, GetProp, Spin } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation } from 'react-router';
import { colors } from '../../../styles';
import { containerStyle, drawerSize, extraStyle, RightDrawerStyles } from '../RightDrawer/style';

type IColumnsAdjuster = {
	title: React.ReactNode;
	key: string;
	isColumnVisible?: boolean;
}[];

interface IColumnsAdjusterProps {
	columns: { key: React.Key; title: React.ReactNode }[];
	onChangeColumns: (columns: any[]) => void;
}

const ColumnsAdjusterComponent = forwardRef<any, IColumnsAdjusterProps>((
	{
		columns,
		onChangeColumns,
	},
	ref,
) => {
	const originalColumns = columns;

	const { pathname } = useLocation();

	const [ isOpen, setIsOpen ] = useState<boolean>(false);
	const [ adjustedColumns, setAdjustedColumns ] = useState<IColumnsAdjuster | null>(null);

	useImperativeHandle(ref, () => ({
		open: () => setIsOpen(true),
		close: () => setIsOpen(false),
	}));

	const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
		setAdjustedColumns(
			adjustedColumns.map((item) =>
				checkedValues.includes(item.key)
					? { ...item, isColumnVisible: true }
					: { ...item, isColumnVisible: false },
			),
		);
	};

	const chooseAll = () =>
		setAdjustedColumns(adjustedColumns?.map((item) => ({
			...item,
			isColumnVisible: true,
		})));

	const clearAll = () =>
		setAdjustedColumns(
			adjustedColumns?.map((item) =>
				item.key === 'menu'
					? { ...item, isColumnVisible: true }
					: { ...item, isColumnVisible: false },
			),
		);

	const applyChanges = () => {
		localStorage.setItem(
			pathname,
			JSON.stringify({
				...JSON.parse(localStorage.getItem(pathname)),
				columnSettings: adjustedColumns,
			}),
		);

		const visibleColumns = adjustedColumns?.filter((item) => item.isColumnVisible);

		onChangeColumns(visibleColumns);
	};

	const getTaskPos = (key) => adjustedColumns.findIndex((item) => item.key === key);

	const handleDragEnd = (ev) => {
		const { active, over } = ev;

		if (active.id === over.id) {
			return;
		}

		setAdjustedColumns((items) => {
			const originalPos = getTaskPos(active.id);
			const newPos = getTaskPos(over.id);

			return arrayMove(items, originalPos, newPos);
		});
	};

	const ListItem = (props: { id: string; title: React.ReactNode }) => {
		const { id, title } = props;

		const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

		const style = {
			border: `solid thin ${
				adjustedColumns?.find((item) => item.key === id)?.isColumnVisible
					? colors.primary
					: colors.grayTableBorder
			}`,
			borderRadius: '6px',
			padding: '12px',
			backgroundColor: `${colors.whiteBase}`,
			touchAction: 'none',
			transition,
			transform: CSS.Transform.toString(transform),
		};

		return (
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				<Flex justify="space-between" align="center">
					<Checkbox value={id}>{title}</Checkbox>

					<RiDraggable
						size={20}
						color={colors.gray}
						style={{ transform: 'rotate(90deg)', cursor: 'grab' }}
					/>
				</Flex>
			</div>
		);
	};

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 200,
				tolerance: 6,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	useEffect(() => {
		const localColumnSetting = JSON.parse(localStorage.getItem(pathname))?.columnSettings;

		const localKeys = localColumnSetting?.map((item) => item.key).sort() ?? [];
		const originalKeys = originalColumns?.map((item) => item.key).sort() ?? [];
		const isEqual = localKeys.every((key, idx) => originalKeys?.[idx] === key);

		const initialAdjustedColumns =
			isEqual && localColumnSetting
				? localColumnSetting
				: originalColumns?.map((item) => ({
					...item,
					key: String(item.key),
					title: item.title,
					isColumnVisible: true,
				}));

		setAdjustedColumns(initialAdjustedColumns);

		onChangeColumns(initialAdjustedColumns?.filter((item) => item.isColumnVisible));
	}, [ originalColumns ]);

	const onClose = () => {
		setIsOpen(false);
	};

	return (
		<div style={containerStyle}>
			<Drawer title="Настройка таблицы"
					placement="right"
					closable={false}
					open={isOpen}
					getContainer={false}
					mask={false}
					maskClosable={false}
					styles={RightDrawerStyles}
					size={drawerSize}
					extra={
						<Flex align="center" style={extraStyle}>
							<RiCloseLine size={16} onClick={onClose}/>
						</Flex>
					}
			>
				{!originalColumns || !adjustedColumns ? (
					<Flex>
						<Spin/>
					</Flex>
				) : (
					<Flex vertical gap={8}>
						<Flex justify="space-between">
							<Button type="text" onClick={chooseAll}>
								Выбрать все
							</Button>
							<Button type="text" onClick={clearAll}>
								Очистить выбор
							</Button>
						</Flex>
						<Checkbox.Group
							onChange={onChange}
							value={adjustedColumns
								?.filter((item) => item.isColumnVisible)
								.map((item) => item.key)}
						>
							<DndContext
								sensors={sensors}
								collisionDetection={closestCorners}
								onDragEnd={handleDragEnd}
								modifiers={[ restrictToVerticalAxis ]}
							>
								<Flex gap={12} vertical style={{ width: '100%' }}>
									<SortableContext
										items={adjustedColumns.map((x) => x.key)}
										strategy={verticalListSortingStrategy}
									>
										{adjustedColumns?.map((item) => (
											<ListItem id={item.key} title={item.title} key={item.key}/>
										))}
									</SortableContext>
								</Flex>
							</DndContext>
						</Checkbox.Group>

						<Button type="primary" onClick={applyChanges}>
							Применить
						</Button>
					</Flex>
				)}
			</Drawer>
		</div>
	);
});

export const ColumnsAdjuster = memo(ColumnsAdjusterComponent);
