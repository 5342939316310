import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { AddNewNestedGroup, DropdownLink } from 'shared/ui';
import { useAddNewCatalog } from './AddNewCatalog.model';

interface IAddNewCatalogProps {
	currentCatalogId?: string;
}

const AddNewCatalogUi: React.FC<IAddNewCatalogProps> = ({ currentCatalogId }) => {
	const { t } = useTypedTranslation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
	const nameValue = Form.useWatch('displayName', form);
	const { loading, error, addNewCatalog } = useAddNewCatalog();

	const onFinish = async ({ displayName, desc }) => {
		await addNewCatalog(displayName, desc, currentCatalogId && currentCatalogId);
		if (!error) {
			form.resetFields();
			setIsModalOpen(false);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			{currentCatalogId ? (
				<DropdownLink
					icon={<AddNewNestedGroup size={16} />}
					title={t(l => l.catalogs.groups.createNestedGroup)}
					onClick={showModal}
				/>
			) : (
				<Button
					icon={<RiAddCircleLine />}
					style={{ maxWidth: 'fit-content' }}
					onClick={showModal}
				>
					{t(l => l.catalogs.groups.addNewCatalog)}
				</Button>
			)}

			<Modal
				title="Добавить новый справочник"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item name="displayName" label="Наименование справочника" required>
						<Input />
					</Form.Item>
					<Form.Item name="desc" label="Описание">
						<Input />
					</Form.Item>

					<Form.Item>
						<Flex gap={4} justify="flex-end">
							<Button type="text" onClick={handleCancel}>
								{t(l => l.common.buttons.cancel)}
							</Button>
							<Button
								htmlType="submit"
								type="primary"
								loading={loading}
								disabled={!nameValue}
							>
								{t(l => l.common.buttons.create)}
							</Button>
						</Flex>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export const AddNewCatalog = React.memo(AddNewCatalogUi);
