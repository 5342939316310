import React from 'react';
import { useGetClassifiersQuery } from '../../api/generatedApi/mdmgApi';
import { FormInputProps } from '../../types';
import { DropdownSelect } from '../../ui';

type ISelectClassifierInputProps = FormInputProps<string | null>;

export const SelectClassifierInput = ({ value, onChange }: ISelectClassifierInputProps) => {
	const { data: classifiers } = useGetClassifiersQuery({});

	return (
		<DropdownSelect
			value={value}
			onChange={onChange}
			options={classifiers?.map((x) => ({
				label: x.displayName,
				value: x.id,
			}))}
		/>
	);
};
