import { RiLayoutGridLine, RiTable3 } from '@remixicon/react';
import { Button, Flex, Result, Skeleton, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClassifiersCardsList, ClassifiersTable } from 'features/classifiers';
import { TaskType, useRealtimeEvent } from 'entities/events';
import { ClassifierResponse, mdmgApi, useGetClassifiersQuery } from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/newCreateApiFile';
import { useGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { useAppDispatch } from 'shared/hooks';
import { CardsSorter, Placeholder } from 'shared/ui';

const ClassifiersWidgetUi: React.FC = () => {
	const dispatch = useAppDispatch();

	const [ tableView, setTableView ] = useState<boolean>(false);
	const [ data, setData ] = useState<Array<ClassifierResponse>>([]);

	const [ searchParams ] = useSearchParams();
	const searchValue = searchParams.get('searchValue');
	const isExistSearchValue = searchValue && searchValue.length > 0;

	const {
		data: classifiers,
		isLoading: isClassifiersLoading,
		isFetching: isClassifiersFetching,
		error: classifiersError,
	} = useGetClassifiersQuery(
		{},
		{
			skip: isExistSearchValue,
		});

	const {
		data: classifiersFromSearch,
		isLoading: isClassifiersFromSearchLoading,
		isFetching: isSearchFetching,
		error: classifiersFromSearchError,
	} = useGetSearchResultQuery({
		entityType: 'classifier',
		textRequest: searchValue,
	}, {
		skip: !isExistSearchValue,
	});

	const handler = () => {
		dispatch(mdmgApi.util.invalidateTags([ 'Classifier' ]));
		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
	};
	useRealtimeEvent(TaskType.UPDATE_CLASSIFIER_BACKGROUND_TASK, handler);
	useRealtimeEvent(TaskType.DELETE_CLASSIFIER_BACKGROUND_TASK, handler);

	const handleSetTable = () => setTableView(true);
	const handleSetGrid = () => setTableView(false);

	useEffect(() => {
		if (isExistSearchValue && classifiersFromSearch) {
			setData(classifiersFromSearch as Array<ClassifierResponse>);
		} else if (!isExistSearchValue && classifiers) {
			setData(classifiers);
		}
	}, [ classifiers, classifiersFromSearch, isExistSearchValue ]);

	const isLoading = isExistSearchValue ? isClassifiersFromSearchLoading : isClassifiersLoading;
	const isFetching = isExistSearchValue ? isSearchFetching : isClassifiersFetching;
	const error = isExistSearchValue ? classifiersFromSearchError : classifiersError;

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={24}>
				<Flex vertical gap={12}>
					<Flex vertical gap={4}>
						<Flex gap={4} style={{ alignSelf: 'flex-end' }}>
							<Button
								icon={<RiLayoutGridLine/>}
								type={tableView ? 'text' : 'primary'}
								onClick={handleSetGrid}
							/>
							<Button
								icon={<RiTable3/>}
								type={tableView ? 'primary' : 'text'}
								onClick={handleSetTable}
							/>
						</Flex>
						<Flex justify="space-between">
							<Typography.Title level={2}>
								Перечень классификаторов
							</Typography.Title>

							{!tableView && (
								<CardsSorter<ClassifierResponse>
									defaultState={classifiers}
									sorterProps={[
										{ sortType: 'alphabet', sortField: 'displayName' },
									]}
									callback={setData}
								/>
							)}
						</Flex>
					</Flex>

					<Spin spinning={isFetching && !isLoading}>
						<Flex gap={12} wrap="wrap">
							{isLoading && (
								<>
									<Skeleton
										avatar
										active
										paragraph={{ rows: 3 }}
										style={{ maxWidth: 325 }}
									/>
									<Skeleton
										avatar
										active
										paragraph={{ rows: 3 }}
										style={{ maxWidth: 325 }}
									/>
									<Skeleton
										avatar
										active
										paragraph={{ rows: 3 }}
										style={{ maxWidth: 325 }}
									/>
								</>
							)}

							{!isLoading && error && (
								<Result
									title={'Ошибка при получении групп классификаторов!'}
									subTitle={`Текст ошибки: ${JSON.stringify(error)}`}
								/>
							)}

							{!isLoading && !error && (!data || data.length === 0) && (
								<Flex style={{ width: '100%' }} justify="center">
									<Placeholder
										title={
											searchValue
												? 'По вашему запросу ничего не найдено'
												: 'Вы не добавили еще ни одного классификатора'
										}
										subTitle={
											searchValue
												? 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить'
												: 'Для создания нового классификатора нажмите на кнопку «Добавить классификатор»'
										}
										isSearchIcon={!!searchValue}
									/>
								</Flex>
							)}


							{!tableView && !isLoading && !error && data && (
								<ClassifiersCardsList items={data}/>
							)}

							{tableView && !isLoading && !error && data && (
								<ClassifiersTable items={data}/>
							)}
						</Flex>
					</Spin>

				</Flex>
			</Flex>
		</Flex>
	);
};

export const ClassifiersListWidget = React.memo(ClassifiersWidgetUi);
