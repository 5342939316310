import { Flex, Result, Spin, Typography } from 'antd';
import React, { memo, useMemo } from 'react';
import { IntegrationCard, IntegrationCardSkeleton } from 'features/integrations';
import { useGetConnectionsListQuery, useGetSupportedSystemsQuery } from 'shared/api/generatedApi/integrationsApi';
import { useTypedTranslation } from 'shared/hooks';
import {
	FilterButtonType,
	useIntegrationsListFilter,
} from './useIntegrationsListFilter';

export const IntegrationsList = memo(() => {
	const { t } = useTypedTranslation();
	const [ activeButton ] = useIntegrationsListFilter();

	const { data, isLoading, isFetching, error } = useGetConnectionsListQuery({
		reverse: false,
		...(activeButton !== FilterButtonType.all && {
			filterKey: activeButton === FilterButtonType.active ? 'is_active' : 'is_failed',
			filterValue: 'true',
		}),
		pageSize: 1000,
	});

	const {
		data: systems,
		error: systemsErr,
		isLoading: systemsLoading,
	} = useGetSupportedSystemsQuery({
		page: 1,
		pageSize: 10,
	});

	const groupedData = useMemo(() => {
		if (!data?.data) {
			return {};
		}

		return Object.fromEntries(
			Object.entries<Array<any>>(
				data.data.reduce((acc, item) => {
					const sourceType = item.connection_info.source_type_id;
					if (!acc[sourceType]) {
						acc[sourceType] = [];
					}
					acc[sourceType].push({
						id: item.connection_id,
						systemId: item.connection_info.source_type_id,
						connectionInfo: item.connection_info,
					});
					return acc;
				}, {}))
				.map(([ key, value ]) => ([
					key,
					value.sort((x, y) => Number(y.connectionInfo.is_pinned) - Number(x.connectionInfo.is_pinned)),
				])),
		);
	}, [ data?.data ]);

	if (systemsLoading || isLoading) {
		return (
			<IntegrationCardSkeleton/>
		);
	}

	if (error || systemsErr) {
		return (
			<Result
				status="error"
				title={t(l => l.integrations.errors.listError)}
				subTitle={JSON.stringify(error)}
			/>
		);
	}

	return (
		<Spin spinning={isFetching}>
			<Flex vertical gap={12}>
				{systems?.data.map((system) => {
					const items = groupedData[system.system_id];
					return items?.length > 0 && (
						<Flex key={system.system_id} vertical gap={12}>
							<Typography.Title level={2}>
								{system.system_name}
							</Typography.Title>
							<Flex wrap={'wrap'} gap={12}>
								{items.map((item) => (
									<IntegrationCard key={item.id}
													 {...item}
									/>
								))}
							</Flex>
						</Flex>
					);
				})}
			</Flex>
		</Spin>
	);
});