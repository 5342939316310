import { Flex, Tooltip } from 'antd';
import React, { memo } from 'react';

interface FoldedListItemProps {
	items: (string | JSX.Element)[];
}

const FoldedListItems = memo(({
								  items,
							  }: FoldedListItemProps) => {
	if (items.length === 0) {
		return;
	}

	const InnerList = () => (
		<Flex vertical={true}>
			{items
				.filter((_, idx) => idx != 0)
				.map((item, idx) => (
					<span key={idx} style={{
						fontWeight: '500',
						lineHeight: 2,
					}}>
						{item}
					</span>
				))
			}
		</Flex>);

	return (
		<Flex gap={4}>
			<div>
				{items[0]}
			</div>

			{items.length > 1 && (
				<Tooltip color={'white'}
						 title={InnerList}
				>
					<Flex align={'center'}
						  justify={'center'}
						  style={{
							  width: 24,
							  height: 16,
							  borderRadius: 3,
							  overflow: 'hidden',
							  border: '1px #E97B2D solid',
							  wordWrap: 'break-word',
							  color: '#E97B2D',
							  fontSize: 10,
							  fontWeight: '700',
							  lineHeight: 13,
						  }}>
						+{items.length - 1}
					</Flex>
				</Tooltip>
			)}
		</Flex>
	);
});

export {
	FoldedListItems,
};