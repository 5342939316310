import { Flex, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ClassifierItemsTableWidget } from 'widgets/Classifiers';
import { ClassifierItemCatalogItemsListWidget } from 'widgets/Classifiers/ClassifierItemCatalogItemsListWidget';
import { ClassifierItemTreeWidget } from 'widgets/Classifiers/ClassifierItems/ClassifierItemsTree';
import { Transactions } from 'widgets/Transactions';
import { ClassifierLinkAttributesFilter } from 'features/classifiers/ClassifierLinkAttributesFilter';
import { AddClassifierRecord } from 'features/classifiers/ClassifierRecords/AddClassifierRecord';
import { SearchInnerClassifiersItems } from 'features/classifiers/SearchInnerClassifiersItems';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { useGetCurrentClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.model';
import { removeCurrentClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.store';
import { routes } from 'shared/config';
import { changeSearchParam } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { SubSider } from 'shared/ui';

const ClassifierListPageUi: React.FC = () => {
	const dispatch = useAppDispatch();

	const { classifierGroupId, classifierItemId } = useParams();
	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ selectedAttribute, setSelectedAttribute ] = useState<string | null>(null);

	const { currentClassifierGroup, loading } = useGetCurrentClassifierGroup();

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Перечень классификаторов',
				url: `${routes.classifiers.main}`,
				child: {
					displayName: 'Перечень записей классификатора',
					url: `/${routes.classifiers.main}/${currentClassifierGroup?.id}/${routes.classifiers.records}`,
				},
			}),
		);

		return () => {
			dispatch(removeCurrentClassifierGroup());
		};
	}, []);

	const onChangeSelectedAttribute = (attributeId: string) => {
		setSearchParams(
			changeSearchParam(searchParams, 'attributeId', attributeId),
		);
	};

	useEffect(() => {
		setSelectedAttribute(searchParams.get('attributeId'));
	}, [ searchParams.get('attributeId') ]);

	return (
		<div>
			{loading && (
				<Flex vertical align="center" justify="center">
					<Spin size="large"/>
				</Flex>
			)}
			{currentClassifierGroup && (
				<Flex vertical gap={24}>
					<Flex justify="space-between">
						<Typography.Title level={1}>
							{currentClassifierGroup?.displayName}
						</Typography.Title>
						<Transactions/>
					</Flex>
					<SubSider>
						<ClassifierItemTreeWidget/>
					</SubSider>

					<Flex vertical gap={24}>

						{classifierItemId && <SearchInnerClassifiersItems/>}

						<AddClassifierRecord parentId={classifierItemId} isButton/>

						<ClassifierLinkAttributesFilter attributes={currentClassifierGroup.linkClassifierAttributes}
														selectedAttribute={selectedAttribute}
														onChangeSelectedAttribute={onChangeSelectedAttribute}
						/>

						{selectedAttribute && (
							<ClassifierItemCatalogItemsListWidget classifier={currentClassifierGroup}
																  classifierItemId={classifierItemId}
																  attributeId={selectedAttribute}
							/>
						)}
						{!selectedAttribute && (
							<ClassifierItemsTableWidget classifierId={classifierGroupId}
														classifierItemId={classifierItemId}
							/>
						)}
					</Flex>
				</Flex>
			)}
		</div>
	);
};

export const ClassifierListPage = React.memo(ClassifierListPageUi);
