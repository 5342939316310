import { RiAddCircleLine } from '@remixicon/react';
import { App, Button } from 'antd';
import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { addNewClassifierGroup, setCurrentClassifier } from 'entities/classifiers/classifiersGroups/classifier.store';
import { useCreateClassifierMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

const CreateClassifierGroup = memo(() => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { notification } = App.useApp();

	const [ createGroup, { isLoading } ] = useCreateClassifierMutation();

	const create = async () => {
		await createGroup({
			createClassifierRequest: { displayName: '', linkClassifierAttributes: [] },
		})
			.unwrap()
			.then((res) => {
				dispatch(addNewClassifierGroup(res));
				dispatch(setCurrentClassifier(res));
				navigate(`${res.id}`);
			})
			.catch((e) => {
				errorHelper(
					t((l) => l.classifier.error.newClassifierCreation),
					e,
					notification,
				);
			});
	};

	return (
		<Button
			icon={<RiAddCircleLine/>}
			style={{ maxWidth: 'fit-content' }}
			onClick={create}
			loading={isLoading}
		>
			{t((l) => l.classifier.action.addClassifier)}
		</Button>
	);
});

export {
	CreateClassifierGroup,
};
