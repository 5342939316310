import { DataNode } from 'antd/lib/tree';
import { useMemo } from 'react';

type TreeNode = DataNode;

const useConvertToTree = (flatData: Array<TreeNode & { id: string | number, pId?: string | number | null }>) => {
	return useMemo<Array<TreeNode>>(() => {
		const tree: TreeNode[] = [];
		const childrenMap: { [key: number]: TreeNode[] } = {};

		flatData.map(item => {
			const { id, pId } = item;
			if (!childrenMap[id]) {
				childrenMap[id] = [];
			}
			const node: TreeNode = {
				...item,
				children: childrenMap[id],
			};

			if (pId == null) {
				tree.push(node);
			} else {
				if (!childrenMap[pId]) {
					childrenMap[pId] = [];
				}
				childrenMap[pId].push(node);
			}
		});

		return tree;
	}, [ flatData ]);
};

export {
	useConvertToTree,
};