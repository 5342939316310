import { RiEditLine } from '@remixicon/react';
import React from 'react';
import { useNavigate } from 'react-router';
import { DeleteCatalogRecordUi } from 'features/catalogs/CatalogRecords/DeleteCatalogRecord';
import {
	CatalogAttributeDeclarationResponse,
	CatalogItemResponse,
	ClassifierItemResponse,
} from 'shared/api/generatedApi/mdmgApi';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { ItemValuesType } from 'shared/helpers/types';
import { useTypedTranslation } from 'shared/hooks';
import { Chip, DropdownLink, FoldedListItems } from 'shared/ui';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import ItemActions from 'shared/ui/components/ItemActions';
import i18next from '../../../i18n';

export type ICatalogRecord = {
	id: string;
	status: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;

export interface IRecordValue {
	[key: string]: {
		attributeId: string;
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

export const chipTitle = (str: string): string => {
	switch (str) {
		case 'NORMALIZED':
			return i18next.t('common.statuses.normalized');

		case 'NON_NORMALIZED':
			return i18next.t('common.statuses.nonNormalized');

		case 'BLOCKED':
			return i18next.t('common.statuses.blocked');

		case 'DELETED':
			return i18next.t('common.statuses.deleted');

		case 'DUPLICATE':
			return i18next.t('common.statuses.dupe');

		default:
			'';
	}
};

export const useRecordsDtoToTableMapper = () => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const convertValues = (
		values: IRecordValue,
		attributeDeclarations: CatalogAttributeDeclarationResponse[],
		relatedClassifierItems: ClassifierItemResponse[] | null,
		relatedCatalogItems: CatalogItemResponse[] | null,
	): IMappedRecordValue => {
		return Object.fromEntries(
			attributeDeclarations
				.map((declaration) => {
					const { id, attribute } = declaration;
					const value = values[id]?.value;

					if (
						attribute.type === CellTypesEnum.RELATION &&
						value
					) {
						const isRelationToClassifier = (attribute.restrictions.scopeIds as string[])?.length > 0;
						const ids = [ value as unknown as string ].flat();
						const items = isRelationToClassifier
							? relatedClassifierItems
							: relatedCatalogItems;
						const displayNames = items
							?.filter(({ id }) => ids.includes(id))
							.map(({ displayName }) => displayName.length > 0
								? displayName
								: t((l) => l.common.defaultNames.emptyName),
							) ?? [];

						return [
							attribute.id,
							<FoldedListItems key={attribute.id} items={displayNames}/>,
						];
					} else {
						return [
							attribute.id,
							CellValueParser(
								value,
								declaration.attribute.type as CellTypesEnum,
								attribute.list
							),
						];
					}
				}));
	};

	const mapRecordsDtoToTable = (
		recordsDto: CatalogItemResponse[] | null,
		attributes: CatalogAttributeDeclarationResponse[],
		relatedClassifierItems: ClassifierItemResponse[] | null,
		relatedCatalogItems: CatalogItemResponse[] | null,
	): ICatalogRecords => {
		if (!recordsDto) {
			return null;
		}
		return recordsDto
			.map((item) => ({
				id: item.id,
				key: item.id,
				catalogId: item.catalogId,
				status: (
					<Chip status={item.status.toLowerCase() as ChipStatus}>
						{chipTitle(item.status)}
					</Chip>
				),
				displayName: <div dangerouslySetInnerHTML={{ __html: String(item.displayName) }} />,
				menu: (
					<ItemActions
						items={[
							{
								key: 'edit',
								label: (
									<DropdownLink
										icon={<RiEditLine size={16}/>}
										title={t((l) => l.common.buttons.edit)}
										onClick={() =>
											navigate(
												`${window.location.pathname}/record/${item.id}`,
											)
										}
									/>
								),
							},
							{
								key: 'delete',
								label: <DeleteCatalogRecordUi recordId={item.id}/>,
							},
						]}
					/>
				),
				...convertValues(item?.values as IRecordValue, attributes, relatedClassifierItems, relatedCatalogItems),
			}));
	};

	return {
		mapRecordsDtoToTable,
	};
};