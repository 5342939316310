import { RiCheckboxCircleLine } from '@remixicon/react';
import { App, Button, Flex, Form, Input, Result, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	ConfigurationStatus,
	ExecutionType, RelationalMetadata, S3Metadata, TopicMetadata,
	useGetConfigurationQuery,
	useGetMetadataQuery,
	useUpdateConfigurationMutation,
} from 'shared/api/generatedApi/integrationsApi';
import { SelectCatalogInput } from 'shared/components/SelectCatalogInput';
import { errorHelper } from 'shared/helpers';
import { DropdownSelect } from 'shared/ui';

const executionTypeOpts: Array<{ label: string; value: ExecutionType }> = [
	{
		label: 'По расписанию',
		value: 'scheduled',
	},
	{
		label: 'Ручной',
		value: 'manual',
	},
	{
		label: 'Ручной немедленно',
		value: 'manual_immediate',
	},
];

const StatusLabel = ({ title, color } : { title: string, color: string }) => {
	return (
		<Flex align={'center'} gap={8}>
			<div style={{ width: 8, height: 8, borderRadius: '50%', background: color }}></div>
			<span>{title}</span>
		</Flex>
	);
};

const statusOpts: Array<{ label: React.ReactElement; value: ConfigurationStatus }> = [
	{
		label: <StatusLabel title="Остановлен" color={'var(--error)'}/>,
		value: 'inactive',
	},
	{
		label: <StatusLabel title="Выполняется" color={'var(--success)'}/>,
		value: 'active',
	},
];

const onDeleteActionOptions = [
	{
		label: 'Foo',
		value: 'foo',
	},
	{
		label: 'Bar',
		value: 'bar',
	},
	{
		label: 'Baz',
		value: 'baz',
	}
];

interface IEditExchangeConfigInfoProps {
	integrationId: string;
	exchangeConfigId?: string;
	onUpsert?: (exchangeConfigId: string) => void;
}

const EditExchangeConfigInfo = React.memo((
	{
		integrationId,
		exchangeConfigId,
		onUpsert,
	}: IEditExchangeConfigInfoProps,
) => {
	const { notification } = App.useApp();

	const [ form ] = Form.useForm();

	const executionType = Form.useWatch('execution_type', form);

	const [ extractionTargetOptions, setExtractionTargetOptions ] = useState<any[] | null>();
	
	const { data, isLoading, error } = useGetConfigurationQuery(
		{
			connectionId: integrationId,
			configurationId: exchangeConfigId,
		},
		{
			skip: !exchangeConfigId,
		},
	);

	const { data: metadata } = useGetMetadataQuery({
		connectionId: integrationId,
	});

	const [ patch ] = useUpdateConfigurationMutation();

	const onFinish = async (v) => {
		const { execution_type, schedule_interval, ...rest } = v;

		try {
			await patch({
				connectionId: integrationId,
				configurationId: exchangeConfigId,
				exchangeConfigurationRequest: {
					...rest,
					activation_mode: {
						...data.activation_mode,
						execution_type,
						schedule_interval,
					},
				},
			})
				.unwrap();
			onUpsert && onUpsert(exchangeConfigId);
		} catch (err) {
			errorHelper(
				'Ошибка при обновлении полей формы!',
				err,
				notification,
			);
		}
	};

	useEffect(() => {
		if (data) {
			const { execution_type, schedule_interval } = data.activation_mode;
			form.setFieldsValue({ ...data, execution_type, schedule_interval });
		}
	}, [ data ]);

	useEffect(() => {
		if (!metadata) return;
		if (metadata.source_type === 'postgres') {
			const { tables } = metadata.metadata as RelationalMetadata;
			setExtractionTargetOptions(Object.keys(tables).map((key) => ({
				label: key,
				value: key,
			})));
		} else if (metadata.source_type === 'kafka') {
			const { topics } = metadata.metadata as TopicMetadata;
			setExtractionTargetOptions(topics.map((key) => ({
				label: key,
				value: key,
			})));
		} else if (metadata.source_type === 's3') {
			const { buckets } = metadata.metadata as S3Metadata;
			setExtractionTargetOptions(buckets.map((key) => ({
				label: key,
				value: key,
			})));
		}
	}, [ metadata ]);

	if (error) {
		return <Result status="error" title="Ошибка!" subTitle={JSON.stringify(error)}/>;
	}

	if (isLoading) {
		return (
			<Flex vertical style={{ width: '100%' }} gap={12}>
				<Skeleton.Input active style={{ width: '100%' }}/>
				<Skeleton.Input active style={{ width: '100%' }}/>
				<Skeleton.Input active style={{ width: '100%' }}/>
				<Skeleton.Input active style={{ width: '100%' }}/>
			</Flex>
		);
	}

	return (
		<Form layout="vertical" form={form} onFinish={onFinish}>
			<Typography.Title level={1} style={{ marginBottom: 12 }}>
				Настройки конфигурации обмена
			</Typography.Title>
			<Form.Item name="name" label="Наименование конфигурации" required>
				<Input/>
			</Form.Item>
			<Form.Item name="description" label="Описание">
				<Input/>
			</Form.Item>
			<Form.Item name="on_delete_action"
					   label="Действие при удалении позиции во внешней системе"
			>
				<DropdownSelect options={onDeleteActionOptions}/>
			</Form.Item>
			<Form.Item name="status" label="Статус">
				<DropdownSelect options={statusOpts}/>
			</Form.Item>
			<Form.Item name="execution_type" label="Режим импорта позиций">
				<DropdownSelect options={executionTypeOpts}/>
			</Form.Item>
			{executionType === 'scheduled' && (
				<Form.Item name="schedule_interval"
					   label="Регулярное выражение для настройки расписания обмена"

				>
					<Input placeholder="Введите CRON-выражение"/>
				</Form.Item>
			)}
			<Typography.Title level={2} style={{ marginBottom: 12 }}>
				Настройки маппинга
			</Typography.Title>
			<Form.Item name="destination"
					   label="Справочник, в который будут импортированы позиции"
			>
				<SelectCatalogInput/>
			</Form.Item>
			<Form.Item name="extraction_targets" label="Источник данных" required>
				<DropdownSelect mode="multiple"
								options={extractionTargetOptions}
				/>
			</Form.Item>
			<Button
				icon={<RiCheckboxCircleLine/>}
				style={{ width: '100%' }}
				htmlType="submit"
			>
				Подтвердить выбранный источник данных
			</Button>
		</Form>
	);
});

export {
	EditExchangeConfigInfo,
};
