import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';
import { useTypedTranslation } from 'shared/hooks';

interface ICreateCompatibility {
    entityId: string;
}

export const CreateCompatibilityButton = ({ entityId }: ICreateCompatibility) => {

    const navigate = useNavigate();
    const { t } = useTypedTranslation();

    const onClick = () => {
        //  todo: отрефакторить
        if (window.location.href.includes('classifiers') && window.location.href.includes('records')) {
            return navigate(`/${routes.compatibility.main}/create/CLASSIFIER_ITEM/${entityId}`);
        } else if (window.location.href.includes('catalogs') && window.location.href.includes('record')) {
            return navigate(`/${routes.compatibility.main}/create/CATALOG_ITEM/${entityId}`);
        } else if (window.location.href.includes('classifiers')) {
            return navigate(`/${routes.compatibility.main}/create/CLASSIFIER/${entityId}`);
        } else if (window.location.href.includes('catalogs')) {
            return navigate(`/${routes.compatibility.main}/create/CATALOG/${entityId}`);
        }
    };

    return (
        <Button
            type="default"
            icon={<RiAddCircleLine />}
            onClick={onClick}
        >
            {t((l) => l.compatibilities.actions.add.btnTitle)}
        </Button>
    );
};
