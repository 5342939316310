import { Flex } from 'antd';
import cn from 'classnames';
import React from 'react';
import { ConfigurationStatus } from 'shared/api/generatedApi/integrationsApi';
import s from './statusBadge.module.scss';

interface IStatusBadgeProps {
	status: ConfigurationStatus
}

const statusNames: Record<ConfigurationStatus, string> = {
	active: 'Выполняется',
	inactive: 'Остановлена'
};

const StatusBadge = React.memo(({ status }: IStatusBadgeProps) => {
	return (
		<Flex justify={'center'}
			  align={'center'}
			  className={cn([
				  s.badge,
				  s[status],
			  ])}
		>
			{statusNames[status]}
		</Flex>
	);
});

export {
	StatusBadge
};