import { RiDeleteBin2Line } from '@remixicon/react';
import { App, Button, Checkbox, Flex, Form, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { TaskType, useAsyncOperation } from 'entities/events';
import {
	deleteMetaAttributesGroup,
	removeCurrentAttributeGroup,
} from 'entities/metadata/attributesGroups/attributesGroups.store';
import { useDeleteAttributeGroupAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { useAppDispatch } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

interface IDeleteAttributeGroupProps {
	groupId: string;
	currentGroupId: string;
}

const DeleteAttributeGroupUi: React.FC<IDeleteAttributeGroupProps> = ({ groupId, currentGroupId }) => {
	const { notification } = App.useApp();
	const { t } = useTypedTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const { execute } = useAsyncOperation(
		useDeleteAttributeGroupAsyncMutation,
		TaskType.DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK
	);

	const locationGroupId = () => {
		if (currentGroupId && currentGroupId !== groupId) {
			return currentGroupId;
		}
		return '';
	};

	const onFinish = async () => {
		try {
			setLoading(true);
			await execute({
				ids: [groupId],
			});
			setIsChecked(false);
			setIsModalOpen(false);
			dispatch(deleteMetaAttributesGroup(groupId));
			if (currentGroupId === groupId) {
				dispatch(removeCurrentAttributeGroup());
			}
			navigate(`/${routes.metadata.main}/${routes.metadata.group}/${locationGroupId()}`);
		} catch (error) {
			errorHelper(
				t((l) => l.attributeGroup.deleteAttributeGroup.error),
				error,
				notification
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBin2Line size={16} />}
				title={t((l) => l.common.buttons.delete)}
				onClick={() => setIsModalOpen(true)}
			/>

			<Modal
				title={t((l) => l.attributeGroup.deleteAttributeGroup.title)}
				open={isModalOpen}
				onOk={() => setIsModalOpen(false)}
				onCancel={() => setIsModalOpen(false)}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							{t((l) => l.attributeGroup.deleteAttributeGroup.alert)}
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
							{t((l) => l.attributeGroup.deleteAttributeGroup.agreement)}
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={() => setIsModalOpen(false)}>
							{t((l) => l.common.buttons.cancel)}
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							{t((l) => l.common.buttons.delete)}
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteAttributeGroup = React.memo(DeleteAttributeGroupUi);
