import { Checkbox, Flex, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DetailCard } from 'shared/ui';
import s from './TransactionsList.module.scss';

export type ITransaction = {
	id: string;
	displayName?: string;
	createdAt?: string;
	updatedAt?: string;
	committedAt?: string;
};

interface ITransactionsList {
	transactions: ITransaction[];
	onChange: (ids: string[]) => void;
	onClick: (id: string) => void;
}

export const TransactionsList: React.FC<ITransactionsList> = ({
	transactions,
	onChange,
	onClick,
}) => {
	const [sortedTransactions, setSortedTransactions] = useState<ITransaction[]>([]);
	const [ids, setIds] = useState<string[]>([]);
	const [id, setId] = useState<string>('');
	const { t } = useTypedTranslation();

	const handlerClick = (e: React.MouseEvent, transactionId: string) => {
		const newId = transactionId !== id ? transactionId : '';
		setId(newId);
		onClick(newId);
	};

	const handlerChange = (e: CheckboxChangeEvent) => {
		let newIds: string[];

		if (e.target.checked) {
			newIds = [...ids, e.target.name];
			setIds(newIds);
		} else {
			newIds = ids.filter((x) => x !== e.target.name);
			setIds(newIds);
		}
		onChange(newIds);
	};

	useEffect(() => {
		if (!transactions) return;

		const sortedList = !transactions.length
			? []
			: [...transactions]?.sort((a, b) => {
					return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
				});

		setSortedTransactions(sortedList);
	}, [transactions]);

	return (
		<div className={s.container}>
			<Flex vertical gap={0}>
				{sortedTransactions.map((x) => (
					<div
						className={cn(
							sortedTransactions.length === 1 ? [s.item, s.single] : [s.item]
						)}
						key={x.id}
					>
						<DetailCard
							onClick={(e) => handlerClick(e, x.id)}
							className={cn([x.id === id ? s.selected : ''])}
							hoverable={x.id !== id}
							secondSection={
								<Flex vertical gap={12}>
									<Flex vertical gap={4}>
										<Typography.Paragraph>{x.displayName}</Typography.Paragraph>
										<Typography.Paragraph>
											<span style={{ color: colors.grayIcon }}>
												{new Date(x.updatedAt).toLocaleString('ru-RU')} •{' '}
												{'userName' in x
													? (x.userName as string)
													: t((l) => l.common.defaultNames.emptyName)}
											</span>
										</Typography.Paragraph>
									</Flex>
									<div className={s.stopBubbling}>
										<Checkbox
											name={x.id}
											onClick={(e) => e.stopPropagation()}
											onChange={handlerChange}
											checked={ids.includes(x.id)}
										>
											<span onClick={(e) => e.stopPropagation()}>
												{t((l) => l.transactions.addToCompareTitle)}
											</span>
										</Checkbox>
									</div>
								</Flex>
							}
						/>
					</div>
				))}
			</Flex>
		</div>
	);
};
