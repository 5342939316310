import { App, Flex, Form } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { EditRecordStatus } from 'features/catalogs/CatalogRecords/EditRecordStatus/EditRecordStatus';
import { UnitConverter } from 'features/catalogs/UnitConverter';
import { useMeasurementUnitConversionCache } from 'entities/metadata/measures';
import {
	mdmgApi,
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogItemQuery,
	useGetCatalogRestrictionTableDeclarationsQuery,
	useLazyConvertQuery,
	useLazyGetRestrictionTableItemsQuery,
	useUpdateCatalogItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper, mapValueForDto } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { mapValueForForm } from 'shared/helpers/mapValueForForm';
import {
	useAppDispatch,
	useTypedTranslation,
	useHandleQueryError,
} from 'shared/hooks';

export type RecordType = {
	id: string;
	attributeName: string | JSX.Element;
	value: string | JSX.Element | JSX.Element[];
	key: string;
};

type Values = {
	[key: string]: {
		attributeId: string;
		attributeName: string;
		value?: any;
	};
};

type RestrictionItem = Record<string, string[]>;

type RestrictionTable = {
	id: string;
	items: RestrictionItem[];
};

export const useEditCatalogRecord = () => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { recordId, catalogGroupId } = useParams();

	const { addItemToCache, findConversion } = useMeasurementUnitConversionCache();

	const [ form ] = Form.useForm();

	const [ recordsList, setRecordsList ] = useState<RecordType[]>([]);
	const [ restrictions, setRestrictions ] = useState<RestrictionTable[]>(null);

	const attributeDeclarationSelectedMeasurementUnit = useRef<Map<string, string>>(new Map());

	const {
		data: record,
		error: recordError,
		isFetching: isRecordsLoading,
	} = useGetCatalogItemQuery({
		id: recordId,
	});

	const {
		data: attributeDeclarationsList,
		error: attributeDeclarationsListError,
		isFetching: isDeclarationsLoading,
	} = useGetCatalogAttributeDeclarationsQuery({
		catalogId: catalogGroupId,
	});

	const { data: catalogRestrictionTablesDeclarations } = useHandleQueryError(
		useGetCatalogRestrictionTableDeclarationsQuery({
			catalogId: catalogGroupId,
		}),
		(l) => l.catalogs.restrictions.declarationsTableErr,
	);

	const [ fetchRestrictionTableItems, { isFetching: isRestrictionTableItemsLoading } ] =
		useLazyGetRestrictionTableItemsQuery();
	const [ fetchUpdateItem ] = useUpdateCatalogItemMutation();

	const [ fetchConvert ] = useLazyConvertQuery();

	const status = Form.useWatch('status', form);
	useEffect(() => {
		updateItem().then();
	}, [ status ]);

	const getRestrictionValuesForAttribute = (
		attributeId: string,
		dataValues: Values,
	): DefaultOptionType[] => {
		if (isRestrictionTableItemsLoading || !restrictions) {
			return [];
		}

		const restrictionDeclarations = catalogRestrictionTablesDeclarations.filter((x) =>
			x.restrictionTable.attributeIds.includes(attributeId),
		);

		if (restrictionDeclarations.length === 0) {
			return [];
		}

		return Array.from(
			restrictionDeclarations
				.map((restrictionDeclaration) => {
					const tableId = restrictionDeclaration.restrictionTable.id;
					const restrictionTableItems: Record<string, string[]>[] = restrictions.find(
						(x) => x.id === tableId,
					)?.items;

					return restrictionTableItems.reduce((acc, item) => {
						const isAllAttributesSatisfy = Object.entries(item).every(
							([ attrId, restrictionValues ]) => {
								if (attrId == attributeId) {
									return true;
								}

								const currentValue = Object.values(dataValues).find(
									(x) => x.attributeId == attrId,
								)?.value;

								return (
									currentValue == null ||
									currentValue === '' ||
									restrictionValues.includes(currentValue)
								);
							},
						);

						if (isAllAttributesSatisfy && attributeId in item) {
							acc.push(...item[attributeId]);
						}

						return acc;
					}, []);
				})
				.reduce((acc, item) => {
					//	ищем пересечения значений с учетом всех ограничительных таблиц
					if (acc.size == 0) {
						item.forEach((x) => acc.add(x));
					} else {
						acc.forEach((value) => {
							if (!item.find((x) => x === value)) {
								acc.delete(value);
							}
						});
					}
					return acc;
				}, new Set<string>()),
		).map((x) => ({
			label: x || t((l) => l.common.defaultNames.emptyValue),
			value: x || '',
		}));
	};

	const convertValue = async (
		toMeasurementId: string,
		fromMeasurementId: string,
		value: number,
	) => {
		if (!fromMeasurementId) {
			return value;
		}

		try {
			return await fetchConvert({
				fromMeasurementId: fromMeasurementId,
				toMeasurementId: toMeasurementId,
				value: value,
			}).unwrap();
		} catch (error) {
			errorHelper(
				t((l) => l.catalogs.records.converterErr),
				error,
				notification,
			);

			throw error;
		}
	};

	const deTouchFields = () => {
		const fields = Object.entries(form.getFieldsValue()).map(([ name, value ]) => ({
			name,
			value,
			touched: false,
			errors: [],
		}));

		form.setFields(fields);
	};

	const updateItem = async () => {
		try {
			if (!form.isFieldsTouched()) {
				return;
			}

			try {
				await form.validateFields();
			} catch (error) {
				console.error(error);
				return;
			}

			const values = form.getFieldsValue();
			deTouchFields();

			const convertedValues = Object.fromEntries(
				await Promise.all(
					Object.entries(form.getFieldsValue())
						.filter(([ key ]) => key !== 'status')
						.map(async ([ declarationId, value ]) => {
							const { attribute, restrictions } = attributeDeclarationsList.find(
								(x) => x.id === declarationId,
							);
							const attributeType = attribute.type;

							const defaultMeasurementId = restrictions?.measurementId?.toString();
							const selectedMeasurementUnit = attributeDeclarationSelectedMeasurementUnit.current.get(declarationId);

							let actualValue = value;

							if (selectedMeasurementUnit &&
								defaultMeasurementId &&
								selectedMeasurementUnit != defaultMeasurementId) {
								const convertedValue = await convertValue(
									defaultMeasurementId,
									selectedMeasurementUnit,
									+value,
								);

								addItemToCache(
									declarationId,
									convertedValue,
									defaultMeasurementId,
									value,
									selectedMeasurementUnit);

								actualValue = convertedValue;
							}

							return [
								declarationId,
								mapValueForDto(attributeType, actualValue),
							] as [ string, any ];
						}),
				),
			);

			await fetchUpdateItem({
				catalogId: catalogGroupId,
				id: recordId,
				updateCatalogItemRequest: {
					status: values.status.toUpperCase(),
					values: convertedValues,
				},
			}).unwrap();

			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
			dispatch(mdmgApi.util.invalidateTags([ 'CatalogItems' ]));
		} catch (err) {
			errorHelper('Ошибка при редактировании записи', err, notification);
		}
	};

	useEffect(() => {
		if (record && attributeDeclarationsList) {
			const { values } = record;

			form.setFieldsValue({
				...Object.fromEntries(
					attributeDeclarationsList.map(({ id, attribute, restrictions }) => {
						const { type, list } = attribute;

						let value = record.values[id]?.value as never as string | number | null | undefined;

						const defaultMeasurementId = restrictions?.measurementId?.toString();
						if (defaultMeasurementId && value) {
							const selectedMeasurementUnit = attributeDeclarationSelectedMeasurementUnit.current.get(id);
							if (defaultMeasurementId != selectedMeasurementUnit) {
								const actualValue = findConversion(id, value, defaultMeasurementId, selectedMeasurementUnit);
								if (actualValue) {
									value = actualValue;
								} else {
									attributeDeclarationSelectedMeasurementUnit.current.set(id, defaultMeasurementId);
								}
							}
						}

						return [ id, mapValueForForm(type, list, value) ];
					}),
				),
				status: record.status.toLowerCase(),
			});

			const flattenedRecords: RecordType[] = [
				{
					attributeName: 'Статус позиции',
					id: 'status',
					key: 'status',
					value: (
						<Form.Item name="status">
							<EditRecordStatus/>
						</Form.Item>
					),
				},
				...attributeDeclarationsList.map((declaration) => {
					const { id, attribute, restrictions } = declaration;
					const relationOptions = getRestrictionValuesForAttribute(attribute.id, values);

					const measurementId = attributeDeclarationSelectedMeasurementUnit.current.get(id)
						?? restrictions?.measurementId?.toString();
					return {
						id: id,
						key: id,
						attributeName: (
							<Flex gap={8} style={{ width: 300 }}>
								<div>
									{attribute.displayName}
								</div>

								{(attribute.type === 'FLOAT' && measurementId) && (
									<UnitConverter
										measurementGroupId={attribute.measurementGroupId}
										value={{
											value: Number(form.getFieldValue(id)),
											measurementId: measurementId,
										}}
										onChange={({ value, measurementId }, {
											value: oldValue,
											measurementId: oldMeasurementId,
										}) => {
											form.setFieldValue(id, value);
											attributeDeclarationSelectedMeasurementUnit.current.set(id, measurementId);
											addItemToCache(id, oldValue, oldMeasurementId, value, measurementId);
										}}
									/>
								)}
							</Flex>
						),
						value: CellInputParser(id, attribute, {
							attributeDeclaration: declaration,
							callback: updateItem,
							selectOptions: relationOptions,
						}),
					};
				}),
			];

			setRecordsList(flattenedRecords);
		}
	}, [ attributeDeclarationsList, record, restrictions ]);

	useEffect(() => {
		if (!catalogRestrictionTablesDeclarations) {
			return;
		}

		Promise.all(
			catalogRestrictionTablesDeclarations.map(async (x) => {
				const items = await fetchRestrictionTableItems({
					restrictionTableId: x.restrictionTable.id,
				}).unwrap();

				return {
					id: x.restrictionTable.id,
					items: items.map<RestrictionItem>((x) =>
						Object.fromEntries(
							Object.entries(x.values).map(([ key, values ]) => [
								key,
								(values as Array<any>).filter(
									(value) => value != null && value != '',
								),
							]),
						),
					),
				} as RestrictionTable;
			}),
		).then((res) => setRestrictions(res));
	}, [ catalogRestrictionTablesDeclarations ]);

	return {
		form,
		recordsList,
		loading: isRecordsLoading || isDeclarationsLoading || isRestrictionTableItemsLoading,
		error: recordError || attributeDeclarationsListError,
	};
};
