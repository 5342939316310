import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { AddNewNestedGroup, DropdownLink } from 'shared/ui';
import { useCreateAttributeGroup } from './addAttributeGroup.model';

interface IAddAttributeGroupProps {
	groupId?: string;
}

const AddAttributeGroupUi: React.FC<IAddAttributeGroupProps> = ({ groupId }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { loading, createNewGroup, error } = useCreateAttributeGroup();

	const [form] = Form.useForm();
	const nameValue = Form.useWatch('displayName', form);

	const onFinish = async ({ displayName, desc }) => {
		await createNewGroup(displayName, desc, groupId && groupId);
		if (!error) {
			form.resetFields();
			setIsModalOpen(false);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			{groupId ? (
				<DropdownLink
					icon={<AddNewNestedGroup size={16} />}
					title="Добавить вложенную группу"
					onClick={showModal}
				/>
			) : (
				<Button
					icon={<RiAddCircleLine />}
					style={{ maxWidth: 'fit-content' }}
					onClick={showModal}
				>
					Добавить группу атрибутов
				</Button>
			)}

			<Modal
				title="Создание группы атрибутов"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item name="displayName" label="Наименование группы атрибутов" required>
						<Input />
					</Form.Item>
					<Form.Item name="desc" label="Описание">
						<Input />
					</Form.Item>

					<Form.Item>
						<Flex gap={4} justify="flex-end">
							<Button type="text" onClick={handleCancel}>
								Отменить
							</Button>
							<Button
								htmlType="submit"
								type="primary"
								loading={loading}
								disabled={!nameValue}
							>
								Создать
							</Button>
						</Flex>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export const AddAttributeGroup = React.memo(AddAttributeGroupUi);
