import { Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ConstraintTables } from 'widgets/Metadata/ConstraintTables';
import { ContextNamesWidget } from 'widgets/Metadata/ContextNamesWidget';
import { MeasureWidget } from 'widgets/Metadata/Measures';
import { MetadataAttributesListWidget } from 'widgets/Metadata/MetadataAttributesListWidget';
import { MetadataGroupTreeWidget } from 'widgets/Metadata/MetadataGroupTreeWidget';
import { Transactions } from 'widgets/Transactions';
import { RealtimeBaseEvent, TaskType, useRealtimeEvent } from 'entities/events';
import { deleteMetaAttributesGroup } from 'entities/metadata/attributesGroups/attributesGroups.store';
import { deleteMetaAttributeItems } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { mdmgApi, useGetAttributeGroup1Query } from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { useAppDispatch, useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { setSubMenuCollapsed } from 'shared/ui';
import { SubSider } from 'shared/ui/components/SubSider';

enum MetadataPageTub {
	Attribites = 'attributes',
	Measures = 'measures',
	ConstraintTables = 'constraintTables',
	Compatibilities = 'compatibilities',
}

export const MetadataPage: React.FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTypedTranslation();

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { metaAttributeGroupId: attributeGroupId } = useParams();

	const [activeKey, setActiveKey] = useState<string>('');
	const [title, setTitle] = useState<string>(t((l) => l.breadcrumbs.metadata));

	const items: TabsProps['items'] = useMemo(
		() => [
			{
				key: MetadataPageTub.Attribites,
				label: t((l) => l.attributes.attributesTabTitle),
				children: (
					<>
						<SubSider>
							<MetadataGroupTreeWidget />
						</SubSider>
						<MetadataAttributesListWidget />
					</>
				),
			},
			{
				key: MetadataPageTub.Measures,
				label: t((l) => l.measures.measuresTabTitle),
				children: <MeasureWidget />,
			},
			{
				key: MetadataPageTub.ConstraintTables,
				label: t((l) => l.constraintTables.constraintTablesTabTitle),
				children: <ConstraintTables />,
			},
			{
				key: MetadataPageTub.Compatibilities,
				label: t((l) => l.compatibilities.tabTitle),
				children: <ContextNamesWidget />,
			},
		],
		[t]
	);

	const { data: currAttrGroup } = useHandleQueryError(
		useGetAttributeGroup1Query(
			{
				id: attributeGroupId,
			},
			{
				skip: !attributeGroupId,
			}
		),
		(l) => l.attributes.attributeGroupErr
	);

	const onChange = (key: string) => {
		setActiveKey(key);
		navigate(`/${routes.metadata.main}/${key}`);
	};

	useEffect(() => {
		dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	useEffect(() => {
		if (pathname.includes(MetadataPageTub.Attribites)) {
			setActiveKey(MetadataPageTub.Attribites);
			if (currAttrGroup && attributeGroupId) {
				setTitle(currAttrGroup.displayName);
			} else {
				setTitle(t((l) => l.breadcrumbs.metadata));
			}
		} else if (pathname.includes(MetadataPageTub.Measures)) {
			setActiveKey(MetadataPageTub.Measures);
			setTitle(t((l) => l.breadcrumbs.metadata));
		} else if (pathname.includes(MetadataPageTub.ConstraintTables)) {
			setActiveKey(MetadataPageTub.ConstraintTables);
			setTitle(t((l) => l.breadcrumbs.metadata));
		} else if (pathname.includes(MetadataPageTub.Compatibilities)) {
			setActiveKey(MetadataPageTub.Compatibilities);
			setTitle(t((l) => l.breadcrumbs.metadata));
		} else {
			setActiveKey(MetadataPageTub.Attribites);
			setTitle(t((l) => l.breadcrumbs.metadata));
		}
	}, [pathname, currAttrGroup, t]);

	useRealtimeEvent(
		TaskType.DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK,
		({ payload }: RealtimeBaseEvent) => {
			const deletedAttributeGroupIds = payload.entityIds as string[];

			dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));

			if (deletedAttributeGroupIds.includes(attributeGroupId)) {
				dispatch(deleteMetaAttributesGroup(attributeGroupId));
				navigate(`/${routes.metadata.main}`);
			}
		}
	);

	useRealtimeEvent(
		TaskType.DELETE_ATTRIBUTES_BACKGROUND_TASK,
		({ payload }: RealtimeBaseEvent) => {
			const deletedAttributeIds = payload.entityIds as string[];

			dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
			dispatch(mdmgApi.util.invalidateTags(['Attributes']));
			dispatch(deleteMetaAttributeItems(deletedAttributeIds));
		}
	);

	return (
		<>
			<Flex vertical align="flex-end">
				<Flex justify="space-between" style={{ width: '100%' }}>
					<Typography.Title level={1}>{title}</Typography.Title>
					<Transactions />
				</Flex>

				<Tabs
					items={items}
					style={{ paddingBottom: 65, width: '100%' }}
					destroyInactiveTabPane
					activeKey={activeKey}
					onChange={onChange}
				/>
			</Flex>
		</>
	);
};
