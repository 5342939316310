import { Badge, Flex } from 'antd';
import React from 'react';
import { FileValue } from '../types';
import { FilePreview, FoldedListItems } from '../ui';
import { ItemValuesType } from './types';

export enum CellTypesEnum {
	STRING = 'STRING',
	FLOAT = 'FLOAT',
	INT = 'INTEGER',
	BOOL = 'BOOL',
	BOOLEAN = 'BOOLEAN',
	TEXT = 'TEXT',
	DATETIME = 'DATE_TIME',
	COLOR = 'COLOR',
	FILE = 'FILE',
	FORMULA = 'FORMULA',
	RELATION = 'RELATION',
}

export interface IFormula {
	rule: string;
	wizardEntries: { [key: string]: unknown }[];
	formulaTests: IFormulaTests;
}

export interface IFormulaTests {
	testItems: number[];
	preferredNodeIds: number[];
	rightItems: number[];
	selectedPairs: ILongPair[];
}

export interface ILongPair {
	first: number;
	second: number;
}

export const CellValueParser = (
	value: ItemValuesType,
	type: CellTypesEnum,
	list?: boolean,
): string | JSX.Element => {
	// Нельзя проверять !value, потому что value может быть false для логического типа данных
	if (value === undefined || value === null) {
		return '';
	}

	if (list) {
		const arr = [ value ].flat();
		if (arr.length == 0) {
			return '';
		}

		const items = [ value ].flat()
			.map(x => CellValueParser(x, type, false));

		return <FoldedListItems items={items}/>;
	}

	switch (type) {
		case CellTypesEnum.STRING:
		case CellTypesEnum.TEXT:
			return <div dangerouslySetInnerHTML={{ __html: String(value) }} />;

		case CellTypesEnum.INT:
			return <div dangerouslySetInnerHTML={{ __html: String(value) }} />;

		case CellTypesEnum.BOOL:
		case CellTypesEnum.BOOLEAN:
			return value ? 'Да' : 'Нет';

		case CellTypesEnum.DATETIME:
			return new Date(value as string).toLocaleDateString('ru-RU');

		case CellTypesEnum.COLOR:
			return (
				<Flex gap={8}>
					<Badge
						styles={{ indicator: { width: 15, height: 15 } }}
						color={value as string}
					/>
					{value as string}
				</Flex>
			);

		case CellTypesEnum.FILE:
			return <FilePreview file={value as FileValue}/>;

		case CellTypesEnum.FORMULA:
			return String(value);

		default:
			return String(value);
	}
};

export enum AttributeRestrictionEnum {
	mask = 'Маска ввода',
	defaultValue = 'Значение по умолчанию',
	accuracy = 'Точность',
	required = 'Обязательное',
	formulaId = 'Формула',
	scopeIds = 'Область',
	measurementId = 'Единица измерения',
	maxLength = 'Максимальная длина',
	unique = 'Уникальное',
	removalChildren = 'Удалять потомков при удалении родителя',
}
