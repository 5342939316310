import { RiCheckLine } from '@remixicon/react';
import { App, Flex, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { setCurrentClassifier } from 'entities/classifiers/classifiersGroups/classifier.store';
import { RealtimeBaseEvent, TaskType, useAsyncOperation, useRealtimeEvent } from 'entities/events';
import {
	ClassifierResponse,
	mdmgApi,
	useUpdateClassifierAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/newCreateApiFile';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import { SelectAttribute } from './components/SelectAttribute';

interface IEditClassifierMainInfo {
	classifier: ClassifierResponse;
	isDropdownLink?: boolean;
	displayNameAttributeDeclarationId?: string;
}

type PartialExceptId<T extends { id: string; displayName: string }> = Partial<Omit<T, 'id'>> & {
	id: T['id'];
	displayName: T['displayName'];
};

const EditClassifierMainInfoUi: React.FC<IEditClassifierMainInfo> = ({
	classifier,
	isDropdownLink,
	displayNameAttributeDeclarationId,
}) => {
	const { t } = useTypedTranslation();
	const [form] = Form.useForm();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { execute: editClassifier } = useAsyncOperation(
		useUpdateClassifierAsyncMutation,
		TaskType.UPDATE_CLASSIFIER_BACKGROUND_TASK
	);

	useRealtimeEvent(
		TaskType.UPDATE_CLASSIFIER_BACKGROUND_TASK,
		(e: RealtimeBaseEvent) => {
			if ((e.payload.entityIds as string[]).includes(classifier?.id)) {
				dispatch(mdmgApi.util.invalidateTags(['Classifier']));
				dispatch(transactionServiceApi.util.invalidateTags(['Transaction']));
			}
		},
		[classifier]
	);

	const handleUpdate = async (arg: PartialExceptId<ClassifierResponse>) => {
		try {
			await editClassifier({
				id: classifier.id,
				updateClassifierRequest: {
					...arg,
					linkClassifierAttributes: arg.linkClassifierAttributes as unknown as string[],
				},
			});
		} catch (error) {
			dispatch(setCurrentClassifier(classifier));
			errorHelper(
				t((l) => l.classifier.error.classifierEditing),
				error,
				notification
			);
		}
	};

	const handleBlur = () => {
		const formVals = form.getFieldsValue();
		handleUpdate(formVals);
	};

	const dropdownLinkHandler = useCallback(async () => {
		const arg = {
			displayName: classifier.displayName,
			displayNameAttributeDeclarationId,
		};
		await handleUpdate(arg as ClassifierResponse);
	}, [displayNameAttributeDeclarationId]);

	useEffect(() => {
		if (classifier) form.setFieldsValue(classifier);
	}, [classifier]);

	return isDropdownLink ? (
		<DropdownLink
			title={t((l) => l.classifier.action.setAsRecordName)}
			icon={<RiCheckLine size={16} />}
			onClick={dropdownLinkHandler}
		/>
	) : (
		<Flex vertical gap={12}>
			<Flex vertical gap={12}>
				<Typography.Title level={2}>
					{t((l) => l.common.defaultNames.mainInfo)}
				</Typography.Title>
				<Form layout="vertical" form={form}>
					<Form.Item
						label={t((l) => l.common.defaultNames.name)}
						name="displayName"
						required
					>
						<Input onBlur={handleBlur} />
					</Form.Item>
					<Form.Item
						label={t((l) => l.common.defaultNames.description)}
						name="description"
					>
						<Input onBlur={handleBlur} />
					</Form.Item>
				</Form>
			</Flex>
			<Flex vertical gap={12}>
				<Typography.Title level={2}>
					{t((l) => l.classifier.common.attributesSetting)}
				</Typography.Title>
				<Form layout="vertical">
					<Form.Item label={t((l) => l.classifier.action.setAttributesForClassification)}>
						<SelectAttribute classifier={classifier} />
					</Form.Item>
				</Form>
			</Flex>
		</Flex>
	);
};

export const EditClassifierMainInfo = React.memo(EditClassifierMainInfoUi);
