import { RiAddCircleLine, RiDeleteBin6Line, RiEdit2Line } from '@remixicon/react';
import { Alert, Flex, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { GetEntityChangesApiArg, useGetEntityChangesQuery } from 'shared/api/generatedApi/mdmgApi';
import { Filters, DateRangeFilter, Filter } from 'shared/components/Filters';
import { usePagination } from 'shared/hooks';
import { colors } from 'shared/styles';
import { WorkTable } from 'shared/ui';

interface IEntityChangesTableProps {
	entityId: string;
	propertyNamesMapper: (propertyName: string) => string;
	propertyValuesMapper: (propertyName: string, propertyValue: any) => any;
}

interface ITableItem {
	id: string;
	propertyName: string;
	value: any;
	userName: string;
	transactionName: string;
	date: string;
}

const eventTypeIcons = {
	'CREATE': <RiAddCircleLine size={18} color={colors.success}/>,
	'UPDATE': <RiEdit2Line size={18} color={colors.warning}/>,
	'DELETE': <RiDeleteBin6Line size={18} color={colors.error}/>,
};

const columns = [
	{
		title: 'Наименование атрибута',
		key: 'propertyName',
		dataIndex: 'propertyName',
	},
	{
		title: 'Значение атрибута',
		key: 'value',
		dataIndex: 'value',
	},
	{
		title: 'Пользователь',
		key: 'userName',
		dataIndex: 'userName',
	},
	{
		title: 'Транзакция',
		key: 'transactionName',
		dataIndex: 'transactionName',
	},
	{
		title: 'Дата и время',
		key: 'date',
		dataIndex: 'date',
	},
];

const filters: Filter[] = [
	new DateRangeFilter('date', 'Дата и время')
];

const EntityChangesTable = ({ entityId, propertyNamesMapper, propertyValuesMapper }: IEntityChangesTableProps) => {

	const {
		pagination,
		asTablePagination,
	} = usePagination();

	const [ dataSource, setDataSource ] = useState<ITableItem[] | null>(null);
	const [ filterValues, setFilterValues ] = useState<Pick<GetEntityChangesApiArg, 'fromDate' | 'toDate'>>();

	const { data, isLoading, isFetching, error } = useGetEntityChangesQuery({
		entityId: entityId,
		page: pagination?.current,
		limit: pagination?.pageSize,
		...filterValues,
	}, {
		skip: !pagination,
	});

	useEffect(() => {
		if (!data?.data) {
			return;
		}

		setDataSource(data.data
			.map((item) => ({
				id: item.entityId,
				propertyName: propertyNamesMapper(item.propertyName),
				value: (
					<Flex gap={4} align="center">
						{!!eventTypeIcons[item.eventType] && (
							eventTypeIcons[item.eventType]
						)}
						<div>
							{propertyValuesMapper(item.propertyName, item.value)}
						</div>
					</Flex>
				),
				userName: item.userName,
				transactionName: item.transactionDisplayName,
				date: dayjs(item.createdAt).format('DD.MM.YYYY HH:mm:ss'),
			})));
	}, [ data?.data ]);

	const onFilterChange = useCallback((filters: any) => {
		setFilterValues({
			...filters.date?.length === 2
				? {
					fromDate: dayjs(filters.date?.[0]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
					toDate: dayjs(filters.date?.[1]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
				} : {},
		});
	}, []);

	return (
		<>
			<Filters filters={filters}
					 onChange={onFilterChange}
			/>

			{isLoading && (
				<Spin spinning={true}/>
			)}

			{error && (
				<Alert type="error"
					   message={JSON.stringify(error)}
				/>
			)}

			{!isLoading && !error && (
				<WorkTable columns={columns}
						   dataSource={dataSource}
						   loading={isFetching}
						   {...asTablePagination(data?.meta?.total)}
				/>
			)}
		</>
	);
};

export {
	EntityChangesTable,
};