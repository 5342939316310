import { notification } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Measure, setCurrentMeasureGroup } from 'entities/metadata/measures';
import {
	MeasurementResponse,
	useLazyGetMeasurementGroupQuery,
	useLazyGetMeasurementsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';

export const useSearchMeasureUnit = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();
	const [getGroup] = useLazyGetMeasurementGroupQuery();
	const [getMeasurements] = useLazyGetMeasurementsQuery();

	const searchHandler = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const { metaMeasureGroupId } = useParams();

	const { currentMeasureGroup } = useAppSelector((state) => state.entities.metadata.measures);

	const dispatch = useAppDispatch();

	const fetchSearch = (searchValue: string) => {
		getSearchResult({
			entityType: 'measurement',
			textRequest: searchValue,
			parentId: metaMeasureGroupId,
		})
			.unwrap()
			.then((res) => {
				const group = {
					...currentMeasureGroup,
					childrenMeasures: res as MeasurementResponse[],
				};
				dispatch(setCurrentMeasureGroup(group));
			})
			.catch((err) => {
				errorHelper('Ошибка при получении списка записей!', err, notification);
			});
	};

	const getData = async (searchValue: string) => {
		let group: Measure = null;

		await getGroup({ id: metaMeasureGroupId })
			.unwrap()
			.then((res) => {
				group = { ...res };
				fetchSearch(searchValue);
			})
			.catch((err) => errorHelper('Ошибка при получении группы ЕИ!', err, notification));

		await getMeasurements({ measurementGroupId: metaMeasureGroupId })
			.unwrap()
			.then((res) => {
				group = { ...group, childrenMeasures: res };
				dispatch(setCurrentMeasureGroup(group));
			})
			.catch((err) => {
				errorHelper('Ошибка при получении ЕИ!', err, notification);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			if (!currentMeasureGroup) {
				getData(searchValue);
			} else {
				fetchSearch(searchValue);
			}
		}
	}, [searchParams.get('searchValue')]);

	return {
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		isLoading: isSearchItemsLoading,
	};
};
