import { RiEditLine } from '@remixicon/react';
import { Alert, Checkbox, Spin } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import {
	ClassifierItemResponse,
	useGetClassifierAttributeDeclarationsQuery,
	useGetClassifierItemQuery,
	useGetClassifierItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { useTypedTranslation, useWorkTable } from 'shared/hooks';
import { Chip, ChipStatus, DropdownLink, Placeholder, WorkTable } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { DeleteClassifierRecord } from '../ClassifierRecords/DeleteClassifierRecord';

type ItemValuesType = string | number | boolean;

type IClassifierRecord = {
	id: string;
	status: JSX.Element;
	menu: JSX.Element;
	key: string;
};

type IClassifierRecords = Array<IClassifierRecord>;

interface IRecordValue {
	[key: string]: {
		attributeName: string;
		value: ItemValuesType;
	};
}

interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

interface ClassifierItemsTableProps {
	classifierId: string;
	classifierItemId?: string | null;
	searchSubstring?: string | null;
	searchDetails?: any | null;
	selectable?: boolean;
	multipleSelect?: boolean;
	onClick?: (id: string) => void;
	onSelect?: (ids: string[]) => void;
}

const ClassifierItemsTableComponent = forwardRef((
	{
		classifierId,
		classifierItemId,
		searchSubstring,
		searchDetails,
		selectable,
		multipleSelect,
		onClick,
		onSelect,
	}: ClassifierItemsTableProps,
	ref) => {
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const tableState = useWorkTable();

	const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);

	useImperativeHandle(ref, () => ({
		setSelection: (ids: string[]) => setSelectedRowKeys(ids),
		clearSelection: () => setSelectedRowKeys([]),
	}));

	const {
		data: classifierAttributeDeclarations,
		isFetching: isFetchingDeclarations,
		isLoading: isLoadingDeclarations,
		error: errorDeclarations,
	} = useGetClassifierAttributeDeclarationsQuery({
		classifierId,
	});

	const {
		data: itemsList,
		isFetching: isFetchingClassifierItems,
		isLoading: isLoadingClassifierItems,
		error: errorClassifierItems,
	} = useGetClassifierItemsQuery({
		classifierId: classifierId,
		parentItemId: classifierItemId,
		...searchSubstring && { searchSubstring },
		...searchDetails && { searchDetails },
		...tableState.toDto(),
	}, {
		skip: !tableState.isReady,
	});

	const {
		data: selectedClassifier,
		isFetching: isFetchingClassifierItem,
		isLoading: isLoadingClassifierItem,
		error: errorClassifierItem,
	} = useGetClassifierItemQuery({
		id: classifierItemId,
	}, {
		skip: !classifierItemId,
	});

	const classifiersList = itemsList?.data;

	const convertValues = (values: IRecordValue): IMappedRecordValue =>
		Object.fromEntries(
			classifierAttributeDeclarations
				?.map(({ id, attribute }) => [
					id,
					CellValueParser(values[id]?.value, attribute.type as CellTypesEnum),
				]) ?? [],
		);

	const mapRecordsDtoToTable = (recordsDto: ClassifierItemResponse[] | null): IClassifierRecords => {
		if (!recordsDto) {
			return null;
		}

		return recordsDto.map((item) => ({
			id: item.id,
			key: item.id,
			status: (
				<Chip status={item.status.toLowerCase() as ChipStatus}>
					{chipTitle(item.status)}
				</Chip>
			),
			menu: (
				<ItemActions
					items={[
						{
							key: `${item?.id}-${randomString(4)}`,
							label: <DeleteClassifierRecord recordId={item.id}/>,
						},
						{
							key: `${item?.id}-${randomString(4)}`,
							label: (
								<DropdownLink
									icon={<RiEditLine size={16}/>}
									title="Редактировать"
									onClick={() => {
										navigate(
											`/${routes.classifiers.main}/${classifierId}/${routes.classifiers.records}/${item.id}/${routes.classifiers.edit}`,
										);
									}}
								/>
							),
						},
					]}
				/>
			),
			...(item.values && convertValues(item.values as unknown as IRecordValue)),
		}));
	};

	const dataSource = useMemo(() => {
		if (errorClassifierItems || errorClassifierItem) {
			return [];
		}

		if (classifiersList && (classifierItemId == null || selectedClassifier)) {
			return mapRecordsDtoToTable([
				...(classifierItemId && selectedClassifier ? [ selectedClassifier ] : []),
				...classifiersList,
			]);
		}
		return null;
	}, [ selectedClassifier, classifiersList, errorClassifierItems, errorClassifierItem ]);

	const columns = useMemo(() => {
		if (classifierAttributeDeclarations) {
			return [
				{
					title: 'Статус',
					dataIndex: 'status',
					key: 'status',
					sorter: true,
				},
				...classifierAttributeDeclarations.map((decl) => ({
					key: decl.id,
					dataIndex: decl.id,
					title: decl.attribute.displayName,
					sorter: true,
				})),
			];
		}
		return null;
	}, [ classifierAttributeDeclarations ]);

	const Empty = useMemo(() => {
		if (errorDeclarations || errorClassifierItems || errorClassifierItem) {
			return (
				<Alert type="error"
					   message={JSON.stringify(errorDeclarations || errorClassifierItems || errorClassifierItem)}
				/>
			);
		}

		return <Placeholder
			title={t(l => l.common.search.nothingFound)}
			subTitle={t(l => l.common.search.checkRequest)}
			isSearchIcon={true}
		/>;
	}, [ errorDeclarations, errorClassifierItems, errorClassifierItem ]);

	const rowSelection: TableRowSelection = useMemo(() => ({
		type: multipleSelect ? 'checkbox' : 'radio',
		selectedRowKeys: selectedRowKeys,
		onChange: setSelectedRowKeys,
		renderCell: (checked, record) => {
			return (
				<div onClick={e => e.stopPropagation()}>
					<Checkbox checked={checked}
							  onChange={() => {
								  if (multipleSelect) {
									  setSelectedRowKeys(prev => checked
										  ? prev.filter((key) => key !== record.key)
										  : [ ...prev, record.key ]);
								  } else {
									  setSelectedRowKeys(checked ? [] : [ record.key ]);
								  }
							  }}/>
				</div>
			);
		},
	}), [ multipleSelect, selectedRowKeys ]);

	useEffect(() => {
		onSelect?.(selectedRowKeys);
	}, [ selectedRowKeys ]);

	if (isLoadingDeclarations || isLoadingClassifierItems || isLoadingClassifierItem) {
		return (
			<Spin size={'large'}/>
		);
	}

	const isFetching = isFetchingDeclarations || isFetchingClassifierItems || isFetchingClassifierItem;

	return (
		<WorkTable loading={isFetching}
				   columns={columns}
				   dataSource={dataSource}
				   rowSelection={selectable ? rowSelection : null}
				   onRow={(r) => ({
					   onClick: () => onClick?.(r.id),
				   })}
				   scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
				   locale={{ emptyText: Empty }}
				   {...tableState.toWorkTableProps(itemsList?.meta?.total)}
		/>
	);
});

const ClassifierItemsTable = memo(ClassifierItemsTableComponent);

export {
	ClassifierItemsTable,
};
